import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import styled from 'styled-components';
import ActivityIndicator from '../../components/ActivityIndicator';

const TableNavigation = ({
  isBusy,
  hasSelection,
  messageAction,
  downloadPDFs,
  downloadXMLs,
  loadingPDF,
  loadingXML,
}) => {
  return (
    <Grid columns={1} verticalAlign="middle" padded="vertically">
      <GridRow stretched>
        <GridColumn textAlign="right">
          {isBusy && (
            <ActivityIndicator title={messageAction || 'Loading...'} />
          )}
          <div style={{ marginTop: 24 }}>
            <Button
              primary
              compact
              disabled={hasSelection.length > 0 ? false : true}
              onClick={downloadPDFs}
              loading={loadingPDF}
            >
              Descargar PDFs
            </Button>
            <Button
              primary
              compact
              disabled={hasSelection.length > 0 ? false : true}
              onClick={downloadXMLs}
              loading={loadingXML}
            >
              Descargar XMLs
            </Button>
          </div>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

TableNavigation.propTypes = {
  isBusy: PropTypes.bool,
  hasSelection: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  downloadPDFs: PropTypes.func,
  downloadXMLs: PropTypes.func,
  messageAction: PropTypes.string,
};

const Instructions = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;

export default TableNavigation;
