import React, { forwardRef } from 'react';
import { Form, Segment } from 'semantic-ui-react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import FormGroupLocations from './FormGroupLocations';

const FormHeader = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    /* and other goodies */
  } = formik;
  return (
    <>
      <Segment>
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="Nombre"
            label="Nombre"
            name="Nombre"
            value={values.Nombre}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.Nombre && { content: errors.Nombre }}
          />
          <Form.Input
            placeholder="RFC"
            label="RFC"
            name="RFC"
            value={values.RFC}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.RFC && { content: errors.RFC }}
          />
          <Form.Input
            placeholder="Residencia Fiscal"
            label="Residencia Fiscal"
            name="ResidenciaFiscal"
            value={values.ResidenciaFiscal}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.ResidenciaFiscal && { content: errors.ResidenciaFiscal }
            }
          />
          <Form.Input
            placeholder="Número Registro Tributario"
            label="Número Registro Tributario"
            name="NumRegTributario"
            value={values.NumRegTributario}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.NumRegTributario && { content: errors.NumRegTributario }
            }
          />
        </Form.Group>
        <FormGroupLocations
          formik={formik}
          values={{
            Pais: values.Pais,
            Estado: values.Estado,
            Municipio: values.Municipio,
            Localidad: values.Localidad,
            CodigoPostal: values.CodigoPostal,
            Colonia: values.Colonia,
            Calle: values.Calle,
            NumExterior: values.NumExterior,
            NumInterior: values.NumInterior,
            Referencia: values.Referencia,
          }}
          names={{
            Pais: 'Pais',
            Estado: 'Estado',
            Municipio: 'Municipio',
            Localidad: 'Localidad',
            CodigoPostal: 'CodigoPostal',
            Colonia: 'Colonia',
            Calle: 'Calle',
            NumExterior: 'NumExterior',
            NumInterior: 'NumInterior',
            Referencia: 'Referencia',
          }}
          errors={{
            Pais: errors.Pais,
            Estado: errors.Estado,
            Municipio: errors.Municipio,
            Localidad: errors.Localidad,
            CodPos: errors.CodigoPostal,
            Colonia: errors.Colonia,
            Calle: errors.Calle,
            NumExterior: errors.NumExterior,
            NumInterior: errors.NumInterior,
            Referencia: errors.Referencia,
          }}
        />
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
