import React, { useState, useEffect } from 'react';
import { Menu, Dropdown } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import Logo from '../assets/graphics/flexlogic.png';
import MenuItem from './MenuItem';
import { Link, useLocation } from 'react-router-dom';
import Avatar from 'react-avatar';
import feathers, { useAuth } from '../services/feathers';
import { getRoutes } from '../Routes';
import ModalClient from './ModalClient';
import '../styles/styles.scss';
import swal from 'sweetalert';

export default ({ currentPath, globalActions }) => {
  const { pathname } = useLocation();
  const auth = useAuth(); 
  const [isRaised, setIsRaised] = useState(false);
  const [enabledMenus, setEnabledMenus] = useState([]);
  const serviceTaxPACS = feathers.service('tax-pacs');


  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);



  useEffect(() => {
    let localEnabledMenus = [];

    // console.log('information about the auth: ', auth);

    //This metod return the name of the roles available for the user
    getRoutes(auth)
      .filter(({ allow }) => allow)
      .map((item) => {
        if (!localEnabledMenus.includes(item.category))
          localEnabledMenus.push(item.category);
      }
    );

    setEnabledMenus(localEnabledMenus);
    // console.log('categorias del menu: ', localEnabledMenus); 
    //"Logistics" "Anexo 24" "Billing MX" "Operative" "Admin" "Reports"

  }, []);



  const scrollHandler = (e) => {
    const { pageYOffset } = window;
    setIsRaised(pageYOffset >= 5 ? true : false);
  };



  const options = [
    { key: 'user', text: 'Account', icon: 'user' },
    { key: 'settings', text: 'Settings', icon: 'settings' },
    ...(auth && auth.user && auth.user.clients.length > 1
      ? [
          {
            key: 'switch',
            text: 'Switch Client',
            icon: 'exchange',
            onClick: async () => {
              // console.debug('clients', JSON.stringify(auth.user.clients, null, 2));
              const newClientId = await ModalClient(auth.user);
              if (newClientId) {
                const result = await feathers
                  .service('users')
                  .patch(auth.user._id, {
                    client: newClientId,
                  });

                if (result) {
                  let resultTaxPACS = await serviceTaxPACS.find({
                    query: {
                      Organizacion: result.client,
                    },
                  });

                  if (resultTaxPACS.data && resultTaxPACS.data.length > 0) {
                    if (resultTaxPACS.data[0].Status === 0) {
                      swal('DEMO', '¡Se timbrará en modo DEMO!', 'warning');
                      setTimeout(function () {
                        window.location.reload();
                      }, 2000);
                    } else {
                      window.location.reload();
                    }
                  }

                  return;
                }
              }
            },
          },
        ]
      : []),
    {
      key: 'sign-out',
      text: 'Sign Out',
      icon: 'sign out',
      onClick: () => {
        auth.logout();
      },
    },
  ];

  const menuCategories = [
    'Admin',
    'Logistics',
    'Reports',
    'Operative',
    'Billing MX',
    'Anexo 24',
  ];

  const getActiveHeader = (menu, current) => {
    let active = null;

    getRoutes(auth).map((route) => {
      if (route.path.toLocaleLowerCase() == current.toLocaleLowerCase()) {
        active = route.category;
      }
    });
    return String(menu).toLowerCase() == String(active).toLowerCase();
  };
  const getClientName = () =>
    (auth && auth.user && auth.user.client && auth.user.client.name) || null;

  return (
    <Container raised={isRaised}>
      <LogoContainer>
        <Link to="/">
          <img src={Logo} width="128" alt="FlexLogic" />
        </Link>
      </LogoContainer>

      <MainMenu>
        <Menu secondary>
          {menuCategories.map((element) => {
            return (
              enabledMenus.includes(element) && (
                <Dropdown
                  key={Math.random()}
                  item
                  text={element}
                  className={
                    getActiveHeader(element, currentPath) == true
                      ? 'item-header'
                      : null
                  }
                  disabled={enabledMenus.includes(element) ? false : true}
                >
                  <Dropdown.Menu>
                    {getRoutes(auth)
                      .filter(({ allow }) => allow)
                      .map(({ title, path, category }, index) => {
                        return (
                          <MenuItem
                            key={Math.random() + index}
                            title={title}
                            linkTo={currentPath === path ? pathname : path}
                            active={currentPath === path}
                            itemCategory={category}
                            menuCategory={element}
                          />
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              )
            );
          })}
        </Menu>
      </MainMenu>

      <WrapperActions>
        {/* <ClientName>{getClientName() || <>&nbsp;</>}</ClientName> */}

        <ButtonsActions>
          <GlobalActions>{globalActions}</GlobalActions>
          <UserMenu>
            <Dropdown
              trigger={
                <Avatar
                  name={
                    auth && auth.user
                      ? `${auth.user.name} ${auth.user.lastName}`
                      : 'Unknown'
                  }
                  size="32"
                  round
                  color="#4f9aa8"
                />
              }
              options={options}
              header={
                getClientName() ? (
                  <Dropdown.Header
                    icon="address card"
                    content={getClientName() || ''}
                  />
                ) : (
                  <></>
                )
              }
              pointing="top right"
              icon={null}
            />
          </UserMenu>
        </ButtonsActions>
      </WrapperActions>
    </Container>
  );
};

const Container = styled.header`
  position: fixed;
  display: flex;
  height: 84px;
  width: 100%;
  padding: 0 16px;
  background-color: lightgrey;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  background-color: #f5f5f5;
  z-index: 100;
  transition-property: box-shadow;
  transition-duration: 0.25s;

  ${({ raised }) =>
    raised &&
    css`
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
        0 2px 10px 0 rgba(0, 0, 0, 0.12);
    `}
`;

const LogoContainer = styled.div`
  margin-right: 32px;
`;

const MainMenu = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-grow: 1;
`;

const GlobalActions = styled.div``;

const UserMenu = styled.div`
  margin-left: 16px;
`;

const WrapperActions = styled.div``;

const ClientName = styled.div`
  text-align: end;
  margin-bottom: 0.3em;
  font-size: large;
`;

const ButtonsActions = styled.div`
  text-align: end;

  & > div {
    display: inline-block;
  }
`;
