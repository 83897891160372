import React, { useState, useEffect, useRef } from 'react';
import { Form, Dimmer, Loader } from 'semantic-ui-react';
import SlidingPanel from '../../../components/SLSlidingPanel';
import swal from 'sweetalert';
import { Formik } from 'formik';
import Actions from './Actions';
import FormDocument from './FormDocument';
import feathers from '../../../services/feathers';
import moment from 'moment';
import validationScheme from './validationSchema';
import initialValues from './initialValuesBlanck';
import ModalTrip from './ModalTrip';
import { useAuth } from '../../../services/feathers';
import NumeroALetras from './NumeroALetras';

const Drawer = ({ openPanel, onClose, currentDocumentId, data, setQuery }) => {
  const formikRef = useRef();
  const auth = useAuth();

  const [relate, setRelate] = useState(null);
  const [relation, setRelation] = useState(null);

  const [isValid, setIsValid] = useState(false);
  const [resetConceptos, setResetConceptos] = useState(false);
  const [isMaterialPeligroso, setIsMaterialPeligroso] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lastDocument, setLastDocument] = useState(null);
  const [userPOS, setUserPOS] = useState(null);
  const [isCartaPorte, setIsCartaPorte] = useState(
    currentDocumentId
      ? currentDocumentId && currentDocumentId.ubicaciones.length > 0
        ? true
        : false
      : true
  ); //Devuelve TRUE
  const [currentSection, setCurrentSection] = useState(
    (currentDocumentId && 'EDIT') || 'CREATE'
  );

  const servicePOSFolios = feathers.service('pos-folios');
  const serviceReceptores = feathers.service('receptores');
  const tipoMateriaService = feathers.service('catalogo-tipo-materia');
  const refForm = React.useRef(null);
  const serviceTrip = feathers.service('optima-trips');
  const serviceDocumentos = feathers.service('documentos');
  const servicePOS = feathers.service('pos');
  const serviceCodigoPostal = feathers.service('catalogo-codigo-postal');
  const serviceDocumentosConceptos = feathers.service('documentos-conceptos');
  const serviceCFDIRelacion = feathers.service('cfdi-relacion');

  const serviceDocumentosConceptosImpuestos = feathers.service(
    'documentos-conceptos-impuestos'
  );
  const serviceDocumentosCartaPorte = feathers.service(
    'documentos-carta-porte'
  );
  const serviceDocumentosCartaPorteDomicilios = feathers.service(
    'documentos-carta-porte-domicilios'
  );
  const serviceDocumentosCartaPorteAutos = feathers.service(
    'documentos-carta-porte-autos'
  );
  const serviceDocumentosCartaPorteFigurasTransp = feathers.service(
    'documentos-carta-porte-figuras-transp'
  );
  const serviceDocumentosCartaPorteCantTransp = feathers.service(
    'documentos-carta-porte-cant-transp'
  );
  const serviceDocumentosCartaPorteAutosRemolques = feathers.service(
    'documentos-carta-porte-autos-remolques'
  );

  let drawerTitles = {
    CREATE: {
      title: 'CFDI Versión 4.0', //Traer la número de versión automático
      subtitle: '',
    },
    EDIT: {
      title: 'Editar CFDI Versión 4.0', //Traer la número de versión automático
      subtitle: '',
    },
  };

  let title = drawerTitles[currentSection].title;
  let subtitle = drawerTitles[currentSection].subtitle;

  let origenId = '';
  let destinoId = '';
  let distancia = 0;

  //obtener informacion del cfdi seleccionado para la relacion
  const handleRelated = (row, rel = '04') => {
    setRelate(row);
    setRelation(rel);
  };

  //Method to get the last Document
  const getLastDocument = async () => {
    let lastDocument = await serviceDocumentos.find({
      query: {
        Organizacion: auth.user.client._id,
        $sort: { id: -1 },
        $limit: 1,
      },
    });

    lastDocument =
      (lastDocument &&
        lastDocument.data &&
        lastDocument.data.length &&
        lastDocument.data[0]) ||
      {};
    setLastDocument(lastDocument);
    setLoading(false);

    // console.log(`GETTING THE DOCUMENT ON DRAWER COMPONENTE LINE 99: `, lastDocument);
  };

  //Method to get the userPost
  const getUserPOS = async () => {
    let userPOS = await servicePOS.get(auth.user.client._id);
    userPOS =
      userPOS && userPOS.length && userPOS[0] && !isObjEmpty(userPOS[0])
        ? userPOS[0]
        : null;
    setUserPOS(userPOS);
    setLoading(false);
    // console.log(`GETTING THE LAST POS ON DRAWER COMPONENT LINE 118: `, userPOS);
  };

  const isObjEmpty = (obj) => {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  };

  //Method to close drawer view
  const handledOnClose = async () => {
    let confirmation = await swal('¿Quieres cerrar el formulario?', {
      buttons: ['No', 'Sí'],
    });

    if (confirmation) {
      setCurrentSection('CREATE');
      onClose(false);
    }
  };

  const getCodigoPostal = async (cp) => {
    let findResult = await serviceCodigoPostal.find({
      query: { c_CodigoPostal: cp },
    });

    findResult =
      (findResult.data && findResult.data.length > 0 && findResult.data[0]) ||
      null;

    return findResult;
  };

  const setDocumento = async (values) => {
    let resultCreateDocumento;
    values.idUser = auth.user._id;
    values.Organizacion = auth.user.client._id;
    if (values.ReceptorNombre && values.ReceptorNombre.Nombre) {
      values.ReceptorNombre = values.ReceptorNombre.Nombre;
    }
    if (currentSection === 'EDIT') {
      resultCreateDocumento = await serviceDocumentos.patch(
        currentDocumentId.id,
        values
      );
    } else {
      if (!values.Folio) {
        let data = await getPOSFolios(values);
        if (data) {
          values.Folio = data.Folio;
        }
      }
      console.log('Folio: ', values.Folio);
      resultCreateDocumento = await serviceDocumentos.create(values);
    }
    return resultCreateDocumento;
  };

  const setDocumentoUbicaciones = async (values, id) => {
    if (currentSection === 'EDIT') {
      if (currentDocumentId.ubicaciones.length > 0 && isCartaPorte) {
        await serviceDocumentosCartaPorteDomicilios.remove(
          currentDocumentId.id
        );
      }
    }
    for (let [index, ubicacion] of values.ubicaciones.entries()) {
      ubicacion.id = id;
      ubicacion.idRow = index + 1;

      let cpResult = await getCodigoPostal(values.LugarExpedicion);

      let fechaDocumentoDomicilio = moment(ubicacion.FechaHoraSalidaLlegada);
      let isDTS = fechaDocumentoDomicilio.clone().isDST();

      if (isDTS) {
        ubicacion.FechaHoraSalidaLlegada = fechaDocumentoDomicilio
          .clone()
          .utcOffset(cpResult.DiffHorrarioVerano * 60)
          .format('YYYY-MM-DD HH:mm:ss.SSS');
      } else {
        ubicacion.FechaHoraSalidaLlegada = fechaDocumentoDomicilio
          .clone()
          .utcOffset(cpResult.DiffHorrarioInvierno * 60)
          .format('YYYY-MM-DD HH:mm:ss.SSS');
      }

      //TODO esto no será fijo, hay que seleccionarlo en el front end.
      if (ubicacion.TipoUbicacion === 'Origen') {
        origenId = ubicacion.IDUbicacion;
      }
      if (ubicacion.TipoUbicacion === 'Destino') {
        destinoId = ubicacion.IDUbicacion;
      }

      distancia = distancia + ubicacion.DistanciaRecorrida;

      await serviceDocumentosCartaPorteDomicilios.create(ubicacion);
    }
  };

  const setDocumentoCantTransp = async (
    idConcepto,
    idRowConcepto,
    cantidad
  ) => {
    if (isCartaPorte) {
      await serviceDocumentosCartaPorteCantTransp.create({
        id: idConcepto,
        idRowConcepto: idRowConcepto,
        idRow: 1,
        IDOrigen: origenId,
        IDDestino: destinoId,
        Cantidad: cantidad,
        CveTransporte: '01',
      });
    }
  };

  const setDocumentoConceptos = async (values, id) => {
    let resultCreateDocumentoConcepto;

    if (relate && relation) {
      for (let i = 0; i < values.conceptos.length; i++) {
        if (values.conceptos[i].PrecioUnitario > 0) {
          values.conceptos[i].cfdi_tipoRelacionuuid = relate.timbre_uuid;
          values.conceptos[i].cfdi_tipoRelacion = relation;
        }
      }
    }

    if (currentSection === 'EDIT') {
      if (currentDocumentId.conceptos.length > 0) {
        if (isCartaPorte) {
          await serviceDocumentosCartaPorteCantTransp.remove(
            currentDocumentId.id
          );
        }
        await serviceDocumentosConceptos.remove(currentDocumentId.id);
      }
    }

    for (let [index, concepto] of values.conceptos.entries()) {
      concepto.id = id;
      concepto.idRow = index + 1;

      if (concepto.TipoConcepto === 0) {
        if (concepto.Importe) {
          concepto.ImpuestosTraslados = concepto.Importe;
        }
        concepto.ValorMercancia = 0.0;
        concepto.PesoEnKg = 0.0;
        concepto.MonedaMercancia = '';
        concepto.FraccionArancelariaComercio = '';
        concepto.ImpuestosTraslados =
          concepto.Subtotal === concepto.Total
            ? 0.0
            : concepto.ImpuestosTraslados;
        resultCreateDocumentoConcepto = await serviceDocumentosConceptos.create(
          concepto
        );
      } else if (concepto.TipoConcepto !== 0 && isCartaPorte) {
        concepto.PesoNeto = 0;
        concepto.CargoPorTasacion = 0;
        concepto.UnidadPeso = '';
        concepto.LogisticaInversaRecoleccionDevolucion = 0;
        if (concepto.TipoMateria !== '05') {
          concepto.DescripcionMateria = '';
        }
        if (concepto.TipoMateria === '05') {
          concepto.DescripcionMateria = 'OTRA';
        }
        resultCreateDocumentoConcepto = await serviceDocumentosConceptos.create(
          concepto
        );
      }

      if (isCartaPorte) {
        if (concepto.TipoConcepto !== 0) {
          await setDocumentoCantTransp(
            resultCreateDocumentoConcepto.id,
            concepto.idRow,
            concepto.Cantidad
          );
        }
      }
    }

    setRelate(null);
    setRelation(null);
  };

  const setDocumentoConceptosImpuestos = async (values, id) => {
    let resultCreateDocumentoConceptoImpuesto;
    let cont = 0;

    if (currentSection === 'EDIT') {
      if (values.conceptos.length > 0) {
        for (let concepto of values.conceptos) {
          if (concepto.ImporteBase) {
            if (concepto.TipoConcepto === 0 && cont === 0) {
              cont++;
              let result = await serviceDocumentosConceptosImpuestos.find({
                query: { id: currentDocumentId.id },
              });
              if (result && result.data && result.data.length > 0) {
                await serviceDocumentosConceptosImpuestos.remove(
                  currentDocumentId.id
                );
              }
            }
          }
        }
      }
    }

    for (let [index, concepto] of values.conceptos.entries()) {
      concepto.id = id;
      concepto.idRow = index + 1;
      if (concepto.TipoConcepto === 0) {
        if (concepto.Importe !== 0 && concepto.ImporteBase) {
          if (
            concepto.ImpuestosRetenciones === '0.00' ||
            concepto.ImpuestosRetenciones === 0
          ) {
            concepto.TasaOCuota = 'Tasa';
            concepto.idImpuesto = 1;
            concepto.CodigoSAT = '002';
            concepto.idTipoTax = 1;
            resultCreateDocumentoConceptoImpuesto =
              await serviceDocumentosConceptosImpuestos.create(concepto);
          } else {
            concepto.TasaOCuota = 'Tasa';
            concepto.idImpuesto = 1;
            concepto.CodigoSAT = '002';
            concepto.idTipoTax = 1;
            resultCreateDocumentoConceptoImpuesto =
              await serviceDocumentosConceptosImpuestos.create(concepto);

            if (resultCreateDocumentoConceptoImpuesto) {
              concepto.TasaOCuota = 'Tasa';
              concepto.idImpuesto = concepto.idImpuesto + 1;
              concepto.CodigoSAT = '002';
              concepto.idTipoTax = 2;
              concepto.Valor = concepto.ImpuestoRetencionValor;
              concepto.Importe = concepto.ImpuestosRetenciones;
              resultCreateDocumentoConceptoImpuesto =
                await serviceDocumentosConceptosImpuestos.create(concepto);
            }
          }
        }
        if (
          concepto.Importe === 0 &&
          concepto.ImporteBase &&
          concepto.Valor === 0 &&
          concepto.PerfilImpuestos === 3
        ) {
          if (concepto.ImpuestosRetenciones === '0.00') {
            concepto.TasaOCuota = 'Exento';
            concepto.idImpuesto = 1;
            concepto.CodigoSAT = '002';
            concepto.idTipoTax = 1;
            resultCreateDocumentoConceptoImpuesto =
              await serviceDocumentosConceptosImpuestos.create(concepto);
          }
        } else if (
          concepto.Importe === 0 &&
          concepto.ImporteBase &&
          concepto.Valor === 0 &&
          concepto.PerfilImpuestos !== 4
        ) {
          if (concepto.ImpuestosRetenciones === '0.00') {
            concepto.TasaOCuota = 'Tasa';
            concepto.idImpuesto = 1;
            concepto.CodigoSAT = '002';
            concepto.idTipoTax = 1;
            resultCreateDocumentoConceptoImpuesto =
              await serviceDocumentosConceptosImpuestos.create(concepto);
          } else {
            concepto.TasaOCuota = 'Tasa';
            concepto.idImpuesto = 1;
            concepto.CodigoSAT = '002';
            concepto.idTipoTax = 1;
            resultCreateDocumentoConceptoImpuesto =
              await serviceDocumentosConceptosImpuestos.create(concepto);

            if (resultCreateDocumentoConceptoImpuesto) {
              concepto.TasaOCuota = 'Tasa';
              concepto.idImpuesto = concepto.idImpuesto + 1;
              concepto.CodigoSAT = '002';
              concepto.idTipoTax = 2;
              concepto.Valor = concepto.ImpuestoRetencionValor;
              concepto.Importe = concepto.ImpuestosRetenciones;
              resultCreateDocumentoConceptoImpuesto =
                await serviceDocumentosConceptosImpuestos.create(concepto);
            }
          }
        }
      }
    }
  };

  const setDocumentoAutoTransportes = async (values, id) => {
    if (currentSection === 'EDIT' && isCartaPorte) {
      await serviceDocumentosCartaPorteAutos.patch(currentDocumentId.id, {
        id: id,
        PermSCT: values.PermSCT,
        AutoConfigVehicular: values.AutoConfigVehicular,
        NumPermitoSCT: values.NumPermitoSCT,
        AutoPlacaVM: values.AutoPlacaVM,
        AutoAnioModeloVM: values.AutoAnioModeloVM,
        AseguraRespCivil: values.AseguraRespCivil,
        PolizaRespCivil: values.PolizaRespCivil,
        AseguraMedAmbiente: values.AseguraMedAmbiente,
        PolizaMedAmbiente: values.PolizaMedAmbiente,
        AseguraCarga: values.AseguraCarga,
        PolizaCarga: values.PolizaCarga,
        PrimaSeguro: values.PrimaSeguro,
        PesoBrutoVehicular: values.PesoBruto,
      });
    } else {
      await serviceDocumentosCartaPorteAutos.create({
        id: id,
        PermSCT: values.PermSCT,
        AutoConfigVehicular: values.AutoConfigVehicular,
        NumPermitoSCT: values.NumPermitoSCT,
        AutoPlacaVM: values.AutoPlacaVM,
        AutoAnioModeloVM: values.AutoAnioModeloVM,
        AseguraRespCivil: values.AseguraRespCivil,
        PolizaRespCivil: values.PolizaRespCivil,
        AseguraMedAmbiente: values.AseguraMedAmbiente,
        PolizaMedAmbiente: values.PolizaMedAmbiente,
        AseguraCarga: values.AseguraCarga,
        PolizaCarga: values.PolizaCarga,
        PrimaSeguro: values.PrimaSeguro,
        PesoBrutoVehicular: values.PesoBruto,
      });
    }
  };

  const setDocumentoAutosRemolques = async (values, id) => {
    if (currentSection === 'EDIT') {
      if (currentDocumentId.PlacaR1 && isCartaPorte) {
        await serviceDocumentosCartaPorteAutosRemolques.remove(
          currentDocumentId.id
        );
      }
    }

    if (values.PlacaR1) {
      await serviceDocumentosCartaPorteAutosRemolques.create({
        id: id,
        idRow: 1,
        Placa: values.PlacaR1,
        SubtipoRem: values.Remolque1,
        Alias: values.AliasR1,
      });
    }

    if (values.PlacaR2) {
      await serviceDocumentosCartaPorteAutosRemolques.create({
        id: id,
        idRow: 2,
        Placa: values.PlacaR2,
        SubtipoRem: values.Remolque2,
        Alias: values.AliasR2,
      });
    }
  };

  const setDocumentoFiguraTransporte = async (values, id) => {
    if (isCartaPorte) {
      if (currentSection === 'EDIT') {
        if (currentDocumentId.figuraTransportes.length > 0) {
          await serviceDocumentosCartaPorteFigurasTransp.remove(
            currentDocumentId.id
          );
        }
      }
      for (let [index, figura] of values.figuraTransportes.entries()) {
        figura.id = id;
        figura.idRow = index + 1;
        await serviceDocumentosCartaPorteFigurasTransp.create(figura);
      }
    }
  };

  const setDocumentoCartaPorte = async (values, id) => {
    if (isCartaPorte) {
      if (currentSection === 'EDIT') {
        await serviceDocumentosCartaPorte.remove(currentDocumentId.id);
      }
      await serviceDocumentosCartaPorte.create({
        id: id,
        Version: '3.1',
        TransporInternac: 1, //Para decir que va a méxico
        TotalDistRec: distancia ? distancia : 0.0,
        EntradaSalidaMerc: values.EntradaSalidaMerc,
        ViaEntradaSalidas: '01', //Autotransporte
        PaisOrigenDestino: values.PaisOrigenDestino,
        idCCP: values.idCCP,
        RegimenAduanero: values.RegimenAduanero,
      });
    }
  };

  // const saveReceptorOnCatalog = async (values) => {
  //   let receptorResult = await serviceReceptores.find({
  //     query: {
  //       RFC: values.ReceptorRFC,
  //       Organizacion: values.Organizacion,
  //     },
  //   });
  //   let receptor = {};
  //   if (receptorResult.total > 0) {
  //     receptor = receptorResult.data[0];
  //   }

  //   receptor.CodPos = values.ReceptorCodPos;
  //   receptor.Nombre = values.ReceptorNombre;
  //   receptor.Organizacion = values.Organizacion;
  //   receptor.Pais = values.ReceptorPais;
  //   receptor.RFC = values.ReceptorRFC;
  //   receptor.RegistroTributarioExterior =
  //     values.ReceptorRegistroTributarioExterior;

  //   if (receptor.id) {
  //     let result = await serviceReceptores.patch(receptor.id, receptor);
  //   } else {
  //     let resultCreate = await serviceReceptores.create(receptor);
  //   }
  // };

  const handleSave = async (values, data) => {
    // console.log( 'INFORMACION DE LOS VALORES EN EL FORMULARIO: ', values );

    // Cuando es tipo Ingreso y solamente tiene un concepto
    if (
      values.idTipoComprobante === 'I' &&
      isCartaPorte &&
      values.conceptos.length <= 1
    ) {
      swal('Error', '¡Mínimo debe de tener un ingreso y un traslado!', 'error');
      return true;
    }

    // Valida que cuando es Ingreso, tenga por lo menos un ingreso y un traslado
    if (values.idTipoComprobante === 'I' && values.conceptos.length >= 1) {
      var conceptos0 = 0;
      var conceptos1 = 0;
      var conceptos2 = 0;

      for (let concepto of values.conceptos) {
        if (concepto.TipoConcepto === 0) {
          conceptos0 = conceptos0 + 1;
        }
        if (concepto.TipoConcepto === 1) {
          conceptos1 = conceptos1 + 1;
        }
        if (concepto.TipoConcepto === 2) {
          conceptos2 = conceptos2 + 1;
        }
      }

      if (conceptos0 === 0) {
        swal(
          'Error',
          '¡Debe de haber por lo menos 1 concepto de tipo: Ingreso!',
          'error'
        );
        return true;
      }
      if (conceptos1 === 0 && conceptos2 === 0 && isCartaPorte) {
        swal(
          'Error',
          '¡Debe de haber por lo menos 1 concepto de tipo: Traslado!',
          'error'
        );
        return true;
      }
    }

    //Valida que existan ubicaciones
    if (values.ubicaciones && values.ubicaciones.length === 0 && isCartaPorte) {
      swal(
        'Error',
        '¡Debe de haber por lo menos 1 ubicación de tipo Origen y Destino!',
        'error'
      );
      return true;
    }

    //Valida que en ubicaciones haya por lo menos 1 Origen y 1 Destino
    if (values.ubicaciones.length >= 1) {
      var ubicaciones0 = 0;
      var ubicaciones1 = 0;
      for (let ubicacion of values.ubicaciones) {
        if (ubicacion.TipoUbicacion === 'Origen') {
          ubicaciones0 = ubicaciones0 + 1;
        }
        if (ubicacion.TipoUbicacion === 'Destino') {
          ubicaciones1 = ubicaciones1 + 1;
        }
      }

      if (ubicaciones0 === 0) {
        swal(
          'Error',
          '¡Debe de haber por lo menos 1 ubicación de tipo: Origen!',
          'error'
        );
        return true;
      }
      if (ubicaciones1 === 0) {
        swal(
          'Error',
          '¡Debe de haber por lo menos 1 ubicación de tipo: Destino!',
          'error'
        );
        return true;
      }
    }

    if (values) {
      setLoading(true);
      try {
        //Actualiza el folio
        if (currentSection === 'CREATE') {
          updateFolio(values);
        }
        let cpResult = await getCodigoPostal(values.LugarExpedicion);
        let fechaDocumento = moment(values.FechaDocumento);
        let isDTS = fechaDocumento.clone().isDST();

        if (isDTS) {
          values.FechaDocumento = fechaDocumento
            .clone()
            .utcOffset(cpResult.DiffHorrarioVerano * 60)
            .format('YYYY-MM-DD HH:mm:ss.SSS');
        } else {
          values.FechaDocumento = fechaDocumento
            .clone()
            .utcOffset(cpResult.DiffHorrarioInvierno * 60)
            .format('YYYY-MM-DD HH:mm:ss.SSS');
        }

        values.ImpuestosTraslados &&
          (values.ImpuestosTraslados2 = values.ImpuestosTraslados);

        values.Total && (values.Total2 = values.Total);

        if (values.idTipoComprobante === 'I' && values.conceptos.length > 0) {
          values.ImpuestosTraslados = 0;
          values.Subtotal = 0;
          values.ImpuestosRetenciones = 0;
          values.Total = 0;
          for (let concepto of values.conceptos) {
            if (concepto.TipoConcepto === 0) {
              values.ImpuestosTraslados =
                values.ImpuestosTraslados + concepto.Importe;
              values.Subtotal = values.Subtotal + concepto.Subtotal;
              values.ImpuestosRetenciones =
                parseFloat(values.ImpuestosRetenciones) +
                parseFloat(concepto.ImpuestosRetenciones);
              values.Total =
                values.ImpuestosTraslados +
                values.Subtotal -
                values.ImpuestosRetenciones;
            }
          }
        }

        if (values.idMetodoPago === 'PUE') {
          values.Condicion = 'CONTADO';
        } else {
          values.Condicion = 'CREDITO';
        }

        !values.ImpuestosTraslados &&
          (values.ImpuestosTraslados = values.ImpuestosTraslados2);
        !values.Total && (values.Total = values.Total2);
        values.TotalLetra = NumeroALetras(values.Total, values.idMoneda);

        if (!values.idMetodoPago) {
          values.idMetodoPago = 'PPD';
        }

        //Documento
        let resultCreateDocumento = await setDocumento(values);

        if (isCartaPorte) {
          // Ubicaciones
          await setDocumentoUbicaciones(values, resultCreateDocumento.id);

          //Autotransporte;
          await setDocumentoAutoTransportes(values, resultCreateDocumento.id);

          //Autotransporte Remolques;
          await setDocumentoAutosRemolques(values, resultCreateDocumento.id);

          //TODO Agregar el Remolque 1 y 2 a la Placa.

          // FiguraTransporte;
          await setDocumentoFiguraTransporte(values, resultCreateDocumento.id);

          //Carta Porte
          await setDocumentoCartaPorte(values, resultCreateDocumento.id);
        }

        // Conceptos;
        await setDocumentoConceptos(values, resultCreateDocumento.id);

        // ConceptosImpuestos;x
        await setDocumentoConceptosImpuestos(values, resultCreateDocumento.id);

        // alert(resultCreateDocumento.id);

        //relacionando CFDIS
        // if( relate ){
        //   await handleSaveRelate(values);
        // }

        setCurrentSection('EDIT');
        if (currentSection === 'EDIT') {
          swal('Éxito', '¡Se ha editado correctamente!', 'success');
        }
        onClose(true);
      } catch (error) {
        console.log('error', error);

        swal('Error', error.message, 'error');
        setLoading(false);
      }
    } else swal('Error', 'Dont Save User', 'error');
  };

  const posFoliosService = feathers.service('pos-folios');

  const getPOSFolios = async (values) => {
    let response = await posFoliosService.find({
      query: {
        idPOS: values.idPOS,
        idTipoComprobante: values.idTipoComprobante,
        Serie: values.Serie,
      },
    });

    let data =
      (response && response.data && response.data.length && response.data[0]) ||
      null;
    return data;
  };

  async function updateFolio(values) {
    let data = await getPOSFolios(values);
    if (data) {
      values.Folio = data.Folio;
      let response = await servicePOSFolios.patch(values.idPOS, {
        idPOS: data.idPOS,
        idTipoComprobante: data.idTipoComprobante,
        Serie: data.Serie,
        Folio: ++data.Folio,
      });
    }
  }

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const getUnidadMedida = async (c_ClaveUnidad) => {
    let response = await feathers.service('catalogo-clave-unidad').find({
      query: {
        c_ClaveUnidad: c_ClaveUnidad,
      },
    });
    let data =
      (response &&
        response.data &&
        response.data.length &&
        response.data[0].Nombre) ||
      null;
    return data;
  };

  const getDescriptionTipoMateria = async (value) => {
    if (value) {
      let response = await tipoMateriaService.find({
        query: {
          c_ClaveTipoMateria: value,
        },
      });
      return response.data[0] ? response.data[0].Descripcion : '';
    }
  };

  //Agrega información Cargar Viaje
  async function verViaje(TripID, formik, dataConceptos) {
    if (TripID) {
      let viaje = await serviceTrip.get(TripID);

      formik.values.PlacaR1 =
        // viaje.Remolques.length > 0 ? viaje.Remolques[0].PrimaryPlate : '';
        '';
      formik.values.Remolque1 =
        //viaje.Remolques.length > 0 ? viaje.Remolques[0].SubTipoRemolque : '';
        '';
      formik.values.PlacaR2 =
        viaje.Remolques.length > 1 ? viaje.Remolques[1].PrimaryPlate : '';
      formik.values.Remolque2 =
        viaje.Remolques.length > 1 ? viaje.Remolques[1].SubTipoRemolque : '';
      formik.values.figuraTransportes = [];
      for (let i = 0; i < viaje.Choferes.length; i++) {
        let chofer = {
          TipoFigura: '01',
          NombreFigura:
            viaje.Choferes[i].FirstName + ' ' + viaje.Choferes[i].LastName,
          RFC: viaje.Choferes[i].RFC ? viaje.Choferes[i].RFC : '',
          NumLicencia: viaje.Choferes[i].LicenseNumber,
          ResidenciaFiscal: 'MEX',
          NumRegTributario: viaje.Choferes[i].NumRegistroTributario
            ? viaje.Choferes[i].NumRegistroTributario
            : '',
          Calle: viaje.Choferes[i].PhysicalAddress1
            ? viaje.Choferes[i].PhysicalAddress1
            : '',
          NumExterior: '',
          NumInterior: '',
          Referencia: '',
          Pais: '',
          Estado: '',
          Municipio: '',
          Localidad: '',
          CodigoPostal: '',
          Colonia: '',
          ParteTransporte: '',
        };
        formik.values.figuraTransportes.push(chofer);
      }

      if (viaje.Shipment.length > 0) {
        formik.values.ubicaciones = [
          {
            TipoUbicacion: 'Origen',
            TipoEstacion: '',
            NumEstacion: '',
            NombreEstacion: '',
            NavegacionTrafijo: '',
            RFCRemitenteDestinatario: '',
            ResidenciaFiscal:
              viaje.Shipment[0].Shipper.Country == 'MX'
                ? 'MEX'
                : viaje.Shipment[0].Shipper.Country == 'US'
                ? 'USA'
                : '',
            NumRegTributario: '',
            IDUbicacion: 'OR000001',
            FechaHoraSalidaLlegada: moment().format(),
            DistanciaRecorrida: 0.0,
            Calle: viaje.Shipment[0].Shipper.Address1
              ? (viaje.Shipment[0].Shipper.Address1 || '') +
                ' ' +
                (viaje.Shipment[0].Shipper.Address2 || '')
              : '',
            NumExterior: '',
            NumInterior: '',
            Referencia: '',
            Pais:
              viaje.Shipment[0].Shipper.Country == 'MX'
                ? 'MEX'
                : viaje.Shipment[0].Shipper.Country == 'US'
                ? 'USA'
                : '',
            Estado: viaje.Shipment[0].Shipper.StateProvince,
            Municipio: '',
            Localidad: '',
            CodigoPostal: viaje.Shipment[0].Shipper.Postal,
            Colonia: '',
            CartaPorte: isCartaPorte ? 'true' : 'false',
          },
          {
            TipoUbicacion: 'Destino',
            TipoEstacion: '',
            NumEstacion: '',
            NombreEstacion: '',
            NavegacionTrafijo: '',
            RFCRemitenteDestinatario: 'XEXX010101000',
            ResidenciaFiscal:
              viaje.Shipment[0].Consignee.Country == 'MX'
                ? 'MEX'
                : viaje.Shipment[0].Consignee.Country == 'US'
                ? 'USA'
                : '',
            NumRegTributario: '',
            IDUbicacion: 'DE000002',
            FechaHoraSalidaLlegada: moment(viaje.ArrivalDateTime).format(),
            DistanciaRecorrida: 0.0,
            Calle: viaje.Shipment[0].Consignee.Address1
              ? (viaje.Shipment[0].Consignee.Address1 || '') +
                ' ' +
                (viaje.Shipment[0].Consignee.Address2 || '')
              : '',
            NumExterior: '',
            NumInterior: '',
            Referencia: '',
            Pais:
              viaje.Shipment[0].Consignee.Country == 'MX'
                ? 'MEX'
                : viaje.Shipment[0].Consignee.Country == 'US'
                ? 'USA'
                : '',
            Estado: viaje.Shipment[0].Consignee.StateProvince,
            Municipio: '',
            Localidad: '',
            CodigoPostal: viaje.Shipment[0].Consignee.Postal,
            Colonia: '',
            CartaPorte: isCartaPorte ? 'true' : 'false',
          },
        ];
      }
    }

    //Agregar conceptos
    let newConceptos = formik.values.conceptos ? formik.values.conceptos : [];
    if (resetConceptos) {
      newConceptos = [];
      formik.values.conceptos = [];
    }
    if (dataConceptos) {
      await Promise.all(
        dataConceptos.map(async (data, index) => {
          if (data['Clave ProdServ SAT']) {
            if (data.NP !== 'Grand Total') {
              let unidadMedidaNombre = await getUnidadMedida(
                data['Unidad Medida']
              );
              newConceptos.push({
                Cantidad: data['Cantidad']
                  ? data['Cantidad']
                  : data['Cant Piezas ']
                  ? data['Cant Piezas ']
                  : 1,
                idProducto: 0,
                Descripcion: data['Descripcion']
                  ? data['Descripcion']
                  : data['DESCRIPCION']
                  ? data['DESCRIPCION']
                  : '',
                Valor: 16.0,
                PrecioUnitario: 0.0,
                Subtotal: 0.0,
                Descuento: 0.0,
                ImpuestosTraslados: 0.0,
                ImpuestosRetenciones: 0.0,
                Total: 0.0,
                idReferencia: 0,
                idReferenciaModulo: 0,
                TipoConcepto:
                  formik.values.idTipoComprobante === 'I' &&
                  (isCartaPorte || !isCartaPorte)
                    ? 1
                    : 2,
                Valor: 16.0,
                ClaveProdServ: data['Clave ProdServ SAT']
                  ? data['Clave ProdServ SAT'].toString()
                  : data['CLAVE SAT']
                  ? data['CLAVE SAT'].toString()
                  : '',
                CuentaPredial: '',
                NumeroIdentificacion: '',
                ClaveUnidad: data['Unidad Medida']
                  ? data['Unidad Medida']
                  : 'H87',
                UnidadMedida: unidadMedidaNombre
                  ? unidadMedidaNombre.toString()
                  : 'Pieza',
                ValorMercancia: data['Valor Mercancia']
                  ? data['Valor Mercancia']
                  : data['Valor Dolares']
                  ? data['Valor Dolares']
                  : 0.0,
                PesoEnKg: data['Peso en KG']
                  ? data['Peso en KG'].toFixed(6)
                  : data['Peso Bruto']
                  ? data['Peso Bruto']
                  : 0.0,
                MonedaMercancia: data['Moneda Valor Mercancia']
                  ? data['Moneda Valor Mercancia']
                  : data['Moneda']
                  ? data['Moneda']
                  : '',
                cfdi_tipoRelacion: '',
                cfdi_tipoRelacionuuid: '',
                NumeroIdentificacionComercio: '',
                FraccionArancelariaComercio: data['Fraccion Arancelaria MX']
                  ? data['Fraccion Arancelaria MX'].toString()
                  : data['Fracción MX']
                  ? data['Fracción MX'].toString()
                  : data['FRACCION MX']
                  ? data['FRACCION MX'].toString()
                  : '',
                CantidadAduanaComercio: 0.0,
                UnidadAduanaComercio: '',
                ValorUnitarioAduanaComercio: 0.0,
                ValorDolaresComercio: 0.0,
                DescripcionIngles: '',
                cfdi_RelacionIdDoc: 0,
                cfdi_RelacionNumParcialidad: 0,
                cfdi_RelacionReferencia: '',
                idDoc_Ref: 0,
                CveMaterialPeligroso: data['Clave Material Peligroso']
                  ? data['Clave Material Peligroso']
                  : '',
                Embalaje: data['Embalaje'] ? data['Embalaje'] : '',
                EmbalajeDescripcion: data['Embalaje Descripcion']
                  ? data['Embalaje Descripcion']
                  : '',
                UUIDComercioExterior: data['UUID Comercio Exterior']
                  ? data['UUID Comercio Exterior']
                  : '',
                Pedimento: data['Pedimento'] ? data['Pedimento'] : '',
                Dimensiones: '',
                TipoDocumentoAduanero: data['TipoDocumentoAduanero']
                  ? data['TipoDocumentoAduanero']
                  : '',
                RFCImportador: data['RFCImportador']
                  ? data['RFCImportador']
                  : '',
                IdentificacionDocumentoAduanero: data[
                  'NumeroIdentificacionAduanero'
                ]
                  ? data['NumeroIdentificacionAduanero']
                  : '',
                TipoMateria: data['TipoMateria'] ? data['TipoMateria'] : '',
                DescripcionMateria: data['TipoMateria']
                  ? await getDescriptionTipoMateria(data['TipoMateria'])
                  : '',
                CartaPorte: isCartaPorte ? 'true' : 'false',
              });
            }
          }
        })
      );
      formik.values.conceptos = newConceptos;
    }
    await formik.setValues(formik.values);
    await formik.validateForm().then((errors) => {
      formik.setTouched({ ...formik.touched, ...errors });
    });
  }

  //useEffects
  useEffect(() => {
    getLastDocument();
    getUserPOS();
  }, []);

  return (
    //PAGE TO GENERATE A CFDI

    <SlidingPanel
      isOpen={openPanel} //Show the form if true
      size={100}
      title={title}
      subtitle={subtitle}
      onClose={handledOnClose} //Close the form
      style={{ backgroundColor: '#f5f5f5' }}
    >
      {(!loading && lastDocument && userPOS && (
        <Formik
          innerRef={formikRef}
          initialValues={
            currentDocumentId
              ? currentDocumentId
              : initialValues(lastDocument, userPOS)
          }
          validateOnChange={false}
          validationSchema={validationScheme}
          onSubmit={handleSave}
        >
          {(formikProps) => (
            <Form
              onKeyDown={onKeyDown}
              onSubmit={formikProps.handleSubmit}
              loading={loading}
              style={{ marginRight: '15px' }}
            >
              <ModalTrip
                formik={formikProps}
                verViaje={verViaje}
                resetConceptos={resetConceptos}
                setResetConceptos={setResetConceptos}
              />
              <FormDocument
                onSave={handleSave}
                ref={refForm}
                isValid={isValid}
                setIsValid={setIsValid}
                isCartaPorte={isCartaPorte}
                setIsCartaPorte={setIsCartaPorte}
                isMaterialPeligroso={isMaterialPeligroso}
                setIsMaterialPeligroso={setIsMaterialPeligroso}
                formik={formikProps} //Enviando las formik propps al componente hijo
                isEdit={currentSection === 'EDIT' ? true : false}
                data={data}
                setQuery={setQuery}
                handleRelated={handleRelated}
              />

              <Actions
                handledOnClose={handledOnClose}
                isValid={isValid}
                formik={formikProps}
              />
            </Form>
          )}
        </Formik>
      )) || (
        <Dimmer active inverted>
          <Loader inverted>Cargando</Loader>
        </Dimmer>
      )}
    </SlidingPanel>
  );
};

export default Drawer;
