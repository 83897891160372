import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import HeaderBG from '../../components/HeaderBG';
import Drawer from './Drawer';
import { Container, Breadcrumb } from 'semantic-ui-react';
import Table from '../Conceptos/Table';
import swal from 'sweetalert';
import feathers, { useAuth } from '../../services/feathers';
import Filters from './Filters';
import TopNavigation from './TopNavigation';

const Conceptos = (props) => {
  const service = feathers.service('conceptos');
  const auth = useAuth();

  let [query, setQuery] = useState({
    Descripcion: { $like: '%%' },
    Organizacion: auth.user.client._id,
  });
  const [isBusy, setIsBusy] = useState(false);
  const [selection, setSelection] = useState([]);
  const [data, setData] = useState([]);
  const [isSupport, setIsSupport] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [currentConcepto, setCurrentConcepto] = useState({});
  const [concepto, setConcepto] = useState(null);

  useEffect(() => {
    const filter = () => {
      if (!query)
        query = {
          Descripcion: { $like: '%%' },
          Organizacion: auth.user.client._id,
        };
      getData();
    };

    filter();
  }, [query]);

  useEffect(() => {
    const { isSupport } = (auth && auth.user) || {},
      client = (auth && auth.user && auth.user.client) || null,
      isInvalid = (!isSupport && !client && true) || false;

    setIsSupport(isSupport);
    setConcepto(client);
    setIsValid(!isInvalid);
  }, [auth.user]);

  const handleCreateClick = () => {
    const [clients, client] = auth.user.isSupport
      ? [[], {}]
      : [[{ ...auth.user.client }], { ...auth.user.client }];
    openDrawer({ clients, client });
  };

  const openDrawer = async (concepto) => {
    concepto.Organizacion = auth.user.client._id;

    // //getUsoCFDI
    // if (concepto.idUsoCFDI) {
    //   let result = await serviceUsoCFDI.find({
    //     query: {
    //       CCFDIID: concepto.idUsoCFDI,
    //     },
    //   });
    //   if (result) {
    //     concepto.idUsoCFDIString = result.data[0].c_UsoCFDI;
    //   }
    // } else {
    //   concepto.idUsoCFDI = 38;
    //   concepto.idUsoCFDIString = 'S01';
    // }

    // //getFormaPago
    // if (concepto.idFormaPago) {
    //   let result = await serviceFormaPago.find({
    //     query: {
    //       CFpagoID: concepto.idFormaPago,
    //     },
    //   });
    //   if (result) {
    //     concepto.idFormaPagoString = result.data[0].c_FormaPago;
    //   }
    // } else {
    //   concepto.idFormaPago = 44;
    //   concepto.idFormaPagoString = '99';
    // }

    // //getMetodoPago
    // if (concepto.idMetodoPago) {
    //   let result = await serviceMetodoPago.find({
    //     query: {
    //       CMpagoID: concepto.idMetodoPago,
    //     },
    //   });
    //   if (result) {
    //     concepto.idMetodoPagoString = result.data[0].c_MetodoPago;
    //   }
    // } else {
    //   concepto.idMetodoPago = 4;
    //   concepto.idMetodoPagoString = 'PPD';
    // }

    setCurrentConcepto(concepto);
    setOpenPanel(true);
  };

  const handleClose = (isSuccess) => {
    setCurrentConcepto({});
    setOpenPanel(false);

    isSuccess && getData();
  };
  const getData = async () => {
    if (query) {
      let data = [],
        result = [];
      try {
        setIsBusy(true);
        setSelection([]);

        if (query.Organizacion != '') {
          do {
            result = await service.find({
              query: {
                ...query,
                $skip: data.length,
              },
            });
            data = data.concat(result.data);
          } while (data.length !== result.total);
        }
      } catch (err) {
        console.error('Error', err);
        data = [];
        swal({
          title: 'Error',
          text: 'Failed get concepto',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);
      }
    }
  };
  const handleEditClick = (concepto) => {
    openDrawer(concepto);
  };
  const handleDeleteClick = async (data) => {
    let confirmation = await swal('Do you want delete this item?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      try {
        await service.remove(data.idProducto);
        swal('Borrado', '¡Se ha borrado correctamente!', 'success');
      } catch (exception) {
        swal('Error', 'Dont delete transporte', 'error');
      }
      getData();
    }
  };

  const getClientName = () =>
    (auth && auth.user && auth.user.client && auth.user.client.name) || null;

  return (
    <>
      <DefaultLayout
        currentPath={props.match.path}
        globalActions={
          <TopNavigation
            isBusy={isBusy}
            onCreateClick={handleCreateClick}
            isValid={isValid}
          />
        }
      >
        <HeaderBG />
        <Container className="fluid-95">
          <Breadcrumb
            icon="right angle"
            sections={[
              { key: getClientName(), content: getClientName(), link: false },
              // { key: 'Carta Porte', content: 'Carta Porte', link: false },
              {
                key: 'BillOfLadingMx',
                content: 'Conceptos',
                active: true,
              },
            ]}
          />
          <Filters
            isBusy={isBusy}
            setQuery={setQuery}
            isValid={isValid}
            getData={getData}
          />
          <Table
            data={data}
            selection={selection}
            setSelection={setSelection}
            isSupport={isSupport}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
          ></Table>
        </Container>
      </DefaultLayout>
      {openPanel ? (
        <Drawer
          openPanel={openPanel}
          onClose={handleClose}
          currentConcepto={currentConcepto}
        ></Drawer>
      ) : null}
    </>
  );
};

export default Conceptos;
