import React from 'react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';

function DropdownMedioTransporte({
  val,
  setValues,
  child,
  label,
  formik,
  error,
}) {
  let { setFieldValue, handleBlur } = formik;
  return (
    <DropdownSearchFetch
      placeholder={label}
      fluid
      selection
      label={label}
      value={val.description ? val.description : ''}
      onChange={(e, { value }) => {
        switch (child) {
          case 'transporteSalida':
            setFieldValue('transporteSalida', value);
            break;
          case 'transporteArribo':
            setFieldValue('transporteArribo', value);
            break;
          case 'transporteSalidaAduana':
            setFieldValue('transporteSalidaAduana', value);
            break;
          default:
            break;
        }
        setValues({ ...val, description: value });
      }}
      featherService="catalogo-medio-transporte"
      columName="Descripcion"
      columValue="c_MedioTransporte"
      mapValues={(value) => {
        return {
          key: value['c_MedioTransporte'],
          text: `${value['Descripcion']}`,
          value: value['c_MedioTransporte'],
        };
      }}
      isFormDrop={true} //bandera para mostrar dropDown con errors
      onBlur={(_, data) => {
        Object.freeze(data);
        switch (child) {
          case 'transporteSalida':
            data = { ...data, name: 'transporteSalida' };
            break;
          case 'transporteArribo':
            data = { ...data, name: 'transporteArribo' };
            break;
          case 'transporteSalidaAduana':
            data = { ...data, name: 'transporteSalidaAduana' };
            break;
          default:
            break;
        }
        handleBlur({ target: { ...data } });
      }}
      error={error}
    />
  );
}

export default DropdownMedioTransporte;
