import React from 'react';
import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';
import feathers from '../../../services/feathers';
import ComplementButtonPdf from '../ComplementButtonPdf';

const Actions = ({
  isBusy,
  hasSelection,
  getComplement,
  bankInformation,
  onClose,
  setIsBusy,
}) => {
  const servicePDF = feathers.service('export-view-pdf');

  return (
    <ActionContainer>
      <ComplementButtonPdf
        hasSelection={hasSelection}
        servicePDF={servicePDF}
        getComplement={getComplement}
        bankInformation={bankInformation}
        onClose={onClose}
        isBusy={isBusy}
        setIsBusy={setIsBusy}
      >
        Guardar y Ver PDF
      </ComplementButtonPdf>
      <Button primary compact disabled={isBusy} style={{ width: '180px' }}>
        Guardar y Timbrar
      </Button>
    </ActionContainer>
  );
};

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
`;

export default Actions;
