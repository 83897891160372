import React, { forwardRef } from 'react';
import { Form, Segment, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { Formik, Field, FieldArray } from 'formik';
import DropdownSimpleFetch from '../../../components/Formik/DropdownSimpleFetch';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import DropdownFiguraTransporte from './DropdownFiguraTransporte';

import { result } from 'lodash';

const FormFiguraTransporteChofer = ({ formik, index, figuraTransporte }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    /* and other goodies */
  } = formik;

  return (
    <div key={index} className="ui form">
      <Form.Group widths={'equal'}>
        <DropdownFiguraTransporte
          formik={formik}
          index={index}
          figuraTransporte={figuraTransporte}
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <DropdownSearchFetch
          fluid
          selection
          label="Tipo Figura *"
          value={figuraTransporte.TipoFigura}
          onChange={(e, { value }) =>
            setFieldValue(`figuraTransportes.${index}.TipoFigura`, value)
          }
          placeholder="Tipo Figura"
          featherService="catalogo-figura-transporte"
          columName="Descripcion"
          columValue="c_FiguraTransporte"
          mapValues={(value) => {
            return {
              key: Math.random(),
              text: `${value['Descripcion']}`,
              value: value['c_FiguraTransporte'],
            };
          }}
          error={
            errors.figuraTransportes &&
            errors.figuraTransportes[index] &&
            errors.figuraTransportes[index]
              .TipoFigura && // touched.figuraTransportes &&
            // touched.figuraTransportes[index] &&
            // touched.figuraTransportes[index].TipoFigura &&
            {
              content:
                errors.figuraTransportes[index] &&
                errors.figuraTransportes[index].TipoFigura,
            }
          }
        />
        <Form.Input
          placeholder="Nombre"
          label="Nombre *"
          name={`figuraTransportes.${index}.NombreFigura`}
          value={figuraTransporte.NombreFigura}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.figuraTransportes &&
            errors.figuraTransportes[index] &&
            errors.figuraTransportes[index].NombreFigura &&
            touched.figuraTransportes &&
            touched.figuraTransportes[index] &&
            touched.figuraTransportes[index].NombreFigura && {
              content:
                errors.figuraTransportes[index] &&
                errors.figuraTransportes[index].NombreFigura,
            }
          }
        />
        <Form.Input
          placeholder="Número de Licencia"
          label="Número de Licencia *"
          name={`figuraTransportes.${index}.NumLicencia`}
          value={figuraTransporte.NumLicencia}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.figuraTransportes &&
            errors.figuraTransportes[index] &&
            errors.figuraTransportes[index].NumLicencia &&
            touched.figuraTransportes &&
            touched.figuraTransportes[index] &&
            touched.figuraTransportes[index].NumLicencia && {
              content:
                errors.figuraTransportes[index] &&
                errors.figuraTransportes[index].NumLicencia,
            }
          }
        />
        <Form.Input
          placeholder="RFC"
          label="RFC *"
          name={`figuraTransportes.${index}.RFC`}
          value={figuraTransporte.RFC}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.figuraTransportes &&
            errors.figuraTransportes[index] &&
            errors.figuraTransportes[index].RFC &&
            touched.figuraTransportes &&
            touched.figuraTransportes[index] &&
            touched.figuraTransportes[index].RFC && {
              content:
                errors.figuraTransportes[index] &&
                errors.figuraTransportes[index].RFC,
            }
          }
        />
        <DropdownSearchFetch
          fluid
          selection
          label="Residencia Fiscal *"
          value={
            figuraTransporte.ResidenciaFiscal
              ? figuraTransporte.ResidenciaFiscal
              : 'MEX'
          }
          onChange={(e, { value }) =>
            setFieldValue(`figuraTransportes.${index}.ResidenciaFiscal`, value)
          }
          placeholder="Residencia Fiscal"
          featherService="catalogo-pais"
          columName="Descripcion"
          columValue="c_Pais"
          mapValues={(value) => {
            return {
              key: Math.random(),
              text: `${value['c_Pais']} - ${value['Descripcion']}`,
              value: value['c_Pais'],
            };
          }}
          error={
            errors.figuraTransportes &&
            errors.figuraTransportes[index] &&
            errors.figuraTransportes[index]
              .ResidenciaFiscal && // touched.figuraTransportes &&
            // touched.figuraTransportes[index] &&
            // touched.figuraTransportes[index].ResidenciaFiscal &&
            {
              content:
                errors.figuraTransportes[index] &&
                errors.figuraTransportes[index].ResidenciaFiscal,
            }
          }
        />
        {figuraTransporte.ResidenciaFiscal &&
          figuraTransporte.ResidenciaFiscal !== 'MEX' && (
            <>
              <Form.Input
                placeholder="Número de Registro Tributario"
                label="Número de Registro Tributario *"
                name={`figuraTransportes.${index}.NumRegTributario`}
                value={figuraTransporte.NumRegTributario}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors.figuraTransportes &&
                  errors.figuraTransportes[index] &&
                  errors.figuraTransportes[index].NumRegTributario &&
                  touched.figuraTransportes &&
                  touched.figuraTransportes[index] &&
                  touched.figuraTransportes[index].NumRegTributario && {
                    content:
                      errors.figuraTransportes[index] &&
                      errors.figuraTransportes[index].NumRegTributario,
                  }
                }
              />
            </>
          )}
      </Form.Group>
      {figuraTransporte.TipoFigura !== '01' && (
        <>
          <Form.Group widths={'equal'}>
            <Form.Input
              placeholder="Calle"
              label="Calle"
              name={`figuraTransportes.${index}.Calle`}
              value={figuraTransporte.Calle}
              onChange={handleChange}
              onBlur={handleBlur}
              //   error={
              //     validations.name && {
              //       content: 'Required idTipoComprobante',
              //     }
              //   }
            />
            <Form.Input
              placeholder="Número Exterior"
              label="Número Exterior"
              name={`figuraTransportes.${index}.NumExterior`}
              value={figuraTransporte.NumExterior}
              onChange={handleChange}
              onBlur={handleBlur}
              //   error={
              //     validations.name && {
              //       content: 'Required idTipoComprobante',
              //     }
              //   }
            />
            <Form.Input
              placeholder="Número Interior"
              label="Número Interior"
              name={`figuraTransportes.${index}.NumInterior`}
              value={figuraTransporte.NumInterior}
              onChange={handleChange}
              onBlur={handleBlur}
              //   error={
              //     validations.name && {
              //       content: 'Required idTipoComprobante',
              //     }
              //   }
            />
            <Form.Input
              placeholder="Referencia"
              label="Referencia"
              name={`figuraTransportes.${index}.Referencia`}
              value={figuraTransporte.Referencia}
              onChange={handleChange}
              onBlur={handleBlur}
              //   error={
              //     validations.name && {
              //       content: 'Required idTipoComprobante',
              //     }
              //   }
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <DropdownSearchFetch
              fluid
              selection
              label="País"
              value={figuraTransporte.Pais}
              onChange={(e, { value }) =>
                setFieldValue(`figuraTransportes.${index}.Pais`, value)
              }
              placeholder="País"
              featherService="catalogo-pais"
              columName="Descripcion"
              columValue="c_Pais"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_Pais']} - ${value['Descripcion']}`,
                  value: value['c_Pais'],
                };
              }}
            />
            <DropdownSearchFetch
              fluid
              selection
              label="Estado"
              value={figuraTransporte.Estado}
              onChange={(e, { value }) =>
                setFieldValue(`figuraTransportes.${index}.Estado`, value)
              }
              placeholder="Estado"
              featherService="catalogo-estado"
              columName="Nombre"
              columValue="c_Estado"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_Estado']} - ${value['Nombre']}`,
                  value: value['c_Estado'],
                };
              }}
            />
            {figuraTransporte.Pais === 'MEX' ? (
              <>
                <DropdownSearchFetch
                  fluid
                  selection
                  label="Municipio"
                  value={figuraTransporte.Municipio}
                  onChange={(e, { value }) =>
                    setFieldValue(`figuraTransportes.${index}.Municipio`, value)
                  }
                  placeholder="Municipio"
                  featherService="catalogo-municipio"
                  columName="Descripcion"
                  columValue="CmunicipioID"
                  mapValues={(value) => {
                    return {
                      key: Math.random(),
                      text: `${value['Descripcion']}`,
                      value: value['CmunicipioID'],
                    };
                  }}
                />
                <DropdownSearchFetch
                  fluid
                  selection
                  label="Localidad"
                  value={figuraTransporte.Localidad}
                  onChange={(e, { value }) =>
                    setFieldValue(`figuraTransportes.${index}.Localidad`, value)
                  }
                  placeholder="Localidad"
                  featherService="catalogo-localidad"
                  columName="Descripcion"
                  columValue="ClocalidadID"
                  mapValues={(value) => {
                    return {
                      key: Math.random(),
                      text: `${value['Descripcion']}`,
                      value: value['ClocalidadID'],
                    };
                  }}
                />
                <DropdownSearchFetch
                  fluid
                  selection
                  label="Código Postal"
                  value={figuraTransporte.CodPostal}
                  onChange={(e, { value }) =>
                    setFieldValue(
                      `figuraTransportes.${index}.CodigoPostal`,
                      value
                    )
                  }
                  placeholder="Código Postal"
                  featherService="catalogo-codigo-postal"
                  columName="c_CodigoPostal"
                  columValue="c_CodigoPostal"
                  mapValues={(value) => {
                    return {
                      key: Math.random(),
                      text: `${value['c_CodigoPostal']}`,
                      value: value['c_CodigoPostal'],
                    };
                  }}
                />
                <DropdownSearchFetch
                  fluid
                  selection
                  label="Colonia"
                  value={figuraTransporte.Colonia}
                  onChange={(e, { value }) =>
                    setFieldValue(`figuraTransportes.${index}.Colonia`, value)
                  }
                  placeholder="Colonia"
                  featherService="catalogo-colonia"
                  columName="Nombre"
                  columValue="CcoloniaID"
                  mapValues={(value) => {
                    return {
                      key: Math.random(),
                      text: `${value['Nombre']}`,
                      value: value['CcoloniaID'],
                    };
                  }}
                />
              </>
            ) : (
              <>
                <Form.Input
                  placeholder="Municipio"
                  label="Municipio"
                  name={`figuraTransportes.${index}.Municipio`}
                  value={figuraTransporte.Municipio}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  //   error={
                  //     validations.name && {
                  //       content: 'Required idTipoComprobante',
                  //     }
                  //   }
                />
                <Form.Input
                  placeholder="Localidad"
                  label="Localidad"
                  name={`figuraTransportes.${index}.Localidad`}
                  value={figuraTransporte.Localidad}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  //   error={
                  //     validations.name && {
                  //       content: 'Required idTipoComprobante',
                  //     }
                  //   }
                />
                <Form.Input
                  placeholder="CodigoPostal"
                  label="CodigoPostal"
                  name={`figuraTransportes.${index}.CodigoPostal`}
                  value={figuraTransporte.CodigoPostal}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  //   error={
                  //     validations.name && {
                  //       content: 'Required idTipoComprobante',
                  //     }
                  //   }
                />
                <Form.Input
                  placeholder="Colonia"
                  label="Colonia"
                  name={`figuraTransportes.${index}.Colonia`}
                  value={figuraTransporte.Colonia}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  //   error={
                  //     validations.name && {
                  //       content: 'Required idTipoComprobante',
                  //     }
                  //   }
                />
              </>
            )}
          </Form.Group>
        </>
      )}
    </div>
  );
};

export default forwardRef((props, ref) => (
  <FormFiguraTransporteChofer {...props} innerRef={ref} />
));
