import React from 'react';
import { Button, Container, Dropdown } from 'semantic-ui-react';
import HeaderBG from '../components/HeaderBG';
import ProductosTest from '../components/ProductosTest/Productos';
import SectionTitle from '../components/SectionTitle';
import DefaultLayout from '../layouts/DefaultLayout';

export default (props) => {
  return (
    <DefaultLayout
      currentPath={props.match.path}
      globalActions={
        <>
          <Dropdown.Menu />
        </>
      }
    >
      <HeaderBG />
      <Container style={{ width: '95%' }}>
        <SectionTitle>Products</SectionTitle>
        <ProductosTest props={props} />
      </Container>
    </DefaultLayout>
  );
};
