import React, { forwardRef } from 'react';
import { Form, Segment } from 'semantic-ui-react';
import { Icon, Label } from 'semantic-ui-react';
import ModalCertificado from './ModalCertificado';
import ModalLlave from './ModalLlave';
const FormHeader = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue
    /* and other goodies */
  } = formik;
  return (
    <>
      <Segment>
        
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="Descripcion"
            label="Descripcion"
            name="Descripcion"
            value={values.Descripcion}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Descripcion && { content: errors.Descripcion }
            }
          />
          <Form.Input
            placeholder="No Serie"
            label="PlNoSerieaca"
            name="NoSerie"
            value={values.NoSerie}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.NoSerie && { content: errors.NoSerie }
            }
          />
          <Form.Input
            placeholder="Vigencia Inicio"
            label="VigenciaInicio"
            name="VigenciaInicio"
            type="date"
            value={values.VigenciaInicio }
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.VigenciaInicio && { content: errors.VigenciaInicio }
            }
          />
          
          <Form.Input
            placeholder="Vigencia Fin"
            label="VigenciaFin"
            name="VigenciaFin"
            type="date"
            value={values.VigenciaFin}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.VigenciaFin && { content: errors.VigenciaFin }
            }
          />
          </Form.Group>
          <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="Ruta Certificado"
            label="Ruta"
            name="Ruta"
            value={values.Ruta}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Ruta && { content: errors.Ruta }
            }
          />
          <Form.Input
            placeholder="Ruta Llave"
            label="RutaLlave"
            name="RutaLlave"
            value={values.RutaLlave}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.RutaLlave && { content: errors.RutaLlave }
            }
          />
          {/* <Form.Field>
              <label>Ruta</label>
              <Label>
                {values.Ruta}
              </Label>
            </Form.Field>
          <Form.Field>
              <label>Ruta Llaves</label>
              <Label>
                {values.RutaLlave}
              </Label>
            </Form.Field> */}
          <Form.Input
            placeholder="Contraseña"
            label="Contrasena"
            name="Contrasena"
            value={values.Contrasena}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.Contrasena && { content: errors.Contrasena }
            }
          />
          <Form.Input
            placeholder="RFC"
            label="RFC"
            name="RFC"
            value={values.RFC}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.RFC && { content: errors.RFC }
            }
          />
          </Form.Group>
          <Form.Group widths={'equal'}>
          
          <Form.Field>
            <label>Status</label>
            
          <Form.Input
            name="Status"
            label={values.Status==1?"Activo":"Inactivo"}
            type="checkbox"
            checked={values.Status}
            onChange={(event) => {
              const target = event.target;
              const value = target.type === 'checkbox' ? target.checked : target.value;
              setFieldValue('Status', value?1:0)
            }}
            error={
              errors.Status && { content: errors.Status }
            }/>
          </Form.Field>
          <Form.Field>
            <label>Certificado</label>
            <ModalCertificado formik={formik} />
          </Form.Field>
          <Form.Field>
            <label>Llave</label>
            <ModalLlave formik={formik} />
          </Form.Field>
          <Form.Field>
            <label>&nbsp;</label>
            &nbsp;
          </Form.Field>
          </Form.Group>
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
