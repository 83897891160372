import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { useAuth } from './services/feathers';

import Login from './pages/Login';
import Parcels from './pages/Parcels';
// import ParcelsItems from './pages/ParcelItems';
import Orders from './pages/Orders';
import ProductosTest from './pages/ProductosTest';
//import Trips from './pages/Trips';
import BillOfLadingMx from './pages/BillOfLadingMx';
import Consolidations from './pages/Consolidations';
import Products from './pages/Products';
import Dailylogs from './pages/Dailylogs';
import RecoverPassword from './pages/RecoverPassword';
import Users from './pages/Users';
import Cat_Colonia from './pages/Cat_Colonia';
import ComplementoPago from './pages/ComplementoPago';
import ReportesTimbrado from './pages/ReportesTimbrado';
import Choferes from './pages/Choferes';
import Remolques from './pages/Remolques';
import Transportes from './pages/Transportes';
import Cat_Pais from './pages/Cat_Pais';
import Clients from './pages/Clients';
import Shippers from './pages/Shippers';
import Partners from './pages/Partners';
import Partnerships from './pages/Partnerships';
import BajaShipmentsReports from './pages/BajaShipmentsReport';
import AutomationRules from './pages/AutomationRules';
import Packing from './pages/Packing';
import Transactions from './pages/Transactions';
import BinCatalog from './pages/Bins';
// import Offers from './pages/Offers';
import ImportExport from './pages/ImportExport';
import Emisores from './pages/Emisores';
import Receptores from './pages/Receptores';
import Conceptos from './pages/Conceptos';
import Ubicaciones from './pages/Ubicaciones';
import TaxPac from './pages/TaxPacs';
import Sucursales from './pages/Sucursales';
import Certificados from './pages/Certificados';
import DarwinCCE from './pages/DarwinCCE/DarwinCCE';

export const getRoutes = (auth) =>
  auth
    ? [
        {
          title: 'Orders',
          path: '/orders',
          category: 'Logistics',
          component: Orders,
          allow: auth.user.allowOrders,
          exact: true,
        },
        // {
        //   title: 'Trips',
        //   path: '/trips',
        //   category: 'Logistics',
        //   component: Trips,
        //   allow: auth.user.allowTrips,
        //   exact: true,
        // },

        {
          title: 'Productos Test',
          path: '/productosTest',
          category: 'Logistics',
          component: ProductosTest,
          allow: auth.user.allowProductosTest,
          exact: true,
        },
        {
          title: 'Interface Darwin',
          path: '/anexo-24',
          category: 'Anexo 24',
          component: DarwinCCE,
          allow: auth.user.allowAnexo24,
          exact: true,
        },
        {
          title: 'Billing MX',
          path: '/bill-of-lading-mx',
          category: 'Billing MX',
          component: BillOfLadingMx,
          allow: auth.user.allowBillOfLadingMX,
          exact: true,
        },
        {
          title: 'Parcels',
          path: '/parcels',
          category: 'Logistics',
          component: Parcels,
          allow: auth.user.allowParcels,
          exact: true,
        },
        {
          title: 'Consolidated Cargo',
          path: '/consolidations',
          category: 'Operative',
          component: Consolidations,
          allow: auth.user.allowConsolidations,
          exact: true,
        },
        {
          title: 'Products',
          path: '/products',
          category: 'Operative',
          component: Products,
          allow: auth.user.allowProducts,
          exact: true,
        },
        {
          title: 'Dailylogs',
          path: '/dailylogs',
          category: 'Operative',
          component: Dailylogs,
          allow: auth.user.allowDailylogs,
          exact: true,
        },
        {
          title: 'Users',
          path: '/users',
          category: 'Admin',
          component: Users,
          allow: auth.user.allowUsers,
          exact: true,
        },
        {
          title: 'Clients',
          path: '/clients',
          category: 'Admin',
          component: Clients,
          allow: auth.user.allowClients,
          exact: true,
        },
        {
          title: 'Shippers',
          path: '/shippers',
          category: 'Admin',
          component: Shippers,
          allow: auth.user.allowShippers,
          exact: true,
        },
        {
          title: 'Partners',
          path: '/partners',
          category: 'Admin',
          component: Partners,
          allow: auth.user.allowPartners,
          exact: true,
        },
        {
          title: 'Partnerships',
          path: '/partnerships',
          category: 'Admin',
          component: Partnerships,
          allow: auth.user.allowPartnerships,
          exact: true,
        },
        // {
        //   title: 'Catálogo Países',
        //   path: '/cat_Pais',
        //   category: 'Admin',
        //   component: Cat_Pais,
        //   allow: auth.user.allowCat_Pais,
        //   exact: true,
        // },
        // {
        //   title: 'Catálogo Colonias',
        //   path: '/cat_Colonia',
        //   category: 'Admin',
        //   component: Cat_Colonia,
        //   allow: auth.user.allowCat_Colonia,
        //   exact: true,
        // },
        {
          title: 'Complemento Pago',
          path: '/complemento-pago',
          category: 'Billing MX',
          component: ComplementoPago,
          allow: auth.user.allowComplementoPago,
          exact: true,
        },
        {
          title: 'Reporte Folios',
          path: '/reportes-timbrado',
          category: 'Billing MX',
          component: ReportesTimbrado,
          allow: auth.user.allowReportesTimbrado,
          exact: true,
        },
        {
          title: 'Choferes',
          path: '/choferes',
          category: 'Billing MX',
          component: Choferes,
          allow: auth.user.allowChoferes,
          exact: true,
        },
        {
          title: 'Remolques',
          path: '/remolques',
          category: 'Billing MX',
          component: Remolques,
          allow: auth.user.allowRemolques,
          exact: true,
        },
        {
          title: 'Transportes',
          path: '/transportes',
          category: 'Billing MX',
          component: Transportes,
          allow: auth.user.allowTrasnsportes,
          exact: true,
        },
        {
          title: 'Emisores',
          path: '/emisores',
          category: 'Billing MX',
          component: Emisores,
          allow: auth.user.allowEmisores,
          exact: true,
        },
        {
          title: 'Receptores',
          path: '/receptores',
          category: 'Billing MX',
          component: Receptores,
          allow: auth.user.allowReceptores,
          exact: true,
        },
        {
          title: 'Conceptos',
          path: '/conceptos',
          category: 'Billing MX',
          component: Conceptos,
          allow: auth.user.allowConceptos,
          exact: true,
        },
        {
          title: 'Ubicaciones',
          path: '/ubicaciones',
          category: 'Billing MX',
          component: Ubicaciones,
          allow: auth.user.allowUbicaciones,
          exact: true,
        },
        // {
        //   title: 'Tax PACs',
        //   path: '/taxPaxs',
        //   category: 'Billing MX',
        //   component: TaxPac,
        //   allow: auth.user.allowTaxPacs,
        //   exact: true,
        // },
        // {
        //   title: 'Sucursales',
        //   path: '/Sucursales',
        //   category: 'Billing MX',
        //   component: Sucursales,
        //   allow: auth.user.allowSucursales,
        //   exact: true,
        // },
        {
          title: 'Certificados',
          path: '/Certificados',
          category: 'Billing MX',
          component: Certificados,
          allow: auth.user.allowCertificados,
          exact: true,
        },
        {
          title: 'Packing',
          path: '/packing',
          category: 'Operative',
          component: Packing,
          allow: auth.user.allowPacking,
          exact: false,
        },
        {
          title: 'Import-Export Report',
          path: '/import-export',
          category: 'Operative',
          component: ImportExport,
          allow: auth.user.allowImportExport,
        },
        {
          title: 'Baja Report',
          path: '/bajashipmentsreport',
          category: 'Reports',
          component: BajaShipmentsReports,
          allow: auth.user.allowBajaShipmentsReport,
        },
        {
          title: 'Automation Rules',
          path: '/automationrules',
          category: 'Logistics',
          component: AutomationRules,
          allow: auth.user.allowAutomationRules,
        },
        {
          title: 'Transactions',
          path: '/transactions',
          category: 'Reports',
          component: Transactions,
          allow: auth.user.allowTransactions,
        },
        {
          title: 'Bin Catalog',
          path: '/bins',
          category: 'Operative',
          component: BinCatalog,
          allow: auth.user.allowBinCatalog,
        },
        // BAJA savings uncompleted for now. Until see if working with Baja again
        // {
        //   title: 'Baja Revolve Savings',
        //   path: '/bajarevolvesavngs',
        //   category: 'Reports',
        //   component: BajaRevolveSavings,
        //   allow: auth.user.allowBajaRevolveSavings,
        // },
        // {
        //   title: 'Offers',
        //   path: "/offers",
        //   component: Offers,
        //   allow: auth.user.allowOffers,
        //   exact: true
        // }
      ]
    : [];

const AuthContext = React.createContext(false);

export default (props) => {
  const auth = useAuth();
  const [state, setState] = useState({
    isAuthenticated: false,
    ready: false,
    routes: [],
    pathTo: null,
  });

  useEffect(() => {
    // console.debug('Routes useEffect auth', auth);
    const id = setTimeout(() => {
      const state = {
        isAuthenticated: false,
        ready: false,
        routes: [],
        pathTo: null,
      };

      if (auth && auth.user !== null) {
        const routes = getRoutes(auth);
        const route = routes.find((route) => route.allow);

        state.isAuthenticated = auth.user && true;
        state.ready = true;
        state.routes = routes;
        state.pathTo = (route && route.path) || '/orders';
      }

      setState(state);
    }, 100);

    return () => clearTimeout(id);
  }, [auth]);

  return state.ready ? (
    <AuthContext.Provider value={state.isAuthenticated}>
      <BrowserRouter>
        <Switch>
          {/* Routes */}
          <PublicRoute
            path="/login"
            exact
            component={Login}
            pathTo={state.pathTo}
          />

          <PublicRoute
            path="/forgot"
            exact
            component={RecoverPassword}
            pathTo={state.pathTo}
          />

          {state.routes.map((route, index) => (
            <PrivateRoute
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.component}
              allow={route.allow}
            />
          ))}

          {/* Redirections */}
          <Route path="/" exact>
            <Redirect to={state.pathTo} />
          </Route>

          <Route path="*">
            <Redirect to={state.pathTo} />
          </Route>
        </Switch>
      </BrowserRouter>
    </AuthContext.Provider>
  ) : (
    <Fragment />
  );
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function SessionRoute({
  component: Component,
  needLogin,
  pathTo,
  allow = true,
  ...others
}) {
  return (
    <AuthContext.Consumer>
      {(isAuthenticated) => (
        <Route
          {...others}
          render={(routeProps) => {
            const isAllow = isAuthenticated === needLogin && allow;

            return isAllow ? (
              <Component {...routeProps} />
            ) : (
              <Redirect
                to={{
                  pathname: pathTo,
                  state: { from: routeProps.location },
                }}
              />
            );
          }}
        />
      )}
    </AuthContext.Consumer>
  );
}

const PublicRoute = (props) => SessionRoute({ ...props, needLogin: false });
/*
  path="/login"
  exact
  component={Login}
  pathTo={state.pathTo}
*/

const PrivateRoute = (props) =>
  SessionRoute({ ...props, needLogin: true, pathTo: '/login' });
