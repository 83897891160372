import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import FormCasosPartidas from './FormCasosPartidas';

const EditModal = ({
  dimmer,
  open,
  title,
  handleCancel,
  handleSave,
  currentRow,
  setCurrentRow,
  size,
  tipoDeCaso,
  setTipoDeCaso,
  primerCaso,
  setPrimerCaso,
  observaciones,
  setObservaciones,
  marca,
  setMarca,
  modelo,
  setModelo,
  subModelo,
  setSubModelo,
  numeroSerie,
  setNumeroSerie,
}) => {
  return (
    <Modal dimmer={dimmer} open={open} onClose={handleCancel} className={'sm'}>
      <Modal.Header>{title}</Modal.Header>
      {/* <Modal.Description>
          {title ? title : 'No Data'}
        </Modal.Description> */}
      <Modal.Content>
        <FormCasosPartidas
          currentRow={currentRow}
          setCurrentRow={setCurrentRow}
          tipoDeCaso={tipoDeCaso}
          setTipoDeCaso={setTipoDeCaso}
          primerCaso={primerCaso}
          setPrimerCaso={setPrimerCaso}
          observaciones={observaciones}
          setObservaciones={setObservaciones}
          marca={marca}
          setMarca={setMarca}
          modelo={modelo}
          setModelo={setModelo}
          subModelo={subModelo}
          setSubModelo={setSubModelo}
          numeroSerie={numeroSerie}
          setNumeroSerie={setNumeroSerie}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button
          content="Guardar"
          labelPosition="right"
          icon="checkmark"
          onClick={handleSave}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default EditModal;
