module.exports = () => {
  return {
    tipoOperacion: '',
    claveDocumento: '',
    codigoImportador: '',
    pesoBruto: '',
    bultos: '',
    transporteSalida: 7,
    transporteArribo: 7,
    transporteSalidaAduana: 7,
    destinoZona: 9,
    tipoArchivo: '',
    paisCompra: '',
    transportista: {
      codigoTransportista: '',
      idVehiculo: '',
      pais: '',
    },
    factura: {
      numeroFactura: '',
      fechaFacturacion: '',
      terminoFacturacion: '',
      monedaFacturacion: '',
      valorMoneda: '',
      valorDolares: '',
      codigoProveedor: '',
      observaciones: '',
      pesoBruto: '',
      pesoNeto: '',
      bultos: 0,
      fletes: 0,
      seguros: 0,
      embalajes: 0,
      otros: 0,
      origenMercancia: 'USA',
      descargoMercancia: 'USA',
      destinoMercancia: 'USA',
      codigoTipoBultos: '',
      tipoOperacion: '',
    },
    fechas: {
      fechaEntrada: null,
      fechaPago: null,
      fechaExtraccion: null,
      fechaPresentacion: null,
      fechaImp: null,
      fechaPagoOriginal: null,
    },
    mercancias: [
      {
        fraccion: '',
        descripcion: '',
        noParte: '',
        valorMercancia: '',
        cantidadComercial: '',
        unidadMedidaComercial: '',
        cantidadTarifa: '',
        paisOrigen: '',
        paisComprador: '',
        precioUnitario: '',
        valorAduana: '',
        valorDolares: '',
        umt: '',
        pesoNeto: '',
        pesoBruto: '',
        numeroFactura: '',
        ordenCompra: '',
        casosPartidas: {
          tipoDeCaso: '',
          primerCaso: '',
          observaciones: '',
          marca: '',
          modelo: '',
          subModelo: '',
          numeroSerie: '',
        },
      },
    ],
  };
};
