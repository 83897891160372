import React, { useState, useEffect, useRef } from 'react';
import { Form, Dimmer, Loader } from 'semantic-ui-react';
import SlidingPanel from '../../../components/SLSlidingPanel';
import swal from 'sweetalert';
import { Formik } from 'formik';
import Actions from './Actions';
import FormDocument from './FormDocument';
import ModalForm from './ModalForm';
import { useAuth } from '../../../services/feathers';
import InitialValuesBlank from './InitialValuesBlank';
import validationScheme from './validationSchema';

const Drawer = ({ openPanel, onClose }) => {
  const formikRef = useRef();
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const refForm = React.useRef(null);
  const [currentSection, setCurrentSection] = useState('EDIT' || 'CREATE');
  const [xmlString, setXmlString] = useState('');
  const [xmlVersion, setXmlVersion] = useState('');

  let drawerTitles = {
    CREATE: {
      title: 'Upload XML',
      subtitle: '',
    },
  };

  let title = drawerTitles.title;
  let subtitle = drawerTitles.subtitle;

  const handledOnClose = async () => {
    let confirmation = await swal('Do you want close this form?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      setCurrentSection('CREATE');
      onClose(false);
    }
  };
  useEffect(() => {
    getLastDocument();
  }, []);

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }
  const getLastDocument = () => {
    setLoading(false);
  };
  const handleSave = () => {
    console.log('handleSave');
  };

  return (
    <SlidingPanel
      isOpen={openPanel}
      size={100}
      title={title}
      subtitle={subtitle}
      onClose={handledOnClose}
      style={{ backgroundColor: '#f5f5f5' }}
    >
      {(!loading && (
        <Formik
          innerRef={formikRef}
          initialValues={InitialValuesBlank()}
          validateOnChange={false}
          validationSchema={validationScheme}
          onSubmit={handleSave}
        >
          {(formikProps) => (
            <Form
              onKeyDown={onKeyDown}
              onSubmit={formikProps.handleSubmit}
              loading={loading}
              style={{ marginRight: '15px' }}
            >
              <ModalForm
                formik={formikProps}
                initialValues={InitialValuesBlank()}
                xmlString={xmlString}
                setXmlString={setXmlString}
                xmlVersion={xmlVersion}
                setXmlVersion={setXmlVersion}
              />
              <FormDocument
                onSave={handleSave}
                ref={refForm}
                formik={formikProps}
              />

              <Actions
                style={{ 'justify-content': 'center' }}
                handledOnClose={handledOnClose}
                formik={formikProps}
                xmlString={xmlString}
                xmlVersion={xmlVersion}
                setLoading={setLoading}
              />
            </Form>
          )}
        </Formik>
      )) || (
        <Dimmer active inverted>
          <Loader inverted>Cargando</Loader>
        </Dimmer>
      )}
    </SlidingPanel>
  );
};

export default Drawer;
