import React, { useState, useEffect } from 'react';
import {
  Form as SemanticForm,
  Segment as SegmentForm,
  Label,
} from 'semantic-ui-react';
import styled from 'styled-components';
import swal from 'sweetalert';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import DatePicker from 'react-datepicker';
import feathers, { useAuth } from '../../../services/feathers';
import moment from 'moment';
import SemanticInput from '../../../components/SemanticInput';
import NumeroALetras from '../../BillOfLadingMx/Drawer/NumeroALetras';
import axios from 'axios';

const Form = ({
  isBusy,
  setIsBusy,
  dataPago: current,
  onSuccess,
  children,
  hasSelection,
  onClose,
  bankInformation,
  setGetComplement,
}) => {
  const [dataPago, setDataPago] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [fechaDocumento, setFechaDocumento] = useState(new Date());
  const { CtaOrdenante, NomBancoOrdExt } = bankInformation;
  const [ordenante, setOrdenante] = useState(CtaOrdenante);
  const [nomBank, setNomBank] = useState(NomBancoOrdExt);
  const [numOperacion, setNumOperacion] = useState();

  const serviceDocumentos = feathers.service('documentos');
  const serviceDocumentosConceptos = feathers.service('documentos-conceptos');
  const serviceDocumentosImpuestos = feathers.service(
    'documentos-conceptos-impuestos'
  );
  const serviceDocPagos = feathers.service('documentos-pagos');
  const serviceDocPagosImpuestos = feathers.service(
    'documentos-pagos-impuestos'
  );
  const [idFormaPago, setIdFormaPago] = useState('');
  const [monedaDR, setMonedaDR] = useState(hasSelection[0].idMoneda);
  const [TipoCambio, setTipoCambio] = useState(hasSelection[0].TipoDeCambio);
  const [TotalFacturas, setTotalFacturas] = useState();
  const servicePOS = feathers.service('pos');
  const servicePOSFolios = feathers.service('pos-folios');
  let serviceTimbrar = feathers.service('timbrar-cfdi');
  const [userPOS, setUserPOS] = useState(null);
  const auth = useAuth();

  useEffect(() => {
    let totalPago = 0.0;
    if (hasSelection) {
      for (let info of hasSelection) {
        totalPago = totalPago + parseFloat(info.MontoPago);
      }
    }
    setTotalFacturas(totalPago);
  }, []);

  useEffect(() => {
    setGetComplement({
      pFormaPago: idFormaPago,
      pFormaPagoDescripcion: '',
      CtaOrdenante: ordenante,
      NomBancoOrdExt: nomBank,
      NumOperacion: numOperacion,
      pFechaPago: startDate,
      TipoCambio: TipoCambio,
      MonedaDR: monedaDR,
    });
  }, [
    idFormaPago,
    ordenante,
    nomBank,
    startDate,
    numOperacion,
    TipoCambio,
    monedaDR,
  ]);

  const isObjEmpty = (obj) => {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  };

  const getPOSFolios = async (values) => {
    let response = await servicePOSFolios.find({
      query: {
        idPOS: values.idPOS,
        idTipoComprobante: values.idTipoComprobante,
        Serie: values.Serie,
      },
    });

    let data =
      (response && response.data && response.data.length && response.data[0]) ||
      null;
    return data;
  };

  const updateFolio = async (values) => {
    let data = await getPOSFolios(values);
    if (data) {
      let folio = parseInt(data.Folio);
      let response = await servicePOSFolios.patch(values.idPOS, {
        idPOS: data.idPOS,
        idTipoComprobante: data.idTipoComprobante,
        Serie: data.Serie,
        Folio: ++folio,
      });
      return folio;
    }
  };

  const timbrar = async (id, idPOS, idSucursal) => {
    let service = feathers.service('pos');
    let resultPos = await service.find({ query: { idPOS, idSucursal } });
    resultPos = (resultPos.data.length && resultPos.data[0]) || null;

    if (resultPos) {
      let idCertificado = resultPos.idCertificado;
      let idPAC = resultPos.idPAC;
      let resultTimbrado = await serviceTimbrar.create({
        idDocumento: id,
        idCertificado: idCertificado,
        idPAC: idPAC,
        sUser: auth.user._id,
      });
      if (
        resultTimbrado.resultTimbrado.errors &&
        resultTimbrado.resultTimbrado.errors.length
      ) {
        swal('Error', resultTimbrado.resultTimbrado.errors[0], 'error');
      } else {
        swal('Timbrado', '¡Se ha timbrado correctamente!', 'success');
      }
    }
  };

  const handledOnSubmit = async (event) => {
    if (dataPago && hasSelection) {
      try {
        setIsBusy(true);
        let dataDocumento;
        let dataPagoImpuestos = {};
        let id = parseInt(hasSelection[0].id);
        let resultDataDocumento = await serviceDocumentos.find({
          query: {
            id: id,
          },
        });

        dataDocumento = resultDataDocumento.data[0];
        dataDocumento.idTipoComprobante = 'P';
        dataDocumento.Serie = 'PG';
        dataDocumento.idMoneda = 'XXX';
        dataDocumento.idUsoCFDI = 'CP01';
        dataDocumento.TipoDeCambio = TipoCambio;
        dataDocumento.FechaDocumento = moment(fechaDocumento)
          .subtract(1, 'hours')
          .clone()
          .format('YYYY-MM-DDTHH:mm:ss.000[Z]');
        dataDocumento.Subtotal = 0.0;
        dataDocumento.ImpuestosTraslados = 0.0;
        dataDocumento.ImpuestosRetenciones = 0.0;
        dataDocumento.Total = 0.0;
        dataDocumento.TotalLetra = NumeroALetras(
          dataDocumento.Total,
          hasSelection[0].idMoneda
        );
        dataDocumento.cfd_version = null;
        dataDocumento.cfd_noCertificado = null;
        dataDocumento.cfd_Certificado = null;
        dataDocumento.cfd_sello = null;
        dataDocumento.cfd_cadenaOriginal = null;
        dataDocumento.cfd_xml = null;
        dataDocumento.timbre_version = null;
        dataDocumento.timbre_uuid = null;
        dataDocumento.timbre_FechaTimbrado = null;
        dataDocumento.timbre_noCertificadoSAT = null;
        dataDocumento.timbre_selloSAT = null;
        dataDocumento.timbre_RFCPAC = null;

        //Folios
        let userPOS = await servicePOS.get(auth.user.client._id);
        userPOS =
          userPOS && userPOS.length && userPOS[0] && !isObjEmpty(userPOS[0])
            ? userPOS[0]
            : null;
        setUserPOS(userPOS);

        let resultFolio = await updateFolio(dataDocumento);
        dataDocumento.Folio = resultFolio;
        dataDocumento.idStatus = 1;
        dataDocumento.id = null;

        let resultCreateDocumento = await serviceDocumentos.create(
          dataDocumento
        );

        //DocumentosConceptos
        let dataDocumentoConcepto = {};
        dataDocumentoConcepto.id = parseInt(resultCreateDocumento.id);
        dataDocumentoConcepto.idRow = 1;
        dataDocumentoConcepto.Cantidad = 1;
        dataDocumentoConcepto.idProducto = 0;
        dataDocumentoConcepto.Descripcion = 'Pago';
        dataDocumentoConcepto.PrecioUnitario = 0;
        dataDocumentoConcepto.Subtotal = 0;
        dataDocumentoConcepto.Descuento = 0;
        dataDocumentoConcepto.ImpuestosTraslados = 0;
        dataDocumentoConcepto.ImpuestosRetenciones = 0;
        dataDocumentoConcepto.Total = 0;
        dataDocumentoConcepto.idReferenciaModulo = 0;
        dataDocumentoConcepto.idReferencia = 0;
        dataDocumentoConcepto.ClaveProdServ = '84111506';
        dataDocumentoConcepto.UnidadMedida = '';
        dataDocumentoConcepto.ClaveUnidad = 'ACT';
        dataDocumentoConcepto.TipoConcepto = 0;

        let resultCreateDocumentoConcepto =
          await serviceDocumentosConceptos.create(dataDocumentoConcepto);

        let cont = 0;

        for (let data of hasSelection) {
          let montoPagado = parseFloat(data.MontoPago);

          dataPago.Total = montoPagado;
          dataPago.ImpSaldoAnt = data.SaldoActual;
          dataPago.ImpPagado = montoPagado;
          dataPago.ImpSaldoInsoluto = dataPago.ImpSaldoAnt - dataPago.ImpPagado;
          dataPago.PagosTotalFactura = data.SaldoActual;
          dataPago.PagosImportePagado = montoPagado;
          dataPago.MetodoPagoDR = 'PPD';
          dataPago.MonedaDR = monedaDR;
          dataPago.idUser = data.idSocioNegocio;
          dataPago.ref_Serie = data.Serie;
          dataPago.ref_Folio = data.Folio;
          dataPago.ref_uuid = data.timbre_uuid;
          dataPago.ref_id = parseInt(data.id);
          dataPago.id = parseInt(resultCreateDocumento.id);
          dataPago.idRow = ++cont;
          dataPago.NumParcialidad = data.Parcialidades + 1;
          dataPago.FechaPago = moment(startDate)
            .clone()
            .format('YYYY-MM-DDTHH:mm:ss.000[Z]');
          dataPago.Fecha_Log = moment(startDate)
            .clone()
            .format('YYYY-MM-DDTHH:mm:ss.000[Z]');
          dataPago.CtaOrdenante = ordenante;
          dataPago.NomBancoOrdExt = nomBank;

          let resultCreatePago = await serviceDocPagos.create(dataPago);
        }

        cont = 0;

        for (let data of hasSelection) {
          let resultDataConceptoImpuestos =
            await serviceDocumentosImpuestos.find({
              query: {
                id: data.id,
              },
            });

          if (resultDataConceptoImpuestos.data) {
            cont++;
            let idRowImpuesto = 1;
            let idsVistos = [];

            for (let impuesto of resultDataConceptoImpuestos.data) {
              resultDataConceptoImpuestos = impuesto;
              // Verificamos si el idImpuesto ya ha sido visto
              if (idsVistos.includes(impuesto.id)) {
                idRowImpuesto++;
              } else {
                idRowImpuesto = 1; // Reiniciamos a 1 si es un id nuevo
              }
              idsVistos.push(impuesto.id); // Agregamos el idImpuesto a la lista de vistos

              dataPagoImpuestos.id = parseInt(resultCreateDocumento.id);
              dataPagoImpuestos.idRow = cont;
              dataPagoImpuestos.idRowImpuesto = idRowImpuesto;
              dataPagoImpuestos.CodigoSAT =
                resultDataConceptoImpuestos.CodigoSAT;
              dataPagoImpuestos.TasaOCuota =
                resultDataConceptoImpuestos.TasaOCuota;
              dataPagoImpuestos.Importe = resultDataConceptoImpuestos.Importe;
              dataPagoImpuestos.ImporteBase =
                resultDataConceptoImpuestos.ImporteBase;
              dataPagoImpuestos.idImpuesto = 1;
              dataPagoImpuestos.idTipoTax =
                resultDataConceptoImpuestos.idTipoTax;
              dataPagoImpuestos.Valor = resultDataConceptoImpuestos.Valor;

              let resultCreatePago = await serviceDocPagosImpuestos.create(
                dataPagoImpuestos
              );
              console.log('Pago y Timbrar');
            }
          }
        }

        //Timbrar
        await timbrar(
          parseInt(resultCreateDocumento.id),
          resultCreateDocumento.idPOS,
          resultCreateDocumento.idSucursal
        );

        setIsBusy(false);
        onClose(true);
        // swal(
        //   'Guardado y Timbrado',
        //   '¡Se ha guardado y timbrado correctamente!',
        //   'success'
        // );
      } catch (exception) {
        console.log(exception);
        swal('Error', exception, 'error');
      }
    }
  };

  const handleChange = (_, { name, value }) => {};

  return (
    <SemanticForm onSubmit={handledOnSubmit} loading={isBusy}>
      <SemanticForm.Group>
        <SemanticForm.Field>
          <label>Total:</label>
          <Label>{TotalFacturas}</Label>
        </SemanticForm.Field>
      </SemanticForm.Group>
      <SemanticForm.Group widths={'equal'}>
        <div className="field">
          <label>
            <b>Fecha Pago*</b>
          </label>
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              dataPago.FechaPago = startDate;
            }}
            timeInputLabel="Hora:"
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeInput
          />
        </div>
        <DropdownSearchFetch
          fluid
          selection
          label="Forma de Pago *"
          value={idFormaPago}
          onChange={(e, { value }) => {
            setIdFormaPago(value);
            dataPago.idFormaPago = value;
          }}
          placeholder="Forma de Pago"
          featherService="catalogo-forma-pago"
          columName="Descripcion"
          columValue="c_FormaPago"
          mapValues={(value) => {
            return {
              key: Math.random(),
              text: `${value['c_FormaPago']} - ${value['Descripcion']}`,
              value: value['c_FormaPago'],
            };
          }}
        />
        <DropdownSearchFetch
          fluid
          selection
          label="Moneda *"
          value={monedaDR}
          onChange={(e, { value }) => {
            setMonedaDR(value);
            dataPago.MonedaDR = value;
          }}
          placeholder="Moneda"
          featherService="catalogo-moneda"
          columName="Descripcion"
          columValue="c_Moneda"
          mapValues={(value) => {
            return {
              key: Math.random(),
              text: `${value['c_Moneda']} - ${value['Descripcion']}`,
              value: value['c_Moneda'],
            };
          }}
        />
        {monedaDR !== 'MXN' && (
          <SemanticInput
            type="decimal"
            min={0}
            placeholder="Tipo de Cambio"
            label="Tipo de Cambio *"
            name="TipoDeCambio"
            value={TipoCambio}
            onChange={(e, { value }) => {
              setTipoCambio(value);
              dataPago.TipoCambio = value;
            }}
          />
        )}
      </SemanticForm.Group>
      {idFormaPago !== '01' && (
        <SemanticForm.Group widths={'equal'}>
          <DropdownSearchFetch
            fluid
            selection
            label="Nombre Banco"
            value={nomBank}
            onChange={(e, { value }) => {
              setNomBank(value);
              dataPago.NomBancoOrdExt = value;
            }}
            placeholder="Nombre Banco"
            featherService="catalogo-banco"
            columName="Descripcion"
            columValue="c_Banco"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_Banco']} - ${value['Descripcion']}`,
                value: value['c_Banco'],
              };
            }}
          />
          <SemanticForm.Input
            placeholder="Número Operación"
            label="Número Operación"
            name="NumOperacion"
            value={dataPago.NumOperacion}
            onChange={(e, { value }) => {
              setNumOperacion(value);
              dataPago.NumOperacion = value;
            }}
          />
          <SemanticForm.Input
            placeholder="Número Cuenta / Cheque"
            label="Número Cuenta / Cheque"
            name="CtaOrdenante"
            value={ordenante}
            onChange={(e, { value }) => {
              value = value.trim();
              value = value.replace(/\s+/g, '');
              setOrdenante(value);
              dataPago.CtaOrdenante = value;
            }}
          />
        </SemanticForm.Group>
      )}

      {children}
    </SemanticForm>
  );
};

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto;
  grid-gap: 1em;
  grid-template-areas: 'name . .' '. . .';
`;

export default Form;
