import React from 'react';
import { Form, Segment, Header } from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

const FormRegistroFechas = ({ formik }) => {
  let { values, setFieldValue } = formik;
  return (
    <>
      <Segment>
        <Header as="h3">Registro de Fechas</Header>
        <Form.Group widths={'equal'}>
          <div className="field">
            <label>Fecha de Entrada</label>
            <DatePicker
              placeholderText="Fecha de Entrada"
              selected={values.fechas.fechaEntrada}
              onChange={(data) => {
                setFieldValue('fechas.fechaEntrada', data);
              }}
              dateFormat="MM/dd/yyyy"
              name="fechas.fechaEntrada"
              value={
                values.fechas.fechaEntrada
                  ? moment(values.fechas.fechaEntrada).format('YYYY-MM-DD')
                  : ''
              }
            />
          </div>
          <div className="field">
            <label>Fecha de Pago</label>
            <DatePicker
              placeholderText="Fecha de Pago"
              selected={values.fechas.fechaPago}
              onChange={(data) => {
                setFieldValue('fechas.fechaPago', data);
              }}
              dateFormat="MM/dd/yyyy"
              name="fechas.fechaPago"
              value={
                values.fechas.fechaPago
                  ? moment(values.fechas.fechaPago).format('YYYY-MM-DD')
                  : ''
              }
            />
          </div>
          <div className="field">
            <label>Fecha de Extraccion</label>
            <DatePicker
              placeholderText="Fecha de Extraccion"
              selected={values.fechas.fechaExtraccion}
              onChange={(data) => {
                setFieldValue('fechas.fechaExtraccion', data);
              }}
              dateFormat="MM/dd/yyyy"
              name="fechas.fechaExtraccion"
              value={
                values.fechas.fechaExtraccion
                  ? moment(values.fechas.fechaExtraccion).format('YYYY-MM-DD')
                  : ''
              }
            />
          </div>
        </Form.Group>
        <Form.Group widths={'equal'}>
          <div className="field">
            <label>Fecha de Presentacion</label>
            <DatePicker
              placeholderText="Fecha de Presentacion"
              selected={values.fechas.fechaPresentacion}
              onChange={(data) => {
                setFieldValue('fechas.fechaPresentacion', data);
              }}
              dateFormat="MM/dd/yyyy"
              name="fechas.fechaPresentacion"
              value={
                values.fechas.fechaPresentacion
                  ? moment(values.fechas.fechaPresentacion).format('YYYY-MM-DD')
                  : ''
              }
            />
          </div>
          <div className="field">
            <label>Fecha Imp EUA/CAN</label>
            <DatePicker
              placeholderText="Fecha Imp EUA/CAN"
              selected={values.fechas.fechaImp}
              onChange={(data) => {
                setFieldValue('fechas.fechaImp', data);
              }}
              dateFormat="MM/dd/yyyy"
              name="fechas.fechaImp"
              value={
                values.fechas.fechaImp
                  ? moment(values.fechas.fechaImp).format('YYYY-MM-DD')
                  : ''
              }
            />
          </div>
          <div className="field">
            <label>Fecha de Pago Original</label>
            <DatePicker
              placeholderText="Fecha de Pago Original"
              selected={values.fechas.fechaPagoOriginal}
              onChange={(data) => {
                setFieldValue('fechas.fechaPagoOriginal', data);
              }}
              dateFormat="MM/dd/yyyy"
              name="fechas.fechaPagoOriginal"
              value={
                values.fechas.fechaPagoOriginal
                  ? moment(values.fechas.fechaPagoOriginal).format('YYYY-MM-DD')
                  : ''
              }
            />
          </div>
        </Form.Group>
      </Segment>
    </>
  );
};

export default FormRegistroFechas;
