import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { FlatfileButton } from '@flatfile/react';
import feathers from '../../services/feathers';

const TopNavigation = ({ isBusy, onCreateClick, isValid, onSaveBulkData }) => {
  const [subTipoRem, setSubTipoRem] = useState();

  useEffect(() => {
    async function cargarCatalogos() {
      const subTipoRemolqueService = await feathers.service(
        'catalogo-sub-tipo-rem'
      );
      let catalogoSubTipoRem = await subTipoRemolqueService.find();
      let catalogoCodigoSubTipoRem = catalogoSubTipoRem.data.map((tipo) => {
        return { value: tipo.CSubTipoRemID, label: tipo.c_SubTipoRem };
      });
      setSubTipoRem(catalogoCodigoSubTipoRem);
    }
    cargarCatalogos();
  }, []);

  return (
    <>
      {subTipoRem && (
        <FlatfileButton
          settings={{
            type: 'remolques',
            fields: [
              {
                label: 'Placa',
                key: 'Placa',
                validators: [{ validate: 'required' }],
              },
              {
                label: 'Alias',
                key: 'Alias',
                validators: [{ validate: 'required' }],
              },
              {
                label: 'SubTipoRemolque',
                key: 'SubTipoRemolque',
                type: 'select',
                options: subTipoRem,
              },
            ],
          }}
          licenseKey={'8966b5ef-41eb-4d04-806b-f05b03741e6e'}
          customer={{
            companyId: 'ABC-123',
            companyName: 'ABC Corp.',
            email: 'john@abc123.com',
            name: 'John Smith',
            userId: '12345',
          }}
          onData={async (results) => {
            // do something with the results
            onSaveBulkData(results);
          }}
          render={(importer, launch) => {
            return (
              <Button
                primary
                compact
                onClick={launch}
                disabled={isBusy || !isValid}
              >
                Upload file
              </Button>
            );
          }}
        />
      )}
      &nbsp;
      <Button
        primary
        compact
        onClick={onCreateClick}
        disabled={isBusy || !isValid}
      >
        Crear Remolque
      </Button>
    </>
  );
};

TopNavigation.propTypes = {
  isBusy: PropTypes.bool,
  onCreateClick: PropTypes.func,
  isValid: PropTypes.bool,
};

export default TopNavigation;
