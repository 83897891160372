import React, { forwardRef, useState } from 'react';
import { Form, Segment, Header, Modal, Button, Table } from 'semantic-ui-react';
import DropDown from '../../../components/Formik/Dropdown';
import feathers from '../../../services/feathers';
import DropdownSimpleFetch from '../../../components/Formik/DropdownSimpleFetch';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import DropdownAutoTransporte from './DropdownAutoTransporte';
import FormAutotransporteRemolques from './FormAutotransporteRemolques';

const FormHeader = ({ formik, isMaterialPeligroso }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleSearchChange,
    getOptions,
    toggleSearch,
    fetchOptions,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    /* and other goodies */
  } = formik;

  const [open, setOpen] = useState(false);

  const [fetchOpt, setfetchOpt] = useState([]);
  const [value, setValue] = useState('');

  const handleValidate = () => {
    formik.validateForm().then((errors) => {
      formik.setTouched({ ...formik.touched, ...errors });
    });
  };

  return (
    <>
      <Segment>
        <Header as="h3">Autotransporte</Header>
        <Button
          type="button"
          content="Editar"
          primary
          onClick={() => setOpen(true)}
        />
        {values.AutoPlacaVM || values.PermSCT ? (
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Auto Placa</Table.HeaderCell>
                <Table.HeaderCell>Tipo de permiso</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {values.AutoPlacaVM || values.PermSCT ? (
              <Table.Body>
                <Table.Row
                  error={
                    errors.PermSCT ||
                    errors.AutoConfigVehicular ||
                    errors.AseguraRespCivil ||
                    errors.AutoAnioModeloVM ||
                    errors.AutoPlacaVM ||
                    errors.NumPermitoSCT ||
                    errors.PolizaRespCivil
                  }
                >
                  <Table.Cell>{values?.AutoPlacaVM}</Table.Cell>
                  <Table.Cell>{values?.PermSCT}</Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : null}
          </Table>
        ) : null}
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          size="fullscreen"
        >
          <Modal.Content>
            <Form.Group>
              <DropdownAutoTransporte formik={formik} autoTransporte={values} />
            </Form.Group>
            <Modal.Header as="h3">Editar Autotransporte</Modal.Header>
            <div className="ui form">
              <Form.Group widths={'equal'}>
                <DropdownSearchFetch
                  fluid
                  selection
                  label="Tipo Permiso SCT *"
                  value={values.PermSCT}
                  onChange={(e, { value }) => setFieldValue('PermSCT', value)}
                  placeholder="Tipo Permiso SCT"
                  featherService="catalogo-tipo-permiso"
                  columName="Descripcion"
                  columValue="c_TipoPermiso"
                  mapValues={(value) => {
                    return {
                      key: Math.random(),
                      text: `${value['c_TipoPermiso']} - ${value['Descripcion']}`,
                      value: value['c_TipoPermiso'],
                    };
                  }}
                  error={errors.PermSCT && { content: errors.PermSCT }}
                />
                <DropdownSearchFetch
                  fluid
                  selection
                  label="Autotransporte *"
                  value={values.AutoConfigVehicular}
                  onFetchInit={(fetchOptions) => {
                    setfetchOpt(fetchOptions);
                  }}
                  onChange={(e, { value }, fetchOptions) => {
                    setfetchOpt(fetchOptions);
                    setValue(value);
                    setFieldValue('AutoConfigVehicular', value);
                  }}
                  placeholder="Autotransporte"
                  featherService="catalogo-config-autotransporte"
                  columName="Descripcion"
                  columValue="c_ConfigAutotransporte"
                  mapValues={(value) => {
                    return {
                      key: Math.random(),
                      text: `${value['c_ConfigAutotransporte']} - ${value['Descripcion']}`,
                      value: value['c_ConfigAutotransporte'],
                    };
                  }}
                  error={
                    errors.AutoConfigVehicular && {
                      content: errors.AutoConfigVehicular,
                    }
                  }
                />
              </Form.Group>
              <Form.Group widths={'equal'}>
                <Form.Input
                  placeholder="Número de Permiso Otorgado por la SCT"
                  label="Número de Permiso Otorgado por la SCT *"
                  name="NumPermitoSCT"
                  value={values.NumPermitoSCT}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.NumPermitoSCT &&
                    touched.NumPermitoSCT && { content: errors.NumPermitoSCT }
                  }
                />
                <Form.Input
                  placeholder="Placa"
                  label="Placa *"
                  name="AutoPlacaVM"
                  value={values.AutoPlacaVM}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.AutoPlacaVM &&
                    touched.AutoPlacaVM && { content: errors.AutoPlacaVM }
                  }
                />
                <Form.Input
                  type="number"
                  min={0}
                  placeholder="Año Modelo"
                  label="Año Modelo *"
                  name="AutoAnioModeloVM"
                  value={values.AutoAnioModeloVM}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.AutoAnioModeloVM &&
                    touched.AutoAnioModeloVM && {
                      content: errors.AutoAnioModeloVM,
                    }
                  }
                />
                <Form.Input
                  type="decimal"
                  min={0}
                  placeholder="Peso Bruto Vehicular"
                  label="Peso Bruto Vehicular *"
                  name="PesoBruto"
                  value={values.PesoBruto}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.PesoBruto &&
                    touched.PesoBruto && {
                      content: errors.PesoBruto,
                    }
                  }
                />
              </Form.Group>
              <Form.Group widths={'equal'}>
                <Form.Input
                  placeholder="Responsabilidad Civil (Aseguradora)"
                  label="Responsabilidad Civil (Aseguradora) *"
                  name="AseguraRespCivil"
                  value={values.AseguraRespCivil}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.AseguraRespCivil &&
                    touched.AseguraRespCivil && {
                      content: errors.AseguraRespCivil,
                    }
                  }
                />
                <Form.Input
                  placeholder="Responsabilidad Civil (Poliza)"
                  label="Responsabilidad Civil (Poliza) *"
                  name="PolizaRespCivil"
                  value={values.PolizaRespCivil}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.PolizaRespCivil &&
                    touched.PolizaRespCivil && {
                      content: errors.PolizaRespCivil,
                    }
                  }
                />
                {isMaterialPeligroso && (
                  <>
                    <Form.Input
                      placeholder="Medio Ambiente (Aseguradora)"
                      label="Medio Ambiente (Aseguradora)"
                      name="AseguraMedAmbiente"
                      value={values.AseguraMedAmbiente}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors.AseguraMedAmbiente &&
                        touched.AseguraMedAmbiente && {
                          content: errors.AseguraMedAmbiente,
                        }
                      }
                    />
                    <Form.Input
                      placeholder="Medio Ambiente (Poliza)"
                      label="Medio Ambiente (Poliza)"
                      name="PolizaMedAmbiente"
                      value={values.PolizaMedAmbiente}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors.PolizaMedAmbiente &&
                        touched.PolizaMedAmbiente && {
                          content: errors.PolizaMedAmbiente,
                        }
                      }
                    />
                  </>
                )}
                <Form.Input
                  placeholder="Carga (Aseguradora)"
                  label="Carga (Aseguradora)"
                  name="AseguraCarga"
                  value={values.AseguraCarga}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.AseguraCarga &&
                    touched.AseguraCarga && { content: errors.AseguraCarga }
                  }
                />
                <Form.Input
                  placeholder="Carga (Poliza)"
                  label="Carga (Poliza)"
                  name="PolizaCarga"
                  value={values.PolizaCarga}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.PolizaCarga &&
                    touched.PolizaCarga && { content: errors.PolizaCarga }
                  }
                />
                <Form.Input
                  type="decimal"
                  placeholder="Prima Seguro"
                  min={0}
                  label="Prima Seguro *"
                  name="PrimaSeguro"
                  value={values.PrimaSeguro}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.PrimaSeguro &&
                    touched.PrimaSeguro && { content: errors.PrimaSeguro }
                  }
                />
              </Form.Group>

              <FormAutotransporteRemolques
                formik={formik}
                fetchOpt={fetchOpt}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="button"
              content="Cancelar"
              color="red"
              labelPosition="right"
              icon="cancel"
              onClick={() => setOpen(false)}
            />
            <Button
              content="Cerrar y guardar"
              labelPosition="right"
              icon="checkmark"
              onClick={() => (handleValidate(), setOpen(false))}
              positive
            />
          </Modal.Actions>
        </Modal>{' '}
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
