import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import HeaderBG from '../../components/HeaderBG';
import Drawer from './Drawer';
import { Container, Breadcrumb } from 'semantic-ui-react';
import Table from '../Receptores/Table';
import swal from 'sweetalert';
import feathers, { useAuth } from '../../services/feathers';
import Filters from './Filters';
import TopNavigation from './TopNavigation';

const Receptores = (props) => {
  const service = feathers.service('receptores');
  const serviceUsoCFDI = feathers.service('catalogo-uso-cfdi');
  const serviceMetodoPago = feathers.service('catalogo-metodo-pago');
  const serviceFormaPago = feathers.service('catalogo-forma-pago');
  const auth = useAuth();

  let [query, setQuery] = useState({
    Nombre: { $like: '%%' },
    Organizacion: auth.user.client._id,
  });
  const [isBusy, setIsBusy] = useState(false);
  const [selection, setSelection] = useState([]);
  const [data, setData] = useState([]);
  const [isSupport, setIsSupport] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [currentReceptor, setCurrentReceptor] = useState({});
  const [receptor, setReceptor] = useState(null);

  useEffect(() => {
    const filter = () => {
      if (!query)
        query = { Nombre: { $like: '%%' }, Organizacion: auth.user.client._id };
      getData();
    };

    filter();
  }, [query]);

  useEffect(() => {
    const { isSupport } = (auth && auth.user) || {},
      client = (auth && auth.user && auth.user.client) || null,
      isInvalid = (!isSupport && !client && true) || false;

    setIsSupport(isSupport);
    setReceptor(client);
    setIsValid(!isInvalid);
  }, [auth.user]);

  const handleCreateClick = () => {
    const [clients, client] = auth.user.isSupport
      ? [[], {}]
      : [[{ ...auth.user.client }], { ...auth.user.client }];
    openDrawer({ clients, client });
  };

  const openDrawer = async (receptor) => {
    receptor.Organizacion = auth.user.client._id;

    //getUsoCFDI
    if (receptor.idUsoCFDI) {
      let result = await serviceUsoCFDI.find({
        query: {
          CCFDIID: receptor.idUsoCFDI,
        },
      });
      if (result) {
        receptor.idUsoCFDIString = result.data[0].c_UsoCFDI;
      }
    } else {
      receptor.idUsoCFDI = 1;
      receptor.idUsoCFDIString = 'G01';
    }

    //getFormaPago
    if (receptor.idFormaPago) {
      let result = await serviceFormaPago.find({
        query: {
          CFpagoID: receptor.idFormaPago,
        },
      });
      if (result) {
        receptor.idFormaPagoString = result.data[0].c_FormaPago;
      }
    } else {
      receptor.idFormaPago = 44;
      receptor.idFormaPagoString = '99';
    }

    //getMetodoPago
    if (receptor.idMetodoPago) {
      let result = await serviceMetodoPago.find({
        query: {
          CMpagoID: receptor.idMetodoPago,
        },
      });
      if (result) {
        receptor.idMetodoPagoString = result.data[0].c_MetodoPago;
      }
    } else {
      receptor.idMetodoPago = 4;
      receptor.idMetodoPagoString = 'PPD';
    }

    setCurrentReceptor(receptor);
    setOpenPanel(true);
  };

  const handleClose = (isSuccess) => {
    setCurrentReceptor({});
    setOpenPanel(false);

    isSuccess && getData();
  };

  const getData = async () => {
    if (query) {
      let data = [],
        result = [];
      try {
        setIsBusy(true);
        setSelection([]);

        if (query.Organizacion != '') {
          do {
            result = await service.find({
              query: {
                ...query,
                $skip: data.length,
              },
            });
            data = data.concat(result.data);
          } while (data.length !== result.total);
        }
      } catch (err) {
        console.error('Error', err);
        data = [];
        swal({
          title: 'Error',
          text: 'Failed get recepor',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);
      }
    }
  };

  const handleEditClick = (receptor) => {
    openDrawer(receptor);
  };

  const handleDeleteClick = async (data) => {
    let confirmation = await swal('Do you want delete this item?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      try {
        await service.remove(data.id);
        swal('Borrado', '¡Se ha borrado correctamente!', 'success');
      } catch (exception) {
        swal('Error', 'Dont delete transporte', 'error');
      }
      getData();
    }
  };

  const getClientName = () =>
    (auth && auth.user && auth.user.client && auth.user.client.name) || null;

  return (
    <>
      <DefaultLayout
        currentPath={props.match.path}
        globalActions={
          <TopNavigation
            isBusy={isBusy}
            onCreateClick={handleCreateClick}
            isValid={isValid}
          />
        }
      >
        <HeaderBG />
        <Container className="fluid-95">
          <Breadcrumb
            icon="right angle"
            sections={[
              { key: getClientName(), content: getClientName(), link: false },
              // { key: 'Carta Porte', content: 'Carta Porte', link: false },
              {
                key: 'BillOfLadingMx',
                content: 'Receptores',
                active: true,
              },
            ]}
          />
          <Filters
            isBusy={isBusy}
            setQuery={setQuery}
            isValid={isValid}
            getData={getData}
          />
          <Table
            data={data}
            selection={selection}
            setSelection={setSelection}
            isSupport={isSupport}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
          ></Table>
        </Container>
      </DefaultLayout>
      {openPanel ? (
        <Drawer
          openPanel={openPanel}
          onClose={handleClose}
          currentReceptor={currentReceptor}
        ></Drawer>
      ) : null}
    </>
  );
};

export default Receptores;
