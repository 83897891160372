import React from 'react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';

function DropdownDestinoZona({ formik, destinoZona, setDestinoZona }) {
  let { errors, touched, setFieldValue, handleBlur } = formik;
  return (
    <DropdownSearchFetch
      fluid
      selection
      label="Destino o Zona"
      name="destinoZona"
      value={destinoZona.description ? destinoZona.description : ''}
      onChange={(e, { value }) => {
        setFieldValue('destinoZona', value);
        setDestinoZona({ ...destinoZona, description: value });
      }}
      placeholder="Destino o Zona"
      featherService="catalogo-destinos-mercancias"
      columName="Descripcion"
      columValue="c_DestinoMercancia"
      mapValues={(value) => {
        return {
          key: value['c_DestinoMercancia'],
          text: `${value['Descripcion']}`,
          value: value['c_DestinoMercancia'],
        };
      }}
      isFormDrop={true} //bandera para mostrar dropDown con errors
      onBlur={(_, data) => {
        Object.freeze(data);
        data = { ...data, name: 'destinoZona' };
        handleBlur({ target: { ...data } });
      }}
      error={
        errors.destinoZona &&
        touched.destinoZona && { content: errors.destinoZona }
      }
    />
  );
}

export default DropdownDestinoZona;
