import React, { forwardRef, useState } from 'react';
import { Form, Segment, Header } from 'semantic-ui-react';
import DropdownPais from './DropdownPais';

const FormTransportista = ({ formik }) => {
  let { errors, touched, values, handleChange, handleBlur } = formik;
  const [val, setValues] = useState({
    Pais: '',
  });

  return (
    <>
      <Segment>
        <Header as="h3">Registro de Transportista</Header>
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="Codigo del Transportista"
            label="Codigo del Transportista"
            name="transportista.codigoTransportista"
            value={values.transportista.codigoTransportista}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.transportista &&
              errors.transportista.codigoTransportista &&
              touched.transportista &&
              touched.transportista.codigoTransportista && {
                content: errors.transportista.codigoTransportista,
              }
            }
          />

          <Form.Input
            placeholder="ID del Vehículo"
            label="ID del Vehículo"
            name="transportista.idVehiculo"
            value={values.transportista.idVehiculo}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.transportista &&
              errors.transportista.idVehiculo &&
              touched.transportista &&
              touched.transportista.idVehiculo && {
                content: errors.transportista.idVehiculo,
              }
            }
          />
          <DropdownPais
            placeholder="Pais"
            label="Pais"
            name="transportista.pais"
            value={values.transportista.pais}
            val={val}
            setValues={setValues}
            child={'transportista'}
            formik={formik}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.transportista &&
              errors.transportista.pais &&
              touched.transportista &&
              touched.transportista.pais && {
                content: errors.transportista.pais,
              }
            }
          />
        </Form.Group>
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormTransportista {...props} innerRef={ref} />
));
