import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { statusOptions, countryOptions } from '../../constants/statusOptions';
import DateRangePicker from '../DateRangePicker';
import SemanticInput from '../SemanticInput';
import SemanticDropdown from '../SemanticDropdown';
import ButtonsFilter from '../ButtonsFilter';
import feathers from '../../services/feathers';

const queryInit = {
  orderDate: {
    $gte: moment().startOf('day'),
    $lte: moment().endOf('day'),
  },
  shopName: '',
  orderName: '',
};

const reducerQuery = (prevState, { name, value, type, query }) => {
  if (type === 'init' && query) {
    return query;
  } else if (name) {
    return { ...prevState, [name]: value };
  }

  return prevState;
};

const OrdersFilters = ({ isBusy, query: base, setQuery }) => {
  // States
  const [query, dispatchQuery] = useReducer(reducerQuery, { ...queryInit });

  // Effects
  useEffect(() => {
    // console.count('OrdersFilters query');
    const sendQuery = async () => {
      handleSearchClick();
      const productos = await feathers.service('product-data').find({});
      //console.log('Produtos');
      //console.log(productos);
      const ordenes = await feathers.service('orders').find({});
      //console.log('Ordenes');
      //console.log(ordenes);
    };

    sendQuery();
  }, []);

  // Handles
  const handleChangeInput = (_, { name, value }) => {
    const names = name.split('.');
    const newQuery = {
      name: names.shift(),
      value,
    };

    //console.log('newQuery', newQuery);
    //console.log('name:', name);
    //console.log('value:', value);
    if (names.length) {
      newQuery.value = {
        ...query[newQuery.name],

        [names.shift()]: value,
      };
    }

    dispatchQuery(newQuery);
  };

  const handleSearchClick = (event, data) => {
    const toSearch = ($search) => ({ $search });
    const { shopName, orderNumber } = query;
    /*const queryShippingAddress = Object.keys(shipping_address).reduce(
      (prev, item) => {
        if (shipping_address[item] && shipping_address[item].length) {
          const value =
            item === 'country'
              ? shipping_address[item][0] !== 'NUS'
                ? { $in: [...shipping_address[item]] }
                : { $nin: ['US'] }
              : toSearch(shipping_address[item]);

          return {
            ...prev,

            [`shipping_address.${item}`]: value,
          };
        }

        return prev;
      },
      {}
    );*/

    const queryMap = {
      orderDate: query.orderDate,
      ...(shopName && { shopName: toSearch(shopName) }),
      ...(orderNumber && { orderNumber: toSearch(orderNumber) }),
    };

    //console.debug('query', JSON.stringify(queryMap, null, 2));
    setQuery && setQuery(queryMap);
  };

  const handleClearClick = (event, data) => {
    dispatchQuery({
      type: 'init',
      query: {
        ...queryInit,

        orderDate: {
          $gte: moment().startOf('day'),
          $lte: moment().endOf('day'),
        },
      },
    });
  };

  return (
    <>
      {/* <pre>query: {JSON.stringify(query, null, 2)}</pre> */}

      <FiltersGrid>
        <DateRangePicker
          name="orderDate"
          label="Order Date"
          timePicker={false}
          startDate={query.orderDate.$gte}
          endDate={query.orderDate.$lte}
          maxDate={moment()}
          onChange={([$gte, $lte]) => {
            dispatchQuery({
              name: 'orderDate',
              value: { $gte, $lte },
            });
          }}
          style={{ gridArea: 'date' }}
          disabled={isBusy}
        />

        <SemanticInput
          name="shopName"
          label="Store"
          value={query.shopName}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'store' }}
          disabled={isBusy}
        />

        {/*<SemanticInput
          name="tags"
          label="Tags"
          value={query.tags}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'tags' }}
          disabled={isBusy}
        />*/}
        {/*<SemanticInput
          name="shipping_address.first_name"
          label="Consignee Name"
          value={query.shipping_address.first_name}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'consignee' }}
          disabled={isBusy}
      />*/}
      </FiltersGrid>
      <FiltersGrid>
        {/*<SemanticInput
          name="shipping_address.state"
          label="Consignee State"
          value={query.shipping_address.state}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'province' }}
          disabled={isBusy}
        />*/}

        {/*<SemanticInput
          name="shipping_address.zip"
          label="Consignee ZIP"
          value={query.shipping_address.zip}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'zip' }}
          disabled={isBusy}
        />*/}

        {/*<SemanticDropdown
          name="shipping_address.country"
          label="Country"
          value={query.shipping_address.country}
          onChange={handleChangeInput}
          fluid
          multiple
          selection
          options={countryOptions}
          style={{ gridArea: 'country' }}
          disabled={isBusy}
        />
        */}

        {/*<SemanticDropdown
          name="integrator_fulfillment_status"
          label="Status"
          value={query.integrator_fulfillment_status}
          onChange={handleChangeInput}
          fluid
          multiple
          selection
          options={statusOptions}
          style={{ gridArea: 'status' }}
          disabled={isBusy}
      />*/}
      </FiltersGrid>
      <FiltersGrid>
        <SemanticInput
          name="orderNumber"
          label="Order Number"
          value={query.orderNumber}
          onChange={handleChangeInput}
          fluid
          style={{ gridArea: 'tags' }}
          disabled={isBusy}
        />
      </FiltersGrid>
      <ButtonsFilter
        isBusy={isBusy}
        onSearchClick={handleSearchClick}
        onClearClick={handleClearClick}
      />
    </>
  );
};

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto;
  grid-gap: 1em;
  grid-template-areas:
    'date store consignee province'
    'zip country status tags';
`;

OrdersFilters.propTypes = {
  isBusy: PropTypes.bool,
  query: PropTypes.object,
  setQuery: PropTypes.func,
};

export default OrdersFilters;
