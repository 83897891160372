import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';

const ButtonsFilterComprobantePago = ({
  isBusy,
  isSearchBusy,
  onSearchClick,
  style,
}) => {
  return (
    <WrapperButtons style={style}>
      <Button
        primary
        compact
        disabled={typeof isSearchBusy === 'boolean' ? isSearchBusy : isBusy}
        onClick={onSearchClick}
      >
        <Icon name="search" /> Buscar
      </Button>
    </WrapperButtons>
  );
};

const WrapperButtons = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

ButtonsFilterComprobantePago.propTypes = {
  isBusy: PropTypes.bool,
  isSearchBusy: PropTypes.bool,
  onSearchClick: PropTypes.func,
  style: PropTypes.object,
};

export default ButtonsFilterComprobantePago;
