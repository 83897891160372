import { errors } from '@feathersjs/client';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({

  //FormHeader
  idTipoComprobante: Yup.string().required('Requerido'),
  //Serie: Yup.string().required('Requerido'),
  //Folio: Yup.number().required('Requerido'),
  // FechaDocumento: Yup.date().required('Requerido'),
  idRegimen: Yup.string().required('Requerido'),
  idUsoCFDI: Yup.string().when(['idTipoComprobante'], {
    is: (idTipoComprobante) => idTipoComprobante === 'I',
    then: Yup.string()
      .required('Requerido')
      .matches(/^(G02.+|(?!G02).*)?$/, 'Solamente para Egresos'),
  }),
  idFormaPago: Yup.string().required('Requerido'),
  RegimenAduanero: Yup.string().when('CartaPorte', {
    is: (CartaPorte) => CartaPorte === 'true',
    then: Yup.string().required('Requerido'),
  }),
  idCCP: Yup.string().when('CartaPorte', {
    is: (CartaPorte) => CartaPorte === 'true',
    then: Yup.string().required('Requerido'),
  }),
  idMetodoPago: Yup.string().when('CartaPorte', {
    is: (CartaPorte) => CartaPorte === 'true',
    then: Yup.string().required('Requerido'),
  }),
  idFormaPago: Yup.string()
    .when(['idMetodoPago'], {
      is: (idMetodoPago) => idMetodoPago === 'PPD',
      then: Yup.string()
        .required('Requerido')
        .matches(
          /^(99)?$/,
          'FormaPago debe ser igual a Por Definir con el método de pago seleccionado'
        ),
    })
    .when(['idMetodoPago'], {
      is: (idMetodoPago) => idMetodoPago === 'PUE',
      then: Yup.string()
        .required('Requerido')
        .matches(
          /^(99.+|(?!99).*)?$/,
          'FormaPago debe ser diferente a Por Definir con el método de pago seleccionado'
        ),
    }),
  LugarExpedicion: Yup.string().required('Requerido'),
  idMoneda: Yup.string().required('Requerido'),
  TipoDeCambio: Yup.string().when(['idMoneda'], {
    is: (idMoneda) => idMoneda !== 'MXN',
    then: Yup.string()
      .required('Requerido')
      .matches(/^(1.+|(?!1).*)?$/, 'Debe ser diferente a 1'),
  }),



  //FormEmisor
  EmisorNombre: Yup.string().required('Requerido'),
  EmisorRFC: Yup.string()
    .required('Requerido')
    .matches(
      /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/,
      'Ingresa un RFC válido'
  ),



  //FormReceptor
  ReceptorNombre: Yup.string().required('Requerido'),
  ReceptorCodPos: Yup.string().required('Requerido'),
  Condicion: Yup.string().when(['idMetodoPago'], {
    is: (idMetodoPago) => {
      return idMetodoPago === 'PPD' || idMetodoPago === 'PUE';
    },
    then: Yup.string().required('Requerido'),
  }),
  ReceptorRFC: Yup.string()
    .required('Requerido')
    .matches(
      /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/,
      'Ingresa un RFC válido'
    ),
  ReceptorRegimenLey: Yup.string().required('Requerido'),
  //   ReceptorCodPos: Yup.string().when(['ReceptorRFC'], {
  //     is: (ReceptorRFC) => ReceptorRFC === 'LCT121113ES0',
  //     then: Yup.string()
  //       .required()
  //       .matches(
  //         /^([A-Z][0-9][A-Z] [0-9][A-Z][0-9])?$/,
  //         'Ingresa un Código Postal válido'
  //       ),
  //   }),





  //FormConcepto
  conceptos: Yup.array().of(
    Yup.object().shape({
      Descripcion: Yup.string().required('Requerido'),
      ClaveProdServ: Yup.string().required('Requerido'),
      UnidadMedida: Yup.string().required('Requerido'),
      TipoConcepto: Yup.string().required('Requerido'),
      //SeleccionConcepto: Yup.string().required('Requerido'),
      Cantidad: Yup.number().when(['TipoConcepto'], {
        is: (TipoConcepto) => TipoConcepto === '0',
        then: Yup.number()
          .required('Requerido')
          .positive('Debe ser mayor a cero'),
      }),
      PrecioUnitario: Yup.number().when(['TipoConcepto'], {
        is: (TipoConcepto) => TipoConcepto === '0',
        then: Yup.number()
          .required('Requerido')
          .positive('Debe ser mayor a cero'),
      }),
      // PerfilImpuestos: Yup.string().when(['TipoConcepto'], {
      //   is: (TipoConcepto) => TipoConcepto === '0',
      //   then: Yup.string().required('Requerido'),
      // }),
      Subtotal: Yup.number().when(['TipoConcepto'], {
        is: (TipoConcepto) => TipoConcepto === '0',
        then: Yup.number()
          .required('Requerido')
          .positive('Debe ser mayor a cero'),
      }),
      // Total: Yup.number().when(['TipoConcepto'], {
      //   is: (TipoConcepto) => TipoConcepto === '0',
      //   then: Yup.number()
      //     .required('Requerido')
      //     .positive('Debe ser mayor a cero'),
      // }),

      FraccionArancelariaComercio: Yup.string().when(
        ['CartaPorte', 'TipoConcepto'],
        {
          is: (CartaPorte, TipoConcepto) =>
            CartaPorte === 'true' &&
            (TipoConcepto === '1' || TipoConcepto === '2'),
          then: Yup.string().required('Requerido'),
        }
      ),
      PesoEnKg: Yup.number().when(['CartaPorte', 'TipoConcepto'], {
        is: (CartaPorte, TipoConcepto) =>
          CartaPorte === 'true' &&
          (TipoConcepto === '1' || TipoConcepto === '2'),
        then: Yup.number()
          .required('Requerido')
          .positive('Debe ser mayor a cero'),
      }),
      // ValorMercancia: Yup.number().when(['TipoConcepto'], {
      //   is: (TipoConcepto) => TipoConcepto !== '0',
      //   then: Yup.number()
      //     .required('Requerido')
      //     .positive('Debe ser mayor a cero'),
      // }),
      // PesoEnKg: Yup.number().when(['TipoConcepto'], {
      //   is: (TipoConcepto) => TipoConcepto !== '0',
      //   then: Yup.number()
      //     .required('Requerido')
      //     .positive('Debe ser mayor a cero'),
      // }),
      // MonedaMercancia: Yup.string().when(['TipoConcepto'], {
      //   is: (TipoConcepto) => TipoConcepto !== '0',
      //   then: Yup.string().required('Requerido'),
      // }),
      //Embalaje: Yup.string().required('Requerido'),
    })
  ),




  //FormUbicaciones
  ubicaciones: Yup.array().of(
    Yup.object().shape({
      NombreUbicacion: Yup.string().when('CartaPorte', {
        is: (CartaPorte) => CartaPorte === 'true',
        then: Yup.string().required('Requerido'),
      }),
      // TipoUbicacion: Yup.string().required('Requerido'),
      TipoUbicacion: Yup.string().when('CartaPorte', {
        is: (CartaPorte) => CartaPorte === 'true',
        then: Yup.string().required('Requerido'),
      }),
      RFCRemitenteDestinatario: Yup.string().when('CartaPorte', {
        is: (CartaPorte) => CartaPorte === 'true',
        then: Yup.string()
          .required('Requerido')
          .matches(
            /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})|('XEXX01001000')?$/,
            'Ingresa un RFC válido'
          ),
      }),
      //NumRegTributario: Yup.string().min(6, 'Mínimo 6 dígitos'),
      ResidenciaFiscal: Yup.string().when('CartaPorte', {
        is: (CartaPorte) => CartaPorte === 'true',
        then: Yup.string().required('Requerido'),
      }),
      //FechaHoraSalidaLlegada: Yup.date().required('Requerido'),
      Calle: Yup.string().when('CartaPorte', {
        is: (CartaPorte) => CartaPorte === 'true',
        then: Yup.string().required('Requerido'),
      }),
      Pais: Yup.string().when('CartaPorte', {
        is: (CartaPorte) => CartaPorte === 'true',
        then: Yup.string().required('Pais requerido'),
      }),
      Estado: Yup.string().when(['Pais'], {
        is: (Pais) => Pais === 'MEX' || Pais === 'CAN' || Pais === 'USA',
        then: Yup.string().required('Requerido'),
      }),
      NumRegTributario: Yup.string().when(['CartaPorte', 'ResidenciaFiscal'], {
        is: (CartaPorte, ResidenciaFiscal) =>
          CartaPorte === 'true' && ResidenciaFiscal !== 'MEX',
        then: Yup.string()
          .required('Requerido')
          .matches(/^([0-9]{6,40})?$/, 'Ingresa un número de registro válido'),
        otherwise: Yup.string().nullable(),
      }),
      CodigoPostal: Yup.string().when(['Pais'], {
        is: (Pais) => Pais === 'MEX' || Pais === 'CAN' || Pais === 'USA',
        then: Yup.string().required('Requerido'),
      }),
      DistanciaRecorrida: Yup.number().when('CartaPorte', {
        is: (CartaPorte) => CartaPorte === 'true',
        then: Yup.number().when(['TipoUbicacion'], {
          is: (TipoUbicacion) => TipoUbicacion === 'Destino',
          then: Yup.number().positive('Debe ser mayor a 0.1'),
        }),
      }),
      // CodigoPostal: Yup.string().when(['Pais'], {
      //   is: (Pais) => Pais === 'MEX',
      //   then: Yup.string()
      //     .required('Requerido')
      //     .matches(/^([0-9]{5})?$/, 'Ingresa un Código Postal válido'),
      // }),
    })
  ),





  //FormAutotransporte
  PermSCT: Yup.string().when(['CartaPorte'], {
    is: (CartaPorte) => CartaPorte === 'true',
    then: Yup.string().required('Requerido'),
  }),
  AutoConfigVehicular: Yup.string().when(['CartaPorte'], {
    is: (CartaPorte) => CartaPorte === 'true',
    then: Yup.string().required('Requerido'),
  }),
  NumPermitoSCT: Yup.string().when(['CartaPorte'], {
    is: (CartaPorte) => CartaPorte === 'true',
    then: Yup.string().required('Requerido'),
  }),
  AutoPlacaVM: Yup.string().when(['CartaPorte'], {
    is: (CartaPorte) => CartaPorte === 'true',
    then: Yup.string().required('Requerido'),
  }),
  AutoAnioModeloVM: Yup.string().when(['CartaPorte'], {
    is: (CartaPorte) => CartaPorte === 'true',
    then: Yup.string()
      .required('Requerido')
      .matches(/^(\d{4})?$/, 'Ingresa un año válido'),
  }),
  AseguraRespCivil: Yup.string().when(['CartaPorte'], {
    is: (CartaPorte) => CartaPorte === 'true',
    then: Yup.string().required('Requerido'),
  }),
  PolizaRespCivil: Yup.string().when(['CartaPorte'], {
    is: (CartaPorte) => CartaPorte === 'true',
    then: Yup.string().required('Requerido'),
  }),
  PesoBruto: Yup.string().when(['CartaPorte'], {
    is: (CartaPorte) => CartaPorte === 'true',
    then: Yup.string().required('Requerido'),
  }),
  //AseguraCarga: Yup.string(),
  // AseguraCarga: Yup.string().when(['PolizaCarga'], {
  //   is: (PolizaCarga) => PolizaCarga && PolizaCarga !== '',
  //   then: Yup.string().required('Requerido cuando hay Carga(Aseguradora)'),
  // }),
  PolizaCarga: Yup.string().when(['AseguraCarga'], {
    is: (AseguraCarga) => AseguraCarga && AseguraCarga !== '',
    then: Yup.string().required('Requerido cuando hay Carga(Aseguradora)'),
  }),
  //PrimaSeguro: Yup.number().required('Requerido'),
  //AseguraMedAmbiente: Yup.string(),
  PolizaMedAmbiente: Yup.string().when(['AseguraMedAmbiente'], {
    is: (AseguraMedAmbiente) => AseguraMedAmbiente && AseguraMedAmbiente !== '',
    then: Yup.string().required(
      'Requerido cuando hay Aseguradora Medio Ambiente'
    ),
  }),
  PlacaR1: Yup.string().when(['validationRemolque'], {
    is: (validationRemolque) => validationRemolque === '1',
    then: Yup.string().required('Requerido con este tipo de Autotransporte'),
  }),
  Remolque1: Yup.string().when(['validationRemolque'], {
    is: (validationRemolque) => validationRemolque === '1',
    then: Yup.string().required('Requerido con este tipo de Autotransporte'),
  }),


  
  //FiguraTransporte
  figuraTransportes: Yup.array().of(
    Yup.object().shape({
      TipoFigura: Yup.string().required('Requerido'),
      //NombreFigura: Yup.string().required('Requerido'),
      NumLicencia: Yup.string()
        .required('Requerido')
        .min(6, 'Mínimo 6 dígitos')
        .max(16, 'Máximo 16 dígitos'),
      RFC: Yup.string()
        .required('Requerido')
        .matches(
          /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/,
          'Ingresa un RFC válido'
        ),
      //ResidenciaFiscal: Yup.string().required('Requerido'), //TODO - Irving revisar si es requerido o no.
      // NumRegTributario: Yup.string().min(6, 'Mínimo 6 dígitos'),
      // NumRegTributario: Yup.string().when(['ResidenciaFiscal'], {
      //   is: (ResidenciaFiscal) => ResidenciaFiscal !== 'MEX',
      //   then: Yup.string().required('Requerido').min(6, 'Mínimo 6 dígitos'),
      // }),
    })
  ),
});

export default validationSchema;
