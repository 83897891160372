import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TopNavigation from './TopNavigation';
import HeaderBG from '../../components/HeaderBG';
import Drawer from './Drawer';
import { Container, Breadcrumb } from 'semantic-ui-react';
import { useAuth } from '../../services/feathers';
import ModalUpload from './ModalUpload';

const DarwinCCE = (props) => {
  const auth = useAuth();
  // States
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [isSupport, setIsSupport] = useState(false);
  const [client, setClient] = useState(null);
  const [isValid, setIsValid] = useState(false);

  // Effects

  useEffect(() => {
    // console.debug('auth', JSON.stringify(auth, null, 2));
    const { isSupport } = (auth && auth.user) || {},
      client = (auth && auth.user && auth.user.client) || null,
      isInvalid = (!isSupport && !client && true) || false;

    setIsSupport(isSupport);
    setClient(client);
    setIsValid(!isInvalid);
  }, [auth.user]);

  // Functions
  const openDrawer = () => {
    setOpenPanel(true);
  };

  // Handles
  const handleCreateClick = () => {
    openDrawer();
  };
  const handleClose = (isSuccess) => {
    setOpenPanel(false);
  };

  const getClientName = () =>
    (auth && auth.user && auth.user.client && auth.user.client.name) || null;

  return (
    <>
      <DefaultLayout
        currentPath={props.match.path}
        globalActions={
          <TopNavigation
            isBusy={isBusy}
            onCreateClick={handleCreateClick}
            isValid={isValid}
            setOpenModalUpload={setOpenModalUpload}
          />
        }
      >
        <HeaderBG />

        <Container className="fluid-95">
          <Breadcrumb
            icon="right angle"
            sections={[
              { key: getClientName(), content: getClientName(), link: false },
              {
                key: 'Anexo 24',
                content: 'Anexo 24',
                active: true,
              },
            ]}
          />
        </Container>
      </DefaultLayout>

      {openPanel ? (
        <Drawer openPanel={openPanel} onClose={handleClose}></Drawer>
      ) : null}

      <ModalUpload
        open={openModalUpload}
        setOpen={setOpenModalUpload}
      ></ModalUpload>
    </>
  );
};

export default DarwinCCE;
