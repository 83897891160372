import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

const TopNavigation = ({
  isBusy,
  onCreateClick,
  isValid,
  setOpenModalUpload,
}) => {
  return (
    <>
      {/* <Button
        compact
        onClick={() => {
          setOpenModalUpload(true);
        }}
        disabled={isBusy || !isValid}
      >
        Upload XML
      </Button> */}
      <Button
        compact
        primary
        onClick={onCreateClick}
        disabled={isBusy || !isValid}
      >
        Crear DarwinCCE 
      </Button>
    </>
  );
};

TopNavigation.propTypes = {
  isBusy: PropTypes.bool,
  onCreateClick: PropTypes.func,
  isValid: PropTypes.bool,
};

export default TopNavigation;
