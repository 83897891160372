import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import swal from 'sweetalert';
import { Formik } from 'formik';
import Actions from './Actions';
import FormRemolque from './FormRemolques';
import axios from 'axios';
import feathers from '../../../services/feathers';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import * as Yup from 'yup';

const Drawer = ({ openPanel, onClose, currentRemolque }) => {
  const service = feathers.service('remolques');
  const [currentSection, setCurrentSection] = useState(
    (currentRemolque && currentRemolque.id && 'EDIT') || 'CREATE'
  );
  useEffect(() => {});
  const handleSave = async (values, { setSubmitting }) => {
    if (values) {
      let success = true;
      try {
        if (values.id) {
          let result = await service.patch(values.id, values);
          swal('Actualizado', '¡Se ha actualizado correctamente!', 'success');
        } else {
          let resultCreate = await service.create(values);
          swal('Guardado', '¡Se ha guardado correctamente!', 'success');
        }
      } catch (exception) {
        console.log(exception);
        swal('Error', 'Dont Save transporte', 'error');
      }
      if (success) {
        setCurrentSection('CREATE');
        onClose(true);
      }
    } else swal('Error', 'Dont Save remolque', 'error');
  };

  const handledOnClose = async () => {
    let confirmation = await swal('Do you want close this form?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      setCurrentSection('CREATE');
      onClose(false);
    }
  };
  const SignupSchema = Yup.object().shape({
    Placa: Yup.string().max(20, 'Too Long!').required('Required'),
    Alias: Yup.string().max(50),
    SubTipoRemolque: Yup.number().required('Required'),
  });

  let drawerTitles = {
    CREATE: {
      title: 'Nuevo Remolque',
      subtitle: '',
    },
    EDIT: {
      title: 'Editar Remolque',
      subtitle: '',
    },
  };

  let title = drawerTitles[currentSection].title;
  let subtitle = drawerTitles[currentSection].subtitle;

  return (
    <SlidingPanel
      isOpen={openPanel}
      size={100}
      title={title}
      subtitle={subtitle}
      onClose={handledOnClose}
    >
      <Formik
        initialValues={currentRemolque}
        onSubmit={handleSave}
        validationSchema={SignupSchema}
      >
        {(formikProps) => (
          <Form
            onSubmit={formikProps.handleSubmit}
            style={{ marginRight: '15px' }}
          >
            <FormRemolque
              onSave={handleSave}
              // ref={refForm}
              // isValid={isValid}
              // setIsValid={setIsValid}
              formik={formikProps}
            />

            <Actions formik={formikProps} handledOnClose={handledOnClose} />
          </Form>
        )}
      </Formik>
    </SlidingPanel>
  );
};

export default Drawer;
