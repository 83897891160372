const moment = require('moment');

module.exports = (lastDocument, userPOS) => {
  return {
    idTipoComprobante: 'T', //DONE
    Serie: 'AA', //DONE DefiniAyuda cuando se quierer einiciar folio
    Folio: lastDocument ? lastDocument.Folio : 0, ///DONE Incrementable ✅
    // FechaDocumento: moment().subtract(6, 'hours').format(), //DONE
    FechaDocumento: moment().format(), //DONE
    Condiciones: '', // TODO De donde se saca
    idUsoCFDI: 'S01', // TODO Forzado asi en Traslados
    idMetodoPago: null, // TODO Forzado asi en traslados
    idFormaPago: null, // TODO Forzado asi en traslados
    idSucursal: userPOS && userPOS[0].idSucursal ? userPOS[0].idSucursal : null, //DONE Cada emisor es una sucursal
    idUser: 'irving', // TODO Agregar el ID que se tiene aquí del usuario.
    idPOS: userPOS ? userPOS[0].idPOS : null, //DONE Despues - Se supoe que cada emisor puede tener diferentes lugares para facturar
    EsGlobal: '0', //DONE No se usa
    idModulo: 1, //DONE Podemos dejar 1, pero es para nosotros
    idSocioNegocio: 10372, //DONE Después - El id del cliente
    TipoDeCambio: 1.0, //DONE
    Subtotal: 0.0, //IDEA Despues agregarlo pero como texto, no es un input y calcularlo conforme se va llenando la información de conceptos.
    TotalDescuentos: 0.0, //IDEA Despues Agregar la suma al final de los conceptos
    ImpuestosTraslados: 0.0, //IDEA Despues Agregar la suma al final de los conceptos
    ImpuestosRetenciones: 0.0, //IDEA Despues Agregar la suma al final de los conceptos
    ImpuestosGlobalTraslados: 0.0, //IDEA Despues
    ImpuestosGlobalRetenciones: 0.0, //IDEA Despues
    Total: 0.0, //IDEA Despues - Agregar la suma al final de los conceptos
    TotalLetra: 'CERO PESOS 00/100 M.N.', //IDEA Despues - Agregar la suma al final de los conceptos
    MotivoDescuento: '', //IDEA Despues - Preguntar en donde se pone.
    Condicion: 'CONTADO', //TODO  Traslado no tiene nada.
    Comentarios: 'Traslado Internacional Salida', //IDEA Agregar un textarea para comentarios al final del formulario.
    idStatus: 1, //IDEA  1= Preparado, 2 = Cancelado, 3 = Emitido, 4 = Cancelado en proceso
    idRegimen: userPOS ? userPOS[0].RegimenFiscal : '', //IDEA  Se toma del Emisor
    LugarExpedicion: userPOS ? userPOS[0].CodigoPostal : '', //IDEA Se toma del POS del emisor
    idMoneda: 'MXN', //TODO en realidad va XXX, pero esta bien asi
    TipoViaje: 'Internacional',
    EntradaSalida: 'Entrada',
    PaisInicial: 'USA',
    ////  Estos datos son del emisor, POS, se requierer Nombre y RFC
    EmisorNombre: userPOS ? userPOS[0].NombreSucursal : '',
    EmisorRFC: userPOS ? userPOS[0].TaxID : '',
    EmisorCalle: 'ENRIQUE GARCIA SANCHEZ',
    EmisorNumExterior: '30',
    EmisorNumInterior: '',
    EmisorColonia: 'SAN BENITO',
    EmisorLocalidad: 'HERMOSILLO',
    EmisorReferencia: '',
    EmisorMunicipio: '',
    EmisorEstado: '',
    EmisorPais: '',
    EmisorCodPos: '',
    /////  Estos datos son del receptor o cliente, pero en traslado es el emisor
    ReceptorNombre: userPOS ? userPOS[0].NombreSucursal : '',
    ReceptorRFC: userPOS ? userPOS[0].TaxID : '',
    ReceptorCalle: 'ENRIQUE GARCIA SANCHEZ',
    ReceptorNumExterior: '30',
    ReceptorNumInterior: '',
    ReceptorColonia: 'SAN BENITO',
    ReceptorLocalidad: 'HERMOSILLO',
    ReceptorReferencia: '',
    ReceptorMunicipio: '',
    ReceptorEstado: '',
    ReceptorPais: '',
    ReceptorCodPos: userPOS ? userPOS[0].CodigoPostal : '',
    ReceptorRegistroTributarioExterior: '',
    conceptos: [
      {
        Cantidad: 25.0, //DONE
        idProducto: 0, //DONE Después
        Descripcion: 'ADAPTADOR DE CORRIENTE', //DONE
        PrecioUnitario: 0.0, //DONE Disable cuando es Traslado
        Subtotal: 0.0, //DONE Disable cuando es Traslado
        Descuento: 0.0, //DONE Disable cuando es Traslado
        idImpuesto: 1, //Ingresos
        TasaOCuota: 'Tasa', //Ingresos
        idTipoTax: 1, //Ingresos
        ImporteBase: 0.0, //Ingresos
        Valor: 16.0, //Ingresos
        Importe: 0.0, //Ingresos
        CodigoSAT: '002', //Ingresos
        ImpuestosTraslados: 0.0, //Para ingresos
        ImpuestosRetenciones: 0.0, //Para ingresos
        Total: 0.0, //DONE Disable cuando es Traslado
        PerfilImpuestos: '',
        idReferencia: 0, //DONE Sin uso
        idReferenciaModulo: 0, //DONE Sin uso
        TipoConcepto: 0, //DONE //IDEA 0 - Ingresos, 1 - Translados, 2  - Translados
        ClaveProdServ: '43211600', //DONE //IDEA es Sat código - TipoConcepto 2 -> cat_ClaveProdServCP
        CuentaPredial: '', //IDEA después
        NumeroIdentificacion: '', //IDEA después
        ClaveUnidad: 'H87', //DONE
        UnidadMedida: 'Pieza', //DONE
        ValorMercancia: 22.0, //DONE Carta Porte
        PesoEnKg: 12.0, //DONE Carta Porte
        MonedaMercancia: 'MXN', //DONE Carta Porte
        cfdi_tipoRelacion: '', //IDEA Después
        cfdi_tipoRelacionuuid: '', //IDEA Después
        NumeroIdentificacionComercio: '', //IDEA Después - comercio exterior.
        FraccionArancelariaComercio: '0101290300', //DONE Carta Porte
        CantidadAduanaComercio: 0.0, //IDEA Después - comercio exterior.
        UnidadAduanaComercio: '', //IDEA Después - comercio exterior.
        ValorUnitarioAduanaComercio: 0.0, //IDEA Después - comercio exterior.
        ValorDolaresComercio: 0.0, //IDEA Después - comercio exterior.
        DescripcionIngles: '', //IDEA Después - comercio exterior.
        cfdi_RelacionIdDoc: 0, //IDEA Después - complemento de pagos.
        cfdi_RelacionNumParcialidad: 0, //IDEA Después - complemento de pagos.
        cfdi_RelacionReferencia: '', //IDEA Después - complemento de pagos.
        idDoc_Ref: 0, //IDEA Después - complemento de pagos.
        CveMaterialPeligroso: '', //TODO Carta Porte - catalogo - requerido si es material peligroso en ClaveProdServ
        Embalaje: '', //TODO Carta Porte - catalogo - requerido si es material peligroso en ClaveProdServ
        EmbalajeDescripcion: '', //TODO Carta Porte - requerido si es material peligroso en ClaveProdServ
        UUIDComercioExterior: '', //TODO Carta Porte - opcional
        Pedimento: '', //TODO Carta Porte - opcional con formato validado, si espacio deben ser 15, y si no con espacios lo que den.
        Dimensiones: '', //TODO Carta Porte - opcional cuando es material peligroso.
      },
      {
        Cantidad: 5.0, //DONE
        idProducto: 0, //DONE Después
        Descripcion: 'ADAPTADOR DE CORRIENTE', //DONE
        PrecioUnitario: 0.0, //DONE Disable cuando es Traslado
        Subtotal: 0.0, //DONE Disable cuando es Traslado
        Descuento: 0.0, //DONE Disable cuando es Traslado
        idImpuesto: 1, //Ingresos
        TasaOCuota: 'Tasa', //Ingresos
        idTipoTax: 1, //Ingresos
        ImporteBase: 0.0, //Ingresos
        Valor: 16.0, //Ingresos
        Importe: 0.0, //Ingresos
        CodigoSAT: '002', //Ingresos
        ImpuestosTraslados: 0.0, //DONEPara ingresos
        ImpuestosRetenciones: 0.0, //DONEPara ingresos
        Total: 0.0, //DONE Disable cuando es Traslado
        idReferencia: 0, //DONE Sin uso
        idReferenciaModulo: 0, //DONE Sin uso
        TipoConcepto: 1, //DONE //IDEA 0 - Ingresos, 1 - Translados, 2  - Translados
        ClaveProdServ: '43211600', //DONE //IDEA es Sat código - TipoConcepto 2 -> cat_ClaveProdServCP
        CuentaPredial: '', //IDEA después
        NumeroIdentificacion: '', //IDEA después
        ClaveUnidad: 'H87', //DONE
        UnidadMedida: 'Pieza', //DONE
        ValorMercancia: 1500.0, //DONE Carta Porte
        PesoEnKg: 10.0, //DONE Carta Porte
        MonedaMercancia: 'MXN', //DONE Carta Porte
        cfdi_tipoRelacion: '', //IDEA Después
        cfdi_tipoRelacionuuid: '', //IDEA Después
        NumeroIdentificacionComercio: '', //IDEA Después - comercio exterior.
        FraccionArancelariaComercio: '0101290300', //DONE Carta Porte
        CantidadAduanaComercio: 0.0, //IDEA Después - comercio exterior.
        UnidadAduanaComercio: '', //IDEA Después - comercio exterior.
        ValorUnitarioAduanaComercio: 0.0, //IDEA Después - comercio exterior.
        ValorDolaresComercio: 0.0, //IDEA Después - comercio exterior.
        DescripcionIngles: '', //IDEA Después - comercio exterior.
        cfdi_RelacionIdDoc: 0, //IDEA Después - complemento de pagos.
        cfdi_RelacionNumParcialidad: 0, //IDEA Después - complemento de pagos.
        cfdi_RelacionReferencia: '', //IDEA Después - complemento de pagos.
        idDoc_Ref: 0, //IDEA Después - complemento de pagos.
        CveMaterialPeligroso: '', //TODO Carta Porte - catalogo - requerido si es material peligroso en ClaveProdServ
        Embalaje: '', //TODO Carta Porte - catalogo - requerido si es material peligroso en ClaveProdServ
        EmbalajeDescripcion: '', //TODO Carta Porte - requerido si es material peligroso en ClaveProdServ
        UUIDComercioExterior: '', //TODO Carta Porte - opcional
        Pedimento: '', //TODO Carta Porte - opcional con formato validado, si espacio deben ser 15, y si no con espacios lo que den.
        Dimensiones: '', //TODO Carta Porte - opcional cuando es material peligroso.
      },
    ],
    ubicaciones: [
      {
        TipoUbicacion: 'Origen', //DONE Solo Origen o Destino
        TipoEstacion: '', //IDEA No se requiere para autotransport
        NumEstacion: '', //IDEA No se requiere para autotransport
        NombreEstacion: '', //IDEA No se requiere para autotransport
        NavegacionTrafijo: '', //IDEA No se requiere para autotransport
        RFCRemitenteDestinatario: 'LCT121113ES0', //TODO requerido
        ResidenciaFiscal: 'MEX', //TODO Requerido si no es MEX
        NumRegTributario: '', //TODO Requerido si no es MEX
        IDUbicacion: 'OR000001', //TODOrequerido
        FechaHoraSalidaLlegada: moment().format(), //TODOrequerido
        DistanciaRecorrida: 0.0, //TODO0 para origen, requerido para destino
        Calle: 'SOLANO', //DONE Opcional
        NumExterior: '16', //DONE Opcional
        NumInterior: '', //DONE Opcional
        Referencia: '', //DONE Opcional
        Pais: 'MEX', //TODOrequerido
        Estado: 'SON', //TODO requerido -->>  MEX, USA y CA del catalogo
        Municipio: '030', //TODO Opcional
        Localidad: '07', //TODO Opcional
        CodigoPostal: '83249', //TODO requerido -->> MEX del catalogo, USA y CA libre
        Colonia: '2456', //TODO Opcional
      },
      {
        TipoUbicacion: 'Destino', //DONE Solo Origen o Destino
        TipoEstacion: '', //IDEA No se requiere para autotransport
        NumEstacion: '', //IDEA No se requiere para autotransport
        NombreEstacion: '', //IDEA No se requiere para autotransport
        NavegacionTrafijo: '', //IDEA No se requiere para autotransport
        RFCRemitenteDestinatario: 'XEXX010101000', //TODO requerido
        ResidenciaFiscal: 'USA', //TODO Requerido si no es MEX
        NumRegTributario: '123456', //TODO Requerido si no es MEX
        IDUbicacion: 'DE000002', //TODOrequerido
        FechaHoraSalidaLlegada: moment().format(), //TODOrequerido
        DistanciaRecorrida: 15, //TODO0 para origen, requerido para destino
        Calle: '441 N GRAND AV', //DONE Opcional
        NumExterior: '12', //DONE Opcional
        NumInterior: '', //DONE Opcional
        Referencia: '', //DONE Opcional
        Pais: 'USA', //TODOrequerido
        Estado: 'CA', //TODO requerido -->>  MEX, USA y CA del catalogo
        Municipio: '', //TODO Opcional
        Localidad: '', //TODO Opcional
        CodigoPostal: '34343', //TODO requerido -->> MEX del catalogo, USA y CA libre
        Colonia: '', //TODO Opcional
      },
    ],
    figuraTransportes: [
      {
        TipoFigura: '01', //TODO Requerido
        NombreFigura: 'Prueba2', //TODO
        RFC: 'LCT121113ES0', // Requerido
        NumLicencia: '123456', // Requerido
        ResidenciaFiscal: 'MEX', // Opcional, si es MEX no se pone nada
        NumRegTributario: '', // Requerido si no es MEX
        Calle: '', //DONE Opcional
        NumExterior: '', //DONE Opcional
        NumInterior: '', //DONE Opcional
        Referencia: '', //DONE Opcional
        Pais: '', //TODO Requerido si no es MEX
        Estado: '', //TODO Requerido si no es MEX
        Municipio: '', //DONE Opcional
        Localidad: '', //DONE Opcional
        CodigoPostal: '', //TODO Requerido si no es MEX
        Colonia: '', //DONE Opcional
        ParteTransporte: '', //TODO
      },
    ],
    PermSCT: 'TPAF01', //TODO Requerido
    AutoConfigVehicular: 'C2', //TODO Requerido
    NumPermitoSCT: '123WDF8SDF97Y76', //TODO Requerido
    AutoPlacaVM: '2342342', //TODO Requerido
    AutoAnioModeloVM: 2020, //TODO Requerido
    AseguraRespCivil: 'AseguraRespCivil', //TODO Requerido
    PolizaRespCivil: 'PolizaRespCivil', //TODO Requerido
    AseguraMedAmbiente: '', //TODORequerido cuando hay material peligroso
    PolizaMedAmbiente: '', //TODO Requerido cuando hay material peligroso
    AseguraCarga: 'AseguraCarga', //DONE Opcional
    PolizaCarga: 'PolizaCarga', //DONE Opcional
    PrimaSeguro: 0.0, //DONEOpcional
    PlacaR1: '', //TODO Condicional, si el camion tiene remolques , es requerido uno
    Remolque1: '', //TODO Condicional, si el camion tiene remolques , es requerido uno
    PlacaR2: '', //TODO Condicional, si el camion tiene remolques , es requerido uno
    Remolque2: '', //TODO Condicional, si el camion tiene remolques , es requerido uno
  };
};
