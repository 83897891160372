import React, { useState, useEffect } from 'react';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import DrawerFiltersWrapper from '../../../components/DrawerFiltersWrapper';
import Form from './Form';
import Actions from './Actions';
import swal from 'sweetalert';

const Drawer = ({
  openPanel,
  dataPago,
  onClose,
  service,
  hasSelection,
  bankInformation,
}) => {
  const [isBusy, setIsBusy] = useState(false);
  const [getComplement, setGetComplement] = useState({
    pFormaPago: '',
    pFormaPagoDescripcion: '',
    CtaOrdenante: '',
    NomBancoOrdExt: '',
    pFechaPago: '',
  });
  const [section] = useState((dataPago._id && 'EDIT') || 'CREATE');

  let drawerTitles = {
    CREATE: { title: 'Información Complemento de Pago', subtitle: '' },
    EDIT: { title: 'Información Complemento de Pago', subtitle: '' },
  };

  let { title, subtitle } = drawerTitles[section];

  const handledOnClose = async () => {
    let confirm = await swal('¿Quieres cerrar el formulario?', {
      buttons: ['No', 'Si'],
    });
    if (confirm) onClose(!openPanel);
  };

  const handledSuccess = (dataPago) => onClose(true);

  return (
    <SlidingPanel
      isOpen={openPanel}
      size={50}
      title={title}
      subtitle={subtitle}
      onClose={() => handledOnClose()}
    >
      <DrawerFiltersWrapper>
        <Form
          isBusy={isBusy}
          setIsBusy={setIsBusy}
          dataPago={dataPago}
          onSuccess={handledSuccess}
          service={service}
          hasSelection={hasSelection}
          onClose={onClose}
          bankInformation={bankInformation}
          setGetComplement={setGetComplement}
        >
          <Actions
            isBusy={isBusy}
            setIsBusy={setIsBusy}
            onBackClick={handledOnClose}
            hasSelection={hasSelection}
            getComplement={getComplement}
            bankInformation={bankInformation}
            onClose={onClose}
          />
        </Form>
      </DrawerFiltersWrapper>
    </SlidingPanel>
  );
};

export default Drawer;
