import React from 'react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import feathers, { useAuth } from '../../../services/feathers';

function DropdownUbicacion({ formik, index, ubicacion }) {
  let { setFieldValue } = formik;
  const auth = useAuth();

  const loadUbicacion = async (id) => {
    const service = feathers.service('ubicaciones');
    let result = await service.find({
      query: {
        id: id,
      },
    });
    if (result.data.length) return result.data[0];
  };

  let query = (columName, columValue, searchQuery) => {
    let newQuery = {
      $or: [
        { [columName]: { $like: '%' + searchQuery + '%' } },
        { [columValue]: { $like: '%' + searchQuery + '%' } },
      ],
      $limit: 50,
      Organizacion: auth.user.client._id,
    };

    return newQuery;
  };

  let queryInit = (columName, columValue, value) => {
    let newQuery;
    if (value) {
      newQuery = {
        [columValue]: { $like: value },
        $limit: 100,
        Organizacion: auth.user.client._id,
      };
    } else {
      newQuery = {
        $limit: 100,
        Organizacion: auth.user.client._id,
      };
    }

    return newQuery;
  };

  return (
    <DropdownSearchFetch
      fluid
      selection
      label="Ubicacion"
      onChange={async (e, { value }) => {
        setFieldValue(`ubicaciones.${index}.id`, value);
        let ubicacion = await loadUbicacion(value);
        if (ubicacion) {
          setFieldValue(`ubicaciones.${index}.Pais`, ubicacion.Pais);
          setFieldValue(`ubicaciones.${index}.Estado`, ubicacion.Estado);
          setFieldValue(`ubicaciones.${index}.Municipio`, ubicacion.Municipio);
          setFieldValue(`ubicaciones.${index}.Localidad`, ubicacion.Localidad);
          setFieldValue(
            `ubicaciones.${index}.CodigoPostal`,
            ubicacion.CodigoPostal
          );
          setFieldValue(`ubicaciones.${index}.Colonia`, ubicacion.Colonia);
          setFieldValue(`ubicaciones.${index}.Calle`, ubicacion.Calle);
          setFieldValue(
            `ubicaciones.${index}.NumExterior`,
            ubicacion.NumExterior
          );
          setFieldValue(
            `ubicaciones.${index}.NumInterior`,
            ubicacion.NumInterior
          );
          setFieldValue(
            `ubicaciones.${index}.NumRegTributario`,
            ubicacion.NumRegTributario
          );

          setFieldValue(
            `ubicaciones.${index}.RFCRemitenteDestinatario`,
            ubicacion.RFC
          );
          setFieldValue(
            `ubicaciones.${index}.Referencia`,
            ubicacion.Referencia
          );
          setFieldValue(
            `ubicaciones.${index}.ResidenciaFiscal`,
            ubicacion.ResidenciaFiscal
          );
          setFieldValue(
            `ubicaciones.${index}.NombreUbicacion`,
            ubicacion.Nombre
          );
        } else {
          setFieldValue(`ubicaciones.${index}.CodigoPostal`, '');
          setFieldValue(`ubicaciones.${index}.Colonia`, '');
          setFieldValue(`ubicaciones.${index}.Estado`, '');
          setFieldValue(`ubicaciones.${index}.Localidad`, '');
          setFieldValue(`ubicaciones.${index}.Municipio`, '');
          setFieldValue(`ubicaciones.${index}.NumExterior`, '');
          setFieldValue(`ubicaciones.${index}.NumInterior`, '');
          setFieldValue(`ubicaciones.${index}.NumRegTributario`, '');
          setFieldValue(`ubicaciones.${index}.Pais`, '');
          setFieldValue(`ubicaciones.${index}.RFCRemitenteDestinatario`, '');
          setFieldValue(`ubicaciones.${index}.Referencia`, '');
          setFieldValue(`ubicaciones.${index}.ResidenciaFiscal`, '');
          setFieldValue(`ubicaciones.${index}.NombreUbicacion`, '');
        }
      }}
      placeholder="RFC"
      featherService="ubicaciones"
      columName="RFC"
      columValue="id"
      mapValues={(value) => {
        return {
          key: Math.random(),
          text: `${value['RFC']} - ${value['Nombre']}`,
          value: value['id'],
        };
      }}
      queryWithSearch={query}
      queryInit={queryInit}
    />
  );
}

export default DropdownUbicacion;
