import React from 'react';
import styled from 'styled-components';
import { Button, Icon, Segment } from 'semantic-ui-react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import swal from 'sweetalert';
import feathers from '../../../services/feathers';

const Actions = ({
  handledOnClose,
  formik,
  xmlString,
  xmlVersion,
  setLoading,
}) => {
  let { values, validateForm, setTouched } = formik;
  const handleValidate = () => {
    //console.log('formik.values: ', formik.values);
    validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        setTouched({ ...formik.touched, ...errors });

        let transportista;
        let factura;
        let mercancias = [];
        if (errors.transportista) {
          transportista = errors.transportista;
          delete errors.transportista;
        }
        if (errors.factura) {
          factura = errors.factura;
          delete errors.factura;
        }
        if (errors.mercancias) {
          errors.mercancias.map((item, index) => {
            if (item !== undefined) {
              //console.log(item);
              if (item.ordenCompra.includes('20')) {
                mercancias.push(
                  'Orden de compra posicion: ' +
                    (index + 1) +
                    ' ' +
                    item.ordenCompra
                );
              } else {
                mercancias.push(
                  'Orden de compra posicion: ' + (index + 1) + ' requerido'
                );
              }
            }
          });
          delete errors.mercancias;
        }
        let eMessage =
          Object.keys(errors).length > 0
            ? `${'Registro de Encabezado :  \n' + JSON.stringify(errors)}`
            : '';
        let tMessage = transportista
          ? `${
              'Registro de Transportista :  \n' + JSON.stringify(transportista)
            }`
          : '';
        let fMessage = factura
          ? 'Registro de Factura :  \n' + JSON.stringify(factura)
          : '';
        let mMessage =
          mercancias.length > 0
            ? 'Registro de partidas :  \n' + JSON.stringify(mercancias)
            : '';

        swal(
          'Error',
          '¡Revisa la información faltante! \n' +
            (eMessage ? eMessage + '\n' : '') +
            (tMessage ? tMessage + '\n' : '') +
            (fMessage ? fMessage + '\n' : '') +
            (mMessage ? mMessage + '\n' : ''),
          'error'
        );
        //console.log('formik.errors: ', errors);
      } else {
        sendAnexo24();
      }
    });
  };

  const sendAnexo24 = async () => {
    try {
      setLoading(true);
      const anexo24 = feathers.service('anexo-24');
      let options = {
        isFromPortal: true,
        xml: null,
        version: null,
        anexo24: values,
      };
      // console.log('options', options);
      let result = await anexo24.create({ options });
      // console.log('result', result);
      if (result && result.message && result.message === 'success') {
        setLoading(false);
        let anexo24 = result && result.anexo24 ? result.anexo24 : null;
        let txtString = anexo24 ? anexo24.txtString : '';
        let fileName = anexo24 && anexo24.fileName ? anexo24.fileName : '';
        var blob = new Blob([txtString], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, fileName);
        swal('Done', 'Archivo .txt generado con éxito', 'success');
      } else {
        setLoading(false);
        swal('Error', JSON.stringify(result.error), 'error');
      }
    } catch (error) {
      setLoading(false);
      //console.log('error', error);
      swal('Error', JSON.stringify(error), 'error');
    }
  };

  return (
    <Segment>
      <ActionContainer>
        {/* <Button primary compact type="button" onClick={validateBeforeSubmit}> */}
        <Button primary type="button" onClick={handleValidate}>
          <Icon name="bell" /> Guardar
        </Button>

        <Button compact onMouseDown={handledOnClose}>
          <Icon name="reply" /> Back
        </Button>
      </ActionContainer>
    </Segment>
  );
};

const ActionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-template-areas: '. . . . . .';
  margin-left: 500px;
`;

export default Actions;
