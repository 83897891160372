import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  ModalActions,
} from 'semantic-ui-react';
import feathers from '../../services/feathers';
import TableButtonPdf from './TableButtonPdf';
import TableButtonTimbrar from './TableButtonTimbrar';

const servicePDF = feathers.service('export-pdf-cartaporte');

const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  data,
  actionType,
  getData,
  id,
  idPOS,
  idSucursal,
}) => (
  <Modal open={open} onClose={onClose}>
    <ModalHeader>
      {actionType === 'pdf'
        ? 'Confirmar Generación de PDF'
        : 'Confirmar Timbrado'}
    </ModalHeader>
    <ModalContent>
      {actionType === 'pdf'
        ? 'A continuación, se generará un PDF. ¿Desea continuar?'
        : 'A continuación, se timbrará el CFDI seleccionado. ¿Desea continuar?'}
    </ModalContent>
    <ModalActions>
      <Button compact secondary onClick={onClose}>
        Atrás
      </Button>
      {actionType === 'pdf' ? (
        <TableButtonPdf data={data} servicePDF={servicePDF} onClick={onClose} />
      ) : (
        <TableButtonTimbrar
          id={id}
          idPOS={idPOS}
          idSucursal={idSucursal}
          getData={getData}
          onClick={onClose}
        />
      )}
    </ModalActions>
  </Modal>
);

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  data: PropTypes.object,
  actionType: PropTypes.string.isRequired,
  getData: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  idPOS: PropTypes.number.isRequired,
  idSucursal: PropTypes.number.isRequired,
};

export default ConfirmationModal;
