import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Label } from 'semantic-ui-react';
import feathers from '../../services/feathers';
import { useAuth } from '../../services/feathers';

/**
 * Simple Fetch DropDown
 * 
  <DropdownPos
    fluid
    selection
    label="Tipo de Comprobante"
    value={values.idTipoComprobante}
    onChange={(e, { value }) =>
      setFieldValue('idTipoComprobante', value)
    }
    placeholder="Tipo de Comprobante"
    featherService="catalogo-tipo-de-comprobante"
    columName="Descripcion"
    columValue="c_TipoDeComprobante"
  />
 */

const DropDown = ({
  value,
  label,
  onChange,
  featherService,
  columName,
  columValue,
  mapValues,
  error,
  clearable,
  placeholder
}) => {
  const auth = useAuth();
  let service = feathers.service(featherService);
  let [isFetching, setIsFetching] = useState(true);
  let [options, setOptions] = useState([]);
  let [posData, setPosData] = useState([]);

  const mapValuesDefault = (value) => {
    return {
      key: Math.random(),
      text: value[columName],
      value: value[columValue],
    };
  };

  useEffect(() => {
    const fetchInitdata = async () => {
      let resultOptions = await service.get(auth.user.client._id);
      let options = resultOptions[0].map(mapValues || mapValuesDefault);
      setOptions(options);
      setPosData(resultOptions[0]);
      setIsFetching(false);
      // if( label === 'Nombre *' ) console.log('Informacion del emisor: ', resultOptions);
    };
    fetchInitdata();
  }, [columName, columValue, service]);

  const onChangeHandled = (e, data) => {
    // console.log('posData: ', posData);
    onChange(e, data, posData);
  };

  return (
    <Form.Field>
      <label>{label}</label>
      <Dropdown
        clearable
        fluid
        selection
        search={true}
        options={options}
        value={value}
        onChange={onChangeHandled}
        // disabled={isFetching}
        loading={isFetching}
        error={error}
        placeholder={placeholder}
      />
      {error && (
        <Label pointing prompt>
          {error.content}
        </Label>
      )}
    </Form.Field>
  );
};

export default DropDown;
