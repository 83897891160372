import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import swal from 'sweetalert';
import { Formik } from 'formik';
import Actions from './Actions';
import FormConceptos from './FormConceptos';
import feathers from '../../../services/feathers';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import * as Yup from 'yup';

const Drawer = ({ openPanel, onClose, currentConcepto }) => {
  const service = feathers.service('conceptos');
  const [currentSection, setCurrentSection] = useState(
    (currentConcepto && currentConcepto.idProducto && 'EDIT') || 'CREATE'
  );
  useEffect(() => {});

  const handleSave = async (values, { setSubmitting }) => {
    if (values) {
      let success = true;
      try {
        if (currentSection === 'EDIT' && values.idProducto) {
          let result = await service.patch(values.idProducto, values);
          swal('Actualizado', '¡Se ha actualizado correctamente!', 'success');
        } else {
          let concepts = await service.find({
            query: { $limit: 1, $sort: { idProducto: -1 } },
          });
          if (concepts && concepts.data) {
            values.idProducto = concepts.data[0].idProducto + 1;
          }
          if (values.ClaveUnidad) {
            values.UnidadMedida = values.ClaveUnidad;
          }
          if (!values.NumeroIdentificacion) {
            values.NumeroIdentificacion = '';
          }
          let resultCreate = await service.create(values);
          swal('Guardado', '¡Se ha guardado correctamente!', 'success');
        }
      } catch (exception) {
        console.log(exception);
        swal('Error', 'Dont Save Concepto', 'error');
      }
      if (success) {
        setCurrentSection('CREATE');
        onClose(true);
      }
    } else swal('Error', 'Dont Save Conceptos', 'error');
  };

  const handledOnClose = async () => {
    let confirmation = await swal('Do you want close this form?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      setCurrentSection('CREATE');
      onClose(false);
    }
  };

  const Validations = Yup.object().shape({
    // NumeroIdentificacion: Yup.string()
    //   .max(50, 'Too Long!')
    //   .required('Required'),
    // Nombre: Yup.string().max(250, 'Too Long!').required('Required'),
    // idRegimen: Yup.number().nullable(),
    // RegistroTributarioExterior: Yup.string()
    //   .max(100, 'Too Long!')
    //   .required('Required'),
  });

  let drawerTitles = {
    CREATE: {
      title: 'Nuevo Conceptos',
      subtitle: '',
    },
    EDIT: {
      title: 'Editar Conceptos',
      subtitle: '',
    },
  };

  let title = drawerTitles[currentSection].title;
  let subtitle = drawerTitles[currentSection].subtitle;

  return (
    <SlidingPanel
      isOpen={openPanel}
      size={100}
      title={title}
      subtitle={subtitle}
      onClose={handledOnClose}
    >
      <Formik
        initialValues={currentConcepto}
        onSubmit={handleSave}
        validationSchema={Validations}
      >
        {(formikProps) => (
          <Form
            onSubmit={formikProps.handleSubmit}
            style={{ marginRight: '15px' }}
          >
            <FormConceptos onSave={handleSave} formik={formikProps} />

            <Actions formik={formikProps} handledOnClose={handledOnClose} />
          </Form>
        )}
      </Formik>
    </SlidingPanel>
  );
};

export default Drawer;
