import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import 'lodash';
import { Button } from 'semantic-ui-react';
import swal from 'sweetalert';
import { valueFormatter } from '../../components/AgGridColumns';
import { useAuth } from '../../services/feathers';

const Table = ({
  data,
  onEditClick,
  onDeleteClick,
  selection,
  setSelection,
}) => {
  const auth = useAuth();

  // States
  const [gridApi, setGridApi] = useState();
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [columnDefs] = useState([
    {
      headerName: 'RFC',
      field: 'RFC',
      valueFormatter,
    },
    {
      headerName: 'Nombre',
      field: 'Nombre',
      valueFormatter,
    },
    {
      headerName: 'Regimen',
      field: 'idRegimen',
      valueFormatter,
    },
    {
      headerName: 'Calle',
      field: 'Calle',
      valueFormatter,
    },
    {
      headerName: 'Numero Exterior',
      field: 'NumInterior',
      valueFormatter,
    },
    {
      headerName: 'Colonia',
      field: 'Colonia',
      valueFormatter,
    },
    {
      headerName: 'Localidad',
      field: 'Localidad',
      valueFormatter,
    },
    {
      headerName: 'Referencia',
      field: 'Referencia',
      valueFormatter,
    },
    {
      headerName: 'Municipio',
      field: 'Municipio',
      valueFormatter,
    },
    {
      headerName: 'Estado',
      field: 'Estado',
      valueFormatter,
    },
    {
      headerName: 'Pais',
      field: 'Pais',
      valueFormatter,
    },
    {
      headerName: 'CodPos',
      field: 'CodPos',
      valueFormatter,
    },
    {
      headerName: 'Registro Tributario Exterior',
      field: 'RegistroTributarioExterior',
      valueFormatter,
    },
    {
      headerName: 'Exento IVA',
      field: 'ExentoIVA',
      valueFormatter,
    },
    {
      headerName: 'NoRetieneIVA',
      field: 'NoRetieneIVA',
      valueFormatter,
    },
    {
      headerName: 'No Retiene ISR',
      field: 'NoRetieneISR',
      valueFormatter,
    },
    {
      headerName: 'No Retiene IEPS',
      field: 'NoRetieneIEPS',
      valueFormatter,
    },
    {
      headerName: '',
      field: '',
      pinned: 'right',
      cellStyle: { textAlign: 'center' },

      cellRendererFramework: (params) => {
        if (params.data)
          return (
            <Button.Group>
              <Button
                compact
                primary
                onClick={() =>
                  handleEditClick({
                    ...params.data,
                    client: params.data.client && params.data.client._id,
                  })
                }
              >
                Editar
              </Button>
              <Button
                compact
                primary
                onClick={() =>
                  handleDeleteClick({
                    ...params.data,
                    client: params.data.client && params.data.client._id,
                  })
                }
              >
                Borrar
              </Button>
            </Button.Group>
          );
      },
    },
  ]);
  const [defaultColDef] = useState({});

  // Effect
  useEffect(() => {
    if (
      selection &&
      !selection.length &&
      gridApi &&
      gridApi.getSelectedRows().length
    ) {
      gridApi.deselectAll();
    }
  }, [selection, gridApi]);

  useEffect(() => {
    if (gridColumnApi) {
      var allColumnIds = gridColumnApi
        .getAllColumns()
        .filter(({ colDef: { field } }) => field !== '')
        .map(({ colId }) => colId);

      gridColumnApi.autoSizeColumns &&
        gridColumnApi.autoSizeColumns(allColumnIds, false);
    }
  }, [data, gridColumnApi]);

  // Handles
  const handleEditClick = (user) => {
    onEditClick && onEditClick({ ...user });
  };
  const handleDeleteClick = (user) => {
    onDeleteClick && onDeleteClick({ ...user });
  };

  const handleSelectionChanged = (event) => {
    if (selection && setSelection) {
      let rows = [];

      try {
        if (event && event.api) {
          rows = event.api.getSelectedRows().map((row) => row._id);
        }
      } catch (err) {
        rows = [];

        swal({
          title: 'Error',
          text: 'Failed to select users',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setSelection(rows);
      }
    }
  };

  const handleGridReady = ({ api, columnApi }) => {
    setGridApi(api);
    setGridColumnApi(columnApi);
  };

  return (
    <TableContainer className="ag-theme-material" rowFilters={1}>
      <AgGridReact
        modules={AllModules}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowSelection={'multiple'}
        suppressRowClickSelection={true}
        onSelectionChanged={handleSelectionChanged}
        rowData={data}
        pagination={true}
        paginationPageSize={100}
        onGridReady={handleGridReady}
      />
    </TableContainer>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
};

export default Table;
