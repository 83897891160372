import React from 'react';

import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';

function DropdownCodigoPostal({ formik, values, names, errors }) {
  let { setFieldValue } = formik;
  return (
    <DropdownSearchFetch
      fluid
      selection
      label="Código Postal *"
      value={values.CodigoPostal}
      allowAdditions={values.Pais !== 'MEX'}
      name={names.CodigoPostal}
      onChange={(e, { value }) => {
        setFieldValue(names.CodigoPostal, value);
        setFieldValue(names.Colonia, '');
      }}
      valuesChangesToUpdate={[
        values.Pais,
        values.Estado,
        values.Municipios,
        values.Localidad,
        values.CodigoPostal,
      ]}
      placeholder="Código Postal"
      featherService="catalogo-codigo-postal"
      columName="c_CodigoPostal"
      columValue="c_CodigoPostal"
      mapValues={(value) => {
        return {
          key: Math.random(),
          text: `${value['c_CodigoPostal']}`,
          value: value['c_CodigoPostal'],
        };
      }}
      error={
        errors.CodigoPostal && {
          content: errors.CodigoPostal,
        }
      }
      queryWithSearch={(columName, columValue, searchQuery) => {
        let newQuery = {
          $or: [
            {
              [columName]: {
                $like: '%' + searchQuery + '%',
              },
            },
            {
              [columValue]: {
                $like: '%' + searchQuery + '%',
              },
            },
          ],
          c_Estado: values.Estado,
          $limit: 10,
        };

        return newQuery;
      }}
      queryInit={(columName, columValue, value) => {
        let newQuery;
        if (value) {
          newQuery = {
            [columValue]: { $like: value },
            c_Estado: values.Estado,
            $limit: 1,
          };
        } else {
          newQuery = {
            $limit: 10,
            c_Estado: values.Estado,
          };
        }

        return newQuery;
      }}
    />
  );
}

export default DropdownCodigoPostal;
