import React, { useState } from 'react';
import feathers, { useAuth } from '../../services/feathers';
import swal from 'sweetalert';
import { Button } from 'semantic-ui-react';

function TableButtonCancelarTimbrado(props) {
  let {
    id,
    idPOS,
    idSucursal,
    sMotivoCancelacion,
    sUUID,
    getData,
    onCancel,
    disabled,
  } = props;
  const auth = useAuth();
  const [cancelado, setCancelado] = useState(false);
  const [loadingCancelar, setLoadingCancelar] = useState(false);

  const handleCancel = () => {
    Cancelar(id, idPOS, idSucursal, sMotivoCancelacion, sUUID); // La función original de cancelación
    onCancel(); // Llamar a la función proporcionada desde el componente padre
  };

  const Cancelar = async (id, idPOS, idSucursal, sMotivoCancelacion, sUUID) => {
    setLoadingCancelar(true);
    let service = feathers.service('pos');
    let serviceCancelarTimbrado = feathers.service('cancelar-cfdi-timbrado');

    let resultPos = await service.find({ query: { idPOS, idSucursal } });
    resultPos = (resultPos.data.length && resultPos.data[0]) || null;

    if (resultPos) {
      let idCertificado = resultPos.idCertificado;
      let idPAC = resultPos.idPAC;
      let resultCancelado = await serviceCancelarTimbrado.create({
        idDocumento: id,
        idCertificado: idCertificado,
        idPAC: idPAC,
        sUser: auth.user._id,
        sMotivoCancelacion: sMotivoCancelacion,
        sUUID: sUUID,
      });

      if (
        resultCancelado &&
        resultCancelado.resultCancelado.errors &&
        resultCancelado.resultCancelado.errors.length
      ) {
        setLoadingCancelar(false);
        swal('Error', resultCancelado.resultCancelado.errors[0], 'error');
      } else {
        setLoadingCancelar(false);
        swal('Cancelado', '¡Se ha cancelado correctamente!', 'success');
        getData();
        setCancelado(true);
      }

      // alert(id);
    } else {
      setLoadingCancelar(false);
      swal('Error', 'No hay POS', 'error');
    }
  };
  return (
    <Button.Group>
      <Button
        compact
        primary
        loading={loadingCancelar}
        onClick={handleCancel}
        disabled={props.disabled}
      >
        Cancelar
      </Button>
    </Button.Group>
  );
}

export default TableButtonCancelarTimbrado;
