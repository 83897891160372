import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import swal from 'sweetalert';
import { Formik } from 'formik';
import Actions from './Actions';
import FormUbicacion from './FormUbicaciones';
import feathers from '../../../services/feathers';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import * as Yup from 'yup';

const Drawer = ({ openPanel, onClose, currentUbicacion }) => {
  const service = feathers.service('ubicaciones');
  const [currentSection, setCurrentSection] = useState(
    (currentUbicacion && currentUbicacion.id && 'EDIT') || 'CREATE'
  );
  useEffect(() => {});
  const handleSave = async (values, { setSubmitting }) => {
    if (values) {
      let success = true;
      try {
        if (values.id) {
          let result = await service.patch(values.id, values);
          swal('Actualizado', '¡Se ha actualizado correctamente!', 'success');
        } else {
          let result = await service.find({
            query: {
              $limit: 1,
              $sort: {
                id: -1,
              },
            },
          });
          values.id = result.data[0].id;
          values.id++;
          let resultCreate = await service.create(values);
          swal('Guardado', '¡Se ha guardado correctamente!', 'success');
        }
      } catch (exception) {
        //console.log(exception);
        swal('Error', 'Error al guardar la ubicación', 'error');
      }
      if (success) {
        setCurrentSection('CREATE');
        onClose(true);
      }
    } else swal('Error', 'Error al guardar la ubicación', 'error');
  };

  const handledOnClose = async () => {
    let confirmation = await swal('Do you want close this form?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      setCurrentSection('CREATE');
      onClose(false);
    }
  };
  const Validations = Yup.object().shape({
    Nombre: Yup.string().max(250, 'Too Long!').required('Required'),
    RFC: Yup.string().max(30, 'Too Long!').required('Required'),
    ResidenciaFiscal: Yup.string().max(3, 'Too Long!'),
    //NumRegTributario: Yup.string().max(250, 'Too Long!'),
    Calle: Yup.string().max(250, 'Too Long!').required('Required'),
    Pais: Yup.string().max(250, 'Too Long!').required('Required'),
    Estado: Yup.string().max(250, 'Too Long!').required('Required'),
    Municipio: Yup.string().max(250, 'Too Long!').required('Required'),
    Localidad: Yup.string().max(250, 'Too Long!').required('Required'),
    CodigoPostal: Yup.string().max(50, 'Too Long!').required('Required'),
    Colonia: Yup.string().max(250, 'Too Long!').required('Required'),
  });

  let drawerTitles = {
    CREATE: {
      title: 'Nueva Ubicación',
      subtitle: '',
    },
    EDIT: {
      title: 'Editar Ubicación',
      subtitle: '',
    },
  };

  let title = drawerTitles[currentSection].title;
  let subtitle = drawerTitles[currentSection].subtitle;

  return (
    <SlidingPanel
      isOpen={openPanel}
      size={100}
      title={title}
      subtitle={subtitle}
      onClose={handledOnClose}
    >
      <Formik
        initialValues={currentUbicacion}
        onSubmit={handleSave}
        validationSchema={Validations}
      >
        {(formikProps) => (
          <Form
            onSubmit={formikProps.handleSubmit}
            style={{ marginRight: '15px' }}
          >
            <FormUbicacion
              onSave={handleSave}
              // ref={refForm}
              // isValid={isValid}
              // setIsValid={setIsValid}
              formik={formikProps}
            />

            <Actions formik={formikProps} handledOnClose={handledOnClose} />
          </Form>
        )}
      </Formik>
    </SlidingPanel>
  );
};

export default Drawer;
