import React, { useState } from 'react';
import TableContainer from '../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { FormGroup, Form } from 'semantic-ui-react';
import SemanticInput from '../../components/SemanticInput';
import swal from 'sweetalert';

const Table = ({ data, getData, onEditClick, setSelection }) => {
  const [columnDefs] = useState([
    {
      headerName: 'Folio',
      field: 'Folio',
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: 'Serie',
      field: 'Serie',
    },
    // {
    //   headerName: 'idSocioNegocio',
    //   field: 'idSocioNegocio',
    // },
    {
      headerName: 'FechaDocumento',
      field: 'FechaDocumento',
    },
    {
      headerName: 'Moneda',
      field: 'idMoneda',
    },
    {
      headerName: 'Tipo de Cambio',
      field: 'TipoDeCambio',
    },
    {
      headerName: 'Total Pesos',
      field: 'Total',
    },
    {
      headerName: 'Saldo',
      field: 'SaldoActual',
    },
    {
      headerName: 'Monto Pago',
      field: 'MontoPago',
      editable: true,
    },

    {
      headerName: 'timbre_uuid',
      field: 'timbre_uuid',
    },

    // {
    //   headerName: 'Actions',
    //   field: '',
    //   pinned: 'right',
    //   cellStyle: { textAlign: 'center' },
    //   cellRendererFramework: (params) =>
    //     (params && params.data && (
    //       <Button compact primary onClick={() => handledEditClick(params.data)}>
    //         Edit
    //       </Button>
    //     )) ||
    //     null,
    // },
  ]);

  const [defaultColDef] = useState({ flex: 1 });
  const [quickFilterText, setQuickFilterText] = useState('');

  const handledEditClick = (shipper) => {
    onEditClick && onEditClick({ ...shipper });
  };

  const onGridReady = () => {
    getData && getData();
  };

  const onQuickFilterChanged = (e) => {
    setQuickFilterText(e.target.value);
  };

  const handledSelectionChanged = ({ api }) => {
    let rows;
    try {
      if (api) {
        rows = api.getSelectedRows();
      }
    } catch (error) {
      console.error('error :>> ', error);
      rows = [];
      let err = {
        title: 'Error',
        text: 'Failed to select Clients',
        icon: 'error',
        buttons: { cancel: 'Close' },
      };
      swal(err);
    } finally {
      setSelection(rows);
      // console.log( 'Informacion de las rows seleccionadas: ', rows );
    }
  };

  return (
    <Form>
      <FormGroup widths={4}>
        <SemanticInput
          name="filtro"
          label="Buscar en la tabla"
          onChange={onQuickFilterChanged}
          value={quickFilterText}
        />
      </FormGroup>

      <TableContainer className="ag-theme-material" rowFilters={1}>
        <AgGridReact
          rowData={data}
          modules={AllModules}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          pagination={true}
          paginationPageSize={100}
          suppressRowClickSelection={true}
          onGridReady={() => onGridReady()}
          onSelectionChanged={handledSelectionChanged}
          quickFilterText={quickFilterText}
        />
      </TableContainer>
    </Form>
  );
};

export default Table;
