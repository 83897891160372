import React, { forwardRef, useState } from 'react';
import { Form, Segment, Header } from 'semantic-ui-react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import Dropdown from '../../../components/Formik/Dropdown';
import DatePicker from 'react-datepicker';
import FormGroupLocations from './FormGroupLocations';
import DropdownUbicacion from './DropdownUbicacion';
import moment from 'moment';

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const getErrorsAndTouchs = (touched, errors, index, name) => {
  return (
    touched.ubicaciones &&
    touched.ubicaciones[index] &&
    touched.ubicaciones[index][name] &&
    errors.ubicaciones &&
    errors.ubicaciones[index] &&
    errors.ubicaciones[index][name]
  );
};

const getDates = (isoDate) => {
  if (typeof isoDate !== 'string') {
    return isoDate;
  } else {
    if (isoDate.endsWith('Z')) {
      let date = isoDate.slice(0, isoDate.length - 1);
      return new Date(date);
    } else {
      return new Date(isoDate);
    }
  }
};

const FormUbicacion = ({ formik, index, ubicacion }) => {
  let { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    formik;
  const [startDate, setStartDate] = useState(new Date());
  return (
    <div key={index} className="ui form">
      <Form.Group widths={'equal'}>
        <DropdownUbicacion
          formik={formik}
          ubicaciones={ubicacion}
          index={index}
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <Dropdown
          fluid
          selection
          label="Tipo de Ubicación *"
          value={ubicacion.TipoUbicacion}
          onChange={(e, { value }) => {
            setFieldValue(`ubicaciones.${index}.TipoUbicacion`, value);
            if (value) {
              setFieldValue(
                `ubicaciones.${index}.IDUbicacion`,
                value[0].toUpperCase() +
                  value[1].toUpperCase() +
                  '00000' +
                  (index + 1)
              );
            }
          }}
          placeholder="Tipo de Comprobante *"
          options={[
            { key: 'Origen', value: 'Origen', text: 'Origen' },
            { key: 'Destino', value: 'Destino', text: 'Destino' },
          ]}
          error={
            errors.ubicaciones &&
            errors.ubicaciones[index] &&
            errors.ubicaciones[index].TipoUbicacion && {
              content:
                errors.ubicaciones[index] &&
                errors.ubicaciones[index].TipoUbicacion,
            }
          }
        />
        <Form.Input
          control="input"
          placeholder="ID Ubicación"
          label="ID Ubicación *"
          name={`ubicaciones.${index}.IDUbicacion`}
          value={ubicacion.IDUbicacion}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={true}
          //   error={
          //     validations.name && {
          //       content: 'Required idTipoComprobante',
          //     }
          //   }
        />
        <Form.Input
          placeholder="Nombre"
          label="Nombre *"
          name={`ubicaciones.${index}.NombreUbicacion`}
          value={ubicacion.NombreUbicacion ? ubicacion.NombreUbicacion : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.ubicaciones &&
            errors.ubicaciones[index] &&
            errors.ubicaciones[index].NombreUbicacion &&
            touched.ubicaciones &&
            touched.ubicaciones[index] &&
            touched.ubicaciones[index].NombreUbicacion && {
              content:
                errors.ubicaciones[index] &&
                errors.ubicaciones[index].NombreUbicacion,
            }
          }
        />
        <Form.Input
          placeholder="RFC"
          label="RFC *"
          name={`ubicaciones.${index}.RFCRemitenteDestinatario`}
          value={ubicacion.RFCRemitenteDestinatario}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.ubicaciones &&
            errors.ubicaciones[index] &&
            errors.ubicaciones[index].RFCRemitenteDestinatario &&
            touched.ubicaciones &&
            touched.ubicaciones[index] &&
            touched.ubicaciones[index].RFCRemitenteDestinatario && {
              content:
                errors.ubicaciones[index] &&
                errors.ubicaciones[index].RFCRemitenteDestinatario,
            }
          }
        />
        <DropdownSearchFetch
          fluid
          selection
          label="Residencia Fiscal *"
          value={ubicacion.ResidenciaFiscal}
          onChange={(e, { value }) =>
            setFieldValue(`ubicaciones.${index}.ResidenciaFiscal`, value)
          }
          placeholder="Residencia Fiscal"
          featherService="catalogo-pais"
          columName="Descripcion"
          columValue="c_Pais"
          mapValues={(value) => {
            return {
              key: Math.random(),
              text: `${value['c_Pais']} - ${value['Descripcion']}`,
              value: value['c_Pais'],
            };
          }}
          error={
            errors.ubicaciones &&
            errors.ubicaciones[index] &&
            errors.ubicaciones[index].ResidenciaFiscal && {
              content:
                errors.ubicaciones[index] &&
                errors.ubicaciones[index].ResidenciaFiscal,
            }
          }
        />
        {ubicacion.ResidenciaFiscal !== 'MEX' && (
          <Form.Input
            placeholder="Número Registro Tributario"
            label="Número Registro Tributario *"
            name={`ubicaciones.${index}.NumRegTributario`}
            value={ubicacion.NumRegTributario}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.ubicaciones &&
              errors.ubicaciones[index] &&
              errors.ubicaciones[index].NumRegTributario &&
              touched.ubicaciones &&
              touched.ubicaciones[index] &&
              touched.ubicaciones[index].NumRegTributario && {
                content:
                  errors.ubicaciones[index] &&
                  errors.ubicaciones[index].NumRegTributario,
              }
            }
            required={
              ubicacion.ResidenciaFiscal === 'CAN' ||
              ubicacion.ResidenciaFiscal === 'USA'
            }
            disabled={ubicacion.ResidenciaFiscal === 'MEX'}
          />
        )}

        <div className="field">
          <label>
            <b>Fecha/Hora *</b>
          </label>
          <DatePicker
            name={`ubicaciones.${index}.FechaHoraSalidaLlegada`}
            selected={
              ubicacion.FechaHoraSalidaLlegada
                ? moment(ubicacion.FechaHoraSalidaLlegada).toDate()
                : startDate
            }
            onChange={(date) => {
              setStartDate(new Date(date));
              setFieldValue(
                `ubicaciones.${index}.FechaHoraSalidaLlegada`,
                date
              );
            }}
            timeInputLabel="Hora:"
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeInput
          />
        </div>
        {ubicacion.TipoUbicacion !== 'Origen' && (
          <>
            <Form.Input
              disabled={ubicacion.TipoUbicacion === 'Origen' && true}
              type="number"
              placeholder="Distancia Recorrida"
              min={0}
              label="Distancia Recorrida *"
              name={`ubicaciones.${index}.DistanciaRecorrida`}
              value={
                ubicacion.TipoUbicacion === 'Destino'
                  ? ubicacion.DistanciaRecorrida
                  : (ubicacion.DistanciaRecorrida = 0.0)
              }
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.ubicaciones &&
                errors.ubicaciones[index] &&
                errors.ubicaciones[index].DistanciaRecorrida &&
                touched.ubicaciones &&
                touched.ubicaciones[index] &&
                touched.ubicaciones[index].DistanciaRecorrida && {
                  content:
                    errors.ubicaciones[index] &&
                    errors.ubicaciones[index].DistanciaRecorrida,
                }
              }
            />{' '}
          </>
        )}
      </Form.Group>

      <FormGroupLocations
        formik={formik}
        values={{
          Pais: ubicacion.Pais,
          Estado: ubicacion.Estado,
          Municipio: ubicacion.Municipio,
          Localidad: ubicacion.Localidad,
          CodigoPostal: ubicacion.CodigoPostal,
          Colonia: ubicacion.Colonia,
          Calle: ubicacion.Calle,
          NumExterior: ubicacion.NumExterior,
          NumInterior: ubicacion.NumInterior,
          Referencia: ubicacion.Referencia,
        }}
        names={{
          Pais: `ubicaciones.${index}.Pais`,
          Estado: `ubicaciones.${index}.Estado`,
          Municipio: `ubicaciones.${index}.Municipio`,
          Localidad: `ubicaciones.${index}.Localidad`,
          CodigoPostal: `ubicaciones.${index}.CodigoPostal`,
          Colonia: `ubicaciones.${index}.Colonia`,
          Calle: `ubicaciones.${index}.Calle`,
          NumExterior: `ubicaciones.${index}.NumExterior`,
          NumInterior: `ubicaciones.${index}.NumInterior`,
          Referencia: `ubicaciones.${index}.Referencia`,
        }}
        errors={{
          Pais: getErrorsAndTouchs(touched, errors, index, 'Pais'),
          Estado: getErrorsAndTouchs(touched, errors, index, 'Estado'),
          Municipio: getErrorsAndTouchs(touched, errors, index, 'Municipio'),
          Localidad: getErrorsAndTouchs(touched, errors, index, 'Localidad'),
          CodigoPostal: getErrorsAndTouchs(
            touched,
            errors,
            index,
            'CodigoPostal'
          ),
          Colonia: getErrorsAndTouchs(touched, errors, index, 'Colonia'),
          Calle: getErrorsAndTouchs(touched, errors, index, 'Calle'),
          NumExterior: getErrorsAndTouchs(
            touched,
            errors,
            index,
            'NumExterior'
          ),
          NumInterior: getErrorsAndTouchs(
            touched,
            errors,
            index,
            'NumInterior'
          ),
          Referencia: getErrorsAndTouchs(touched, errors, index, 'Referencia'),
        }}
      />
    </div>
  );
};

export default forwardRef((props, ref) => (
  <FormUbicacion {...props} innerRef={ref} />
));
