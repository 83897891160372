
import React, { useState, useEffect } from 'react';
import { Button } from 'semantic-ui-react';


const EditButton = ({title, handleEdit, type})=>{
    return (
        <>
          <Button compact primary type="button" onClick={handleEdit}>
            {title}
          </Button>
        </>
      );
}

export default EditButton;