import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  ModalActions,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import feathers from '../../services/feathers';
import moment from 'moment';
import styled from 'styled-components';

const SubtitleLabel = styled.span`
  font-weight: bold;
`;

const StatusModal = ({
  open,
  onClose,
  data,
  idInvoice,
  idPOS,
  idSucursal,
  idSocioNegocio,
}) => {
  const [dataStatusInvoices, setDataStatusInvoices] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const dateMax = moment().endOf('month').format('YYYYMMDD');
      const serviceStatusInvoices = feathers.service('get-status-invoices');
      try {
        if (!idInvoice) return;
        let formateador;
        const result = await serviceStatusInvoices.find({
          query: {
            idSucursal,
            idPOS,
            idSocioNegocio,
            FechaMax: dateMax,
            idInvoice,
          },
        });

        if (result && result.length > 0) {
          const formateador = getCurrencyFormatter(result[0].MonedaDR);
          result[0].Total = formateador.format(result[0].Total);
          result[0].SaldoActual = formateador.format(result[0].SaldoActual);
          result[0].TotalPagado = formateador.format(result[0].TotalPagado);
        }
        setDataStatusInvoices(result !== 'No info' ? result[0] : 'No info');
        setLoading(false);
      } catch (error) {
        console.error('Error fetching status invoices:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [open, idPOS, idSucursal, idSocioNegocio, idInvoice]);

  function getCurrencyFormatter(currency) {
    if (currency === 'MXN') {
      return new Intl.NumberFormat('es-MX', {
        style: 'currency',
        minimumFractionDigits: 2,
        currency: 'MXN',
      });
    } else {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        minimumFractionDigits: 2,
        currency: 'USD',
      });
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalHeader>Estado de la Factura</ModalHeader>
      {loading && (
        <ModalContent>
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
        </ModalContent>
      )}
      {dataStatusInvoices ? (
        dataStatusInvoices.SaldoActual === '$0.00' ? (
          <ModalContent>
            <div>
              <SubtitleLabel>Estatus de Pago: </SubtitleLabel>
              <span>Pagado</span>
            </div>
            <div>
              <SubtitleLabel>Folio de Pago: </SubtitleLabel>
              <span>{dataStatusInvoices.Pago_Folio}</span>
            </div>
            <div>
              <SubtitleLabel>Total pagado: </SubtitleLabel>
              <span>{dataStatusInvoices.TotalPagado}</span>
            </div>
            <div>
              <SubtitleLabel>Fecha de Pago: </SubtitleLabel>
              <span>
                {moment(dataStatusInvoices.Pago_FechaPago).format('DD-MM-YYYY')}
              </span>
            </div>
            <div>
              <SubtitleLabel>Forma de Pago: </SubtitleLabel>
              <span>{dataStatusInvoices.Pago_DescripcionFormaPago}</span>
            </div>
            <div>
              <SubtitleLabel>Núm. Parcialidad: </SubtitleLabel>
              <span>{dataStatusInvoices.Pago_NumParcialidad}</span>
            </div>
            <div>
              <SubtitleLabel>UUID del Pago: </SubtitleLabel>
              <span>{dataStatusInvoices.Pago_timbre_uuid}</span>
            </div>
          </ModalContent>
        ) : (
          <ModalContent>
            <div>
              <SubtitleLabel>Estatus de Pago: </SubtitleLabel>
              <span>Pendiente de pago</span>
            </div>
            {dataStatusInvoices.Pago_FechaPago && (
              <div>
                <SubtitleLabel>Fecha de Pago: </SubtitleLabel>
                <span>
                  {moment(dataStatusInvoices.Pago_FechaPago).format(
                    'DD-MM-YYYY'
                  )}
                </span>
              </div>
            )}
            <div>
              <SubtitleLabel>Saldo Pendiente a Pagar: </SubtitleLabel>
              <span>{dataStatusInvoices.SaldoActual}</span>
            </div>
            <div>
              <SubtitleLabel>Total: </SubtitleLabel>
              <span>{dataStatusInvoices.Total}</span>
            </div>
            <div>
              <SubtitleLabel>Total Pagado: </SubtitleLabel>
              <span>{dataStatusInvoices.TotalPagado}</span>
            </div>
            <div>
              <SubtitleLabel>Núm. Parcialidad: </SubtitleLabel>
              <span>
                {dataStatusInvoices.Pago_NumParcialidad
                  ? dataStatusInvoices.Pago_NumParcialidad
                  : 0}
              </span>
            </div>
            {dataStatusInvoices.Pago_DescripcionFormaPago && (
              <div>
                <SubtitleLabel>Forma de Pago: </SubtitleLabel>
                <span>{dataStatusInvoices.Pago_DescripcionFormaPago}</span>
              </div>
            )}
          </ModalContent>
        )
      ) : (
        <ModalContent>
          <div>
            <span>No se encontró información</span>
          </div>
        </ModalContent>
      )}
      <ModalActions>
        <Button compact secondary onClick={onClose}>
          Cerrar
        </Button>
      </ModalActions>
    </Modal>
  );
};

StatusModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  data: PropTypes.object,
  actionType: PropTypes.string.isRequired,
  getData: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  idPOS: PropTypes.number.isRequired,
  idSucursal: PropTypes.number.isRequired,
};

export default StatusModal;
