import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import 'lodash';
import { Button } from 'semantic-ui-react';
import swal from 'sweetalert';
import { valueFormatter } from '../../components/AgGridColumns';

const Table = ({ data, onEditClick, onDeleteClick, selection, setSelection }) => {
	// States
	const [ gridApi, setGridApi ] = useState();
	const [ gridColumnApi, setGridColumnApi ] = useState(null);
	const [ columnDefs ] = useState([
		{
			headerName: 'Descripcion',
			field: 'Descripcion',
			valueFormatter
		},
		{
			headerName: 'NoSerie',
			field: 'NoSerie',
			valueFormatter
		},
		{
			headerName: 'VigenciaInicio',
			field: 'VigenciaInicio',
			valueFormatter
		},
		{
			headerName: 'VigenciaFin',
			field: 'VigenciaFin',
			valueFormatter
		},
		{
			headerName: 'Ruta',
			field: 'Ruta',
			valueFormatter: (val) => {
				return val.value ? 'Registrada' : 'No registrada';
			}
		},
		{
			headerName: 'RutaLlave',
			field: 'RutaLlave',
			valueFormatter: (val) => {
				return val.value ? 'Registrada' : 'No registrada';
			}
		},
		{
			headerName: 'Contraseña',
			field: 'Contrasena',
			valueFormatter: (val) => {
				return val.value ? 'Registrada' : 'No registrada';
			}
		},
		{
			headerName: 'RFC',
			field: 'RFC',
			valueFormatter
		},
		{
			headerName: 'Status',
			field: 'Status',
			valueFormatter: (val) => {
				return val ? 'Activo' : 'Inactivo';
			}
		},
		// {
		// 	headerName: 'Certificado Byte',
		// 	field: 'CertificadoByte',
		// 	valueFormatter: (val) => {
		// 		return val.value ? 'Registrada' : 'No registrada';
		// 	}
		// },
		// {
		// 	headerName: 'Llave Byte',
		// 	field: 'LlaveByte',
		// 	valueFormatter: (val) => {
		// 		return val.value ? 'Registrada' : 'No registrada';
		// 	}
		// },
		{
			headerName: '',
			field: '',
			pinned: 'right',
			cellStyle: { textAlign: 'center' },

			cellRendererFramework: (params) => {
				if (params.data)
					return (
						<Button.Group>
							<Button
								compact
								primary
								onClick={() =>
									handleEditClick({
										...params.data,
										client: params.data.client && params.data.client._id
									})}
							>
								Editar
							</Button>
							<Button
								compact
								primary
								onClick={() =>
									handleDeleteClick({
										...params.data,
										client: params.data.client && params.data.client._id
									})}
							>
								Borrar
							</Button>
						</Button.Group>
					);
			}
		}
	]);
	const [ defaultColDef ] = useState({});

	// Effect
	useEffect(
		() => {
			if (selection && !selection.length && gridApi && gridApi.getSelectedRows().length) {
				gridApi.deselectAll();
			}
		},
		[ selection, gridApi ]
	);

	useEffect(
		() => {
			if (gridColumnApi) {
				var allColumnIds = gridColumnApi
					.getAllColumns()
					.filter(({ colDef: { field } }) => field !== '')
					.map(({ colId }) => colId);

				gridColumnApi.autoSizeColumns && gridColumnApi.autoSizeColumns(allColumnIds, false);
			}
		},
		[ data, gridColumnApi ]
	);

	// Handles
	const handleEditClick = (user) => {
		onEditClick && onEditClick({ ...user });
	};
	const handleDeleteClick = (user) => {
		onDeleteClick && onDeleteClick({ ...user });
	};
	const handleSelectionChanged = (event) => {
		if (selection && setSelection) {
			let rows = [];

			try {
				if (event && event.api) {
					rows = event.api.getSelectedRows().map((row) => row._id);
				}
			} catch (err) {
				rows = [];

				swal({
					title: 'Error',
					text: 'Failed to select users',
					icon: 'error',
					buttons: {
						cancel: 'Close'
					}
				});
			} finally {
				setSelection(rows);
			}
		}
	};

	const handleGridReady = ({ api, columnApi }) => {
		setGridApi(api);
		setGridColumnApi(columnApi);
	};

	return (
		<TableContainer className="ag-theme-material" rowFilters={1}>
			<AgGridReact
				modules={AllModules}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				rowSelection={'multiple'}
				suppressRowClickSelection={true}
				onSelectionChanged={handleSelectionChanged}
				rowData={data}
				pagination={true}
				paginationPageSize={100}
				onGridReady={handleGridReady}
			/>
		</TableContainer>
	);
};

Table.propTypes = {
	data: PropTypes.array,
	onEditClick: PropTypes.func,
	onDeleteClick: PropTypes.func,
	selection: PropTypes.array,
	setSelection: PropTypes.func
};

export default Table;
