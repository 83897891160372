import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';

function TableButtonPdf(props) {
  let { servicePDF, data } = props;
  const [loadingPDF, setLoadingPDF] = useState(false);

  const saveFilePDF = async (data) => {
    setLoadingPDF(true);
    let html = await servicePDF.get(data.id);
    await downloadPDF(data, html);
    setLoadingPDF(false);
  };

  const downloadPDF = async (data, html) => {
    const blob = new Blob([html], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    if (data.idStatus === 3) {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${data.EmisorRFC}_${data.ReceptorRFC}_${data.Serie}${data.Folio}_${data.timbre_uuid}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(url, '_blank');
    }
    window.URL.revokeObjectURL(url);
  };

  return (
    <Button
      compact
      primary
      onClick={() => saveFilePDF(data)}
      loading={loadingPDF} // Utilizar el estado de carga para mostrar la animación
    >
      PDF
    </Button>
  );
}

export default TableButtonPdf;
