import React, { forwardRef, useRef, useEffect, useState } from 'react';
import feathers from '../../../services/feathers';
import { Form, Divider, Checkbox, Label } from 'semantic-ui-react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import Dropdown from '../../../components/Formik/Dropdown';
import DropdownConceptos from '../../../components/Formik/DropdownConceptos';
import { cartaPorte } from '../../../constants/Roles';

const FormConcept = ({
  formik,
  isCartaPorte,
  isMaterialPeligroso,
  setIsMaterialPeligroso,
  concepto,
  index,
}) => {
  const conceptosService = feathers.service('conceptos');
  const tipoMateriaService = feathers.service('catalogo-tipo-materia');
  const [perfilImpuestos, setPerfilImpuestos] = useState(0);
  const [retencionIVA, setRetencionIVA] = useState(0);

  useEffect(() => {
    getConcepto();
  }, []);

  const getConcepto = async () => {
    if (concepto.idProducto !== 0) {
      let response = await conceptosService.find({
        query: {
          idProducto: concepto.idProducto,
        },
      });
      setPerfilImpuestos(response.data[0].idTrasladoIVA);
      concepto.PerfilImpuestos = response.data[0].idTrasladoIVA;
      setRetencionIVA(response.data[0].idRetencionIVA);
    }
  };

  const getDescriptionTipoMateria = async (value) => {
    if (value) {
      let response = await tipoMateriaService.find({
        query: {
          c_ClaveTipoMateria: value,
        },
      });
      return response.data[0] ? response.data[0].Descripcion : '';
    }
  };

  let hazmatCode = '';
  const serviceClaveProdCP = feathers.service('catalogo-clave-prod-serv-cp');
  let { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    formik;
  const ref_input = useRef(null);
  const getHazmatCode = async (id) => {
    let result = await serviceClaveProdCP.find({
      query: {
        c_ClaveProdServCP: id,
      },
    });
    if (result.data.length) {
      return result.data[0].MaterialPeligroso;
    } else {
      return '';
    }
  };

  function triggerEvent(element, eventName) {
    var event = document.createEvent('HTMLEvents');
    event.initEvent(eventName, false, true);
    element.handleChange(event);
  }
  return (
    <div key={index} className="ui form">
      <Form.Group widths={'equal'}>
        <Dropdown
          isDisabled={values.idTipoComprobante === 'T' ? true : false}
          fluid
          selection
          label="Tipo de Concepto *"
          value={
            values.idTipoComprobante === 'T'
              ? (concepto.TipoConcepto = 2)
              : concepto.TipoConcepto
          }
          onChange={(e, { value }) => {
            setFieldValue(`conceptos.${index}.TipoConcepto`, value);
          }}
          placeholder="Tipo de Concepto"
          options={
            values.idTipoComprobante === 'I'
              ? [
                  { key: 0, value: 0, text: 'Ingreso' },
                  isCartaPorte && values.idTipoComprobante === 'I' && { key: 1, value: 1, text: 'Traslado' } ,
                ]
              : [
                  {
                    key: 2,
                    value: 2,
                    text: 'Conceptos/Traslados',
                  },
                ]
          }
          onBlur={handleBlur}
          error={
            errors.conceptos &&
            errors.conceptos[index] &&
            errors.conceptos[index].TipoConcepto &&
            // touched.conceptos &&
            // touched.conceptos[index] &&
            // touched.conceptos[index].TipoConcepto && 
            {
              content:
                errors.conceptos[index] && errors.conceptos[index].TipoConcepto,
            }
          }
        />
        {concepto.TipoConcepto === 0 && values.idTipoComprobante === 'I' && (
          <DropdownConceptos
            fluid
            selection
            label="Concepto *"
            value={concepto.Descripcion ? concepto.Descripcion : ''}
            onChange={(e, { value }, posData) => {
              for (let concepto of posData) {
                if (concepto.Descripcion === value) {
                  setFieldValue(
                    `conceptos.${index}.Descripcion`,
                    concepto.Descripcion
                  );
                  setFieldValue(
                    `conceptos.${index}.ClaveProdServ`,
                    concepto.ClaveProdServ
                  );

                  setFieldValue(
                    `conceptos.${index}.ClaveUnidad`,
                    concepto.ClaveUnidad
                  );
                  setFieldValue(
                    `conceptos.${index}.NumeroIdentificacionComercio`,
                    concepto.NumeroIdentificacion
                  );
                  setFieldValue(
                    `conceptos.${index}.idProducto`,
                    concepto.idProducto
                  );
                  setPerfilImpuestos(concepto.idTrasladoIVA);
                  setFieldValue(
                    `conceptos.${index}.PerfilImpuestos`,
                    concepto.idTrasladoIVA
                  );
                  setRetencionIVA(concepto.idRetencionIVA);
                  setFieldValue(
                    `conceptos.${index}.RetencionIVA`,
                    concepto.idRetencionIVA
                  );
                }
              }
            }}
            placeholder="Selecciona un concepto"
            featherService="conceptos"
            columName="Descripcion"
            columValue="Descripcion"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['Descripcion']}`,
                value: value['Descripcion'],
              };
            }}
            error={
              errors.conceptos &&
              errors.conceptos[index] &&
              errors.conceptos[index].SeleccionConcepto &&
              // touched.conceptos &&
              // touched.conceptos[index] &&
              // touched.conceptos[index].SeleccionConcepto && 
              {
                content:
                  errors.conceptos[index] &&
                  errors.conceptos[index].SeleccionConcepto,
              }
            }
          />
        )}

        <Form.Input
          placeholder="Descripción"
          label="Descripción *"
          name={`conceptos.${index}.Descripcion`}
          value={concepto.Descripcion}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.conceptos &&
            errors.conceptos[index] &&
            errors.conceptos[index].Descripcion &&
            touched.conceptos &&
            touched.conceptos[index] &&
            touched.conceptos[index].Descripcion && {
              content:
                errors.conceptos[index] && errors.conceptos[index].Descripcion,
            }
          }
        />
        <DropdownSearchFetch
          fluid
          selection
          label="Código Producto/Servicio *"
          value={concepto.ClaveProdServ}
          onChange={async (e, { value }) => {
            setFieldValue(`conceptos.${index}.ClaveProdServ`, value);
            if (concepto.TipoConcepto == 2) {
              hazmatCode = await getHazmatCode(value);
              if (hazmatCode == '0,1') concepto.CveMaterialPeligroso = '0';
              if (hazmatCode == '1' && !isMaterialPeligroso) {
                setIsMaterialPeligroso(true);
                triggerEvent(ref_input.current, 'change');
              }
            }
          }}
          placeholder="Código Producto/Servicio"
          featherService={
            concepto.TipoConcepto === 2
              ? 'catalogo-clave-prod-serv-cp'
              : 'catalogo-clave-prod-serv'
          }
          columName="Descripcion"
          columValue={
            concepto.TipoConcepto === 2
              ? 'c_ClaveProdServCP'
              : 'c_ClaveProdServ'
          }
          queryInit={(columName, columValue, value) => {
            let newQuery;
            if (value) {
              newQuery = {
                [columValue]: { $like: value },
                $limit: 10,
              };
            } else {
              if (
                concepto.TipoConcepto === 0 &&
                values.idTipoComprobante === 'I' &&
                cartaPorte
              ) {
                // newQuery = {
                //   [columValue]: {
                //     $in: [
                //       78101500, 78101501, 78101502, 78101503, 78101600,
                //       78101601, 78101602, 78101603, 78101604, 78101700,
                //       78101701, 78101702, 78101703, 78101704, 78101705,
                //       78101706, 78101800, 78101801, 78101802, 78101803,
                //       78101804, 78101806, 78101807, 78101900, 78101901,
                //       78101902, 78101903, 78101904, 78101905, 78102200,
                //       78102201, 78102203, 78102204, 78102205, 78121603,
                //       78141500, 78141501, 78141900, 80131500, 81112001,
                //       84121806, 92121800, 92121801, 92121802,
                //     ],
                //   },
                // };
                newQuery = {
                  $limit: 10,
                };
              } else {
                newQuery = {
                  $limit: 10,
                };
              }
            }

            return newQuery;
          }}
          queryWithSearch={(columName, columValue, searchQuery) => {
            let newQuery;
            if (
              concepto.TipoConcepto === 0 &&
              values.idTipoComprobante === 'I' &&
              cartaPorte
            ) {
              // newQuery = {
              //   [columValue]: {
              //     $in: [
              //       78101500, 78101501, 78101502, 78101503, 78101600, 78101601,
              //       78101602, 78101603, 78101604, 78101700, 78101701, 78101702,
              //       78101703, 78101704, 78101705, 78101706, 78101800, 78101801,
              //       78101802, 78101803, 78101804, 78101806, 78101807, 78101900,
              //       78101901, 78101902, 78101903, 78101904, 78101905, 78102200,
              //       78102201, 78102203, 78102204, 78102205, 78121603, 78141500,
              //       78141501, 78141900, 80131500, 81112001, 84121806, 92121800,
              //       92121801, 92121802,
              //     ],
              //   },
              // };
              newQuery = {
                $or: [
                  { [columName]: { $like: '%' + searchQuery + '%' } },
                  { [columValue]: { $like: '%' + searchQuery + '%' } },
                ],
                $limit: 10,
              };
            } else {
              newQuery = {
                $or: [
                  { [columName]: { $like: '%' + searchQuery + '%' } },
                  { [columValue]: { $like: '%' + searchQuery + '%' } },
                ],
                $limit: 10,
              };
            }

            return newQuery;
          }}
          mapValues={(value) => {
            return {
              key: Math.random(),
              text: `${
                value[
                  concepto.TipoConcepto === 2
                    ? 'c_ClaveProdServCP'
                    : 'c_ClaveProdServ'
                ]
              } - ${value['Descripcion']}`,
              value:
                value[
                  concepto.TipoConcepto === 2
                    ? 'c_ClaveProdServCP'
                    : 'c_ClaveProdServ'
                ],
            };
          }}
          error={
            errors.conceptos &&
            errors.conceptos[index] &&
            errors.conceptos[index].ClaveProdServ &&
            // touched.conceptos &&
            // touched.conceptos[index] &&
            // touched.conceptos[index].ClaveProdServ && 
            {
              content:
                errors.conceptos[index] &&
                errors.conceptos[index].ClaveProdServ,
            }
          }
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <DropdownSearchFetch
          fluid
          selection
          label="Unidad de Medida *"
          value={concepto.ClaveUnidad}
          onChange={(e, data) => {
            const { value } = data;
            const UnidadMedida = data.options.find((o) => o.value === value);

            setFieldValue(`conceptos.${index}.ClaveUnidad`, value);
            setFieldValue(
              `conceptos.${index}.UnidadMedida`,
              UnidadMedida?.text || ''
            );
          }}
          placeholder="Unidad de Medida"
          featherService="catalogo-clave-unidad"
          columName="Nombre"
          columValue="c_ClaveUnidad"
          mapValues={(value) => {
            return {
              key: Math.random(),
              text: `${value['c_ClaveUnidad']} - ${value['Nombre']}`,
              value: value['c_ClaveUnidad'],
            };
          }}
          error={
            errors.conceptos?.[index]?.UnidadMedida &&
            // touched.conceptos?.[index]?.UnidadMedida && 
            {
              content: errors.conceptos[index].UnidadMedida,
            }
          }
        />
        <Form.Input
          type="number"
          min={1}
          placeholder="Cantidad"
          label="Cantidad *"
          name={`conceptos.${index}.Cantidad`}
          value={concepto.Cantidad}
          onChange={handleChange}
          onBlur={handleBlur}
          // disabled={
          //   values.idTipoComprobante === 'T' ||
          //   concepto.TipoConcepto !== 0
          //}
          error={
            errors.conceptos &&
            errors.conceptos[index] &&
            errors.conceptos[index].Cantidad &&
            touched.conceptos &&
            touched.conceptos[index] &&
            touched.conceptos[index].Cantidad && 
            {
              content:
                errors.conceptos[index] && errors.conceptos[index].Cantidad,
            }
          }
        />
        <Form.Input
          type="number"
          min={0}
          placeholder="Precio Unitario"
          label="Precio Unitario *"
          name={`conceptos.${index}.PrecioUnitario`}
          value={
            concepto.TipoConcepto !== 0
              ? (concepto.PrecioUnitario = 0.0)
              : concepto.PrecioUnitario
          }
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={
            values.idTipoComprobante === 'T' || concepto.TipoConcepto !== 0
          }
          error={
            errors.conceptos &&
            errors.conceptos[index] &&
            errors.conceptos[index].PrecioUnitario &&
            touched.conceptos &&
            touched.conceptos[index] &&
            touched.conceptos[index].PrecioUnitario && {
              content:
                errors.conceptos[index] &&
                errors.conceptos[index].PrecioUnitario,
            }
          }
        />
        <Form.Input
          placeholder="Número de Identificación"
          label="Número de Identificación"
          name={`conceptos.${index}.NumeroIdentificacionComercio`}
          value={concepto.NumeroIdentificacionComercio}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={
            values.idTipoComprobante === 'T' || concepto.TipoConcepto !== 0
          }
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        {values.idTipoComprobante === 'I' && concepto.TipoConcepto === 0 && (
          <Dropdown
            // isDisabled={
            //   values.idTipoComprobante === 'T' ? true : false
            // }
            fluid
            selection
            label="Traslados de IVA *"
            value={perfilImpuestos}
            onChange={(e, { value }) => {
              setPerfilImpuestos(value);
            }}
            placeholder="Traslados de IVA"
            options={[
              {
                key: 0,
                value: 0,
                text: 'Traslado IVA Tasa 16%',
              },
              {
                key: 1,
                value: 1,
                text: 'Traslado IVA Tasa 8%',
              },
              {
                key: 2,
                value: 2,
                text: 'Traslado IVA Tasa 0%',
              },
              { key: 3, value: 3, text: 'Exento de IVA' },
              { key: 4, value: 4, text: 'No es Objeto de Impuesto' },
            ]}
            onBlur={handleBlur}
            error={
              errors.conceptos &&
              errors.conceptos[index] &&
              errors.conceptos[index].PerfilImpuestos &&
              touched.conceptos &&
              touched.conceptos[index] &&
              touched.conceptos[index].PerfilImpuestos && {
                content:
                  errors.conceptos[index] &&
                  errors.conceptos[index].PerfilImpuestos,
              }
            }
          />
        )}
        <Form.Field>
          <label>Importe Base</label>
          <Label>
            {concepto.TipoConcepto === 0
              ? (concepto.ImporteBase = concepto.Subtotal)
              : (concepto.ImporteBase = 0)}
          </Label>
        </Form.Field>
        <Form.Field>
          <label>Valor %</label>
          <Label>
            {perfilImpuestos === 0
              ? (concepto.Valor = 16)
              : perfilImpuestos === 1
              ? (concepto.Valor = 8)
              : perfilImpuestos === 2
              ? (concepto.Valor = 0)
              : perfilImpuestos === 3
              ? (concepto.Valor = 0)
              : perfilImpuestos === 4 && (concepto.Valor = 0)}
          </Label>
        </Form.Field>
        <Form.Field>
          <label>Impuesto</label>
          <Label>
            {concepto.TipoConcepto === 0
              ? (concepto.Importe = parseFloat(
                  ((concepto.Subtotal * concepto.Valor) / 100).toFixed(2)
                ))
              : (concepto.Importe = 0)}
          </Label>
        </Form.Field>
        <Form.Field>
          <label>Código SAT</label>
          <Label>{concepto.CodigoSAT ? concepto.CodigoSAT : '002'}</Label>
        </Form.Field>
      </Form.Group>

      {values.idTipoComprobante === 'I' && concepto.TipoConcepto === 0 && (
        <>
          <Form.Group widths={'equal'}>
            <Dropdown
              // isDisabled={
              //   values.idTipoComprobante === 'T' ? true : false
              // }
              fluid
              selection
              label="Retención de IVA *"
              value={retencionIVA}
              onChange={(e, { value }) => {
                setRetencionIVA(value);
                setFieldValue(`conceptos.${index}.RetencionIVA`, value);
              }}
              placeholder="Retención de IVA"
              options={[
                {
                  key: 0,
                  value: 0,
                  text: 'Sin Retención IVA',
                },
                {
                  key: 1,
                  value: 1,
                  text: 'Retención 4% IVA',
                },
                {
                  key: 2,
                  value: 2,
                  text: 'Retención 5.3333% IVA',
                },
                { key: 3, value: 3, text: 'Retención 10.6667% IVA' },
              ]}
              onBlur={handleBlur}
              error={
                errors.conceptos &&
                errors.conceptos[index] &&
                errors.conceptos[index].RetencionIVA &&
                touched.conceptos &&
                touched.conceptos[index] &&
                touched.conceptos[index].RetencionIVA && {
                  content:
                    errors.conceptos[index] &&
                    errors.conceptos[index].RetencionIVA,
                }
              }
            />

            <Form.Field>
              <label>Importe Base</label>
              <Label>
                {concepto.TipoConcepto === 0
                  ? (concepto.ImporteBase = concepto.Subtotal)
                  : (concepto.ImporteBase = 0)}
              </Label>
            </Form.Field>
            <Form.Field>
              <label>Valor %</label>
              <Label>
                {retencionIVA === 0
                  ? (concepto.ImpuestoRetencionValor = 0)
                  : retencionIVA === 1
                  ? (concepto.ImpuestoRetencionValor = 4.0)
                  : retencionIVA === 2
                  ? (concepto.ImpuestoRetencionValor = 5.3333)
                  : retencionIVA === 3 &&
                    (concepto.ImpuestoRetencionValor = 10.6667)}
              </Label>
            </Form.Field>
            <Form.Field>
              <label>Impuesto</label>
              <Label>
                {concepto.TipoConcepto === 0
                  ? (concepto.ImpuestosRetenciones = parseFloat(
                      (concepto.Subtotal * concepto.ImpuestoRetencionValor) /
                        100
                    ).toFixed(2))
                  : concepto.ImpuestosRetenciones}
              </Label>
            </Form.Field>
            <Form.Field>
              <label>Código SAT</label>
              <Label>{concepto.CodigoSAT ? concepto.CodigoSAT : '002'}</Label>
            </Form.Field>
          </Form.Group>
          <Form.Group style={{ marginTop: 48 }} widths={'ten'}>
            <Form.Field>
              <label>Subtotal:</label>
              <Label>
                {concepto.TipoConcepto === 0 &&
                concepto.Cantidad &&
                concepto.PrecioUnitario
                  ? (concepto.Subtotal = parseFloat(
                      (concepto.Cantidad * concepto.PrecioUnitario).toFixed(2)
                    ))
                  : (concepto.Subtotal = 0.0)}
              </Label>
            </Form.Field>
            <Form.Field>
              <label>Total:</label>
              <Label>
                {concepto.TipoConcepto === 0
                  ? (concepto.Total = parseFloat(
                      (
                        concepto.Subtotal +
                        concepto.Importe -
                        concepto.ImpuestosRetenciones
                      ).toFixed(2)
                    ))
                  : (concepto.Total = 0.0)}
              </Label>
            </Form.Field>
          </Form.Group>
        </>
      )}

      {concepto.TipoConcepto !== 0 && isCartaPorte && (
        <>
          <Divider horizontal>Carta Porte</Divider>
          <Form.Group widths={'equal'}>
            <DropdownSearchFetch
              fluid
              selection
              label="Fracción Arancelaria *"
              value={
                concepto.FraccionArancelariaComercio
                  ? concepto.FraccionArancelariaComercio
                  : ''
              }
              onChange={(e, { value }) =>
                setFieldValue(
                  `conceptos.${index}.FraccionArancelariaComercio`,
                  value
                )
              }
              placeholder="Fracción Arancelaria"
              featherService="catalogo-fraccion-arancelaria"
              columName="Descripcion"
              columValue="c_FraccionArancelaria"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_FraccionArancelaria']} - ${value['Descripcion']}`,
                  value: value['c_FraccionArancelaria'],
                };
              }}
              error={
                errors.conceptos &&
                errors.conceptos[index] &&
                errors.conceptos[index].FraccionArancelariaComercio &&
                // touched.conceptos &&
                // touched.conceptos[index] &&
                // touched.conceptos[index].FraccionArancelariaComercio && 
                {
                  content:
                    errors.conceptos[index] &&
                    errors.conceptos[index].FraccionArancelariaComercio,
                }
              }
            />
            <Form.Input
              type="number"
              min={0}
              placeholder="Peso en Kgs"
              label="Peso en Kgs *"
              name={`conceptos.${index}.PesoEnKg`}
              value={concepto.PesoEnKg}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.conceptos &&
                errors.conceptos[index] &&
                errors.conceptos[index].PesoEnKg &&
                touched.conceptos &&
                touched.conceptos[index] &&
                touched.conceptos[index].PesoEnKg && {
                  content:
                    errors.conceptos[index] && errors.conceptos[index].PesoEnKg,
                }
              }
            />
            <Form.Input
              type="number"
              min={0}
              placeholder="Valor Mercancía"
              label="Valor Mercancía *"
              name={`conceptos.${index}.ValorMercancia`}
              value={concepto.ValorMercancia}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.conceptos &&
                errors.conceptos[index] &&
                errors.conceptos[index].ValorMercancia &&
                touched.conceptos &&
                touched.conceptos[index] &&
                touched.conceptos[index].ValorMercancia && {
                  content:
                    errors.conceptos[index] &&
                    errors.conceptos[index].ValorMercancia,
                }
              }
            />
            <DropdownSearchFetch
              fluid
              selection
              label="Moneda *"
              value={concepto.MonedaMercancia}
              onChange={(e, { value }) =>
                setFieldValue(`conceptos.${index}.MonedaMercancia`, value)
              }
              placeholder="Moneda"
              find
              featherService="catalogo-moneda"
              columName="Descripcion"
              columValue="c_Moneda"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_Moneda']} - ${value['Descripcion']}`,
                  value: value['c_Moneda'],
                };
              }}
              error={
                errors.conceptos &&
                errors.conceptos[index] &&
                errors.conceptos[index].MonedaMercancia &&
                // touched.conceptos &&
                // touched.conceptos[index] &&
                // touched.conceptos[index].MonedaMercancia && 
                {
                  content:
                    errors.conceptos[index] &&
                    errors.conceptos[index].MonedaMercancia,
                }
              }
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <DropdownSearchFetch
              fluid
              selection
              label="Tipo Materia"
              value={concepto.TipoMateria}
              onChange={(e, { value }) => {
                setFieldValue(`conceptos.${index}.TipoMateria`, value);
                getDescriptionTipoMateria(value).then((res) => {
                  if (value !== '05') {
                    setFieldValue(`conceptos.${index}.DescripcionMateria`, '');
                  } else
                    setFieldValue(
                      `conceptos.${index}.DescripcionMateria`,
                      res.toUpperCase()
                    );
                });
              }}
              placeholder="Tipo Materia"
              find
              featherService="catalogo-tipo-materia"
              columName="Descripcion"
              columValue="c_ClaveTipoMateria"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_ClaveTipoMateria']} - ${value['Descripcion']}`,
                  value: value['c_ClaveTipoMateria'],
                };
              }}
              error={
                errors.conceptos &&
                errors.conceptos[index] &&
                errors.conceptos[index].TipoMateria &&
                // touched.conceptos &&
                // touched.conceptos[index] &&
                // touched.conceptos[index].TipoMateria && 
                {
                  content:
                    errors.conceptos[index] &&
                    errors.conceptos[index].TipoMateria,
                }
              }
            />
            <Form.Input
              placeholder="Descripcion de Materia"
              label="Descripción Materia"
              name={`conceptos.${index}.DescripcionMateria`}
              value={concepto.DescripcionMateria}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <DropdownSearchFetch
              fluid
              selection
              label="Doc. Aduanero"
              value={concepto.TipoDocumentoAduanero}
              onChange={(e, { value }) => {
                setFieldValue(
                  `conceptos.${index}.TipoDocumentoAduanero`,
                  value
                );
                console.log('value', value);
              }}
              placeholder="Tipo Materia"
              find
              featherService="catalogo-documento-aduanero"
              columName="Descripcion"
              columValue="c_ClaveDocumentoAduanero"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_ClaveDocumentoAduanero']} - ${value['Descripcion']}`,
                  value: value['c_ClaveDocumentoAduanero'],
                };
              }}
              error={
                errors.conceptos &&
                errors.conceptos[index] &&
                errors.conceptos[index].TipoDocumentoAduanero &&
                // touched.conceptos &&
                // touched.conceptos[index] &&
                // touched.conceptos[index].TipoDocumentoAduanero && 
                {
                  content:
                    errors.conceptos[index] &&
                    errors.conceptos[index].TipoDocumentoAduanero,
                }
              }
            />
            <Form.Input
              placeholder="Número de Identificación Aduanero"
              label="Ident. Doc. Aduanero"
              name={`conceptos.${index}.IdentificacionDocumentoAduanero`}
              value={concepto.IdentificacionDocumentoAduanero}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <Form.Input
              placeholder="UUID CFDI Comercio Exterior"
              label="UUID CFDI Comercio Exterior"
              name={`conceptos.${index}.UUIDComercioExterior`}
              value={concepto.UUIDComercioExterior}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Input
              placeholder="RFC del Importador"
              label="RFC Importador"
              name={`conceptos.${index}.RFCImportador`}
              value={concepto.RFCImportador}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Input
              placeholder="Pedimentos"
              label="Pedimentos"
              name={`conceptos.${index}.Pedimento`}
              value={concepto.Pedimento}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>
          {true && (
            <>
              <Form.Field>
                <label>Material Peligroso</label>
                <Checkbox
                  ref={ref_input}
                  toggle
                  onChange={(e, { checked }) => {
                    if (!checked) concepto.CveMaterialPeligroso = '0';
                    setIsMaterialPeligroso(checked);
                    if (hazmatCode == '1' && !isMaterialPeligroso) {
                      setIsMaterialPeligroso(true);
                      triggerEvent(ref_input.current, 'change');
                    }
                  }}
                />
              </Form.Field>
              {isMaterialPeligroso && (
                <Form.Group widths={'equal'}>
                  <DropdownSearchFetch
                    fluid
                    selection
                    label="Material Peligroso"
                    value={concepto.CveMaterialPeligroso}
                    onChange={(e, { value }) =>
                      setFieldValue(
                        `conceptos.${index}.CveMaterialPeligroso`,
                        value
                      )
                    }
                    placeholder="Material Peligroso"
                    featherService="catalogo-material-peligroso"
                    columName="Descripcion"
                    columValue="c_MaterialPeligroso"
                    mapValues={(value) => {
                      return {
                        key: Math.random(),
                        text: `${value['c_MaterialPeligroso']} - ${value['Descripcion']}`,
                        value: value['c_MaterialPeligroso'],
                      };
                    }}
                    error={
                      hazmatCode == '1' && {
                        content: 'Required',
                      }
                    }
                  />
                  <DropdownSearchFetch
                    fluid
                    selection
                    label="Embalaje"
                    value={concepto.Embalaje}
                    onChange={(e, { value }) =>
                      setFieldValue(`conceptos.${index}.Embalaje`, value)
                    }
                    placeholder="Embalaje"
                    disabled={concepto.CveMaterialPeligroso === '' && true}
                    featherService="catalogo-tipo-embalaje"
                    columName="Descripcion"
                    columValue="c_TipoEmbalaje"
                    mapValues={(value) => {
                      return {
                        key: Math.random(),
                        text: `${value['c_TipoEmbalaje']} - ${value['Descripcion']}`,
                        value: value['c_TipoEmbalaje'],
                      };
                    }}
                    error={
                      hazmatCode == '1' && {
                        content: 'Required',
                      }
                    }
                  />
                  <Form.Input
                    placeholder="Descripción Embalaje"
                    disabled={concepto.CveMaterialPeligroso === '' && true}
                    label="Descripción Embalaje"
                    name={`conceptos.${index}.EmbalajeDescripcion`}
                    value={concepto.EmbalajeDescripcion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default forwardRef((props, ref) => (
  <FormConcept {...props} innerRef={ref} />
));
