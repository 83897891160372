import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import swal from 'sweetalert';
import { Formik } from 'formik';
import Actions from './Actions';
import FormCertificado from './FormCertificado';
import feathers from '../../../services/feathers';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import * as Yup from 'yup';

const Drawer = ({ openPanel, onClose, currentCertificado }) => {
	const service = feathers.service('tax-certificados');
	const [ currentSection, setCurrentSection ] = useState(
		(currentCertificado && currentCertificado.idCertificado && 'EDIT') || 'CREATE'
	);
	useEffect(() => {});

	const getLastCertificate = async () => {
		let lastCertificate = await service.find({
			query: {
				$sort: { idCertificado: -1 },
				$limit: 1
			}
		});

		if (lastCertificate.data.length > 0) {
			let lastID = lastCertificate.data[0].idCertificado;
			lastID++;
			return lastID;
		} else {
			console.log('No se encontraron registros.');
		}
	};

	const handleSave = async (values, { setSubmitting }) => {
		if (values) {
			values.CertificadoByte = null;
			values.LlaveByte = null;
			let success = true;
			try {
				if (values.idCertificado) {
					let result = await service.patch(values.idCertificado, values);
					swal('Actualizado', '¡Se ha actualizado correctamente!', 'success');
				} else {
					let lastId = await getLastCertificate();
					values.idCertificado = lastId;

					let resultCreate = await service.create(values);
					console.log('resultCreate', resultCreate);
					swal('Guardado', '¡Se ha guardado correctamente!', 'success');
				}
			} catch (exception) {
				console.log(exception);
				swal('Error', 'Dont Save Certificado', 'error');
			}
			if (success) {
				setCurrentSection('CREATE');
				onClose(true);
			}
		} else swal('Error', 'Dont Save Certificado', 'error');
	};

	const handledOnClose = async () => {
		let confirmation = await swal('Do you want close this form?', {
			buttons: [ 'No', 'Yes' ]
		});

		if (confirmation) {
			setCurrentSection('CREATE');
			onClose(false);
		}
	};

	const validationSchema = Yup.object().shape({
		Descripcion: Yup.string().max(100).required('Required'),
		NoSerie: Yup.string().max(200).required('Required'),
		//Ruta: Yup.string().max(500).required('Required'),
		VigenciaInicio: Yup.date().required('Required'),
		VigenciaFin: Yup.date().required('Required'),
		//RutaLlave: Yup.string().max(500).required('Required'),
		Contrasena: Yup.string().max(50).required('Required'),
		RFC: Yup.string().max(20).required('Required'),
		Status: Yup.number().required('Required'),
		CertificadoByte: Yup.string().max(4000).nullable(true),
		LlaveByte: Yup.string().max(4000).nullable(true)
	});

	let drawerTitles = {
		CREATE: {
			title: 'Nuevo Certificado',
			subtitle: ''
		},
		EDIT: {
			title: 'Editar Certificado',
			subtitle: ''
		}
	};

	let title = drawerTitles[currentSection].title;
	let subtitle = drawerTitles[currentSection].subtitle;

	return (
		<SlidingPanel isOpen={openPanel} size={100} title={title} subtitle={subtitle} onClose={handledOnClose}>
			<Formik initialValues={currentCertificado} onSubmit={handleSave} validationSchema={validationSchema}>
				{(formikProps) => (
					<Form onSubmit={formikProps.handleSubmit} style={{ marginRight: '15px' }}>
						<FormCertificado
							onSave={handleSave}
							// ref={refForm}
							// isValid={isValid}
							// setIsValid={setIsValid}
							formik={formikProps}
						/>

						<Actions formik={formikProps} handledOnClose={handledOnClose} />
					</Form>
				)}
			</Formik>
		</SlidingPanel>
	);
};

export default Drawer;
