import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';

const ButtonsFilterCartaPorte = ({
  isBusy,
  isSearchBusy,
  onSearchClick,
  onGenerateReport,
  style,
}) => {
  return (
    <WrapperButtons style={style}>
      <Button
        primary
        compact
        disabled={typeof isSearchBusy === 'boolean' ? isSearchBusy : isBusy}
        onClick={onSearchClick}
        style={{ width: '150px', height: '40px', marginLeft: '8px' }}
      >
        Generar Registro
      </Button>

      {/* <Button
        primary
        compact
        disabled={typeof isSearchBusy === 'boolean' ? isSearchBusy : isBusy}
        onClick={onGenerateReport}
      >
        <Icon name="search" /> Generar Reporte
      </Button> */}
    </WrapperButtons>
  );
};

const WrapperButtons = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

ButtonsFilterCartaPorte.propTypes = {
  isBusy: PropTypes.bool,
  isSearchBusy: PropTypes.bool,
  onSearchClick: PropTypes.func,
  onGenerateReport: PropTypes.func,
  style: PropTypes.object,
};

export default ButtonsFilterCartaPorte;
