import React, { forwardRef } from 'react';
import { Form, Segment, Header, Grid, Divider } from 'semantic-ui-react';
import DropdownSimpleFetch from '../../../components/Formik/DropdownSimpleFetch';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import DropdownPos from '../../../components/Formik/DropdownPos';
import feathers from '../../../services/feathers';

const FormEmisor = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    validateForm,
  } = formik;

  const posFoliosService = feathers.service('pos-folios');

  const getPOSFolios = async (idPOS, tipoComprobante) => {
    let response = await posFoliosService.find({
      query: {
        idPOS: idPOS,
        idTipoComprobante: tipoComprobante,
      },
    });

    let data =
      (response && response.data && response.data.length && response.data[0]) ||
      null;
    return data;
  };

  return (
    <>
      <Segment>
        <Header as="h3">Emisor</Header>
        <Form.Group widths={'equal'}>
          <DropdownPos
            fluid
            selection
            label="Nombre *"
            value={values.idPOS}
            onChange={async (e, { value }, posData) => {
              setFieldValue('idPOS', value);
              setFieldValue('idSucursal', posData[0].idSucursal);
              setFieldValue('EmisorNombre', posData[0].RazonSocial);
              setFieldValue('EmisorRFC', posData[0].TaxID);
              setFieldValue('idRegimen', posData[0].RegimenFiscal);
              setFieldValue('LugarExpedicion', posData[0].CodigoPostal);
              setFieldValue('ReceptorNombre', posData[0].NombreSucursal);
              setFieldValue('ReceptorRFC', posData[0].TaxID);
              setFieldValue('ReceptorRegimenLey', posData[0].RegimenFiscal);
              setFieldValue('ReceptorCodPos', posData[0].CodigoPostal);
              setFieldValue('idPAC', posData[0].idPAC);
              setFieldValue('idCertificado', posData[0].idCertificado);
              await Promise.resolve();
              validateForm();
            }}
            placeholder="Nombre Emisor"
            featherService="pos"
            columName="RazonSocial"
            columValue="idPOS"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['RazonSocial']}`,
                value: value['idPOS'],
              };
            }}
            error={errors.EmisorNombre && { content: errors.EmisorNombre }}
          />

          <Form.Input
            placeholder="RFC"
            label="RFC *"
            name="EmisorRFC"
            value={values.EmisorRFC}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.EmisorRFC &&
              touched.EmisorRFC && { content: errors.EmisorRFC }
            }
          />
          <DropdownSearchFetch
            fluid
            selection
            label="Regimen *"
            value={values.idRegimen}
            onChange={(e, { value }) => setFieldValue('idRegimen', value)}
            placeholder="Regimen"
            featherService="catalogo-regimen-fiscal"
            columName="Descripcion"
            columValue="c_RegimenFiscal"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_RegimenFiscal']} - ${value['Descripcion']}`,
                value: value['c_RegimenFiscal'],
              };
            }}
            error={errors.idRegimen && { content: errors.idRegimen }}
          />

          <Form.Input
            placeholder="Lugar de expedición"
            label="Lugar de expedición *"
            name="LugarExpedicion"
            value={values.LugarExpedicion}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.LugarExpedicion &&
              touched.LugarExpedicion && {
                content: errors.LugarExpedicion,
              }
            }
          />
        </Form.Group>
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormEmisor {...props} innerRef={ref} />
));
