import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DrawerFiltersWrapper from '../../components/DrawerFiltersWrapper';
import SemanticInput from '../../components/SemanticInput';
import ButtonsFilterCartaPorte from '../../components/ButtonsFilterCartaPorte';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Form as SemanticForm } from 'semantic-ui-react';
import feathers, { useAuth } from '../../services/feathers';

const queryInit = {
  idTipoComprobante: '',
  fechaIni: '',
  fechaFin: '',
};

const reducerQuery = (prevState, { name, value, type, query }) => {
  if (type === 'init' && query) {
    return query;
  } else if (name) {
    return { ...prevState, [name]: value };
  }

  return prevState;
};

const Filters = ({ isBusy, query: base, setQuery }) => {
  const auth = useAuth();
  const [query, dispatchQuery] = useReducer(reducerQuery, { ...queryInit });
  const [startDate, setStartDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(() => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    return today;
  });
  const reporteTimbradoService = feathers.service('reportes-timbrado');
  const serviceSucursales = feathers.service('sucursales');

  // Effects
  useEffect(() => {
    const sendQuery = () => {
      handleSearchClick();
    };

    sendQuery();
  }, []);

  // Handles
  const handleChangeInput = (_, { name, value }) =>
    dispatchQuery({ name, value });

  const handleSearchClick = (event, data) => {
    // Create query for feathers
    const queryMap = {
      ...(query.idTipoComprobante && {
        idTipoComprobante: query.idTipoComprobante,
      }),
    };

    // Send query to Partners
    setQuery && setQuery(queryMap);
  };

  const handleGenerateReport = async () => {
    let sucursal;
    if (auth.user) {
      sucursal = await serviceSucursales.find({
        query: {
          Organizacion: auth.user.client._id,
        },
      });
    }
    let jsonData = await reporteTimbradoService.find({
      query: {
        idSucursal: sucursal.data ? sucursal.data[0].idSucursal : 4,
        idTipoComprobante: query.idTipoComprobante
          ? query.idTipoComprobante
          : 'I',
        fechaIni: moment(startDate).format('YYYYMMDD 00:00:00'),
        fechaFin: moment(finalDate).format('YYYYMMDD 23:59:59'),
      },
    });

    if (jsonData && jsonData[0] && jsonData[0].length > 0) {
      // Convertir el JSON a una cadena CSV con formato HTML compatible con Excel
      const csvContent =
        'data:text/html;charset=utf-8,' +
        '<html><head><style>table { border-collapse: collapse; } th, td { border: 0.2px solid black; }</style></head><body>' +
        '<table>' +
        '<tr>' +
        Object.keys(jsonData[0][0])
          .map(
            (key) =>
              `<td style="background-color:green; font-weight:bold; color: white">${key}</td>`
          )
          .join('') +
        '</tr>' +
        jsonData[0]
          .map(
            (obj) =>
              '<tr>' +
              Object.values(obj)
                .map(
                  (value) =>
                    `<td style="background-color:${
                      value === 'green' ? 'green' : 'white'
                    }; color: ${
                      value === 'green' ? 'white' : 'black'
                    }">${value}</td>`
                )
                .join('') +
              '</tr>'
          )
          .join('') +
        '</table>' +
        '</body></html>';
      // Crear un elemento <a> para descargar el archivo
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'reporte-folios.xls'); // Cambiado a .xls
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleClearClick = (event, data) => {
    dispatchQuery({
      type: 'init',
      query: {
        ...queryInit,
      },
    });
  };

  return (
    <>
      <DrawerFiltersWrapper>
        <FiltersGrid>
          <SemanticForm>
            <SemanticForm.Group widths={'equal'}>
              <SemanticInput
                name="idTipoComprobante"
                label="Tipo de Comprobante"
                value={query.idTipoComprobante}
                onChange={handleChangeInput}
                fluid
                style={{ gridArea: 'search', width: '200px' }}
                disabled={isBusy}
              />
              <div className="field">
                <label>
                  <b>Fecha Reporte Inicial</b>
                </label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    const newDate = moment(date).set({
                      hour: new Date().getHours(),
                      minute: new Date().getMinutes(),
                    });
                    setStartDate(newDate.toDate());
                  }}
                  timeInputLabel="Hora:"
                  dateFormat="MM/dd/yyyy"
                  showTimeInput
                  name="fechaIni"
                  style={{ width: '400px' }}
                  value={moment(startDate).format('YYYY-MM-DD')}
                  onClick={() => {}}
                />
              </div>
              <div className="field">
                <label>
                  <b>Fecha Reporte Final</b>
                </label>
                <DatePicker
                  selected={finalDate}
                  onChange={(date) => {
                    const newDate = moment(date).set({
                      hour: new Date().getHours(),
                      minute: new Date().getMinutes(),
                    });
                    setFinalDate(newDate.toDate());
                  }}
                  timeInputLabel="Hora:"
                  dateFormat="MM/dd/yyyy"
                  showTimeInput
                  name="fechaFin"
                  style={{ width: '400px' }}
                  value={moment(finalDate).format('YYYY-MM-DD')}
                  onClick={() => {}}
                />
              </div>

              <ButtonsFilterCartaPorte
                isBusy={isBusy}
                onSearchClick={handleGenerateReport}
                style={{ width: '420px' }}
              />
            </SemanticForm.Group>
          </SemanticForm>
        </FiltersGrid>
      </DrawerFiltersWrapper>
    </>
  );
};

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: 'search role . buttons';
`;

Filters.propTypes = {
  isBusy: PropTypes.bool,
  setQuery: PropTypes.func,
  defaultUser: PropTypes.object,
  isValid: PropTypes.bool,
};

export default Filters;
