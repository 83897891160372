import React, { useState, useReducer, useEffect } from 'react';
import {
  Segment,
  Header,
  Label,
  Table,
  Modal,
  Button,
  Icon,
  FormGroup,
  Form,
  Dropdown,
  FormField,
} from 'semantic-ui-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, Logger } from '@ag-grid-enterprise/all-modules';
import {
  valueFormatter,
  dateTimeCartaPorte,
} from '../../../components/AgGridColumns';
import TableContainer from '../../../components/TableContainer';
import SemanticInput from '../../../components/SemanticInput';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import moment from 'moment';
import swal from 'sweetalert';
import feathers, { useAuth } from '../../../services/feathers';

export const FormRelation = ({ handleRelated, formik }) => {
  const { values } = formik;

  const today = new Date();
  const options = [{ key: 1, text: '04', value: '04' }];
  const service = feathers.service('documentos');
  const auth = useAuth();

  const [open, setOpen] = useState(false);
  const [alertt, setAlertt] = useState(false);
  const [relate, setRelate] = useState(null);
  const [value, setValue] = useState('04');
  const [data, setData] = useState(null);
  const [query, setQuery] = useState(null);
  const [defaultColDefs] = useState({
    filter: false,
    sortable: true,
    enableRowGroup: true,
    resizable: true,
    width: 250,
  });
  const [startDate, setStartDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );
  const [finalDate, setFinalDate] = useState(
    new Date(today.getFullYear(), today.getMonth() + 1, 0)
  );

  const [form, setForm] = useState({
    folio: '',
    idTipoComprobante: values.idTipoComprobante,
    idStatus: 3,
    Serie: '',
    fechaInicial: startDate,
    fechaFinal: finalDate,
  });

  const [columnDefs] = useState([
    {
      headerName: 'Timbrado',
      field: 'cfd_Certificado',
      checkboxSelection: true,
      // headerCheckboxSelection: true,
      cellRendererFramework: (data) => {
        if (data.value) {
          return <Icon name="check" />;
        } else {
          return '';
        }
      },
    },
    {
      headerName: 'Id Interno',
      field: 'id',
      valueFormatter: (value) => {
        return Number(value.value);
      },
    },
    {
      headerName: 'Folio',
      field: 'Folio',
      valueFormatter,
    },
    {
      headerName: 'Folio Fiscal (UUID)',
      field: 'timbre_uuid',
      valueFormatter,
    },
    {
      headerName: 'Serie',
      field: 'Serie',
      valueFormatter,
    },
    {
      ...dateTimeCartaPorte,
      headerName: 'Fecha Documento',
      field: 'FechaDocumento',
    },
  ]);

  useEffect(() => {
    setForm({
      ...form,
      ['idTipoComprobante']: values.idTipoComprobante,
    });
  }, [values.idTipoComprobante]);

  useEffect(() => {
    const sendQuery = () => {
      handleSearchClick();
    };

    sendQuery();
  }, []);

  useEffect(() => {
    const filter = () => {
      if (query) {
        getOptions();
      }
    };

    filter();
  }, [query]);

  const getOptions = async () => {
    let data = [];
    let result = [];

    if (query) {
      try {
        do {
          if (auth.user) {
            result = await service.find({
              query: {
                Organizacion: auth.user.client._id,
                ...query,
                $sort: { id: -1 },
                $skip: data.length,
              },
            });
          }

          data = data.concat(result.data);
        } while (data.length !== result.total);
      } catch (err) {
        console.error('Errorrr', err);
      } finally {
        setData(data);
      }
    }
  };

  const handleSearchClick = (event, data) => {
    const queryMap = {
      ...(form.folio && { Folio: form.folio }),
      ...(form.idTipoComprobante && {
        idTipoComprobante: form.idTipoComprobante,
      }),
      ...(form.idStatus && {
        idStatus: form.idStatus,
      }),
      ...(form.Serie && {
        Serie: form.Serie,
      }),
      ...(startDate &&
        finalDate && {
          FechaDocumento: {
            $gte: moment(startDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
            $lte: moment(finalDate).format('YYYY-MM-DD 23:59:59.000'),
          },
        }),
    };
    setQuery(queryMap);
  };

  const handleChange = (_, { name, value }) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSelectionChanged = ({ api }) => {
    let rows;
    try {
      if (api) {
        rows = api.getSelectedRows();
      }
    } catch (error) {
      console.error('error :>> ', error);
      rows = [];
      let err = {
        title: 'Error',
        text: 'Failed to select Clients',
        icon: 'error',
        buttons: { cancel: 'Close' },
      };
      swal(err);
    } finally {
      rows.length > 0 && setRelate(rows[0]);
    }
  };

  const handleRelation = () => {
    if (!value) {
      setAlertt(true);
      return;
    }
    setAlertt(false);
    handleRelated(relate, value);
    setOpen(false);
  };

  return (
    <>
      <Segment>
        <Header as="h3"> Relacionar CFDI </Header>
        <Button
          type="button"
          content="Relacionar"
          primary
          onClick={() => {
            setOpen(true);
          }}
        />
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          size="fullscreen"
        >
          <Modal.Content>
            <Modal.Header as="h3"> Relacionar CFDI </Modal.Header>

            {/* INICIO DE LOS FILTROS */}
            <Form>
              <FormGroup width={2}>
                <SemanticInput
                  name="folio"
                  label="Folio"
                  value={form.folio}
                  onChange={handleChange}
                />
                <SemanticInput
                  name="Serie"
                  label="Serie"
                  value={form.Serie}
                  onChange={handleChange}
                />
                <div className="field equal width">
                  <label>
                    <b>Fecha Inicial</b>
                  </label>
                  <DatePicker
                    selected={startDate}
                    onChange={(data) => {
                      setStartDate(data);
                    }}
                    timeInputLabel="Hora:"
                    dateFormat="dd/MM/yyyy"
                    name="fechaInicial"
                    value={startDate}
                  />
                </div>
                <div className="field equal width">
                  <label>
                    <b>Fecha Final</b>
                  </label>
                  <DatePicker
                    selected={finalDate}
                    onChange={(data) => {
                      setFinalDate(data);
                    }}
                    timeInputLabel="Hora:"
                    dateFormat="dd/MM/yyyy"
                    name="fechaInicial"
                    value={finalDate}
                  />
                </div>
                <ButtonsContainer>
                  <Button
                    type="button"
                    primary
                    compac
                    onClick={handleSearchClick}
                  >
                    {' '}
                    <Icon name="search" /> Filter{' '}
                  </Button>
                  <Button type="button" compac>
                    {' '}
                    <Icon name="erase" /> Clear{' '}
                  </Button>
                </ButtonsContainer>
              </FormGroup>
            </Form>

            {/* TABLA DE LOS CFDIS */}
            <TableContainer className="ag-theme-material" rowFilters={1}>
              <AgGridReact
                modules={AllModules}
                columnDefs={columnDefs}
                defaultColDef={defaultColDefs}
                rowSelection={'single'}
                suppressRowClickSelection={true}
                onSelectionChanged={handleSelectionChanged}
                rowData={data}
                pagination={true}
                paginationPageSize={100}
                // onGridReady={handleGridReady}
                sortingOrder={['desc', 'asc', null]}
              />
            </TableContainer>
          </Modal.Content>

          {/* Botones para cerrar modal o guardar relacion */}
          <Modal.Actions>
            <Dropdown
              clearable
              selection
              search={true}
              options={options}
              value={value}
              onChange={(e, { value }) => {
                setValue(value);
              }}
              placeholder="Tipo de relacion"
              style={{ width: '200px' }}
            />
            {alertt && (
              <Label basic color="red" pointing>
                {' '}
                Seleccione un valor{' '}
              </Label>
            )}
            <Button
              type="button"
              content="Cancelar"
              color="red"
              labelPosition="right"
              icon="cancel"
              onClick={() => setOpen(false)}
            />
            <Button
              content="Relacionar"
              labelPosition="right"
              icon="checkmark"
              onClick={handleRelation}
              positive
            />
          </Modal.Actions>
        </Modal>
      </Segment>
    </>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-end;
`;
