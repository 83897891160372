import React from 'react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';

function DropdownPais({ label, val, setValues, child, error, formik }) {
  let { setFieldValue, handleBlur} = formik;
  // const handleValidate = ()=> {
  //   validateForm().then((errors) => {
  //     setTouched({ ...formik.touched, ...errors });
  //     console.log(errors)

  //   })
  // }
  return (
    <DropdownSearchFetch
      placeholder={label}
      fluid
      selection
      label={label}
      value={val.Pais}
      featherService="catalogo-pais"
      columName="Descripcion"
      columValue="c_Pais"
      mapValues={(value) => {
        return {
          key: Math.random(),
          text: `${value['c_Pais']} - ${value['Descripcion']}`,
          value: value['c_Pais'],
        };
      }}
      onChange={(e, { value }) => {
        switch (child) {
          case 'transportista':
            setFieldValue('transportista.pais', value);
            break;
          case 'origenMercancia':
            setFieldValue('factura.origenMercancia', value);
            break;
          case 'descargoMercancia':
            setFieldValue('factura.descargoMercancia', value);
            break;
          case 'destinoMercancia':
            setFieldValue('factura.destinoMercancia', value);
            break;

          default:
            break;
        }
        setValues({ ...val, Pais: value });
      }}
      isFormDrop={true} //bandera para mostrar dropDown con errors y quitar error de consola *Invalid prop `error` of type `object` *
      onBlur={(_, data) => {
        // se agrego para quitar warning y asignar el name del input que genera dinamicamente el dropdown
        Object.freeze(data);
        switch (child) {
          case 'transportista':
            data = { ...data, name: 'transportista.pais' };
            break;
          case 'origenMercancia':
            data = { ...data, name: 'factura.origenMercancia' };
            break;
          case 'descargoMercancia':
            data = { ...data, name: 'factura.descargoMercancia' };
            break;
          case 'destinoMercancia':
            data = { ...data, name: 'factura.destinoMercancia' };
            break;
          default:
            break;
        }
        //console.log(data)
        handleBlur({ target: { ...data } });
      }}
      //onBlur={handleBlur}
      //onBlur={handleValidate}
      error={error}
      child={child}
    />
  );
}

export default DropdownPais;
