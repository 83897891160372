import React, { forwardRef, useState } from 'react';
import { Button, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { Dropzone, FileItem } from '@dropzone-ui/react';
import convert from 'xml-js';
import swal from 'sweetalert';
import feathers from '../../../services/feathers';

function exampleReducer(state, action, setDataFiles) {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { open: true, dimmer: action.dimmer };
    case 'CLOSE_MODAL':
      return { open: false };
    default:
      throw new Error();
  }
}

const ModalForm = ({
  formik,
  initialValues,
  xmlString,
  setXmlString,
  xmlVersion,
  setXmlVersion,
}) => {
  let { values, setValues } = formik;
  let [state, dispatch] = React.useReducer(exampleReducer, {
    open: false,
    dimmer: undefined,
  });
  let { open, dimmer } = state;
  const [comprobante, setComprobante] = useState(null);
  const [emisor, setEmisor] = useState(null);
  const [receptor, setReceptor] = useState(null);
  const [conceptos, setConceptos] = useState(null);
  const [complemento, setComplemento] = useState(null);
  const [comercioExterior, setComercioExterior] = useState({});
  const [timbreFiscalDigital, setTimbreFiscalDigital] = useState({});
  const [comercioExtMercancias, setComercioExtMercancias] = useState({});
  const [comercioExtEmisor, setComercioExtEmisor] = useState({});
  const [comercioExtReceptor, setComercioExtReceptor] = useState({});
  const [comercioExtDestinatario, setComercioExtDestinatario] = useState({});
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const handleValidateXML = async () => {
    const validateService = feathers.service('validate-xml');
    setLoading(true);
    let options = { xml: xmlString, version: xmlVersion };
    let result = await validateService.get({ options });
    // console.log(result);
    if (result && result.message && result.message === 'success') {
      setLoading(false);
      let XMLResult = result.XMLResult;
      if (XMLResult.errors && XMLResult.errors.length >= 0) {
        swal(
          'CFDi v' + xmlVersion + ' con errores',
          JSON.stringify(XMLResult.errors),
          'error'
        );
      } else {
        swal('Correcto', 'CFDi v' + xmlVersion, 'success');
        dispatch({ type: 'CLOSE_MODAL' });
        printXML();
      }
    } else {
      setLoading(false);
      swal('Error', JSON.stringify(result.XMLResult), 'error');
    }
  };
  //const [dataFiles, setDataFiles] = useState();
  const xmlJson = (incommingFiles) => {
    incommingFiles.map((item) => {
      //console.log(item)
      if (item && item.errors && item.errors.length > 0) {
        swal('Error', JSON.stringify(item.errors), 'error');
        return null;
      } else return item;
    });
    try {
      if (incommingFiles && incommingFiles.length > 0) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const getXml = event.target.result;
          //console.log(getXml)
          setXmlString(getXml);
          var options = { ignoreComment: true, alwaysChildren: true };
          var result = convert.xml2js(getXml, options);
          // console.log(result);
          setXMLData(result);
        };
        reader.readAsText(incommingFiles[0].file);
      }
      setFiles(incommingFiles);
    } catch (error) {
      //console.log(error);
      swal('Error', error, 'error');
    }
  };
  const setXMLData = (data) => {
    let elements = data.elements;
    let comprobante =
      elements &&
      elements.length >= 0 &&
      elements.filter((el) => el.name === 'cfdi:Comprobante');
    let complemento = null;
    if (comprobante && comprobante.length > 0) {
      setComprobante(comprobante[0]);
      setXmlVersion(comprobante[0].attributes.Version);
      for (let element of comprobante[0].elements) {
        switch (element.name) {
          case 'cfdi:Emisor':
            setEmisor(element);
            break;
          case 'cfdi:Receptor':
            setReceptor(element);
            break;
          case 'cfdi:Conceptos':
            setConceptos(element);
            break;
          case 'cfdi:Complemento':
            complemento = element;
            setComplemento(element);
            break;
          default:
            break;
        }
      }
      // complemento
      let comercioExterior = null;
      let compElements = (complemento && complemento.elements) || [];
      for (let element of compElements) {
        let ElementName = (element && element.name) || null;
        switch (ElementName) {
          case 'cce11:ComercioExterior':
            comercioExterior = element;
            setComercioExterior(element);
            break;
          case 'tfd:TimbreFiscalDigital':
            setTimbreFiscalDigital(element);
            break;
          default:
            break;
        }
      }
      // comercioExterior
      let comExtElements =
        (comercioExterior && comercioExterior.elements) || [];
      for (let element of comExtElements) {
        let ElementName = (element && element.name) || null;
        switch (ElementName) {
          case 'cce11:Mercancias':
            setComercioExtMercancias(element);
            break;
          case 'cce11:Emisor':
            setComercioExtEmisor(element);
            break;
          case 'cce11:Receptor':
            setComercioExtReceptor(element);
            break;
          case 'cce11:Destinatario':
            setComercioExtDestinatario(element);
            break;
          default:
            break;
        }
      }
    }
  };
  const printXML = () => {
    // Header
    let tipoOperacion =
      (comercioExterior &&
        comercioExterior.attributes &&
        comercioExterior.attributes.TipoOperacion) ||
      null;
    let claveDocumento =
      (comercioExterior &&
        comercioExterior.attributes &&
        comercioExterior.attributes.ClaveDePedimento) ||
      null;
    let mercancias = comercioExtMercancias || null;
    let sumaValorAduana = 0;
    let listMercancias = [];

    for (let childMercancia of mercancias.elements) {
      let UnidadAduana =
        (childMercancia &&
          childMercancia.attributes &&
          childMercancia.attributes.UnidadAduana) ||
        null;
      let cantidadAduana =
        childMercancia &&
        childMercancia.attributes &&
        childMercancia.attributes.CantidadAduana
          ? parseFloat(childMercancia.attributes.CantidadAduana)
          : 0;
      switch (UnidadAduana) {
        // kilogramos
        case '01':
          sumaValorAduana = sumaValorAduana + cantidadAduana;
          break;
        // Gramos
        case '02':
        case '16':
          sumaValorAduana = (sumaValorAduana + cantidadAduana) / 1000;
          break;
        // Tonelada
        case '14':
          sumaValorAduana = sumaValorAduana + cantidadAduana;
          //sumaValorAduana = (sumaValorAduana + cantidadAduana) * (1000)
          break;
        default:
          break;
      }

      // Registro de Partidas

      let objectMercancia = {
        fraccion:
          (childMercancia &&
            childMercancia.attributes &&
            childMercancia.attributes.FraccionArancelaria) ||
          null,
        noParte:
          (childMercancia &&
            childMercancia.attributes &&
            childMercancia.attributes.NoIdentificacion) ||
          null,
        unidadMedidaComercial: '',
        cantidadTarifa: cantidadAduana,
        casosPartidas: {},
        // paisOrigen: comercioExtEmisor.elements[0].attributes.Pais,
        paisOrigen:
          (comercioExtReceptor &&
            comercioExtReceptor.elements &&
            comercioExtReceptor.elements.length > 0 &&
            comercioExtReceptor.elements[0].attributes.Pais) ||
          null,
        paisComprador:
          (comercioExtReceptor &&
            comercioExtReceptor.elements &&
            comercioExtReceptor.elements.length > 0 &&
            comercioExtReceptor.elements[0].attributes.Pais) ||
          null,
        precioUnitario: '',
        umt: UnidadAduana,
        pesoNeto: '',
        valorDolares:
          childMercancia &&
          childMercancia.attributes &&
          childMercancia.attributes.ValorDolares,
        numeroFactura:
          ((comprobante &&
            comprobante.attributes &&
            comprobante.attributes.Serie) ||
            '') +
          '-' +
          ((comprobante &&
            comprobante.attributes &&
            comprobante.attributes.Folio) ||
            ''),
      };
      for (let childConceptos of conceptos.elements) {
        if (childConceptos.attributes) {
          let unidad =
            childConceptos &&
            childConceptos.attributes &&
            childConceptos.attributes.unidad
              ? childConceptos.attributes.Unidad.toString().toLowerCase()
              : '';

          switch (unidad) {
            case 'caja':
            case 'cajas':
              objectMercancia.unidadMedidaComercial = '20';
              break;
            case 'kilo':
              objectMercancia.unidadMedidaComercial = '01';
              break;
            case 'gramo':
            case 'gramo neto':
              objectMercancia.unidadMedidaComercial = '02';
              break;
            case 'tonelada':
              objectMercancia.unidadMedidaComercial = '14';
              break;
            case 'pieza':
              objectMercancia.unidadMedidaComercial = '06';
              break;
            default:
              break;
          }
          objectMercancia.precioUnitario =
            (comercioExterior &&
              comercioExterior.attributes &&
              comercioExterior.attributes.TotalUSD) ||
            null;
          let NoIdentificacion =
            (childConceptos &&
              childConceptos.attributes &&
              childConceptos.attributes.NoIdentificacion) ||
            null;
          let noParte = (objectMercancia && objectMercancia.noParte) || null;
          if (
            NoIdentificacion === noParte &&
            NoIdentificacion !== null &&
            noParte !== null
          ) {
            objectMercancia.descripcion =
              (childConceptos &&
                childConceptos.attributes &&
                childConceptos.attributes.Descripcion) ||
              null;
            objectMercancia.valorMercancia =
              (childConceptos &&
                childConceptos.attributes &&
                childConceptos.attributes.Importe) ||
              null;
            objectMercancia.cantidadComercial =
              (childConceptos &&
                childConceptos.attributes &&
                childConceptos.attributes.Cantidad) ||
              null;
            //console.log(objectMercancia.cantidadTarifa,objectMercancia.cantidadComercial)
            //objectMercancia.pesoNeto = (parseFloat(objectMercancia.cantidadTarifa) / parseFloat(objectMercancia.cantidadComercial)).toFixed(2)
            objectMercancia.pesoNeto = sumaValorAduana;
            listMercancias.push(objectMercancia);
          }
        }
      }
    }
    // Registro de Facturas
    let numeroFactura =
      (timbreFiscalDigital &&
        timbreFiscalDigital.attributes &&
        timbreFiscalDigital.attributes.UUID) ||
      null;
    let fechaFacturacion =
      (comprobante && comprobante.attributes && comprobante.attributes.Fecha) ||
      null;
    let terminoFacturacion = comercioExterior.attributes.Incoterm;
    let monedaFacturacion =
      (comprobante &&
        comprobante.attributes &&
        comprobante.attributes.Moneda) ||
      null;
    let valorMoneda =
      (comprobante && comprobante.attributes && comprobante.attributes.Total) ||
      null;
    let valorDolares =
      (comprobante && comprobante.attributes && comprobante.attributes.Total) ||
      null;

    setValues({
      ...values,
      tipoOperacion: tipoOperacion,
      claveDocumento: claveDocumento,
      pesoBruto: sumaValorAduana.toFixed(2),
      factura: {
        numeroFactura: numeroFactura,
        fechaFacturacion: fechaFacturacion,
        terminoFacturacion: terminoFacturacion,
        monedaFacturacion: monedaFacturacion,
        valorMoneda: valorMoneda,
        valorDolares: valorDolares,
        pesoNeto: sumaValorAduana.toFixed(2),
        pesoBruto: sumaValorAduana.toFixed(2),
        bultos: 0,
        fletes: 0,
        seguros: 0,
        embalajes: 0,
        otros: 0,
        origenMercancia: 'USA',
        descargoMercancia: 'USA',
        destinoMercancia: 'USA',
      },
      mercancias: listMercancias,
    });
  };

  const handleDelete = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <Button onClick={() => dispatch({ type: 'OPEN_MODAL' })} type="button">
          Cargar Darwin CCE
        </Button>
      </div>

      <Modal
        dimmer={dimmer}
        open={open}
        onClose={() => dispatch({ type: 'CLOSE_MODAL' })}
      >
        <Modal.Header>Subir XML</Modal.Header>
        <Modal.Content>
          <Dropzone
            onChange={xmlJson}
            value={files}
            onClean
            accept={'.xml'}
            label={'Arrastrar aquí o dar click para seleccionar. '}
            minHeight={'195px'}
            maxHeight={'500px'}
            disableScroll
            maxFiles={1}
          >
            {files.map((file) => (
              <FileItem
                {...file}
                key={file.id}
                onDelete={handleDelete}
                alwaysActive
                preview
                info
                resultOnTooltip
              />
            ))}
          </Dropzone>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={() => {
              dispatch({ type: 'CLOSE_MODAL' });
              setFiles([]);
              setValues(initialValues);
            }}
          >
            Cancelar
          </Button>
          <Button
            positive
            onClick={() => {
              //console.log('archivos', files);
              if (files.length === 0) {
                swal('Error', 'No has seleccionado ningún archivo...', 'error');
              } else if (files.length >= 2) {
                swal(
                  'Error',
                  'Se ha alcanzado la cantidad máxima de archivos (1).',
                  'error'
                );
              } else {
                handleValidateXML();
                //printXML();
                //dispatch({ type: 'CLOSE_MODAL' });
              }
            }}
          >
            Cargar
          </Button>
        </Modal.Actions>
        {loading ? (
          <Dimmer active inverted>
            <Loader inverted>Cargando</Loader>
          </Dimmer>
        ) : null}
      </Modal>
    </div>
  );
};

export default forwardRef((props, ref) => (
  <ModalForm {...props} innerRef={ref} />
));
