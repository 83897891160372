const moment = require('moment');

module.exports = (lastDocument, userPOS) => {
  return {
    idTipoComprobante: 'I', //DONE
    Serie: '-', //DONE DefiniAyuda cuando se quierer einiciar folio
    Folio: 0, ///DONE Incrementable ✅
    // FechaDocumento: moment().subtract(6, 'hours').format(), //DONE
    FechaDocumento: moment().format(), //DONE
    Condiciones: '', // TODO De donde se saca
    idUsoCFDI: 'G03', // TODO Forzado asi en Traslados
    idMetodoPago: 'PPD', // TODO Forzado asi en traslados
    idFormaPago: '99', // TODO Forzado asi en traslados
    idSucursal: userPOS ? userPOS[0].idSucursal : null, //DONE Cada emisor es una sucursal
    idUser: 'irving', // TODO Agregar el ID que se tiene aquí del usuario.
    idPOS: userPOS ? userPOS[0].idPOS : null, //DONE Despues - Se supoe que cada emisor puede tener diferentes lugares para facturar
    EsGlobal: '0', //DONE No se usa
    idModulo: 1, //DONE Podemos dejar 1, pero es para nosotros
    idSocioNegocio: null, //DONE Después - El id del cliente
    TipoDeCambio: 1.0, //DONE
    Subtotal: 0.0, //IDEA Despues agregarlo pero como texto, no es un input y calcularlo conforme se va llenando la información de conceptos.
    TotalDescuentos: 0.0, //IDEA Despues Agregar la suma al final de los conceptos
    ImpuestosTraslados: 0.0, //IDEA Despues Agregar la suma al final de los conceptos
    ImpuestosRetenciones: 0.0, //IDEA Despues Agregar la suma al final de los conceptos
    ImpuestosGlobalTraslados: 0.0, //IDEA Despues
    ImpuestosGlobalRetenciones: 0.0, //IDEA Despues
    Total: 0.0, //IDEA Despues - Agregar la suma al final de los conceptos
    TotalLetra: 'CERO PESOS 00/100 M.N.', //IDEA Despues - Agregar la suma al final de los conceptos
    MotivoDescuento: '', //IDEA Despues - Preguntar en donde se pone.
    Condicion: 'CREDITO', //TODO  Traslado no tiene nada.
    Comentarios: '', //IDEA Agregar un textarea para comentarios al final del formulario.
    idStatus: 1, //IDEA  1= Preparado, 2 = Cancelado, 3 = Emitido, 4 = Cancelado en proceso
    idRegimen: userPOS ? userPOS[0].RegimenFiscal : '', //IDEA  Se toma del Emisor
    LugarExpedicion: userPOS ? userPOS[0].CodigoPostal : '', //IDEA Se toma del POS del emisor
    idMoneda: 'MXN', //TODO en realidad va XXX, pero esta bien asi
    TipoViaje: 'Internacional',
    EntradaSalida: 'Entrada',
    PaisOrigenDestino: 'USA',
    CartaPorte: 'true',
    ////  Estos datos son del emisor, POS, se requierer Nombre y RFC
    EmisorNombre: userPOS ? userPOS[0].RazonSocial : '',
    EmisorRFC: userPOS ? userPOS[0].TaxID : '',
    EmisorCalle: '',
    EmisorNumExterior: '',
    EmisorNumInterior: '',
    EmisorColonia: '',
    EmisorLocalidad: '',
    EmisorReferencia: '',
    EmisorMunicipio: '',
    EmisorEstado: '',
    EmisorPais: '',
    EmisorCodPos: '',
    /////  Estos datos son del receptor o cliente, pero en traslado es el emisor
    ReceptorNombre: '',
    ReceptorRFC: '',
    ReceptorCalle: '',
    ReceptorNumExterior: '',
    ReceptorNumInterior: '',
    ReceptorColonia: '',
    ReceptorLocalidad: '',
    ReceptorReferencia: '',
    ReceptorMunicipio: '',
    ReceptorEstado: '',
    ReceptorPais: '',
    ReceptorCodPos: '',
    ReceptorRegistroTributarioExterior: '',
    conceptos: [
      // {
      //   Cantidad: 1.0,
      //   idProducto: 0, //DONE Después
      //   Descripcion: 'Descripcion',
      //   PrecioUnitario: 0.0,
      //   Subtotal: 0.0,
      //   Descuento: 0.0, //DONE Disable cuando es Traslado
      //   idImpuesto: 1, //Ingresos
      //   TasaOCouta: 'Tasa', //Ingresos
      //   idTipoTax: 1, //Ingresos
      //   ImporteBase: 0.0, //Ingresos
      //   Valor: 16.0, //Ingresos
      //   Importe: 0.0, //Ingresos
      //   CodigoSAT: '002', //Ingresos
      //   ImpuestosTraslados: 0.0, //Para ingresos
      //   ImpuestosRetenciones: 0.0, //Para ingresos
      //   Total: 0.0,
      //   PerfilImpuestos: 0,
      //   idReferencia: 0, //DONE Sin uso
      //   idReferenciaModulo: 0, //DONE Sin uso
      //   TipoConcepto: 0,
      //   ClaveProdServ: '',
      //   CuentaPredial: '', //IDEA después
      //   NumeroIdentificacion: '', //IDEA después
      //   ClaveUnidad: '', //DONE
      //   UnidadMedida: 'Unidad de servicio',
      //   ValorMercancia: 0.0, //DONE Carta Porte
      //   PesoEnKg: 0.0, //DONE Carta Porte
      //   MonedaMercancia: 'USN', //DONE Carta Porte
      //   cfdi_tipoRelacion: '', //IDEA Después
      //   cfdi_tipoRelacionuuid: '', //IDEA Después
      //   NumeroIdentificacionComercio: '', //IDEA Después - comercio exterior.
      //   FraccionArancelariaComercio: '', //DONE Carta Porte
      //   CantidadAduanaComercio: 0.0, //IDEA Después - comercio exterior.
      //   UnidadAduanaComercio: '', //IDEA Después - comercio exterior.
      //   ValorUnitarioAduanaComercio: 0.0, //IDEA Después - comercio exterior.
      //   ValorDolaresComercio: 0.0, //IDEA Después - comercio exterior.
      //   DescripcionIngles: '', //IDEA Después - comercio exterior.
      //   cfdi_RelacionIdDoc: 0, //IDEA Después - complemento de pagos.
      //   cfdi_RelacionNumParcialidad: 0, //IDEA Después - complemento de pagos.
      //   cfdi_RelacionReferencia: '', //IDEA Después - complemento de pagos.
      //   idDoc_Ref: 0, //IDEA Después - complemento de pagos.
      //   CveMaterialPeligroso: '', //TODO Carta Porte - catalogo - requerido si es material peligroso en ClaveProdServ
      //   Embalaje: '', //TODO Carta Porte - catalogo - requerido si es material peligroso en ClaveProdServ
      //   EmbalajeDescripcion: '', //TODO Carta Porte - requerido si es material peligroso en ClaveProdServ
      //   UUIDComercioExterior: '', //TODO Carta Porte - opcional
      //   TipoMateria: '', //TODO Carta Porte - opcional
      //   DescripcionMateria: '', //TODO Carta Porte - opcional
      //   TipoDocumentoAduanero: '', //TODO Carta Porte - opcional
      //   IdentificacionDocumentoAduanero: '', //TODO Carta Porte - opcional
      //   RFCImportador: '', //TODO Carta Porte - opcional
      //   Pedimento: '', //TODO Carta Porte - opcional con formato validado, si espacio deben ser 15, y si no con espacios lo que den.
      //   Dimensiones: '', //TODO Carta Porte - opcional cuando es material peligroso.
      // },
      // {
      //   Cantidad: 1.0,
      //   idProducto: 0, //DONE Después
      //   Descripcion: '',
      //   PrecioUnitario: 0.0,
      //   Subtotal: 0.0,
      //   Descuento: 0.0, //DONE Disable cuando es Traslado
      //   idImpuesto: 1, //Ingresos
      //   TasaOCuota: 'Tasa', //Ingresos
      //   idTipoTax: 1, //Ingresos
      //   ImporteBase: 0.0, //Ingresos
      //   Valor: 16.0, //Ingresos
      //   Importe: 0.0, //Ingresos
      //   CodigoSAT: '002', //Ingresos
      //   ImpuestosTraslados: 0.0, //Para ingresos
      //   ImpuestosRetenciones: 0.0, //Para ingresos
      //   Total: 0.0,
      //   PerfilImpuestos: '',
      //   idReferencia: 0, //DONE Sin uso
      //   idReferenciaModulo: 0, //DONE Sin uso
      //   TipoConcepto: 1,
      //   ClaveProdServ: '',
      //   CuentaPredial: '', //IDEA después
      //   NumeroIdentificacion: '', //IDEA después
      //   ClaveUnidad: '', //DONE
      //   UnidadMedida: '',
      //   ValorMercancia: 0.0, //DONE Carta Porte
      //   PesoEnKg: 0.0, //DONE Carta Porte
      //   MonedaMercancia: '', //DONE Carta Porte
      //   cfdi_tipoRelacion: '', //IDEA Después
      //   cfdi_tipoRelacionuuid: '', //IDEA Después
      //   NumeroIdentificacionComercio: '', //IDEA Después - comercio exterior.
      //   FraccionArancelariaComercio: '', //DONE Carta Porte
      //   CantidadAduanaComercio: 0.0, //IDEA Después - comercio exterior.
      //   UnidadAduanaComercio: '', //IDEA Después - comercio exterior.
      //   ValorUnitarioAduanaComercio: 0.0, //IDEA Después - comercio exterior.
      //   ValorDolaresComercio: 0.0, //IDEA Después - comercio exterior.
      //   DescripcionIngles: '', //IDEA Después - comercio exterior.
      //   cfdi_RelacionIdDoc: 0, //IDEA Después - complemento de pagos.
      //   cfdi_RelacionNumParcialidad: 0, //IDEA Después - complemento de pagos.
      //   cfdi_RelacionReferencia: '', //IDEA Después - complemento de pagos.
      //   idDoc_Ref: 0, //IDEA Después - complemento de pagos.
      //   CveMaterialPeligroso: '', //TODO Carta Porte - catalogo - requerido si es material peligroso en ClaveProdServ
      //   Embalaje: '', //TODO Carta Porte - catalogo - requerido si es material peligroso en ClaveProdServ
      //   EmbalajeDescripcion: '', //TODO Carta Porte - requerido si es material peligroso en ClaveProdServ
      //   UUIDComercioExterior: '', //TODO Carta Porte - opcional
      //   Pedimento: '', //TODO Carta Porte - opcional con formato validado, si espacio deben ser 15, y si no con espacios lo que den.
      //   Dimensiones: '', //TODO Carta Porte - opcional cuando es material peligroso.
      // },
    ],
    ubicaciones: [
      {
        TipoUbicacion: 'Origen', //DONE Solo Origen o Destino
        TipoEstacion: '', //IDEA No se requiere para autotransport
        NumEstacion: '', //IDEA No se requiere para autotransport
        NombreEstacion: '', //IDEA No se requiere para autotransport
        NavegacionTrafijo: '', //IDEA No se requiere para autotransport
        RFCRemitenteDestinatario: '', //TODO requerido
        ResidenciaFiscal: '', //TODO Requerido si no es MEX
        NumRegTributario: '', //TODO Requerido si no es MEX
        IDUbicacion: 'OR000001', //TODOrequerido
        FechaHoraSalidaLlegada: moment().format(), //TODOrequerido
        DistanciaRecorrida: 0.0, //TODO0 para origen, requerido para destino
        Calle: '', //DONE Opcional
        NumExterior: '', //DONE Opcional
        NumInterior: '', //DONE Opcional
        Referencia: '', //DONE Opcional
        Pais: '', //TODOrequerido
        Estado: '', //TODO requerido -->>  MEX, USA y CA del catalogo
        Municipio: '', //TODO Opcional
        Localidad: '', //TODO Opcional
        CodigoPostal: '', //TODO requerido -->> MEX del catalogo, USA y CA libre
        Colonia: '', //TODO Opcional
        CartaPorte: 'true',
      },
      {
        TipoUbicacion: 'Destino', //DONE Solo Origen o Destino
        TipoEstacion: '', //IDEA No se requiere para autotransport
        NumEstacion: '', //IDEA No se requiere para autotransport
        NombreEstacion: '', //IDEA No se requiere para autotransport
        NavegacionTrafijo: '', //IDEA No se requiere para autotransport
        RFCRemitenteDestinatario: '', //TODO requerido
        ResidenciaFiscal: '', //TODO Requerido si no es MEX
        NumRegTributario: '', //TODO Requerido si no es MEX
        IDUbicacion: 'DE000002', //TODOrequerido
        FechaHoraSalidaLlegada: moment().format(), //TODOrequerido
        DistanciaRecorrida: 0.0, //TODO0 para origen, requerido para destino
        Calle: '', //DONE Opcional
        NumExterior: '', //DONE Opcional
        NumInterior: '', //DONE Opcional
        Referencia: '', //DONE Opcional
        Pais: '', //TODOrequerido
        Estado: '', //TODO requerido -->>  MEX, USA y CA del catalogo
        Municipio: '', //TODO Opcional
        Localidad: '', //TODO Opcional
        CodigoPostal: '', //TODO requerido -->> MEX del catalogo, USA y CA libre
        Colonia: '', //TODO Opcional
        CartaPorte: 'true',
      },
    ],
    figuraTransportes: [
      // {
      //   TipoFigura: '', //TODO Requerido
      //   NombreFigura: '', //TODO
      //   RFC: '', // Requerido
      //   NumLicencia: '', // Requerido
      //   ResidenciaFiscal: '', // Opcional, si es MEX no se pone nada
      //   NumRegTributario: '', // Requerido si no es MEX
      //   Calle: '', //DONE Opcional
      //   NumExterior: '', //DONE Opcional
      //   NumInterior: '', //DONE Opcional
      //   Referencia: '', //DONE Opcional
      //   Pais: '', //TODO Requerido si no es MEX
      //   Estado: '', //TODO Requerido si no es MEX
      //   Municipio: '', //DONE Opcional
      //   Localidad: '', //DONE Opcional
      //   CodigoPostal: '', //TODO Requerido si no es MEX
      //   Colonia: '', //DONE Opcional
      //   ParteTransporte: '', //TODO
      // },
    ],
    PermSCT: '', //TODO Requerido
    AutoConfigVehicular: '', //TODO Requerido
    NumPermitoSCT: '', //TODO Requerido
    AutoPlacaVM: '', //TODO Requerido
    AutoAnioModeloVM: '', //TODO Requerido
    AseguraRespCivil: '', //TODO Requerido
    PolizaRespCivil: '', //TODO Requerido
    AseguraMedAmbiente: '', //TODORequerido cuando hay material peligroso
    PolizaMedAmbiente: '', //TODO Requerido cuando hay material peligroso
    AseguraCarga: '', //DONE Opcional
    PolizaCarga: '', //DONE Opcional
    PrimaSeguro: 0.0, //DONEOpcional
    PesoBrutoVehicular: 0.0, //DONEOpcional
    PlacaR1: '', //TODO Condicional, si el camion tiene remolques , es requerido uno
    Remolque1: '', //TODO Condicional, si el camion tiene remolques , es requerido uno
    PlacaR2: '', //TODO Condicional, si el camion tiene remolques , es requerido uno
    Remolque2: '', //TODO Condicional, si el camion tiene remolques , es requerido uno
  };
};
