import React, { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import feathers, { useAuth } from '../../services/feathers';
import NumeroALetras from '../BillOfLadingMx/Drawer/NumeroALetras';
import moment from 'moment';
import swal from 'sweetalert';

const ComplementButtonPdf = ({
  hasSelection,
  bankInformation,
  getComplement,
  onClose,
  isBusy,
  setIsBusy,
}) => {
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [dataPago, setDataPago] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [fechaDocumento, setFechaDocumento] = useState(new Date());
  const { CtaOrdenante, NomBancoOrdExt } = bankInformation;
  const [ordenante, setOrdenante] = useState(CtaOrdenante);
  const [nomBank, setNomBank] = useState(NomBancoOrdExt);
  const [idFormaPago, setIdFormaPago] = useState('');
  const [monedaDR, setMonedaDR] = useState(hasSelection[0].idMoneda);
  const [TipoCambio, setTipoCambio] = useState(hasSelection[0].TipoDeCambio);
  const [userPOS, setUserPOS] = useState(null);

  const serviceDocumentos = feathers.service('documentos');
  const serviceDocumentosConceptos = feathers.service('documentos-conceptos');
  const serviceDocumentosImpuestos = feathers.service(
    'documentos-conceptos-impuestos'
  );
  const serviceDocPagos = feathers.service('documentos-pagos');
  const serviceDocPagosImpuestos = feathers.service(
    'documentos-pagos-impuestos'
  );
  const servicePDF = feathers.service('export-pdf-cartaporte');
  const servicePOS = feathers.service('pos');
  const servicePOSFolios = feathers.service('pos-folios');

  const auth = useAuth();

  const isObjEmpty = (obj) => {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  };

  //Obtener folio
  const getPOSFolios = async (values) => {
    let response = await servicePOSFolios.find({
      query: {
        idPOS: values.idPOS,
        idTipoComprobante: values.idTipoComprobante,
        Serie: values.Serie,
      },
    });

    let data =
      (response && response.data && response.data.length && response.data[0]) ||
      null;
    return data;
  };

  //Actualizar folios
  const updateFolio = async (values) => {
    let data = await getPOSFolios(values);
    if (data) {
      let folio = parseInt(data.Folio);
      let response = await servicePOSFolios.patch(values.idPOS, {
        idPOS: data.idPOS,
        idTipoComprobante: data.idTipoComprobante,
        Serie: data.Serie,
        Folio: ++folio,
      });
      return folio;
    }
  };

  const getPDF = async (data) => {
    let html = await servicePDF.get(data.id);
    const blob = new Blob([html], { type: 'application/pdf' });

    if (data.idStatus === 3) {
      // Si el estado es 3, descargamos el PDF
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        data.EmisorRFC +
          '_' +
          data.ReceptorRFC +
          '_' +
          data.Serie +
          data.Folio +
          '_' +
          data.timbre_uuid +
          '.pdf'
      );

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    }

    //setLoadingPDF(false);
  };

  const saveComplement = async (event) => {
    if (dataPago && hasSelection) {
      try {
        //setLoadingPDF(true);
        setIsBusy(true);
        let dataDocumento;
        let dataPagoImpuestos = {};
        let id = parseInt(hasSelection[0].id);
        let resultDataDocumento = await serviceDocumentos.find({
          query: {
            id: id,
          },
        });

        dataDocumento = resultDataDocumento.data[0];
        dataDocumento.idTipoComprobante = 'P';
        dataDocumento.Serie = 'PG';
        dataDocumento.idMoneda = 'XXX';
        dataDocumento.idUsoCFDI = 'CP01';
        dataDocumento.TipoDeCambio = getComplement.TipoCambio;
        dataDocumento.FechaDocumento = moment(fechaDocumento)
          .subtract(1, 'hours')
          .clone()
          .format('YYYY-MM-DDTHH:mm:ss.000[Z]');
        dataDocumento.Subtotal = 0.0;
        dataDocumento.ImpuestosTraslados = 0.0;
        dataDocumento.ImpuestosRetenciones = 0.0;
        dataDocumento.Total = 0.0;
        dataDocumento.TotalLetra = NumeroALetras(
          dataDocumento.Total,
          hasSelection[0].idMoneda
        );
        dataDocumento.cfd_version = null;
        dataDocumento.cfd_noCertificado = null;
        dataDocumento.cfd_Certificado = null;
        dataDocumento.cfd_sello = null;
        dataDocumento.cfd_cadenaOriginal = null;
        dataDocumento.cfd_xml = null;
        dataDocumento.timbre_version = null;
        dataDocumento.timbre_uuid = null;
        dataDocumento.timbre_FechaTimbrado = null;
        dataDocumento.timbre_noCertificadoSAT = null;
        dataDocumento.timbre_selloSAT = null;
        dataDocumento.timbre_RFCPAC = null;

        //Folios
        let userPOS = await servicePOS.get(auth.user.client._id);
        userPOS =
          userPOS && userPOS.length && userPOS[0] && !isObjEmpty(userPOS[0])
            ? userPOS[0]
            : null;
        setUserPOS(userPOS);

        let resultFolio = await updateFolio(dataDocumento);
        dataDocumento.Folio = resultFolio;
        dataDocumento.idStatus = 1;
        dataDocumento.id = null;

        let resultCreateDocumento = await serviceDocumentos.create(
          dataDocumento
        );

        //DocumentosConceptos
        let dataDocumentoConcepto = {};
        dataDocumentoConcepto.id = parseInt(resultCreateDocumento.id);
        dataDocumentoConcepto.idRow = 1;
        dataDocumentoConcepto.Cantidad = 1;
        dataDocumentoConcepto.idProducto = 0;
        dataDocumentoConcepto.Descripcion = 'Pago';
        dataDocumentoConcepto.PrecioUnitario = 0;
        dataDocumentoConcepto.Subtotal = 0;
        dataDocumentoConcepto.Descuento = 0;
        dataDocumentoConcepto.ImpuestosTraslados = 0;
        dataDocumentoConcepto.ImpuestosRetenciones = 0;
        dataDocumentoConcepto.Total = 0;
        dataDocumentoConcepto.idReferenciaModulo = 0;
        dataDocumentoConcepto.idReferencia = 0;
        dataDocumentoConcepto.ClaveProdServ = '84111506';
        dataDocumentoConcepto.UnidadMedida = '';
        dataDocumentoConcepto.ClaveUnidad = 'ACT';
        dataDocumentoConcepto.TipoConcepto = 0;

        let resultCreateDocumentoConcepto =
          await serviceDocumentosConceptos.create(dataDocumentoConcepto);

        let cont = 0;

        for (let data of hasSelection) {
          let montoPagado = parseFloat(data.MontoPago);

          dataPago.idFormaPago = getComplement.pFormaPago;
          dataPago.Total = montoPagado;
          dataPago.ImpSaldoAnt = data.SaldoActual;
          dataPago.ImpPagado = montoPagado;
          dataPago.ImpSaldoInsoluto = dataPago.ImpSaldoAnt - dataPago.ImpPagado;
          dataPago.PagosTotalFactura = data.SaldoActual;
          dataPago.PagosImportePagado = montoPagado;
          dataPago.MetodoPagoDR = 'PPD';
          dataPago.MonedaDR = getComplement.MonedaDR;
          dataPago.idUser = data.idSocioNegocio;
          dataPago.ref_Serie = data.Serie;
          dataPago.ref_Folio = data.Folio;
          dataPago.ref_uuid = data.timbre_uuid;
          dataPago.ref_id = parseInt(data.id);
          dataPago.id = parseInt(resultCreateDocumento.id);
          dataPago.idRow = ++cont;
          dataPago.NumParcialidad = data.Parcialidades + 1;
          dataPago.FechaPago = moment(getComplement.pFechaPago)
            .clone()
            .format('YYYY-MM-DDTHH:mm:ss.000[Z]');
          dataPago.Fecha_Log = moment(getComplement.pFechaPago)
            .clone()
            .format('YYYY-MM-DDTHH:mm:ss.000[Z]');
          dataPago.CtaOrdenante = getComplement.CtaOrdenante;
          dataPago.NomBancoOrdExt = getComplement.NomBancoOrdExt;
          dataPago.NumOperacion = getComplement.NumOperacion;

          let resultCreatePago = await serviceDocPagos.create(dataPago);
        }

        cont = 0;

        for (let data of hasSelection) {
          let resultDataConceptoImpuestos =
            await serviceDocumentosImpuestos.find({
              query: {
                id: data.id,
              },
            });

          if (resultDataConceptoImpuestos.data) {
            cont++;
            let idRowImpuesto = 1;
            let idsVistos = [];

            for (let impuesto of resultDataConceptoImpuestos.data) {
              resultDataConceptoImpuestos = impuesto;
              // Verificamos si el idImpuesto ya ha sido visto
              if (idsVistos.includes(impuesto.id)) {
                idRowImpuesto++;
              } else {
                idRowImpuesto = 1; // Reiniciamos a 1 si es un id nuevo
              }
              idsVistos.push(impuesto.id); // Agregamos el idImpuesto a la lista de vistos

              dataPagoImpuestos.id = parseInt(resultCreateDocumento.id);
              dataPagoImpuestos.idRow = cont;
              dataPagoImpuestos.idRowImpuesto = idRowImpuesto;
              dataPagoImpuestos.CodigoSAT =
                resultDataConceptoImpuestos.CodigoSAT;
              dataPagoImpuestos.TasaOCuota =
                resultDataConceptoImpuestos.TasaOCuota;
              dataPagoImpuestos.Importe = resultDataConceptoImpuestos.Importe;
              dataPagoImpuestos.ImporteBase =
                resultDataConceptoImpuestos.ImporteBase;
              dataPagoImpuestos.idImpuesto = 1;
              dataPagoImpuestos.idTipoTax =
                resultDataConceptoImpuestos.idTipoTax;
              dataPagoImpuestos.Valor = resultDataConceptoImpuestos.Valor;

              let resultCreatePago = await serviceDocPagosImpuestos.create(
                dataPagoImpuestos
              );
              console.log('Pago');
            }
          }
        }

        //Generacion del pdf
        await getPDF(resultCreateDocumento);
        setIsBusy(false);
        onClose(true);
      } catch (exception) {
        console.log(exception);
        swal('Error', exception, 'error');
        //setLoadingPDF(false);
      }
    }
  };

  return (
    <Button
      type="button"
      compact
      style={{ width: '180px' }}
      primary
      onClick={saveComplement}
      loading={loadingPDF} // Utilizar el estado de carga para mostrar la animación
    >
      Guardar y Ver PDF
    </Button>
  );
};

export default ComplementButtonPdf;
