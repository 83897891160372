import React, { useState } from 'react';
import feathers, { useAuth } from '../../services/feathers';
import swal from 'sweetalert';
import { Button } from 'semantic-ui-react';

function TableButtonTimbrar(props) {
  let { id, idPOS, idSucursal, getData, onClick } = props;
  const auth = useAuth();
  const [timbrado, setTimbrado] = useState(false);
  const [loadingTimbrar, setLoadingTimbrar] = useState(false);

  const Timbrar = async (id, idPOS, idSucursal) => {
    setLoadingTimbrar(true);
    let service = feathers.service('pos');
    let serviceTimbrar = feathers.service('timbrar-cfdi');

    let resultPos = await service.find({ query: { idPOS, idSucursal } });
    resultPos = (resultPos.data.length && resultPos.data[0]) || null;

    if (resultPos) {
      let idCertificado = resultPos.idCertificado;
      let idPAC = resultPos.idPAC;
      let resultTimbrado = await serviceTimbrar.create({
        idDocumento: id,
        idCertificado: idCertificado,
        idPAC: idPAC,
        sUser: auth.user._id,
      });

      if (
        resultTimbrado.resultTimbrado.errors &&
        resultTimbrado.resultTimbrado.errors.length
      ) {
        setLoadingTimbrar(false);
        onClick();
        swal('Error', resultTimbrado.resultTimbrado.errors[0], 'error');
      } else {
        setLoadingTimbrar(false);
        onClick();
        swal('Timbrado', '¡Se ha timbrado correctamente!', 'success');
        getData();
        setTimbrado(true);
      }

      // alert(id);
    } else {
      setLoadingTimbrar(false);
      swal('Error', 'No hay POS', 'error');
    }
  };
  return (
    <Button.Group>
      {/* <Button
    compact
    onClick={() =>
      // handleEditClick({
      //   ...params.data,
      //   client: params.data.client && params.data.client._id,
      // })
      handleEditClick(params.data.id)
    }
  >
    Editar
  </Button> */}

      <Button
        compact
        primary
        loading={loadingTimbrar}
        onClick={() =>
          // handleEditClick({
          //   ...params.data,
          //   client: params.data.client && params.data.client._id,
          // })
          Timbrar(id, idPOS, idSucursal)
        }
      >
        Timbrar
      </Button>
    </Button.Group>
  );
}

export default TableButtonTimbrar;
