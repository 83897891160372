import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, Logger } from '@ag-grid-enterprise/all-modules';
import 'lodash';
import { Button, Icon } from 'semantic-ui-react';
import ReactLoading from 'react-loading';
import swal from 'sweetalert';
import feathers, { useAuth } from '../../services/feathers';
import {
  valueFormatter,
  dateTimeCartaPorte,
  date,
} from '../../components/AgGridColumns';

const Table = ({ data, selection, setSelection, getData }) => {
  const servicePDF = feathers.service('export-pdf-cartaporte');

  // States
  const [gridApi, setGridApi] = useState();
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [columnDefs] = useState([
    {
      headerName: 'Timbrado',
      field: 'cfd_Certificado',
      cellRendererFramework: (data) => {
        if (data.value) {
          return <Icon name="check" />;
        } else {
          return '';
        }
      },
    },

    {
      headerName: 'Id Interno',
      field: 'id',
      valueFormatter: (value) => {
        return Number(value.value);
      },
    },
    {
      headerName: 'Status',
      field: 'idStatus',
      valueFormatter: (val) => {
        return val.value == 1
          ? 'Preparado'
          : val.value == 3
          ? 'Emitido'
          : 'Cancelado';
      },
    },
    {
      headerName: 'Tipo de Comprobante',
      field: 'idTipoComprobante',
      valueFormatter,
    },
    {
      headerName: 'Serie',
      field: 'Serie',
      valueFormatter,
    },
    {
      headerName: 'Folio',
      field: 'Folio',
      valueFormatter,
    },
    {
      ...dateTimeCartaPorte,
      headerName: 'Fecha Documento',
      field: 'FechaDocumento',
    },

    {
      headerName: 'RFC Receptor',
      field: 'ReceptorRFC',
      valueFormatter,
    },
    {
      headerName: 'Receptor Nombre',
      field: 'ReceptorNombre',
      valueFormatter,
    },
    {
      headerName: 'Folio Fiscal (UUID)',
      field: 'timbre_uuid',
      valueFormatter,
    },
    {
      headerName: 'Fecha Timbrado',
      field: 'timbre_FechaTimbrado',
    },
    {
      headerName: 'Versión',
      field: 'timbre_version',
      valueFormatter,
    },
  ]);
  const [defaultColDefs] = useState({
    filter: false,
    sortable: true,
    enableRowGroup: true,
    resizable: true,
    width: 250,
  });

  // Effect
  useEffect(() => {
    if (
      selection &&
      !selection.length &&
      gridApi &&
      gridApi.getSelectedRows().length
    ) {
      gridApi.deselectAll();
    }
  }, [selection, gridApi]);

  const saveFilePDF = async (data) => {
    let html = await servicePDF.get(data.id);
    const url = window.URL.createObjectURL(new Blob([html]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      data.EmisorRFC +
        '_' +
        data.ReceptorRFC +
        '_' +
        data.Serie +
        data.Folio +
        '_' +
        data.timbre_uuid +
        '.pdf'
    );
    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  useEffect(() => {
    if (gridColumnApi) {
      var allColumnIds = gridColumnApi
        .getAllColumns()
        .filter(({ colDef: { field } }) => field !== '')
        .map(({ colId }) => colId);

      gridColumnApi.autoSizeColumns &&
        gridColumnApi.autoSizeColumns(allColumnIds, false);
    }
  }, [data, gridColumnApi]);

  const handleSelectionChanged = (event) => {
    if (selection && setSelection) {
      let rows = [];

      try {
        if (event && event.api) {
          rows = event.api.getSelectedRows().map((row) => row._id);
        }
      } catch (err) {
        rows = [];

        swal({
          title: 'Error',
          text: 'Failed to select users',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setSelection(rows);
      }
    }
  };

  const handleGridReady = ({ api, columnApi }) => {
    setGridApi(api);
    setGridColumnApi(columnApi);
  };

  return (
    <TableContainer className="ag-theme-material" rowFilters={1}>
      <AgGridReact
        modules={AllModules}
        columnDefs={columnDefs}
        defaultColDef={defaultColDefs}
        rowSelection={'multiple'}
        suppressRowClickSelection={true}
        onSelectionChanged={handleSelectionChanged}
        rowData={data}
        pagination={true}
        paginationPageSize={100}
        onGridReady={handleGridReady}
        sortingOrder={['desc', 'asc', null]}
      />
    </TableContainer>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  onEditClick: PropTypes.func,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
};

export default Table;
