import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import swal from 'sweetalert';
import { Formik } from 'formik';
import Actions from './Actions';
import FormReceptor from './FormReceptores';
import axios from 'axios';
import feathers from '../../../services/feathers';
import SlidingPanel from '@bit/softwareland.flex.sl-sliding-panel';
import * as Yup from 'yup';

const Drawer = ({ openPanel, onClose, currentReceptor }) => {
  const service = feathers.service('receptores');
  const [currentSection, setCurrentSection] = useState(
    (currentReceptor && currentReceptor.id && 'EDIT') || 'CREATE'
  );

  useEffect(() => {});

  const handleSave = async (values, { setSubmitting }) => {
    if (values) {
      let success = true;
      try {
        if (values.id) {
          let result = await service.patch(values.id, values);
          swal('Actualizado', '¡Se ha actualizado correctamente!', 'success');
        } else {
          let resultCreate = await service.create(values);
          swal('Guardado', '¡Se ha guardado correctamente!', 'success');
        }
      } catch (exception) {
        console.log(exception);
        swal('Error', 'Dont Save Receptor', 'error');
      }
      if (success) {
        setCurrentSection('CREATE');
        onClose(true);
      }
    } else swal('Error', 'Dont Save receptores', 'error');
  };

  const handledOnClose = async () => {
    let confirmation = await swal('Do you want close this form?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      setCurrentSection('CREATE');
      onClose(false);
    }
  };

  const Validations = Yup.object().shape({
    RFC: Yup.string().max(50, 'Too Long!').required('Required'),
    Nombre: Yup.string().max(250, 'Too Long!').required('Required'),
    idRegimen: Yup.number().nullable(),
    // RegistroTributarioExterior: Yup.string()
    //   .max(100, 'Too Long!')
    //   .required('Required'),
    ExentoIVA: Yup.boolean().nullable(),
    NoRetieneIVA: Yup.boolean().nullable(),
    NoRetieneISR: Yup.boolean().nullable(),
    NoRetieneIEPS: Yup.boolean().nullable(),
    // Calle: Yup.string().max(250, 'Too Long!').required('Required'),
    Pais: Yup.string().max(250, 'Too Long!').required('Required'),
    // Estado: Yup.string().max(250, 'Too Long!').required('Required'),
    // Municipio: Yup.string().max(250, 'Too Long!').required('Required'),
    // Localidad: Yup.string().max(250, 'Too Long!').required('Required'),
    CodPos: Yup.string().max(50, 'Too Long!').required('Required'),
    // Colonia: Yup.string().max(250, 'Too Long!').required('Required'),
  });

  let drawerTitles = {
    CREATE: {
      title: 'Nuevo Receptores',
      subtitle: '',
    },
    EDIT: {
      title: 'Editar Receptores',
      subtitle: '',
    },
  };

  let title = drawerTitles[currentSection].title;
  let subtitle = drawerTitles[currentSection].subtitle;

  return (
    <SlidingPanel
      isOpen={openPanel}
      size={100}
      title={title}
      subtitle={subtitle}
      onClose={handledOnClose}
    >
      <Formik
        initialValues={currentReceptor}
        onSubmit={handleSave}
        validationSchema={Validations}
      >
        {(formikProps) => (
          <Form
            onSubmit={formikProps.handleSubmit}
            style={{ marginRight: '15px' }}
          >
            <FormReceptor
              onSave={handleSave}
              // ref={refForm}
              // isValid={isValid}
              // setIsValid={setIsValid}
              formik={formikProps}
            />

            <Actions formik={formikProps} handledOnClose={handledOnClose} />
          </Form>
        )}
      </Formik>
    </SlidingPanel>
  );
};

export default Drawer;
