import React, { forwardRef, useState, useEffect } from 'react';
import {
  Form,
  Segment,
  Header,
  Dropdown,
  Icon,
  Label,
} from 'semantic-ui-react';

const FormCasosPartidas = ({
  tipoDeCaso,
  setTipoDeCaso,
  primerCaso,
  setPrimerCaso,
  observaciones,
  setObservaciones,
  marca,
  setMarca,
  modelo,
  setModelo,
  subModelo,
  setSubModelo,
  numeroSerie,
  setNumeroSerie,
}) => {
  return (
    <>
      <Segment>
        <Header as="h3">Registro de Casos de Nivel Partidas </Header>
        <Form.Group widths={'equal'}>
          <Form.Input
            type="text"
            placeholder="Tipo de Caso"
            //label="Tipo de Caso"
            name="tipoDeCaso"
            value={tipoDeCaso ? tipoDeCaso : ''}
            onChange={(e, { value }) => {
              setTipoDeCaso(value);
            }}
          />

          <Form.Input
            type="text"
            placeholder="1er. Complemento del Caso"
            //label="1er. Complemento del Caso"
            name="PrimerCaso"
            value={primerCaso ? primerCaso : ''}
            onChange={(e, { value }) => {
              setPrimerCaso(value);
            }}
          />
        </Form.Group>
        <Header as="h3">Registro de Observaciones de Partidas </Header>
        <Form.Group widths={'equal'}>
          <Form.Input
            type="text"
            placeholder="Observaciones"
            // label="Observaciones"
            name="Observaciones"
            value={observaciones ? observaciones : ''}
            onChange={(e, { value }) => {
              setObservaciones(value);
            }}
          />
        </Form.Group>
        <Header as="h3">
          Registro de Descripcion Especifica de Mercancias{' '}
        </Header>
        <Form.Group widths={'equal'}>
          <Form.Input
            type="text"
            placeholder="Marca"
            // label="Marca"
            name="Marca"
            value={marca ? marca : ''}
            onChange={(e, { value }) => {
              setMarca(value);
            }}
          />
          <Form.Input
            type="text"
            placeholder="Modelo"
            // label="Modelo"
            name="Modelo"
            value={modelo ? modelo : ''}
            onChange={(e, { value }) => {
              setModelo(value);
            }}
          />
          <Form.Input
            type="text"
            placeholder="Sub-Modelo"
            // label="Sub-Modelo"
            name="Sub-Modelo"
            value={subModelo ? subModelo : ''}
            onChange={(e, { value }) => {
              setSubModelo(value);
            }}
          />
          <Form.Input
            type="text"
            placeholder="Numero de Serie"
            // label="Numero de Serie"
            name="Numero de Serie"
            value={numeroSerie ? numeroSerie : ''}
            onChange={(e, { value }) => {
              setNumeroSerie(value);
            }}
          />
        </Form.Group>
      </Segment>
    </>
  );
};

export default FormCasosPartidas;
