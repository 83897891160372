import React, { forwardRef } from 'react';
import { Form, Segment } from 'semantic-ui-react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import Dropdown from '../../../components/Formik/Dropdown';

const getErrorsAndTouchs = (touched, errors, index, name) => {
  return (
    touched.ubicaciones &&
    touched.ubicaciones[index] &&
    touched.ubicaciones[index][name] &&
    errors.ubicaciones &&
    errors.ubicaciones[index] &&
    errors.ubicaciones[index][name]
  );
};

const FormHeader = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    /* and other goodies */
  } = formik;

  return (
    <>
      <Segment>
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="Descripción"
            label="Descripción *"
            name={`Descripcion`}
            value={values.Descripcion}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.Descripcion && { content: errors.Descripcion }}
          />
          <DropdownSearchFetch
            fluid
            selection
            label="Código Producto/Servicio *"
            value={values.ClaveProdServ ? values.ClaveProdServ : ''}
            onChange={(e, { value }) => {
              setFieldValue(`ClaveProdServ`, value);
            }}
            placeholder="Código Producto/Servicio"
            featherService={'catalogo-clave-prod-serv'}
            columName="Descripcion"
            columValue={'c_ClaveProdServ'}
            queryInit={(columName, columValue, value) => {
              let newQuery;
              if (value) {
                newQuery = {
                  [columValue]: { $like: value },
                  //$limit: 10,
                };
              } else {
                // newQuery = {
                //   [columValue]: {
                //     // $in: [
                //     //   '78101500',
                //     //   '78101501',
                //     //   '78101502',
                //     //   '78101503',
                //     //   '78101600',
                //     //   '78101601',
                //     //   '78101602',
                //     //   '78101603',
                //     //   '78101604',
                //     //   '78101700',
                //     //   '78101701',
                //     //   '78101702',
                //     //   '78101703',
                //     //   '78101704',
                //     //   '78101705',
                //     //   '78101706',
                //     //   '78101800',
                //     //   '78101801',
                //     //   '78101802',
                //     //   '78101803',
                //     //   '78101804',
                //     //   '78101806',
                //     //   '78101807',
                //     //   '78101900',
                //     //   '78101901',
                //     //   '78101902',
                //     //   '78101903',
                //     //   '78101904',
                //     //   '78101905',
                //     //   '78102200',
                //     //   '78102201',
                //     //   '78102203',
                //     //   '78102204',
                //     //   '78102205',
                //     //   '78121603',
                //     //   '78141500',
                //     //   '78141501',
                //     //   '78141900',
                //     //   '80131500',
                //     //   '81112001',
                //     //   '84121806',
                //     //   '92121800',
                //     //   '92121801',
                //     //   '92121802',
                //     // ],
                //   },
                // };
                // } else {
                newQuery = {
                  $limit: 10,
                };
                // }
              }

              return newQuery;
            }}
            queryWithSearch={(columName, columValue, searchQuery) => {
              let newQuery;

              // newQuery = {
              //   [columValue]: {
              //     // $in: [
              //     //   '78101500',
              //     //   '78101501',
              //     //   '78101502',
              //     //   '78101503',
              //     //   '78101600',
              //     //   '78101601',
              //     //   '78101602',
              //     //   '78101603',
              //     //   '78101604',
              //     //   '78101700',
              //     //   '78101701',
              //     //   '78101702',
              //     //   '78101703',
              //     //   '78101704',
              //     //   '78101705',
              //     //   '78101706',
              //     //   '78101800',
              //     //   '78101801',
              //     //   '78101802',
              //     //   '78101803',
              //     //   '78101804',
              //     //   '78101806',
              //     //   '78101807',
              //     //   '78101900',
              //     //   '78101901',
              //     //   '78101902',
              //     //   '78101903',
              //     //   '78101904',
              //     //   '78101905',
              //     //   '78102200',
              //     //   '78102201',
              //     //   '78102203',
              //     //   '78102204',
              //     //   '78102205',
              //     //   '78121603',
              //     //   '78141500',
              //     //   '78141501',
              //     //   '78141900',
              //     //   '80131500',
              //     //   '81112001',
              //     //   '84121806',
              //     //   '92121800',
              //     //   '92121801',
              //     //   '92121802',
              //     //],
              //   },
              // };
              // } else {
              newQuery = {
                $or: [
                  { [columName]: { $like: '%' + searchQuery + '%' } },
                  { [columValue]: { $like: '%' + searchQuery + '%' } },
                ],
                $limit: 10,
              };
              // }

              return newQuery;
            }}
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_ClaveProdServ']} - ${value['Descripcion']}`,
                value: value['c_ClaveProdServ'],
              };
            }}
            error={errors.ClaveProdServ && { content: errors.ClaveProdServ }}
          />
        </Form.Group>
        <Form.Group widths={'equal'}>
          <DropdownSearchFetch
            fluid
            selection
            label="Unidad de Medida *"
            value={
              values.ClaveUnidad
                ? values.ClaveUnidad
                : (values.ClaveUnidad = 'E48')
            }
            onChange={(e, data) => {
              let UnidadMedida = data.options.find(
                (o) => o.value === data.value
              );
              setFieldValue(`values.ClaveUnidad`, data.value);

              if (UnidadMedida && UnidadMedida.text) {
                setFieldValue(`values.UnidadMedida`, UnidadMedida.text);
              }
            }}
            placeholder="Unidad de Medida"
            featherService="catalogo-clave-unidad"
            columName="Nombre"
            columValue="c_ClaveUnidad"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_ClaveUnidad']} - ${value['Nombre']}`,
                value: value['c_ClaveUnidad'],
              };
            }}
            // error={errors.UnidadMedida && { content: errors.UnidadMedida }}
          />
          <Form.Input
            placeholder="Número Identificación"
            label="Número Identificación "
            name={`NumeroIdentificacion`}
            value={
              values.NumeroIdentificacion ? values.NumeroIdentificacion : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.NumeroIdentificacion && {
                content: errors.NumeroIdentificacion,
              }
            }
          />
          <Dropdown
            fluid
            selection
            label="Traslado de IVA *"
            value={
              values.idTrasladoIVA
                ? values.idTrasladoIVA
                : (values.idTrasladoIVA = 0)
            }
            onChange={(e, { value }) => {
              setFieldValue(`idTrasladoIVA`, value);
            }}
            placeholder="Traslado de IVA"
            options={[
              {
                key: 0,
                value: 0,
                text: 'Traslado IVA Tasa 16%',
              },
              {
                key: 1,
                value: 1,
                text: 'Traslado IVA Tasa 8%',
              },
              {
                key: 2,
                value: 2,
                text: 'Traslado IVA Tasa 0%',
              },
              { key: 3, value: 3, text: 'Exento de IVA' },
              { key: 4, value: 4, text: 'No es Objeto de Impuesto' },
            ]}
            onBlur={handleBlur}
            error={errors.idTrasladoIVA && { content: errors.idTrasladoIVA }}
          />
          <Dropdown
            fluid
            selection
            label="Retención de IVA *"
            value={
              values.idRetencionIVA
                ? values.idRetencionIVA
                : (values.idRetencionIVA = 0)
            }
            onChange={(e, { value }) => {
              setFieldValue(`idRetencionIVA`, value);
            }}
            placeholder="Retención de IVA"
            options={[
              {
                key: 0,
                value: 0,
                text: 'Sin Retención IVA',
              },
              {
                key: 1,
                value: 1,
                text: 'Retención 4% IVA',
              },
              {
                key: 2,
                value: 2,
                text: 'Retención 5.3333% IVA',
              },
              { key: 3, value: 3, text: 'Retención 10.6667% IVA' },
            ]}
            onBlur={handleBlur}
            error={errors.idRetencionIVA && { content: errors.idRetencionIVA }}
          />
        </Form.Group>
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
