import React, { useEffect, useState } from 'react';
import productosJSON from './productos.jsonl';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material.scss';

const Productos = () => {
  const [jsonData, setJsonData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(productosJSON);
        const text = await response.text();
        const dataArray = text
          .split('\n')
          .filter((line) => line.trim() !== '')
          .map(JSON.parse);
        //console.log('dataArray: ', dataArray);
        setJsonData(dataArray);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const gridOptions = {
    //rowSelection: 'multiple',
    defaultToolPanel: 'filter',
    sortable: true,
    sideBar: true,
    pagination: true,
    paginationPageSize: 100,
    suppressMenuHide: true,
    rowGroupPanelShow: 'always',
    enableRangeSelection: true,
    enableRangeHandle: true,
    getRowHeight: function (params) {
      return 100;
    },
    defaultColDef: {
      flex: 1,
      minWidth: 150,
      cellStyle: { textAlign: 'center' },
      valueFormatter: (params) => (params.value ? params.value : '-'),
    },
    columnDefs: [
      {
        headerName: 'Imagen',
        field: 'imageUrl',
        headerCheckboxSelection: true,
        //checkboxSelection: true,
        resizable: true,
        cellRenderer: function (params) {
          if (
            params &&
            params.data &&
            params.data.image &&
            params.data.image.src
          ) {
            const imageUrl = params.data.image.src;
            return `<img src="${imageUrl}" style="margin-top: 4px; width: 90px; height: 90px; border-radius: 50%; object-fit: cover;" />`;
          } else {
            return '';
          }
        },
      },
      {
        headerName: 'ID',
        field: 'id',
        resizable: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'SKU',
        field: 'variants',
        filter: 'agTextColumnFilter',
        valueGetter: function (params) {
          if (params && params.data && params.data.variants) {
            const variants = params.data.variants;
            const formattedVariants = variants.map(function (variant) {
              return variant.sku;
            });
            return formattedVariants.join(' ');
          }
        },
      },
      {
        headerName: 'Barcode',
        field: 'variants',
        filter: 'agTextColumnFilter',
        valueGetter: function (params) {
          if (params && params.data && params.data.variants) {
            const variants = params.data.variants;
            const formattedVariants = variants.map(function (variant) {
              return variant.barcode;
            });
            return formattedVariants.join(' ');
          }
        },
      },
      {
        headerName: 'Title',
        field: 'title',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Type',
        field: 'product_type',
        resizable: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Vendor',
        field: 'vendor',
        resizable: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Tags',
        field: 'tags',
        resizable: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Grams',
        field: 'variants',
        filter: 'agTextColumnFilter',
        valueGetter: function (params) {
          if (params && params.data && params.data.variants) {
            const variants = params.data.variants;
            const formattedVariants = variants.map(function (variant) {
              return variant.grams;
            });
            return formattedVariants.join(' ');
          }
        },
      },
      {
        headerName: 'Price',
        field: 'variants',
        filter: 'agTextColumnFilter',
        valueGetter: function (params) {
          if (params && params.data && params.data.variants) {
            const variants = params.data.variants;

            const formattedVariants = variants.map(function (variant) {
              return variant.price;
            });
            return formattedVariants.join(' ');
          }
        },
      },
      {
        headerName: 'Weight',
        field: 'variants',
        filter: 'agTextColumnFilter',
        valueGetter: function (params) {
          if (params && params.data && params.data.variants) {
            const variants = params.data.variants;
            const formattedVariants = variants.map(function (variant) {
              return (
                variant.weight && variant.weight + ' ' + variant.weight_unit
              );
            });
            return formattedVariants.join(' ');
          }
        },
      },
      {
        headerName: 'Published',
        field: 'published_at',
        resizable: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Published Scope',
        field: 'published_scope',
        resizable: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'URL',
        field: 'url',
        cellRenderer: function (params) {
          if (params && params.data && params.data.url) {
            const url = params.data.url;
            return `<a href="${url}" target="_blank">${url}</a>`;
          }
        },
        resizable: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Published',
        field: 'published_at',
        resizable: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Created',
        field: 'created_at',
        resizable: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Updated',
        field: 'updated_at',
        resizable: true,
        filter: 'agTextColumnFilter',
      },
    ],
  };

  const rowData = jsonData;

  return (
    <div
      className="ag-theme-material"
      style={{ width: '100%', height: '750px' }}
    >
      <AgGridReact
        rowData={rowData}
        gridOptions={gridOptions}
        //domLayout="autoHeight"
      />
    </div>
  );
};

export default Productos;
