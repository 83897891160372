import React, { forwardRef, useState } from 'react';
import { Form, Segment, Header, Label } from 'semantic-ui-react';
import DropdownPais from './DropdownPais';

const FormFactura = ({ formik }) => {
  let { values, errors, touched, handleChange, handleBlur } = formik;
  const [origenMercancia, setOrigenMercancia] = useState({
    Pais: 'USA',
  });
  const [descargoMercancia, setDescargoMercancia] = useState({
    Pais: 'USA',
  });
  const [destinoMercancia, setDestinoMercancia] = useState({
    Pais: 'USA',
  });
  return (
    <>
      <Segment>
        <Header as="h3">Registro de Facturas</Header>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <label>Número de Factura</label>
            <Label>
              {values.factura.numeroFactura
                ? values.factura.numeroFactura
                : 'Número de Factura'}
            </Label>
          </Form.Field>
          <Form.Field>
            <label>Fecha de Facturación</label>
            <Label>
              {values.factura.fechaFacturacion
                ? values.factura.fechaFacturacion
                : 'Fecha de Facturación'}
            </Label>
          </Form.Field>
          <Form.Field>
            <label>Término de Facturación</label>
            <Label>
              {values.factura.terminoFacturacion
                ? values.factura.terminoFacturacion
                : 'Término de Facturación'}
            </Label>
          </Form.Field>
          <Form.Field>
            <label>Moneda de Facturación</label>
            <Label>
              {values.factura.monedaFacturacion
                ? values.factura.monedaFacturacion
                : 'Moneda de Facturación'}
            </Label>
          </Form.Field>
        </Form.Group>

        <Form.Group widths={'equal'}>
          <Form.Field>
            <label>Valor de Moneda Extranjera</label>
            <Label>
              {values.factura.valorMoneda
                ? values.factura.valorMoneda
                : 'Valor de Moneda Extranjera'}
            </Label>
          </Form.Field>
          <Form.Field>
            <label>Valor Dólares</label>
            <Label>
              {values.factura.valorDolares
                ? values.factura.valorDolares
                : 'Valor Dólares'}
            </Label>
          </Form.Field>
          <Form.Input
            type="text"
            placeholder="Código del Proveedor"
            label="Código del Proveedor"
            name="factura.codigoProveedor"
            value={
              values.factura.codigoProveedor
                ? values.factura.codigoProveedor
                : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.factura &&
              errors.factura.codigoProveedor &&
              touched.factura &&
              touched.factura.codigoProveedor && {
                content: errors.factura.codigoProveedor,
              }
            }
          />
          <Form.Input
            placeholder="Observaciones"
            label="Observaciones"
            name="factura.observaciones"
            value={
              values.factura.observaciones ? values.factura.observaciones : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.factura &&
              errors.factura.observaciones &&
              touched.factura &&
              touched.factura.observaciones && {
                content: errors.factura.observaciones,
              }
            }
          />
        </Form.Group>
        <Form.Group widths={'equal'}>
          <Form.Input
            type="number"
            placeholder="Peso Bruto"
            label="Peso Bruto"
            name="factura.pesoBruto"
            value={values.factura.pesoBruto}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.factura &&
              errors.factura.pesoBruto &&
              touched.factura &&
              touched.factura.pesoBruto && { content: errors.factura.pesoBruto }
            }
          />
          <Form.Input
            type="number"
            placeholder="Peso Neto"
            label="Peso Neto"
            name="factura.pesoNeto"
            value={values.factura.pesoNeto}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.factura &&
              errors.factura.pesoBruto &&
              touched.factura &&
              touched.factura.pesoBruto && { content: errors.factura.pesoBruto }
            }
          />
          <Form.Input
            type="number"
            placeholder="Bultos"
            label="Bultos"
            name="factura.bultos"
            value={values.factura.bultos}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.factura &&
              errors.factura.bultos &&
              touched.factura &&
              touched.factura.bultos && { content: errors.factura.bultos }
            }
          />
          <Form.Input
            type="number"
            placeholder="Fletes"
            label="Fletes"
            name="factura.fletes"
            value={values.factura.fletes}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.factura &&
              errors.factura.fletes &&
              touched.factura &&
              touched.factura.fletes && { content: errors.factura.fletes }
            }
          />
        </Form.Group>
        <Form.Group widths={'equal'}>
          <Form.Input
            type="number"
            placeholder="Seguros"
            label="Seguros"
            name="factura.seguros"
            value={values.factura.seguros}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.factura &&
              errors.factura.seguros &&
              touched.factura &&
              touched.factura.seguros && { content: errors.factura.seguros }
            }
          />
          <Form.Input
            type="number"
            placeholder="Embalajes"
            label="Embalajes"
            name="factura.embalajes"
            value={values.factura.embalajes}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.factura &&
              errors.factura.embalajes &&
              touched.factura &&
              touched.factura.embalajes && { content: errors.factura.embalajes }
            }
          />
          <Form.Input
            type="number"
            placeholder="Otros"
            label="Otros"
            name="factura.otros"
            value={values.factura.otros}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.factura &&
              errors.factura.otros &&
              touched.factura &&
              touched.factura.otros && { content: errors.factura.otros }
            }
          />
          <DropdownPais
            placeholder="Origen Mercancia"
            label="Origen Mercancia"
            name="origenMercancia"
            val={origenMercancia}
            value={values.factura.origenMercancia}
            setValues={setOrigenMercancia}
            child={'origenMercancia'}
            formik={formik}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.factura &&
              errors.factura.origenMercancia &&
              touched.factura &&
              touched.factura.origenMercancia && {
                content: errors.factura.origenMercancia,
              }
            }
          />
        </Form.Group>
        <Form.Group widths={'equal'}>
          <DropdownPais
            placeholder="Descargo de Mercancía"
            label="Descargo de Mercancía"
            name="descargoMercancia"
            val={descargoMercancia}
            value={values.factura.descargoMercancia}
            setValues={setDescargoMercancia}
            child={'descargoMercancia'}
            formik={formik}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.factura &&
              errors.factura.descargoMercancia &&
              touched.factura &&
              touched.factura.descargoMercancia && {
                content: errors.factura.descargoMercancia,
              }
            }
          />
          <DropdownPais
            placeholder="Destino de Mercancía"
            label="Destino de Mercancía"
            name="destinoMercancia"
            val={destinoMercancia}
            value={values.factura.destinoMercancia}
            setValues={setDestinoMercancia}
            child={'destinoMercancia'}
            formik={formik}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.factura &&
              errors.factura.destinoMercancia &&
              touched.factura &&
              touched.factura.destinoMercancia && {
                content: errors.factura.destinoMercancia,
              }
            }
          />
          <Form.Input
            placeholder="Código del tipo de bultos"
            label="Código del tipo de bultos"
            name="factura.codigoTipoBultos"
            value={
              values.factura.codigoTipoBultos
                ? values.factura.codigoTipoBultos
                : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.factura &&
              errors.factura.codigoTipoBultos &&
              touched.factura &&
              touched.factura.codigoTipoBultos && {
                content: errors.factura.codigoTipoBultos,
              }
            }
          />
          <Form.Input
            placeholder="Tipo de Operación"
            label="Tipo de Operación"
            name="factura.tipoOperacion"
            value={
              values.factura.tipoOperacion ? values.factura.tipoOperacion : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.factura &&
              errors.factura.tipoOperacion &&
              touched.factura &&
              touched.factura.tipoOperacion && {
                content: errors.factura.tipoOperacion,
              }
            }
          />
        </Form.Group>
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormFactura {...props} innerRef={ref} />
));
