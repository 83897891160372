import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import HeaderBG from '../../components/HeaderBG';
import SectionTitle from '../../components/SectionTitle';
import Drawer from './Drawer';
import { Container, Breadcrumb } from 'semantic-ui-react';
import Table from '../Choferes/Table';
import swal from 'sweetalert';
import feathers, { useAuth } from '../../services/feathers';
import Filters from './Filters';
import TopNavigation from './TopNavigation';

const Choferes = (props) => {
  //Calling the feathers service
  const service = feathers.service('choferes');
  const auth = useAuth();

  let [query, setQuery] = useState({
    Nombre: { $like: '%%' },
    idOrganizacion: auth.user.client._id,
  });
  const [isBusy, setIsBusy] = useState(false);
  const [selection, setSelection] = useState([]);
  const [data, setData] = useState([]);
  const [isSupport, setIsSupport] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [currentChofer, setCurrentChofer] = useState([]);
  const [chofer, setChofer] = useState(null);

  useEffect(() => {
    const filter = () => {
      if (!query)
        // If not exists query information
        query = {
          Nombre: { $like: '%%' },
          idOrganizacion: auth.user.client._id,
        };
      getData(); //METODO TO GET DRIVERS INFORMATION
    };

    filter();
  }, [query]);

  useEffect(() => {
    const { isSupport } = (auth && auth.user) || {},
      client = (auth && auth.user && auth.user.client) || null,
      isInvalid = (!isSupport && !client && true) || false;

    setIsSupport(isSupport);
    setChofer(client);
    setIsValid(!isInvalid);
  }, [auth.user]);

  const handleCreateClick = () => {
    const [clients, client] = auth.user.isSupport
      ? [[], {}]
      : [[{ ...auth.user.client }], { ...auth.user.client }];
    openDrawer({ clients, client });
  };

  const handleSaveBulkData = async (results) => {
    let dataMap = results.validData.map((element) => {
      element.idOrganizacion = auth.user.client._id;
      element.id = 0;
      return element;
    });

    let resultCreate = await service.create(dataMap);
    swal('Guardado', '¡Se ha guardado correctamente!', 'success');
  };

  const openDrawer = (chofer) => {
    chofer.idOrganizacion = auth.user.client._id;
    setCurrentChofer(chofer);
    setOpenPanel(true);
  };

  const handleClose = (isSuccess) => {
    setCurrentChofer({});
    setOpenPanel(false);

    isSuccess && getData();
  };

  //METOD TO GET DRIVERS DATA
  const getData = async () => {
    if (query) {
      let data = [],
        result = [];

      try {
        setIsBusy(true);
        setSelection([]);

        if (query.Nombre.idOrganizacion != '') {
          do {
            result = await service.find({
              query: {
                ...query,
                $skip: data.length,
              },
            });
            data = data.concat(result.data);
          } while (data.length !== result.total);
        }
      } catch (err) {
        console.error('Error', err);
        data = [];
        swal({
          title: 'Error',
          text: 'Failed get Chofer',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);
      }
    }
  };

  const handleEditClick = (chofer) => {
    openDrawer(chofer);
  };

  const handleDeleteClick = async (data) => {
    let confirmation = await swal('Do you want delete this item?', {
      buttons: ['No', 'Yes'],
    });

    if (confirmation) {
      try {
        await service.remove(data.id);
        swal('Eliminado', '¡Se ha borrado correctamente!', 'success');
      } catch (exception) {
        swal('Error', 'Dont delete transporte', 'error');
      }
      getData();
    }
  };

  const getClientName = () =>
    (auth && auth.user && auth.user.client && auth.user.client.name) || null;

  return (
    <>
      <DefaultLayout
        currentPath={props.match.path}
        globalActions={
          <TopNavigation
            isBusy={isBusy}
            onCreateClick={handleCreateClick}
            onSaveBulkData={handleSaveBulkData}
            isValid={isValid}
          />
        }
      >
        <HeaderBG />
        <Container className="fluid-95">
          <Breadcrumb
            icon="right angle"
            sections={[
              { key: getClientName(), content: getClientName(), link: false },
              // { key: 'Carta Porte', content: 'Carta Porte', link: false },
              {
                key: 'BillOfLadingMx',
                content: 'Choferes',
                active: true,
              },
            ]}
          />

          <Filters
            isBusy={isBusy}
            setQuery={setQuery}
            isValid={isValid}
            getData={getData}
          />

          {/*Table to show drivers information*/}
          <Table
            data={data}
            selection={selection}
            setSelection={setSelection}
            isSupport={isSupport}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
          ></Table>
        </Container>
      </DefaultLayout>
      {openPanel ? (
        <Drawer
          openPanel={openPanel}
          onClose={handleClose}
          currentChofer={currentChofer}
        ></Drawer>
      ) : null}
    </>
  );
};

export default Choferes;
