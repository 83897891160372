import React from 'react';
import { Button, Container, Dropdown } from 'semantic-ui-react';
import HeaderBG from '../components/HeaderBG';
import OrdersImport from '../components/Orders/OrdersImport';
import SectionTitle from '../components/SectionTitle';
import DefaultLayout from '../layouts/DefaultLayout';

export default (props) => {
  return (
    <DefaultLayout
      currentPath={props.match.path}
      globalActions={
        <>
          <Dropdown text="Import" as={Button} primary compact>
            <Dropdown.Menu>
              {/* <Dropdown.Item
                text="From ShipHero"
                onClick={() => dispatch(toggleSHImport())}
              /> */}
              <Dropdown.Item text="CSV File" disabled />
            </Dropdown.Menu>
          </Dropdown>
        </>
      }
    >
      <HeaderBG />
      <Container>
        <SectionTitle>Orders</SectionTitle>
        <OrdersImport props={props} />
      </Container>
    </DefaultLayout>
  );
};
