import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { FlatfileButton } from '@flatfile/react';
import feathers from '../../services/feathers';

const TopNavigation = ({ isBusy, onCreateClick, isValid, onSaveBulkData }) => {
  const [paises, setPaises] = useState();

  useEffect(() => {
    async function cargarCatalogos() {
      const paisService = await feathers.service('catalogo-pais');
      let catalogoPais = await paisService.find();
      let catalogoCodigoPais = catalogoPais.data.map((pais) => {
        return { value: pais.CPaisID, label: pais.c_Pais };
      });

      setPaises(catalogoCodigoPais);
    }
    cargarCatalogos();
  }, []);

  return (
    <>
      {paises && (
        <FlatfileButton
          settings={{
            type: 'prueba',
            fields: [
              {
                label: 'RFC',
                key: 'RFC',
                validators: [{ validate: 'required' }],
              },
              {
                label: 'Pais Residencia Fiscal',
                key: 'PaisResidenciaFiscal',
                type: 'select',
                options: paises,
              },
              {
                label: 'Numero Registro Tributario',
                key: 'NumRegistroTributario',
              },
              {
                label: 'Calle',
                key: 'Calle',
                validators: [{ validate: 'required' }],
              },
              { label: 'Numero Exterior', key: 'NumExterior' },
              { label: 'Numero Interior', key: 'NumInterior' },
              { label: 'Referencia', key: 'Referencia' },
              {
                label: 'Pais',
                key: 'Pais',
                validators: [{ validate: 'required' }],
                type: 'select',
                options: paises,
              },
              {
                label: 'Estado',
                key: 'Estado',
                validators: [{ validate: 'required' }],
              },
              {
                label: 'Municipio',
                key: 'Municipio',
                validators: [{ validate: 'required' }],
              },
              {
                label: 'Localidad',
                key: 'Localidad',
                validators: [{ validate: 'required' }],
              },
              {
                label: 'Codigo Postal',
                key: 'CodigoPostal',
                validators: [{ validate: 'required' }],
              },
              {
                label: 'Colonia',
                key: 'Colonia',
                validators: [{ validate: 'required' }],
              },
            ],
          }}
          licenseKey={'8966b5ef-41eb-4d04-806b-f05b03741e6e'}
          customer={{
            companyId: 'ABC-123',
            companyName: 'ABC Corp.',
            email: 'john@abc123.com',
            name: 'John Smith',
            userId: '12345',
          }}
          onData={async (results) => {
            // do something with the results
            onSaveBulkData(results);
          }}
          render={(importer, launch) => {
            return (
              <Button
                primary
                compact
                onClick={launch}
                disabled={isBusy || !isValid}
              >
                Upload file
              </Button>
            );
          }}
        />
      )}
      &nbsp;
      <Button
        primary
        compact
        onClick={onCreateClick}
        disabled={isBusy || !isValid}
      >
        Crear Ubicación
      </Button>
    </>
  );
};

TopNavigation.propTypes = {
  isBusy: PropTypes.bool,
  onCreateClick: PropTypes.func,
  isValid: PropTypes.bool,
};

export default TopNavigation;
