import React, { forwardRef, useState, useEffect } from 'react';
import { Form, Segment, Header, Label } from 'semantic-ui-react';
import DropdownDestinoZona from './DropdownDestinoZona';
import DropdownMedioTransporte from './DropdownMedioTransporte';

const FormHeader = ({ formik, setIsConsolidado }) => {
  let { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    formik;
  let optionsTipoArchivo = [
    { text: 'Pedimento', value: 'P' },
    { text: 'Embarque', value: 'E' },
    { text: 'Consolidado', value: 'C' },
  ];

  const [transporteSalida, setTransporteSalida] = useState({
    description: 7,
  });
  const [transporteArribo, setTransporteArribo] = useState({
    description: 7,
  });
  const [transporteSalidaAduana, setTransporteSalidaAduana] = useState({
    description: 7,
  });
  const [destinoZona, setDestinoZona] = useState({ description: '9' });

  return (
    <>
      <Segment>
        <Header as="h3">Registro de Encabezado o Datos Generales </Header>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <label>Tipo de Operación</label>
            <Label>
              {values.tipoOperacion && values.tipoOperacion == '2'
                ? 'Exp'
                : values.tipoOperacion && values.tipoOperacion == '1'
                ? 'Imp'
                : 'Tipo de Operación'}
            </Label>
          </Form.Field>
          <Form.Field>
            <label>Clave de Documento</label>
            <Label>
              {values.claveDocumento
                ? values.claveDocumento
                : 'Clave de Documento'}
            </Label>
          </Form.Field>
          <Form.Input
            type="text"
            placeholder="Codigo del Importador"
            label="Codigo del Importador"
            name="codigoImportador"
            value={values.codigoImportador}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.codigoImportador &&
              touched.codigoImportador && { content: errors.codigoImportador }
            }
          />

          {/* se carga desde el xml pero se puede editar  */}

          <Form.Input
            type="number"
            min={1}
            placeholder="Peso Bruto"
            label="Peso Bruto"
            name="pesoBruto"
            value={values.pesoBruto}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.pesoBruto &&
              touched.pesoBruto && { content: errors.pesoBruto }
            }
          />

          <Form.Input
            type="number"
            min={0}
            max={999999999999}
            placeholder="Bultos"
            label="Bultos"
            name="bultos"
            value={values.bultos}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.bultos && touched.bultos && { content: errors.bultos }
            }
          />
        </Form.Group>
        <Form.Group widths={'equal'}>
          <DropdownMedioTransporte
            placeholder="Transporte de entrada/salida"
            label="Transporte de entrada/salida"
            name="transporteSalida"
            value={values.transporteSalida}
            val={transporteSalida}
            setValues={setTransporteSalida}
            child={'transporteSalida'}
            formik={formik}
            error={
              errors.transporteSalida &&
              touched.transporteSalida && { content: errors.transporteSalida }
            }
          />
          <DropdownMedioTransporte
            placeholder="Transporte De Arribo"
            label="Transporte de Arribo"
            name="transporteArribo"
            value={values.transporteArribo}
            val={transporteArribo}
            setValues={setTransporteArribo}
            child={'transporteArribo'}
            formik={formik}
          />
          <DropdownMedioTransporte
            placeholder="Transporte de Salida de Aduana"
            label="Transporte de Salida de Aduana"
            name="transporteSalidaAduana"
            value={values.transporteSalidaAduana}
            val={transporteSalidaAduana}
            setValues={setTransporteSalidaAduana}
            child={'transporteSalidaAduana'}
            formik={formik}
          />
          <Form.Field>
            <DropdownDestinoZona
              value={values.destinoZona}
              destinoZona={destinoZona}
              setDestinoZona={setDestinoZona}
              formik={formik}
            />
          </Form.Field>
          <Form.Dropdown
            selection
            clearable
            search={true}
            placeholder="Tipo de Archivo"
            label="Tipo de Archivo"
            options={optionsTipoArchivo}
            name="tipoArchivo"
            values={values.tipoArchivo ? values.tipoArchivo : ''}
            onChange={(e, { value }) => {
              setFieldValue('tipoArchivo', value);
              value === 'C' ? setIsConsolidado(true) : setIsConsolidado(false);
            }}
          />
        </Form.Group>
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
