import React from 'react';
import ActivityIndicator from '../../components/ActivityIndicator';
import { Grid, GridRow, GridColumn, Button } from 'semantic-ui-react';
import styled from 'styled-components';

const TableNavigation = ({
  isBusy,
  hasSelection,
  messageAction,
  onDeleteClick,
  openPanel,
  setOpenPanel,
}) => {
  return (
    <Grid columns={2} verticalAlign="middle" padded="vertically">
      <GridRow stretched>
        <GridColumn>
          {(isBusy && (
            <ActivityIndicator title={messageAction || 'Loading...'} />
          )) || (
            <Instructions>
              Selecciona 1 o más facturas para realizar acciones
            </Instructions>
          )}
          <div style={{ marginTop: 24 }}>
            <Button
              primary
              compact
              disabled={hasSelection.length > 0 ? false : true}
              onClick={() => setOpenPanel(!openPanel)}
            >
              Pagar
            </Button>
          </div>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

const Instructions = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;

export default TableNavigation;
