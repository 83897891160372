import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '../../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import EditButton from './Componentes/EditButton';
import EditModal from './Componentes/EditModal';
// import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch'
// import DropDownAG from './DropDownAG'
import { valueFormatter } from '../../../components/AgGridColumns';
import {  Header, Segment} from 'semantic-ui-react';

const exampleReducer = (state, action, setDataFiles) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { open: true, dimmer: action.dimmer };
    case 'CLOSE_MODAL':
      return { open: false };
    default:
      throw new Error();
  }
};

const GridDetails = ({ formik }) => {
  let { values } = formik;
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [gridApi, setGridApi] = useState();
  let [state, dispatch] = React.useReducer(exampleReducer, {
    open: false,
    dimmer: undefined,
  });
  let { open, dimmer } = state;

  const [noParte, setNoParte] = useState();
  const [tipoDeCaso, setTipoDeCaso] = useState();
  const [primerCaso, setPrimerCaso] = useState();
  const [observaciones, setObservaciones] = useState();
  const [marca, setMarca] = useState();
  const [modelo, setModelo] = useState();
  const [subModelo, setSubModelo] = useState();
  const [numeroSerie, setNumeroSerie] = useState();
  // const [values, setValues] = useState({
  //   Pais: '',
  //   Estado: '',
  //   Municipio: ''
  // });

  // const cellEditorSelector = (params) => {
  //   return {
  //     component: 'dropDownCellRenderer',
  //     params:params,
  //     // component: 'agRichSelectCellEditor',
  //     params: { datosGenerales: datosGenerales, setDatosGenerales: setDatosGenerales, params:params },
  //     popup: true,
  //   };
  // };

  const handleClick = (params) => {
    //console.log("params",params.data.casosPartidas)
    let casosPartidas = params.data.casosPartidas;
    //console.log(casosPartidas)
    setNoParte(params.data.noParte);
    if (casosPartidas.tipoDeCaso) setTipoDeCaso(casosPartidas.tipoDeCaso);
    if (casosPartidas.primerCaso) setPrimerCaso(casosPartidas.primerCaso);
    if (casosPartidas.observaciones)
      setObservaciones(casosPartidas.observaciones);
    if (casosPartidas.marca) setMarca(casosPartidas.marca);
    if (casosPartidas.modelo) setModelo(casosPartidas.modelo);
    if (casosPartidas.subModelo) setSubModelo(casosPartidas.subModelo);
    if (casosPartidas.numeroSerie) setNumeroSerie(casosPartidas.numeroSerie);

    dispatch({ type: 'OPEN_MODAL' });
  };
  const handleCancel = async () => {
    let mercancias = values.mercancias;
    mercancias.map((current) => {
      if (current.noParte === noParte) {
        if (current.casosPartidas.tipoDeCaso)
          setTipoDeCaso(current.casosPartidas.tipoDeCaso);
        if (current.casosPartidas.primerCaso)
          setPrimerCaso(current.casosPartidas.primerCaso);
        if (current.casosPartidas.observaciones)
          setObservaciones(current.casosPartidas.observaciones);
        if (current.casosPartidas.marca) setMarca(current.casosPartidas.marca);
        if (current.casosPartidas.modelo)
          setModelo(current.casosPartidas.modelo);
        if (current.casosPartidas.subModelo)
          setSubModelo(current.casosPartidas.subModelo);
        if (current.casosPartidas.numeroSerie)
          setNumeroSerie(current.casosPartidas.numeroSerie);
      }
    });
    setTipoDeCaso('');
    setPrimerCaso('');
    setObservaciones('');
    setMarca('');
    setModelo('');
    setSubModelo('');
    setNumeroSerie('');

    dispatch({ type: 'CLOSE_MODAL' });
  };
  const handleSave = () => {
    let mercancias = values.mercancias;
    mercancias.map((current) => {
      if (current.noParte === noParte) {
        current.casosPartidas.tipoDeCaso = tipoDeCaso;
        current.casosPartidas.primerCaso = primerCaso;
        current.casosPartidas.observaciones = observaciones;
        current.casosPartidas.marca = marca;
        current.casosPartidas.modelo = modelo;
        current.casosPartidas.subModelo = subModelo;
        current.casosPartidas.numeroSerie = numeroSerie;
      }
    });
    setTipoDeCaso('');
    setPrimerCaso('');
    setObservaciones('');
    setMarca('');
    setModelo('');
    setSubModelo('');
    setNumeroSerie('');
    dispatch({ type: 'CLOSE_MODAL' });
  };

  const [columnDefs] = useState([
    // {
    //   headerName: 'Fraccion',
    //   field: 'fraccion',
    //   valueFormatter,
    //   cellRenderer: 'buttonCellRenderer',
    //   cellRendererParams: {handleEdit: handleClick, title: "editar"}
    // },
    {
      headerName: 'No. de Parte',
      field: 'noParte',
      valueFormatter,
      cellRendererFramework: (params) => {
        return (
          <EditButton
            handleEdit={() => {
              handleClick(params);
            }}
            title={params && params.data && params.data.noParte}
          />
        );
      },
    },
    {
      headerName: 'Fraccion',
      field: 'fraccion',
      valueFormatter,
    },
    {
      headerName: 'Descripcion',
      field: 'descripcion',
      valueFormatter,
    },
    {
      headerName: 'Valor Mercancia / Importe',
      field: 'valorMercancia',
      valueFormatter,
    },
    {
      headerName: 'Cantidad Comercial',
      field: 'cantidadComercial',
      valueFormatter,
    },
    {
      headerName: 'Unidad Medida Comercial',
      field: 'unidadMedidaComercial',
      valueFormatter,
    },
    {
      headerName: 'Cantidad Tarifa',
      field: 'cantidadTarifa',
      valueFormatter,
    },
    {
      headerName: 'Pais Origen/Destino',
      field: 'paisOrigen',
      valueFormatter,
    },
    {
      headerName: 'Pais Comprador/Vendedor',
      field: 'paisComprador',
      valueFormatter,
      //editable: true,
      // cellStyle: {
      //   'text-align': 'left',
      // },
      //cellRenderer: 'dropDownCellRenderer',
      // cellRendererParams: {
      //   placeholder:"País *",
      // featherService:"catalogo-pais",
      // columName:"Descripcion",
      // columValue:"c_Pais",
      // values:{values},
      // setValues:{setValues}
      // },
      //cellEditor: DropDownAG,
      //cellEditorSelector : cellEditorSelector
    },
    {
      headerName: 'Precio Unitario',
      field: 'precioUnitario',
      valueFormatter,
    },
    {
      headerName: 'Valor Aduana / Dolares',
      field: 'valorDolares',
      valueFormatter,
    },
    {
      headerName: 'UMT',
      field: 'umt',
      valueFormatter,
    },
    {
      headerName: 'Peso Neto',
      field: 'pesoNeto',
      valueFormatter,
    },
    {
      headerName: 'Peso Bruto',
      field: 'pesoNeto',
      valueFormatter,
    },
    {
      headerName: 'Orden de Compra',
      field: 'ordenCompra',
      editable: true,
      valueFormatter,
    },
    {
      headerName: 'Numero de Factura',
      field: 'numeroFactura',
      valueFormatter,
    },
  ]);
  const [defaultColDefs] = useState({
    filter: false,
    sortable: true,
    enableRowGroup: true,
    resizable: true,
    //flex: 1,
  });

  const handleGridReady = ({ api, columnApi }) => {
    setGridApi(api);
    setGridColumnApi(columnApi);
  };
  useEffect(() => {
    if (gridColumnApi) {
      var allColumnIds = gridColumnApi
        .getAllColumns()
        .filter(({ colDef: { field } }) => field !== '')
        .map(({ colId }) => colId);

      gridColumnApi.autoSizeColumns &&
        gridColumnApi.autoSizeColumns(allColumnIds, false);
    }
  }, [values, gridColumnApi]);

  return (
    <>
    <Segment>
    <Header as="h3">Registro de Partidas</Header>
      <TableContainer className="ag-theme-material" rowFilters={1}>
        <AgGridReact
          modules={AllModules}
          columnDefs={columnDefs}
          defaultColDef={defaultColDefs}
          rowSelection={'multiple'}
          suppressRowClickSelection={true}
          rowData={values.mercancias}
          pagination={true}
          paginationPageSize={100}
          onGridReady={handleGridReady}
          sortingOrder={['desc', 'asc', null]}
          //sortingOrder={['desc', 'asc', null]}
          //frameworkComponents={{
          //buttonCellRenderer: EditButton,
          //dropDownCellRenderer: DropDownAG
          //}}
        />
      </TableContainer>
      <EditModal
        dimmer={dimmer}
        open={open}
        title={noParte}
        handleCancel={handleCancel}
        handleSave={handleSave}
        size={'large'}
        tipoDeCaso={tipoDeCaso}
        setTipoDeCaso={setTipoDeCaso}
        primerCaso={primerCaso}
        setPrimerCaso={setPrimerCaso}
        observaciones={observaciones}
        setObservaciones={setObservaciones}
        marca={marca}
        setMarca={setMarca}
        modelo={modelo}
        setModelo={setModelo}
        subModelo={subModelo}
        setSubModelo={setSubModelo}
        numeroSerie={numeroSerie}
        setNumeroSerie={setNumeroSerie}
      />
      </Segment>
    </>
  );
};

GridDetails.propTypes = {
  data: PropTypes.array,
  onEditClick: PropTypes.func,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
};

export default GridDetails;
