import React, { useState, useEffect } from 'react';
import { Form, Segment, Header, Modal, Button, Table } from 'semantic-ui-react';
import DropdownSimpleFetch from '../../../components/Formik/DropdownSimpleFetch';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import feathers from '../../../services/feathers';

function FormAutotransporteRemolques({ formik, fetchOpt }) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSearchChange,
    getOptions,
    toggleSearch,
    fetchOptions,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = formik;
  const [configAutotransporte, setConfigAutotransporte] = useState({});

  useEffect(() => {
    const transp = (transporte) => {
      return transporte.c_ConfigAutotransporte === values.AutoConfigVehicular;
    };

    const fetchInitData = async () => {
      let options = await feathers
        .service('catalogo-config-autotransporte')
        .find();
      const findFetchOptions = options.data?.find(transp);

      setConfigAutotransporte(findFetchOptions);
      values.validationRemolque = findFetchOptions?.Remolque;
    };

    fetchInitData();
  }, [values.AutoConfigVehicular]);

  return (
    <div>
      {configAutotransporte?.Remolque?.includes('1') ? (
        <>
          <Header as="h5">Remolque 1</Header>
          <Form.Group>
            <Form.Input
              placeholder="Placa"
              label="Placa"
              name="PlacaR1"
              value={values.PlacaR1}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.PlacaR1 && touched.PlacaR1 && { content: errors.PlacaR1 }
              }
            />
            <DropdownSearchFetch
              fluid
              selection
              label="Remolque/Semiremolque"
              value={values.Remolque1}
              onChange={(e, { value }) => setFieldValue('Remolque1', value)}
              placeholder="Remolque/Semiremolque"
              featherService="catalogo-sub-tipo-rem"
              columName="RemSemiRem"
              columValue="c_SubTipoRem"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_SubTipoRem']} - ${value['RemSemiRem']}`,
                  value: value['c_SubTipoRem'],
                };
              }}
              error={
                errors.Remolque1 &&
                touched.Remolque1 && { content: errors.Remolque1 }
              }
            />
            <Form.Input
              placeholder="Alias"
              label="Alias"
              name="AliasR1"
              value={values.AliasR1}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.AliasR1 && touched.AliasR1 && { content: errors.AliasR1 }
              }
            />
          </Form.Group>
          <Header as="h5">Remolque 2</Header>
          <Form.Group>
            <Form.Input
              placeholder="Placa"
              label="Placa"
              name="PlacaR2"
              value={values.PlacaR2}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <DropdownSearchFetch
              fluid
              selection
              label="Remolque/Semiremolque"
              value={values.Remolque2}
              onChange={(e, { value }) => setFieldValue('Remolque2', value)}
              placeholder="Remolque/Semiremolque"
              featherService="catalogo-sub-tipo-rem"
              columName="RemSemiRem"
              columValue="c_SubTipoRem"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['c_SubTipoRem']} - ${value['RemSemiRem']}`,
                  value: value['c_SubTipoRem'],
                };
              }}
            />
            <Form.Input
              placeholder="Alias"
              label="Alias"
              name="AliasR2"
              value={values.AliasR2}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.AliasR2 && touched.AliasR2 && { content: errors.AliasR2 }
              }
            />
          </Form.Group>
        </>
      ) : null}
    </div>
  );
}

export default FormAutotransporteRemolques;
