import React from 'react';
import { Form, Segment, Header } from 'semantic-ui-react';
import DropdownPais from './DropdownPais';
import DropdownEstado from './DropdownEstado';
import DropdownMunicipio from './DropdownMunicipio';
import DropdownLocalidad from './DropdownLocalidad';
import DropdownCodigoPostal from './DropdownCodigoPostal';
import DropdownColonia from './DropdownColonia';

function FormGroupLocations({ formik, values, names, errors }) {
  let { handleChange, handleBlur } = formik;
  return (
    <>
      <Form.Group widths={'equal'}>
        <Form.Input
          placeholder="Calle"
          label="Calle *"
          name={names.Calle}
          value={values.Calle}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.Calle && {
              content: errors.Calle,
            }
          }
        />
        <Form.Input
          placeholder="Número Exterior"
          label="Número Exterior"
          name={names.NumExterior}
          value={values.NumExterior}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.NumExterior && {
              content: errors.NumExterior,
            }
          }
        />
        <Form.Input
          placeholder="Número Interior"
          label="Número Interior"
          name={names.NumInterior}
          value={values.NumInterior}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.NumInterior && {
              content: errors.NumInterior,
            }
          }
        />
        <Form.Input
          placeholder="Referencia"
          label="Referencia"
          name={names.Referencia}
          value={values.Referencia}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.Referencia && {
              content: errors.Referencia,
            }
          }
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        {names.Pais && (
          <DropdownPais
            formik={formik}
            values={values}
            names={names}
            errors={errors}
          />
        )}

        {names.Estado && (
          <DropdownEstado
            formik={formik}
            values={values}
            names={names}
            errors={errors}
          />
        )}

        {names.Municipio && (
          <DropdownMunicipio
            formik={formik}
            values={values}
            names={names}
            errors={errors}
          />
        )}

        {names.Localidad && (
          <DropdownLocalidad
            formik={formik}
            values={values}
            names={names}
            errors={errors}
          />
        )}

        {names.CodigoPostal && (
          <DropdownCodigoPostal
            formik={formik}
            values={values}
            names={names}
            errors={errors}
          />
        )}

        {names.Colonia && (
          <DropdownColonia
            formik={formik}
            values={values}
            names={names}
            errors={errors}
          />
        )}
      </Form.Group>
    </>
  );
}

export default FormGroupLocations;
