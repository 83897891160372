import React, { forwardRef, useEffect } from 'react';
import { Form, Segment, Header } from 'semantic-ui-react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import DropdownSimpleFetch from '../../../components/Formik/DropdownSimpleFetch';
import DropdownReceptores from '../../../components/Formik/DropdownReceptores';
import Dropdown from '../../../components/Formik/Dropdown';
import feathers from '../../../services/feathers';

const FormReceptor = ({ formik }) => {
  let { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    formik; //Extraer propiedades
  const serviceUsoCFDI = feathers.service('catalogo-uso-cfdi');
  const serviceMetodoPago = feathers.service('catalogo-metodo-pago');
  const serviceFormaPago = feathers.service('catalogo-forma-pago');
  const serviceReceptores = feathers.service('receptores');
  const countries = [ 'MEX', 'CAN', 'USA' ];

  useEffect(() => {
    if (values.idTipoComprobante === 'T') {
      getReceptor();
    }
  }, [values.idTipoComprobante]);

  const getReceptor = async () => {
    const dataReceptor = await serviceReceptores.find({
      query: { Nombre: values.EmisorNombre },
    });

    if (dataReceptor.data && dataReceptor.data.length > 0) {
      setFieldValue('idSocioNegocio', dataReceptor.data[0].id);
    }
  };

  const getUsoCFDI = async (value) => {
    if (value.idUsoCFDI) {
      let result = await serviceUsoCFDI.find({
        query: {
          CCFDIID: value.idUsoCFDI,
        },
      });
      if (result && result.data.length > 0) {
        value.idUsoCFDIString = result.data[0].c_UsoCFDI;
      }
    } else {
      value.idUsoCFDI = 23;
      value.idUsoCFDIString = 'S01';
    }
  };

  const getFormaPago = async (value) => {
    if (value.idFormaPago) {
      let result = await serviceFormaPago.find({
        query: {
          CFpagoID: value.idFormaPago,
        },
      });
      if (result) {
        value.idFormaPagoString = result.data[0].c_FormaPago;
      }
    } else {
      value.idFormaPago = 44;
      value.idFormaPagoString = '99';
    }
  };

  const getMetodoPago = async (value) => {
    if (value.idMetodoPago) {
      let result = await serviceMetodoPago.find({
        query: {
          CMpagoID: value.idMetodoPago,
        },
      });
      if (result) {
        value.idMetodoPagoString = result.data[0].c_MetodoPago;
      }
    } else {
      value.idMetodoPago = 4;
      value.idMetodoPagoString = 'PPD';
    }
  };

  return (
    <>
      <Segment>
        <Header as="h3">Receptor</Header>
        <Form.Group widths={'equal'}>
          {values.idTipoComprobante === 'I' ? (
            <DropdownReceptores
              fluid
              selection
              label="Nombre *"
              value={values.ReceptorNombre ? values.ReceptorNombre : ''}
              onChange={async (e, { value }, posData) => {
                setFieldValue('ReceptorNombre', value);
                for (let receptor of posData) {
                  if (receptor.Nombre === value) {
                    setFieldValue('idSocioNegocio', parseInt(receptor.id));
                    setFieldValue('ReceptorRFC', receptor.RFC);
                    setFieldValue('ReceptorCodPos', receptor.CodPos);
                    if (receptor && receptor.idRegimen) {
                      setFieldValue(
                        'ReceptorRegimenLey',
                        receptor.idRegimen.toString()
                      );
                    }
                    getUsoCFDI(receptor);
                    //getMetodoPago(receptor);
                    //getFormaPago
                    if (receptor.idMetodoPago) {
                      let result = await serviceMetodoPago.find({
                        query: {
                          CMpagoID: receptor.idMetodoPago,
                        },
                      });
                      if (result) {
                        receptor.idMetodoPagoString =
                          result.data[0].c_MetodoPago;
                      }
                    } else {
                      receptor.idMetodoPago = 4;
                      receptor.idMetodoPagoString = 'PPD';
                    }

                    if (receptor.idFormaPago) {
                      let result = await serviceFormaPago.find({
                        query: {
                          CFpagoID: receptor.idFormaPago,
                        },
                      });
                      if (result) {
                        receptor.idFormaPagoString = result.data[0].c_FormaPago;
                      }
                    } else {
                      receptor.idFormaPago = 44;
                      receptor.idFormaPagoString = '99';
                    }
                    setFieldValue('idUsoCFDI', receptor.idUsoCFDIString);
                    setFieldValue('idFormaPago', receptor.idFormaPagoString);
                    setFieldValue('idMetodoPago', receptor.idMetodoPagoString);
                    await Promise.resolve();
                  }
                }
              }}
              placeholder="Nombre"
              featherService="receptores"
              columName="Nombre"
              columValue="Nombre"
              mapValues={(value) => {
                return {
                  key: Math.random(),
                  text: `${value['Nombre']}`,
                  value: value['Nombre'],
                };
              }}
              error={
                errors.ReceptorNombre && {
                  content: errors.ReceptorNombre,
                }
              }
            />
          ) : (
            <Form.Input
              placeholder="Nombre"
              label="Nombre *"
              name="ReceptorNombre"
              value={
                values.EmisorNombre
                  ? (values.ReceptorNombre = values.EmisorNombre)
                  : ''
              }
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={values.idTipoComprobante === 'T' ? true : false}
              error={
                errors.ReceptorNombre &&
                touched.ReceptorNombre && {
                  content: errors.ReceptorNombre,
                }
              }
            />
          )}
          <Form.Input
            placeholder="RFC"
            label="RFC *"
            name="ReceptorRFC"
            value={
              values.idTipoComprobante === 'T' && values.EmisorRFC
                ? (values.ReceptorRFC = values.EmisorRFC)
                : values.ReceptorRFC
            }
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={values.idTipoComprobante === 'T' ? true : false}
            error={
              errors.ReceptorRFC &&
              touched.ReceptorRFC && { content: errors.ReceptorRFC }
            }
          />
          <DropdownSearchFetch
            fluid
            selection
            label="Regimen *"
            value={
              values.idRegimen && values.idTipoComprobante === 'T'
                ? (values.ReceptorRegimenLey = values.idRegimen)
                : values.ReceptorRegimenLey
            }
            onChange={(e, { value }) =>
              setFieldValue('ReceptorRegimenLey', value)
            }
            placeholder="Regimen"
            featherService="catalogo-regimen-fiscal"
            columName="Descripcion"
            columValue="c_RegimenFiscal"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_RegimenFiscal']} - ${value['Descripcion']}`,
                value: value['c_RegimenFiscal'],
              };
            }}
            disabled={values.idTipoComprobante === 'T' ? true : false}
            error={
              errors.ReceptorRegimenLey && {
                content: errors.ReceptorRegimenLey,
              }
            }
          />
          <DropdownSearchFetch
            fluid
            selection
            label="Código Postal *"
            value={
              values.LugarExpedicion && values.idTipoComprobante === 'T'
                ? (values.ReceptorCodPos = values.LugarExpedicion)
                : ( countries.includes( values.PaisOrigenDestino ) ? values.ReceptorCodPos : values.LugarExpedicion  )
            }
            onChange={(e, { value }) => setFieldValue('ReceptorCodPos', value)}
            placeholder="Código Postal"
            featherService="catalogo-codigo-postal"
            columName="c_CodigoPostal"
            columValue="c_CodigoPostal"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_CodigoPostal']}`,
                value: value['c_CodigoPostal'],
              };
            }}
            disabled={ countries.includes( values.PaisOrigenDestino ) ? false : true }
            error={
              errors.ReceptorCodPos && {
                content: errors.ReceptorCodPos,
              }
            }
          />
        </Form.Group>

        <Form.Group widths={'equal'}>
          <DropdownSearchFetch
            fluid
            selection
            label="Uso Comprobante *"
            value={
              values.idUsoCFDI
                ? (values.idUsoCFDI = values.idUsoCFDI)
                : (values.idUsoCFDI = 'G03')
            }
            onChange={(e, { value }) => setFieldValue('idUsoCFDI', value)}
            placeholder="Uso Comprobante"
            featherService="catalogo-uso-cfdi"
            columName="Descripcion"
            columValue="c_UsoCFDI"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_UsoCFDI']} - ${value['Descripcion']}`,
                value: value['c_UsoCFDI'],
              };
            }}
            disabled={values.idTipoComprobante === 'T' ? true : false}
            error={errors.idUsoCFDI && { content: errors.idUsoCFDI }}
          />
          <DropdownSearchFetch
            fluid
            selection
            label="Forma de Pago *"
            value={
              values.idTipoComprobante === 'T'
                ? (values.idFormaPago = '99')
                : values.idFormaPago
            }
            onChange={(e, { value }) => setFieldValue('idFormaPago', value)}
            placeholder="Forma de Pago"
            featherService="catalogo-forma-pago"
            columName="Descripcion"
            columValue="c_FormaPago"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_FormaPago']} - ${value['Descripcion']}`,
                value: value['c_FormaPago'],
              };
            }}
            disabled={values.idTipoComprobante === 'T' ? true : false}
            error={errors.idFormaPago && { content: errors.idFormaPago }}
          />
          <DropdownSearchFetch
            fluid
            selection
            label="Método de Pago *"
            value={
              values.idTipoComprobante === 'T' //Este value viene del input props
                ? (values.idMetodoPago = 'PPD')
                : values.idMetodoPago
            }
            onChange={(e, { value }) => setFieldValue('idMetodoPago', value)}
            placeholder="Método de Pago"
            featherService="catalogo-metodo-pago"
            columName="Descripcion"
            columValue="c_MetodoPago"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_MetodoPago']} - ${value['Descripcion']}`,
                value: value['c_MetodoPago'],
              };
            }}
            disabled={values.idTipoComprobante === 'T' ? true : false}
            error={errors.idMetodoPago && { content: errors.idMetodoPago }}
          />
          {
            values.idMetodoPago !== 'PUE' ? (
              <Form.Input
                placeholder="Condición"
                label="Condición *"
                name="Condicion"
                value={(values.Condicion = 'CREDITO')}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={values.idTipoComprobante === 'T' ? true : false}
                error={
                  errors.Condicion &&
                  touched.Condicion && { content: errors.Condicion }
                }
              />
            ) : (
              <Form.Input
                placeholder="Condición"
                label="Condición *"
                name="Condicion"
                value={(values.Condicion = 'CONTADO')}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={values.idTipoComprobante === 'T' ? true : false}
                error={
                  errors.Condicion &&
                  touched.Condicion && { content: errors.Condicion }
                }
              />
            )

            //   <Dropdown
            //     fluid
            //     selection
            //     label="Condición *"
            //     value={values.Condicion}
            //     onChange={(e, { value }) => {
            //       setFieldValue(`Condicion`, value);
            //     }}
            //     placeholder="Condición"
            //     options={[
            //       {
            //         key: 0,
            //         value: 'CREDITO',
            //         text: 'CREDITO',
            //       },
            //       {
            //         key: 1,
            //         value: 'CONTADO',
            //         text: 'CONTADO',
            //       },
            //     ]}
            //     onBlur={handleBlur}
            //     error={errors.Condicion && { content: errors.Condicion }}
            //   />
            // )
          }
        </Form.Group>
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormReceptor {...props} innerRef={ref} />
));
