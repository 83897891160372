import { authentication } from '@feathersjs/client';
import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Label } from 'semantic-ui-react';
import feathers, { useAuth } from '../../services/feathers';

/**
 * Search Fetch DropDown
 * 
  <DropdownSearchFetch
    fluid
    selection
    label="Tipo de Comprobante"
    value={values.idTipoComprobante}
    onChange={(e, { value }) =>
      setFieldValue('idTipoComprobante', value)
    }
    placeholder="Tipo de Comprobante"
    featherService="catalogo-tipo-de-comprobante"
    columName="Descripcion"
    columValue="c_TipoDeComprobante"
  />
 */

const DropDownSearchTrip = ({
  value,
  label,
  onChange,
  featherService,
  columName,
  columValue,
  mapValues,
  error,
  clearable,
}) => {
  let service = feathers.service(featherService);
  const auth = useAuth();

  let [isFetching, setIsFetching] = useState(true);
  let [options, setOptions] = useState([]);

  const mapValuesDefault = (value) => {
    return {
      key: Math.random(),
      text: value[columName],
      value: value[columValue],
    };
  };

  const handleSearchChange = (e, { searchQuery }) => {
    fetchOptions(searchQuery);
  };

  useEffect(() => {
    const fetchInitdata = async () => {
      let options;
      if (value) {
        let resultValue = await service.find({
          query: {
            [columValue]: { $like: value },
            PreparerID: parseInt(auth.user.client.optima.preparer),
            TripTypeCode: 'R',
            TripStatusCode: 'A',
            $limit: 10,
          },
        });

        options = resultValue.data.map(mapValues || mapValuesDefault);
      } else {
        let resultOptions = await service.find({
          query: {
            PreparerID: parseInt(auth.user.client.optima.preparer),
            TripTypeCode: 'R',
            TripStatusCode: 'A',
            $limit: 10,
            $sort: {
              TripId: -1
            }
          },
        });
        // console.log(resultOptions)
        options = resultOptions.data.map(mapValues || mapValuesDefault);
      }

      setOptions(options);
      setIsFetching(false);
    };
    fetchInitdata();
  }, [columName, columValue, service, value]);

  const fetchOptions = async (searchQuery) => {
    setIsFetching(true);
    let options;
    if (searchQuery.length > 2) {
      let resultOptions = await service.find({
        query: {
          $or: [
            { [columName]: { $like: '%' + searchQuery + '%' } },
            { [columValue]: { $like: '%' + searchQuery + '%' } },
          ],
          PreparerID: parseInt(auth.user.client.optima.preparer),
          TripTypeCode: 'R',
          TripStatusCode: 'A',
          $limit: 10,
        },
      });

      options = resultOptions.data.map(mapValues || mapValuesDefault);
    } else {
      let resultOptions = await service.find({
        query: {
          PreparerID: parseInt(auth.user.client.optima.preparer),
          TripTypeCode: 'R',
          TripStatusCode: 'A',
          $limit: 10,
        },
      });

      options = resultOptions.data.map(mapValues || mapValuesDefault);
    }

    setOptions(options);
    setIsFetching(false);
  };

  return (
    <Form.Field>
      <label>{label}</label>
      <Dropdown
        clearable
        fluid
        selection
        search={true}
        options={options}
        value={value}
        onChange={onChange}
        onSearchChange={handleSearchChange}
        // disabled={isFetching}
        loading={isFetching}
        error={error}
      />
      {error && (
        <Label pointing prompt>
          {error.content}
        </Label>
      )}
    </Form.Field>
  );
};

export default DropDownSearchTrip;
