import React, { forwardRef, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import FormHeader from './FormHeader';
import FormEmisor from './FormEmisor';
import FormReceptor from './FormReceptor';
import FormConcepts from './FormConcepts';
import FormUbicaciones from './FormUbicaciones';
import FormAutotransporte from './FormAutotransporte';
import FormFiguraTransporte from './FormFiguraTransporte';
import { FormRelation } from './FormRelation';

const FormDocument = ({
  currentUser,
  onSave,
  setIsValid,
  innerRef,
  formik,
  isCartaPorte,
  isMaterialPeligroso,
  setIsCartaPorte,
  setIsMaterialPeligroso,
  isEdit,
  data,
  setQuery,
  handleRelated,
}) => {
  return (
    <WrapContent>
      <FormHeader
        formik={formik}
        isCartaPorte={isCartaPorte}
        setIsCartaPorte={setIsCartaPorte}
        isEdit={isEdit}
      />
      {/* Upload to wrapper */}
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} style={{ marginBottom: 12 }}>
            <FormEmisor formik={formik} />
          </Grid.Column>
          <Grid.Column width={16}>
            <FormReceptor formik={formik} />{' '}
            {/* Enviando laa formik props al componente hijo */}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {isCartaPorte && (
        <>
          <FormUbicaciones formik={formik} />
          <FormFiguraTransporte formik={formik} />
          <FormAutotransporte
            formik={formik}
            isMaterialPeligroso={isMaterialPeligroso}
          />
        </>
      )}

      {/* {
        isEdit && ( <FormRelation formik={formik} data = {data} setQuery = { setQuery } /> )
      } */}

      <FormRelation
        data={data}
        setQuery={setQuery}
        handleRelated={handleRelated}
        formik={formik}
      />
      <FormConcepts
        formik={formik}
        isCartaPorte={isCartaPorte}
        isMaterialPeligroso={isMaterialPeligroso}
        setIsMaterialPeligroso={setIsMaterialPeligroso}
      />
    </WrapContent>
  );
};

const WrapContent = styled.div``;

export default forwardRef((props, ref) => (
  <FormDocument {...props} innerRef={ref} />
));
