import React, { forwardRef, useState } from 'react';
import {
  Form,
  Segment,
  Header,
  Table,
  Label,
  Modal,
  Button,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { Formik, Field, FieldArray } from 'formik';
import FormFiguraTransporteChofer from './FormFiguraTransporteChofer';
import DropdownFiguraTransporte from './DropdownFiguraTransporte';

const FormFiguraTransporte = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    /* and other goodies */
  } = formik;

  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const handleEdit = (index) => {
    setIndex(index);
    setOpen(true);
  };

  return (
    <>
      <Segment>
        <Header as="h3">Figura Transporte</Header>
        <FieldArray
          name="figuraTransportes"
          render={(arrayHelpers) => (
            <div>
              <Button
                type="button"
                content="Agregar Figura Transporte"
                primary
                onClick={() => (
                  arrayHelpers.push(''),
                  handleEdit(values?.figuraTransportes?.length)
                )}
              >
                {/* show this when user has removed all friends from the list */}
              </Button>
            </div>
          )}
        />
        {values.figuraTransportes.length !== 0 ? (
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing>Chofer</Table.HeaderCell>
                <Table.HeaderCell>Nombre del chofer</Table.HeaderCell>
                <Table.HeaderCell collapsing>Acciones</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {values.figuraTransportes.map((figuraTransporte, index) => (
              <Table.Body key={index}>
                <Table.Row
                  error={
                    errors.figuraTransportes &&
                    touched.figuraTransportes &&
                    errors.figuraTransportes[index]
                  }
                >
                  <Table.Cell textAlign="center">{index + 1}</Table.Cell>
                  <Table.Cell>{figuraTransporte?.NombreFigura}</Table.Cell>
                  <Table.Cell>
                    <p style={{ whiteSpace: 'nowrap' }}>
                      <a
                        style={{ cursor: 'pointer', color: '#438D9B' }}
                        onClick={() => handleEdit(index)}
                      >
                        Editar
                      </a>
                      {' | '}
                      <FieldArray
                        name="figuraTransportes"
                        render={(arrayHelpers) => (
                          <a
                            style={{
                              marginTop: '2px',
                              color: 'red',
                              cursor: 'pointer',
                            }}
                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                          >
                            Eliminar
                          </a>
                        )}
                      />
                    </p>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ))}
          </Table>
        ) : null}

        <ModalFiguraTransporte
          open={open}
          setOpen={setOpen}
          formik={formik}
          index={index}
        />
      </Segment>
    </>
  );
};

const ModalFiguraTransporte = ({ open, setOpen, formik, index }) => {
  let { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    formik;

  const handleValidate = () => {
    formik.validateForm().then((errors) => {
      formik.setTouched({ ...formik.touched, ...errors });
    });
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size={'fullscreen'}
    >
      <Modal.Header> Figura Transporte {index + 1} </Modal.Header>
      <Modal.Content>
        <FormFiguraTransporteChofer
          formik={formik}
          index={index}
          figuraTransporte={values.figuraTransportes[index]}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="button"
          content="Cancelar"
          color="red"
          labelPosition="right"
          icon="cancel"
          onClick={() => setOpen(false)}
        />
        <Button
          content="Cerrar y guardar"
          labelPosition="right"
          icon="checkmark"
          onClick={() => (handleValidate(), setOpen(false))}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

const WrapContent = styled.div``;

export default forwardRef((props, ref) => (
  <FormFiguraTransporte {...props} innerRef={ref} />
));
