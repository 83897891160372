import React, { useState } from 'react';
import feathers, { useAuth } from '../../services/feathers';
import swal from 'sweetalert';
import { Button } from 'semantic-ui-react';

function TableButtonCancelar(props) {
  let { id, idPOS, idSucursal, getData } = props;
  const auth = useAuth();
  const [cancelado, setCancelado] = useState(false);
  const [loadingCancelar, setLoadingCancelar] = useState(false);

  const Cancelar = async (id, idPOS, idSucursal) => {
    setLoadingCancelar(true);
    let service = feathers.service('pos');
    let serviceCancelar = feathers.service('cancelar-cfdi');
    
    let resultPos = await service.find({ query: { idPOS, idSucursal } });
    resultPos = (resultPos.data.length && resultPos.data[0]) || null;

    if (resultPos) {
      let idCertificado = resultPos.idCertificado;
      let idPAC = resultPos.idPAC;
      let resultCancelado = await serviceCancelar.create({
        idDocumento: id,
        idCertificado: idCertificado,
        idPAC: idPAC,
        sUser: auth.user._id,
      });

      if (
        resultCancelado &&
        resultCancelado.resultCancelado.errors &&
        resultCancelado.resultCancelado.errors.length
      ) {
        setLoadingCancelar(false);
        swal('Error', resultCancelado.resultCancelado.errors[0], 'error');
      } else {
        setLoadingCancelar(false);
        swal('Cancelado', '¡Se ha cancelado correctamente!', 'success');
        getData();
        setCancelado(true);
      }

      // alert(id);
    } else {
      setLoadingCancelar(false);
      swal('Error', 'No hay POS', 'error');
    }
  };

  return (
    <Button.Group>
      <Button
        compact
        primary
        loading={loadingCancelar}
        onClick={() => Cancelar(id, idPOS, idSucursal)}
      >
        Cancelar
      </Button>
    </Button.Group>
  );
}

export default TableButtonCancelar;
