import React, { forwardRef, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { Dropzone, FileItem } from '@dropzone-ui/react';

function exampleReducer(state, action) {
	switch (action.type) {
		case 'OPEN_MODAL':
			return { open: true, dimmer: action.dimmer };
		case 'CLOSE_MODAL':
			return { open: false };
		default:
			throw new Error();
	}
}

const ModalLlave = ({ formik }) => {
	let { values, errors, touched, handleChange, handleBlur, setFieldValue } = formik;

	let [ state, dispatch ] = React.useReducer(exampleReducer, {
		open: false,
		dimmer: undefined
	});

	let { open, dimmer } = state;

	const [ files, setFiles ] = useState([]);
	const updateFiles = (incommingFiles) => {
		if (incommingFiles.length) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const data = e.target.result;
				const base64File = data.split(',')[1];
				if (base64File) {
					formik.values.KeyBase64 = base64File;
				}
			};
			reader.readAsDataURL(incommingFiles[0].file);
		}
		setFiles(incommingFiles);
	};
	const handleDelete = (id) => {
		setFiles(files.filter((x) => x.id !== id));
	};

	return (
		<div>
			<div style={{ marginBottom: 10 }}>
				<Button onClick={() => dispatch({ type: 'OPEN_MODAL' })} type="button">
					Cargar Llave
				</Button>
			</div>

			<Modal dimmer={dimmer} open={open} onClose={() => dispatch({ type: 'CLOSE_MODAL' })}>
				<Modal.Header>Importar Llave</Modal.Header>
				<Modal.Content>
					<Dropzone
						onChange={updateFiles}
						value={files}
						onClean
						accept={'.key'}
						label={'Arrastrar aquí o dar click para seleccionar. '}
						minHeight={'195px'}
						maxHeight={'500px'}
						disableScroll
					>
						{files.map((file) => (
							<FileItem
								{...file}
								key={file.id}
								onDelete={handleDelete}
								alwaysActive
								preview
								info
								resultOnTooltip
							/>
						))}
					</Dropzone>
				</Modal.Content>
				<Modal.Actions>
					<Button negative onClick={() => dispatch({ type: 'CLOSE_MODAL' })}>
						Cancelar
					</Button>
					<Button
						positive
						onClick={() => {
							dispatch({ type: 'CLOSE_MODAL' });
							if (files.length) {
								formik.values.Key = files[0];
							}
						}}
					>
						Importar
					</Button>
				</Modal.Actions>
			</Modal>
		</div>
	);
};

export default forwardRef((props, ref) => <ModalLlave {...props} innerRef={ref} />);
