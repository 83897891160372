import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form as SemanticForm } from 'semantic-ui-react';
import DrawerFiltersWrapper from '../../components/DrawerFiltersWrapper';
import ButtonsFilterComprobantePago from '../../components/ButtonsFilterComprobantePago';
import feathers from '../../services/feathers';
import { useAuth } from '../../services/feathers';
import DropdownReceptores from '../../components/Formik/DropdownReceptores';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import swal from 'sweetalert';

const queryInit = {
  idSucursal: 0,
  idSocioNegocio: 0,
  FechaMax: '0000-00-00 00:00:00.000',
};

const reducerQuery = (prevState, { name, value, type, query }) => {
  if (type === 'init' && query) {
    return query;
  } else if (name) {
    return { ...prevState, [name]: value };
  }

  return prevState;
};

const Filters = ({ isBusy, query: base, setQuery }) => {
  var today = new Date();
  var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const [query, dispatchQuery] = useReducer(reducerQuery, { ...queryInit });
  const [startDate, setStartDate] = useState(lastDayOfMonth);
  const [receptorNombre, setReceptorNombre] = useState('');
  const auth = useAuth();

  // useEffect(() => {
  //   // const sendQuery = () => {
  //   //   handleSearchClick();
  //   // };
  //   // sendQuery();
  // }, []);

  const handleChangeInput = (_, { name, value }) =>
    dispatchQuery({ name, value });

  const handleSearchClick = async (event, data) => {
    if (query && query.ReceptorNombre) {
      let resultPOS = await feathers
        .service('pos')
        .find({ query: { Nombre: auth.user.client.name } });

      // let resultReceptor = await feathers
      //   .service('receptores')
      //   .find({ query: { id: query.ReceptorNombre.id } });
      // console.log('resultReceptor--->', resultReceptor);

      query.idSocioNegocio = parseInt(query.ReceptorNombre.id);
      query.idSucursal = resultPOS.data[0].idSucursal;
      query.FechaMax = moment(startDate).format('YYYY-MM-DD HH:mm:ss.SSS');

      const queryMap = {
        //fuzzy: true,
        ...(query && { query }),
      };
      setQuery && setQuery(queryMap);
    } else {
      swal('Error', '¡Se debe seleccionar un receptor!', 'error');
    }
  };

  return (
    <>
      <DrawerFiltersWrapper>
        <FiltersGrid>
          <SemanticForm>
            <SemanticForm.Group widths={'equal'}>
              <DropdownReceptores
                fluid
                selection
                label="Nombre Receptor*"
                value={receptorNombre}
                onChange={async (e, { value }) => {
                  query.ReceptorNombre = value;
                  setReceptorNombre(value);
                }}
                placeholder="Nombre Receptor"
                featherService="receptores"
                columName="Nombre"
                columValue="Nombre"
                mapValues={(value) => {
                  return {
                    key: Math.random(),
                    text: `${value['Nombre']}`,
                    value: value,
                  };
                }}
              />
              <div className="field">
                <label>
                  <b>Fecha *</b>
                </label>
                <DatePicker
                  selected={startDate}
                  onChange={(data) => {
                    setStartDate(data);
                  }}
                  timeInputLabel="Hora:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  name="FechaMax"
                  value={startDate}
                />
              </div>
            </SemanticForm.Group>
          </SemanticForm>

          <ButtonsFilterComprobantePago
            isBusy={isBusy}
            onSearchClick={handleSearchClick}
          />
          
        </FiltersGrid>
      </DrawerFiltersWrapper>
    </>
  );
};

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: 'search search . buttons';
`;

Filters.propTypes = {
  isBusy: PropTypes.bool,
  onSearch: PropTypes.func,
};

export default Filters;
