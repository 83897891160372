import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TopNavigation from './TopNavigation';
import HeaderBG from '../../components/HeaderBG';
import SectionTitle from '../../components/SectionTitle';
import Filters from './Filters';
import TableNavigation from './TableNavigation';
import Table from './Table';
import { Container, Breadcrumb } from 'semantic-ui-react';
import feathers, { useAuth } from '../../services/feathers';
import swal from 'sweetalert';

const ReportesTimbrado = (props) => {
  const service = feathers.service('documentos');
  const auth = useAuth();
  const [currentDocumentId, setCurrentDocumentId] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [data, setData] = useState([]);
  const [selection, setSelection] = useState([]);
  const [isSupport, setIsSupport] = useState(false);
  const [client, setClient] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [query, setQuery] = useState(null);

  // Effects
  useEffect(() => {
    const filter = () => {
      if (query) {
        getData();
      }
    };

    filter();
  }, [query]);

  useEffect(() => {
    const { isSupport } = (auth && auth.user) || {},
      client = (auth && auth.user && auth.user.client) || null,
      isInvalid = (!isSupport && !client && true) || false;

    setIsSupport(isSupport);
    setClient(client);
    setIsValid(!isInvalid);
  }, [auth.user]);

  // Functions
  const getData = async () => {
    if (query || auth.user) {
      let data = [],
        result = [];

      try {
        setIsBusy(true);
        setSelection([]);
        do {
          if (auth.user) {
            result = await service.find({
              query: {
                Organizacion: auth.user.client._id,
                ...query,
                $sort: { id: -1 },
                $skip: data.length,
              },
            });
          }

          data = data.concat(result.data);
        } while (data.length !== result.total);
      } catch (err) {
        console.error('Error', err);
        data = [];

        swal({
          title: 'Error',
          text: 'Failed get ReportesTimbrado',
          icon: 'error',
          buttons: {
            cancel: 'Close',
          },
        });
      } finally {
        setData(data);
        setIsBusy(false);
      }
    }
  };

  const openDrawer = (documentId) => {
    setCurrentDocumentId(documentId);
  };

  const getClientName = () =>
    (auth && auth.user && auth.user.client && auth.user.client.name) || null;

  return (
    <>
      <DefaultLayout
        currentPath={props.match.path}
        // globalActions={
        //   <TopNavigation
        //     isBusy={isBusy}
        //     onCreateClick={handleCreateClick}
        //     isValid={isValid}
        //   />
        // }
      >
        <HeaderBG />

        <Container className="fluid-95">
          <Breadcrumb
            icon="right angle"
            sections={[
              { key: getClientName(), content: getClientName(), link: false },
              // { key: 'Billing MX', content: 'Billing MX', link: false },
              {
                key: 'ReportesTimbrado',
                content: 'Billing MX',
                active: true,
              },
            ]}
          />
          <Filters
            isBusy={isBusy}
            setQuery={setQuery}
            isValid={isValid}
            isSupport={isSupport}
            client={client}
          />
          {/* 
          <TableNavigation
            isBusy={isBusy}
            haveSelected={selection.length > 0}
            onDeleteClick={handleDeleteClick}
            messageAction={messageAction}
          /> */}
          <Table
            data={data}
            selection={selection}
            setSelection={setSelection}
            isSupport={isSupport}
            getData={getData}
          ></Table>
        </Container>
      </DefaultLayout>
    </>
  );
};

export default ReportesTimbrado;
