import React from 'react';
import Routes from './Routes';
import { ProvideAuth } from './authentication';
import { licenseKey } from './/agGridLicense.js';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(licenseKey);

export default (props) => {
  return (
    <ProvideAuth>
      <Routes />
    </ProvideAuth>
  );
};
