import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Label } from 'semantic-ui-react';
import feathers from '../../services/feathers';
import { useAuth } from '../../services/feathers';

const DropdownConceptos = ({
  value,
  label,
  onChange,
  featherService,
  columName,
  columValue,
  mapValues,
  placeholder,
  error,
}) => {
  const auth = useAuth();
  let service = feathers.service(featherService);
  let [isFetching, setIsFetching] = useState(true);
  let [options, setOptions] = useState([]);
  let [posData, setPosData] = useState([]);

  const mapValuesDefault = (value) => {
    return {
      key: Math.random(),
      text: value[columName],
      value: value[columValue],
    };
  };
  useEffect(() => {
    const fetchInitdata = async () => {
      let id = auth && auth.user && auth.user.client && auth.user.client._id;
      let resultOptions = await service.find({
        query: { Organizacion: id },
      });
      let options = resultOptions.data.map(mapValues || mapValuesDefault);
      setOptions(options);
      setPosData(resultOptions.data);
      setIsFetching(false);
    };
    fetchInitdata();
  }, [columName, columValue, service]);

  const onChangeHandled = (e, data) => {
    onChange(e, data, posData);
  };

  return (
    <Form.Field>
      <label>{label}</label>
      <Dropdown
        clearable
        fluid
        selection
        search={true}
        options={options}
        value={value}
        onChange={onChangeHandled}
        loading={isFetching}
        error={error}
        placeholder={placeholder}
      />
      {error && (
        <Label pointing prompt>
          {error.content}
        </Label>
      )}
    </Form.Field>
  );
};

export default DropdownConceptos;
