import React, { forwardRef, useState } from 'react';
import {
  Segment,
  Header,
  Label,
  Table,
  Modal,
  Button,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import FormConcept from './FormConcept';

const FormConcepts = ({
  formik,
  isCartaPorte,
  isMaterialPeligroso,
  setIsMaterialPeligroso,
}) => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  let { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    formik;

  const handleEdit = (index) => {
    setIndex(index);
    setOpen(true);
  };

  return (
    <>
      <Segment>
        <Header as="h3">Conceptos</Header>
        <FieldArray
          name="conceptos"
          render={(arrayHelpers) => (
            <div>
              <Button
                type="button"
                content="Agregar Concepto"
                primary
                onClick={() => (
                  arrayHelpers.push({
                    CartaPorte: isCartaPorte.toString(),
                    TipoConcepto: null,
                    Cantidad: 1.0,
                    idProducto: 0, //DONE Después
                    Descripcion: '',
                    PrecioUnitario: 0.0,
                    Subtotal: 0.0,
                    Descuento: 0.0, //DONE Disable cuando es Traslado
                    idImpuesto: 1, //Ingresos
                    TasaOCouta: 'Tasa', //Ingresos
                    idTipoTax: 1, //Ingresos
                    ImporteBase: 0.0, //Ingresos
                    Valor: 16.0, //Ingresos
                    Importe: 0.0, //Ingresos
                    CodigoSAT: '002', //Ingresos
                    ImpuestosTraslados: 0.0, //Para ingresos
                    ImpuestosRetenciones: 0.0, //Para ingresos
                    Total: 0.0,
                    PerfilImpuestos: 0,
                    idReferencia: 0, //DONE Sin uso
                    idReferenciaModulo: 0, //DONE Sin uso
                    TipoConcepto: 0,
                    ClaveProdServ: '',
                    CuentaPredial: '', //IDEA después
                    NumeroIdentificacion: '', //IDEA después
                    ClaveUnidad: 'E48', //DONE
                    UnidadMedida: 'Unidad de servicio',
                    ValorMercancia: 0.0, //DONE Carta Porte
                    PesoEnKg: 0.0, //DONE Carta Porte
                    MonedaMercancia: 'USD', //DONE Carta Porte
                    cfdi_tipoRelacion: '', //IDEA Después
                    cfdi_tipoRelacionuuid: '', //IDEA Después
                    NumeroIdentificacionComercio: '', //IDEA Después - comercio exterior.
                    FraccionArancelariaComercio: '', //DONE Carta Porte
                    CantidadAduanaComercio: 0.0, //IDEA Después - comercio exterior.
                    UnidadAduanaComercio: '', //IDEA Después - comercio exterior.
                    ValorUnitarioAduanaComercio: 0.0, //IDEA Después - comercio exterior.
                    ValorDolaresComercio: 0.0, //IDEA Después - comercio exterior.
                    DescripcionIngles: '', //IDEA Después - comercio exterior.
                    cfdi_RelacionIdDoc: 0, //IDEA Después - complemento de pagos.
                    cfdi_RelacionNumParcialidad: 0, //IDEA Después - complemento de pagos.
                    cfdi_RelacionReferencia: '', //IDEA Después - complemento de pagos.
                    idDoc_Ref: 0, //IDEA Después - complemento de pagos.
                    CveMaterialPeligroso: '', //TODO Carta Porte - catalogo - requerido si es material peligroso en ClaveProdServ
                    Embalaje: '', //TODO Carta Porte - catalogo - requerido si es material peligroso en ClaveProdServ
                    EmbalajeDescripcion: '', //TODO Carta Porte - requerido si es material peligroso en ClaveProdServ
                    UUIDComercioExterior: '', //TODO Carta Porte - opcional
                    TipoMateria: '', //TODO Carta Porte - opcional
                    DescripcionMateria: '', //TODO Carta Porte - opcional
                    TipoDocumentoAduanero: '', //TODO Carta Porte - opcional
                    IdentificacionDocumentoAduanero: '', //TODO Carta Porte - opcional
                    RFCImportador: '', //TODO Carta Porte - opcional
                    Pedimento: '', //TODO Carta Porte - opcional con formato validado, si espacio deben ser 15, y si no con espacios lo que den.
                    Dimensiones: '', //TODO Carta Porte - opcional cuando es material peligroso.
                  }),
                  handleEdit(values?.conceptos?.length)
                )}
              >
                {/* show this when user has removed all friends from the list */}
              </Button>
            </div>
          )}
        />
        {values.conceptos.length !== 0 ? (
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing>Concepto</Table.HeaderCell>
                <Table.HeaderCell>Descripción</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" collapsing>
                  Acciones
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {values.conceptos.map((concepto, index) => (
              <Table.Body key={index}>
                <Table.Row
                  error={
                    errors.conceptos &&
                    touched.conceptos &&
                    errors.conceptos[index]
                  }
                >
                  <Table.Cell textAlign="center">{index + 1}</Table.Cell>
                  <Table.Cell>{concepto.Descripcion}</Table.Cell>
                  <Table.Cell>
                    <p style={{ whiteSpace: 'nowrap' }}>
                      <a
                        style={{ cursor: 'pointer', color: '#438D9B' }}
                        onClick={() => handleEdit(index)}
                      >
                        Editar
                      </a>
                      {' | '}
                      <FieldArray
                        name="conceptos"
                        render={(arrayHelpers) => (
                          <a
                            style={{
                              marginTop: '2px',
                              color: 'red',
                              cursor: 'pointer',
                            }}
                            onClick={() => arrayHelpers.remove(index)}
                            // remove a friend from the list
                          >
                            Eliminar
                          </a>
                        )}
                      />
                    </p>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ))}
          </Table>
        ) : null}

        <ModalConcept
          formik={formik}
          isCartaPorte={isCartaPorte}
          isMaterialPeligroso={isMaterialPeligroso}
          setIsMaterialPeligroso={setIsMaterialPeligroso}
          index={index}
          open={open}
          setOpen={setOpen}
        />
        {/* <FieldArray name="conceptos" render={(arrayHelpers) => <div></div>} /> */}
      </Segment>
    </>
  );
};

const ModalConcept = ({
  formik,
  isCartaPorte,
  isMaterialPeligroso,
  setIsMaterialPeligroso,
  index,
  setOpen,
  open,
}) => {
  let { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    formik;

  const handleValidate = () => {
    formik.validateForm().then((errors) => {
      formik.setTouched({ ...formik.touched, ...errors });
    });
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size={'fullscreen'}
    >
      <Modal.Header>Concepto {index + 1} </Modal.Header>
      <Modal.Content>
        <FormConcept
          formik={formik}
          isCartaPorte={isCartaPorte}
          isMaterialPeligroso={isMaterialPeligroso}
          setIsMaterialPeligroso={setIsMaterialPeligroso}
          concepto={values.conceptos[index]}
          index={index}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="button"
          content="Cancelar"
          color="red"
          labelPosition="right"
          icon="cancel"
          onClick={() => setOpen(false)}
        />
        <Button
          content="Cerrar y guardar"
          labelPosition="right"
          icon="checkmark"
          onClick={() => (handleValidate(), setOpen(false))}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

const WrapContent = styled.div``;

const BorderItem = styled.div`
  border: 1px solid #80808087;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
`;

export default forwardRef((props, ref) => (
  <FormConcepts {...props} innerRef={ref} />
));
