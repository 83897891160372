import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  //FormHeader
  tipoOperacion: Yup.string().required('Requerido'),
  claveDocumento: Yup.string().required('Requerido'),
  codigoImportador : Yup.string().required('Requerido').max(10, 'Máximo 10 caracteres'),
  pesoBruto : Yup.string().required('Requerido').max(14, 'Máximo 14 dígitos'),
  bultos : Yup.string().required('Requerido').max(12, 'Máximo 12 dígitos'),
  transporteSalida : Yup.string().required('Requerido'),
  destinoZona : Yup.string().required('Requerido'),
  // Form Transportista
  transportista : Yup.object().shape({
    codigoTransportista : Yup.string().required('Requerido').max(6, 'Máximo 6 caracteres'),
    idVehiculo: Yup.string().required('Requerido').max(17, 'Máximo 17 caracteres'),
    pais: Yup.string().required('Requerido'),
  }),
  // Form Factura
  factura : Yup.object().shape({
    codigoProveedor : Yup.string().required('Requerido').max(10, 'Máximo 10 caracteres'),
    observaciones: Yup.string().required('Requerido'),
    pesoBruto: Yup.string().required('Requerido').max(14, 'Máximo 14 dígitos'),
    pesoNeto: Yup.string().required('Requerido').max(14, 'Máximo 14 dígitos'),
    bultos: Yup.string().required('Requerido'),
    fletes: Yup.string().required('Requerido').max(14, 'Máximo 14 dígitos'),
    seguros: Yup.string().required('Requerido').max(14, 'Máximo 14 dígitos'),
    embalajes: Yup.string().required('Requerido').max(14, 'Máximo 14 dígitos'),
    otros: Yup.string().required('Requerido').max(14, 'Máximo 14 dígitos'),
    origenMercancia: Yup.string().required('Requerido'),
    descargoMercancia: Yup.string().required('Requerido'),
    destinoMercancia: Yup.string().required('Requerido'),
    codigoTipoBultos: Yup.string().required('Requerido').max(6, 'Máximo 6 caracteres'),
    tipoOperacion: Yup.string().required('Requerido'),
  }),
  // Form Partidas
  mercancias: Yup.array().of(
    Yup.object().shape({
      ordenCompra: Yup.string().required('Requerido').max(20, 'Máximo 20 dígitos')
    })
  )
});

export default validationSchema;
