import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '../../components/TableContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, Logger } from '@ag-grid-enterprise/all-modules';
import 'lodash';
import TableButtonCancelar from './TableButtonCancelar';
import TableButtonPdf from './TableButtonPdf';
import TableButtonCancelarTimbrado from './TableButtonCancelarTimbrado';
import ReactLoading from 'react-loading';
import swal from 'sweetalert';
import feathers, { useAuth } from '../../services/feathers';
import initialValues from './Drawer/initialValues';
import styled from 'styled-components';
import ConfirmationModal from './ConfirmationModal';
import StatusModal from './StatusModal';
import {
  Button,
  Icon,
  Modal,
  Header,
  ModalHeader,
  ModalDescription,
  ModalContent,
  ModalActions,
  Radio,
} from 'semantic-ui-react';
import {
  valueFormatter,
  dateTimeCartaPorte,
} from '../../components/AgGridColumns';

const Table = ({ data, onEditClick, selection, setSelection, getData }) => {
  const auth = useAuth();
  const servicePDF = feathers.service('export-pdf-cartaporte');
  const serviceDocumentosCartaPorte = feathers.service(
    'documentos-carta-porte'
  );
  const serviceDocumentosCartaPorteAutos = feathers.service(
    'documentos-carta-porte-autos'
  );
  const serviceDocumentosCartaPorteAutosRemolques = feathers.service(
    'documentos-carta-porte-autos-remolques'
  );
  const serviceDocumentosCartaPorteDomicilios = feathers.service(
    'documentos-carta-porte-domicilios'
  );
  const serviceDocumentosCartaPorteFigurasTransp = feathers.service(
    'documentos-carta-porte-figuras-transp'
  );
  const serviceDocumentosConceptos = feathers.service('documentos-conceptos');
  const serviceDocumentosPago = feathers.service('documentos-pagos');

  // States
  const [gridApi, setGridApi] = useState();
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [open, setOpen] = useState(null);
  const [dataCancel, setDataCancel] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalStatusOpen, setIsModalStatusOpen] = useState(false);
  const [confirmationData, setConfirmationData] = useState(null);
  const [dataStatus, setDataStatus] = useState(null);
  const [confirmationActionType, setConfirmationActionType] = useState(null);
  const [isPaid, setIsPaid] = useState(false);
  const [folio, setFolio] = useState(0);

  const [columnDefs] = useState([
    {
      headerName: 'Timbrado',
      field: 'cfd_Certificado',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      cellRendererFramework: (data) => {
        if (data.value) {
          return <Icon name="check" />;
        } else {
          return '';
        }
      },
    },

    {
      headerName: 'Id Interno',
      field: 'id',
      valueFormatter: (value) => {
        return Number(value.value);
      },
    },
    {
      headerName: 'Status',
      field: 'idStatus',
      valueFormatter: (val) => {
        return val.value == 1
          ? 'Preparado'
          : val.value == 3
          ? 'Emitido'
          : 'Cancelado';
      },
    },
    {
      headerName: 'Tipo de Comprobante',
      field: 'idTipoComprobante',
      valueFormatter,
    },
    {
      headerName: 'Serie',
      field: 'Serie',
      valueFormatter,
    },
    {
      headerName: 'Folio',
      field: 'Folio',
      valueFormatter,
    },
    {
      ...dateTimeCartaPorte,
      headerName: 'Fecha Documento',
      field: 'FechaDocumento',
    },

    {
      headerName: 'RFC Receptor',
      field: 'ReceptorRFC',
      valueFormatter,
    },
    {
      headerName: 'Receptor Nombre',
      field: 'ReceptorNombre',
      valueFormatter,
    },
    {
      headerName: 'Folio Fiscal (UUID)',
      field: 'timbre_uuid',
      valueFormatter,
    },
    {
      headerName: 'Fecha Timbrado',
      field: 'timbre_FechaTimbrado',
    },
    {
      headerName: 'Versión',
      field: 'timbre_version',
      valueFormatter,
    },
    {
      headerName: '',
      field: '',
      pinned: 'right',
      cellStyle: { textAlign: 'center' },
      width: 330,

      cellRendererFramework: (params) => {
        if (params.data)
          if (params.data.Importando)
            return (
              <ReactLoading
                type={'cylon'}
                color={'black'}
                height={30}
                width={30}
              />
            );
        return (
          <>
            {params.data.idStatus !== 3 && params.data.idStatus !== 2 && (
              <Button.Group>
                <Button
                  compact
                  primary
                  onClick={() => handleEditClick(params.data)}
                >
                  Editar
                </Button>

                <Button
                  compact
                  primary
                  onClick={() =>
                    handleOpenConfirmationModal(params.data, 'timbrar')
                  }
                >
                  Timbrar
                </Button>
              </Button.Group>
            )}

            <Button.Group>
              {params.data.cfd_xml && (
                <>
                  {params.data.idTipoComprobante !== 'P' && (
                    <Button
                      compact
                      primary
                      onClick={() => handleOpenStatusModal(params.data)}
                    >
                      Status
                    </Button>
                  )}
                  <Button
                    compact
                    primary
                    onClick={() => saveFileXML(params.data)}
                  >
                    XML
                  </Button>
                </>
              )}
              <TableButtonPdf data={params.data} servicePDF={servicePDF} />

              {/* <Button
                compact
                primary
                onClick={() => handleOpenConfirmationModal(params.data, 'pdf')}
              >
                PDF
              </Button> */}

              {/* Cancelar antes de timbrar */}
              {params.data.idStatus !== 2 &&
                params.data.idStatus !== 3 &&
                ((auth.user.roles.length > 0 &&
                  auth.user.roles.includes('ADMINISTRADOR_CLIENTE')) ||
                  auth.user.isSupport) && (
                  <TableButtonCancelar
                    id={params.data.id}
                    idPOS={params.data.idPOS}
                    idSucursal={params.data.idSucursal}
                    getData={getData}
                  />
                )}
              {/* Cancelar despues de timbrar*/}
              {params.data.idStatus &&
                params.data.idStatus === 3 &&
                ((auth.user.roles.length > 0 &&
                  auth.user.roles.includes('ADMINISTRADOR_CLIENTE')) ||
                  auth.user.isSupport) && (
                  <Button
                    compact
                    primary
                    onClick={() => handleOpenModal(params.data)}
                  >
                    Cancelar
                  </Button>
                )}
            </Button.Group>
          </>
        );
      },
    },
  ]);

  const [defaultColDefs] = useState({
    filter: false,
    sortable: true,
    enableRowGroup: true,
    resizable: true,
    width: 250,
  });

  useEffect(() => {
    if (
      selection &&
      !selection.length &&
      gridApi &&
      gridApi.getSelectedRows().length
    ) {
      gridApi.deselectAll();
    }
  }, [selection, gridApi]);

  const handleOptionChange = (e, { value }) => {
    setSelectedOption(value);
  };

  const handleClose = () => {
    setOpen(false);
    setIsPaid(false);
  };

  const handleOpenModal = async (data) => {
    await handlePayment( data );
    setOpen(true);
    setDataCancel(data);
  };

  const handleOpenConfirmationModal = (data, actionType) => {
    setConfirmationData(data);
    setIsModalOpen(true);
    setConfirmationActionType(actionType);
  };

  const handleOpenStatusModal = (data) => {
    setDataStatus(data);
    setIsModalStatusOpen(true);
  };

  const handleConfirm = () => {
    setIsModalOpen(false);
  };

  const saveFileXML = async (data) => {
    let parser = new DOMParser();
    let xml = parser.parseFromString(data.cfd_xml, 'application/xml');
    const serializer = new XMLSerializer();
    const xmlStr = serializer.serializeToString(xml);
    const url = window.URL.createObjectURL(new Blob([xmlStr]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      data.EmisorRFC +
        '_' +
        data.ReceptorRFC +
        '_' +
        data.Serie +
        data.Folio +
        '_' +
        data.timbre_uuid +
        '.xml'
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  useEffect(() => {
    if (gridColumnApi) {
      var allColumnIds = gridColumnApi
        .getAllColumns()
        .filter(({ colDef: { field } }) => field !== '')
        .map(({ colId }) => colId);

      gridColumnApi.autoSizeColumns &&
        gridColumnApi.autoSizeColumns(allColumnIds, false);
    }
  }, [data, gridColumnApi]);

  // Handles
  const handleEditClick = async (data) => {
    let cartaPorte = await serviceDocumentosCartaPorte.find({
      query: { id: data.id },
    });

    if (cartaPorte && cartaPorte.data.length > 0) {
      data.EntradaSalidaMerc = cartaPorte.data[0].EntradaSalidaMerc;
      data.PaisOrigenDestino = cartaPorte.data[0].PaisOrigenDestino
        ? cartaPorte.data[0].PaisOrigenDestino
        : 'USA';
      data.idCCP = cartaPorte.data[0].idCCP;
      data.RegimenAduanero = cartaPorte.data[0].RegimenAduanero;
    }

    //Autotransportes
    let autotransportes = await serviceDocumentosCartaPorteAutos.find({
      query: { id: data.id },
    });
    autotransportes = autotransportes.data[0];
    if (autotransportes) {
      data.AseguraCarga = autotransportes.AseguraCarga
        ? autotransportes.AseguraCarga
        : '';
      data.AseguraMedAmbiente = autotransportes.AseguraMedAmbiente
        ? autotransportes.AseguraMedAmbiente
        : '';
      data.AseguraRespCivil = autotransportes.AseguraRespCivil
        ? autotransportes.AseguraRespCivil
        : '';
      data.AutoAnioModeloVM = autotransportes.AutoAnioModeloVM
        ? autotransportes.AutoAnioModeloVM
        : '';
      data.AutoConfigVehicular = autotransportes.AutoConfigVehicular
        ? autotransportes.AutoConfigVehicular
        : '';
      data.AutoPlacaVM = autotransportes.AutoPlacaVM
        ? autotransportes.AutoPlacaVM
        : '';
      data.NumPermitoSCT = autotransportes.NumPermitoSCT
        ? autotransportes.NumPermitoSCT
        : '';
      data.PermSCT = autotransportes.PermSCT ? autotransportes.PermSCT : '';
      data.PolizaCarga = autotransportes.PolizaCarga
        ? autotransportes.PolizaCarga
        : '';
      data.PolizaMedAmbiente = autotransportes.PolizaMedAmbiente
        ? autotransportes.PolizaMedAmbiente
        : '';
      data.PolizaRespCivil = autotransportes.PolizaRespCivil
        ? autotransportes.PolizaRespCivil
        : '';
      data.PrimaSeguro = autotransportes.PrimaSeguro
        ? autotransportes.PrimaSeguro
        : 0.0;
      data.PesoBruto = autotransportes.PesoBrutoVehicular
        ? autotransportes.PesoBrutoVehicular
        : '';
    }

    //Remolques
    let remolques = await serviceDocumentosCartaPorteAutosRemolques.find({
      query: { id: data.id },
    });

    remolques = remolques.data;

    if (remolques) {
      for (let remolque of remolques) {
        if (remolque.idRow === 1) {
          data.PlacaR1 = remolque.Placa ? remolque.Placa : '';
          data.Remolque1 = remolque.SubtipoRem ? remolque.SubtipoRem : '';
          data.AliasR1 = remolque.Alias ? remolque.Alias : '';
        } else if (remolque.idRow === 2) {
          data.PlacaR2 = remolque.Placa ? remolque.Placa : '';
          data.Remolque2 = remolque.SubtipoRem ? remolque.SubtipoRem : '';
          data.AliasR2 = remolque.Alias ? remolque.Alias : '';
        }
      }
    }

    //Ubicaciones
    let ubicaciones = await serviceDocumentosCartaPorteDomicilios.find({
      query: { id: data.id },
    });
    ubicaciones = ubicaciones.data;
    data = { ...data, ubicaciones };

    //FiguraTransporte
    let figuraTransportes = await serviceDocumentosCartaPorteFigurasTransp.find(
      {
        query: { id: data.id },
      }
    );
    figuraTransportes = figuraTransportes.data;
    data = { ...data, figuraTransportes };

    //Conceptos
    let conceptos = await serviceDocumentosConceptos.find({
      query: { id: data.id },
    });
    conceptos = conceptos.data;
    for (let concepto of conceptos) {
      cartaPorte && cartaPorte.data.length > 0
        ? (concepto.CartaPorte = 'true')
        : (concepto.CartaPorte = 'false');
    }
    data = { ...data, conceptos };
    data.TipoViaje = 'Internacional';
    data.EntradaSalida = 'Entrada';
    //data.PaisOrigenDestino = 'USA';
    onEditClick && onEditClick(data);
  };

  const handleSelectionChanged = ({ api }) => {
    let rows;
    try {
      if (api) {
        rows = api.getSelectedRows();
      }
    } catch (error) {
      console.error('error :>> ', error);
      rows = [];
      let err = {
        title: 'Error',
        text: 'Failed to select Clients',
        icon: 'error',
        buttons: { cancel: 'Close' },
      };
      swal(err);
    } finally {
      setSelection(rows);
    }
  };

  const handleGridReady = ({ api, columnApi }) => {
    setGridApi(api);
    setGridColumnApi(columnApi);
  };

  const handlePayment = async ({Folio})=>{
    // console.log( 'Folio seleccionado: ', Folio );
    const res = await serviceDocumentosPago.find({
      query:{ref_Folio:Folio}
    });
    // console.log( 'resultado de la busqueda: ', res );
    setFolio( Folio );
    res.data.length > 0 ? setIsPaid( true ) : setIsPaid(false);
  }

  return (
    <TableContainer className="ag-theme-material" rowFilters={1}>
      <AgGridReact
        modules={AllModules}
        columnDefs={columnDefs}
        defaultColDef={defaultColDefs}
        rowSelection={'multiple'}
        suppressRowClickSelection={true}
        onSelectionChanged={handleSelectionChanged}
        rowData={data}
        pagination={true}
        paginationPageSize={100}
        onGridReady={handleGridReady}
        sortingOrder={['desc', 'asc', null]}
      />

      <ConfirmationModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirm}
        data={confirmationData}
        actionType={confirmationActionType}
        getData={getData}
        id={confirmationData?.id}
        idPOS={confirmationData?.idPOS}
        idSucursal={confirmationData?.idSucursal}
      />

      <StatusModal
        open={isModalStatusOpen}
        onClose={() => setIsModalStatusOpen(false)}
        onConfirm={handleConfirm}
        data={dataStatus}
        idInvoice={dataStatus?.id}
        idPOS={dataStatus?.idPOS}
        idSucursal={dataStatus?.idSucursal}
        idSocioNegocio={dataStatus?.idSocioNegocio}
      />

      <Modal
        onClose={ handleClose }
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button>Show Modal</Button>}
      >
       {
        isPaid ? 
        (
          <>
            <ModalHeader>Estatus del comprobante</ModalHeader>
            <ModalContent>
              <ModalDescription>
                <h3 style={ {color:'#4f9aa8'} } > Pagado </h3>
                <p> El documento con folio <strong> {folio} </strong> ha sido pagado </p>
              </ModalDescription>
            </ModalContent>
            <ModalActions>
              <Button compact secundary onClick={ handleClose }>
                Cerrar
              </Button>
            </ModalActions>
          </>
        )
        :
        (
          <>
             <ModalHeader>Motivo de Cancelación</ModalHeader>
              <ModalContent>
                <ModalDescription>
                  <FlexContainer>
                    <div style={{ flex: 1 }}>
                      <Header>Códigos de Cancelación</Header>
                    </div>
                    <div style={{ flex: 1, textAlign: 'left' }}>
                      <Header>¿Cuándo aplica?</Header>
                    </div>
                  </FlexContainer>
                  <FlexContainer>
                    <h5
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Selecciona un motivo de cancelación:
                    </h5>
                  </FlexContainer>
                  <FlexContainer>
                    <FlexCode>
                      <Radio
                        label="01: Comprobante emitido con errores con relación."
                        value="01"
                        disabled
                        checked={selectedOption === '01'}
                        onChange={handleOptionChange}
                        style={{ marginRight: 8 }}
                      />
                    </FlexCode>
                    <FlexDescription>
                      <p>
                        Cuando la factura contiene errores y es necesario sustituirla.
                      </p>
                    </FlexDescription>
                  </FlexContainer>
                  <FlexContainer>
                    <FlexCode>
                      <Radio
                        label="02: Comprobante emitido con errores sin relación"
                        value="02"
                        checked={selectedOption === '02'}
                        onChange={handleOptionChange}
                        style={{ marginRight: 8 }}
                      />
                    </FlexCode>
                    <FlexDescription>
                      <p>Cuando la factura contiene errores y no se sustituirá.</p>
                    </FlexDescription>
                  </FlexContainer>
                  <FlexContainer>
                    <FlexCode>
                      <Radio
                        label="03: No se llevó a cabo la operación"
                        value="03"
                        checked={selectedOption === '03'}
                        onChange={handleOptionChange}
                        style={{ marginRight: 8 }}
                      />
                    </FlexCode>
                    <FlexDescription>
                      <p>Cuando la operación no se concretó.</p>
                    </FlexDescription>
                  </FlexContainer>
                  <FlexContainer>
                    <FlexCode>
                      <Radio
                        label="04: Operación nominativa relacionada en la factura"
                        value="04"
                        disabled
                        checked={selectedOption === '04'}
                        onChange={handleOptionChange}
                        style={{ marginRight: 8 }}
                      />
                    </FlexCode>
                    <FlexDescription>
                      <p>
                        Cuando el receptor requiere una factura nominativa por una
                        venta que se incluyó en la factura global.
                      </p>
                    </FlexDescription>
                  </FlexContainer>
                </ModalDescription>
              </ModalContent>
              <ModalActions>
                <Button compact secundary onClick={ handleClose }>
                  Atrás
                </Button>
                <TableButtonCancelarTimbrado
                  id={dataCancel && dataCancel.id}
                  idPOS={dataCancel && dataCancel.idPOS}
                  idSucursal={dataCancel && dataCancel.idSucursal}
                  sMotivoCancelacion={selectedOption}
                  sUUID={''}
                  getData={getData}
                  onCancel={handleClose}
                  disabled={!selectedOption}
                />
              </ModalActions>
          </>
        )
       }
      </Modal>
    </TableContainer>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  onEditClick: PropTypes.func,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
};

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const FlexCode = styled.div`
  flex: 1;
  padding-top: 8px;
  display: flex;
`;

const FlexDescription = styled.div`
  flex: 1;
  padding-top: 8px;
  text-align: left;
`;

export default Table;
