const fixRole = (item) => String(item).replace(' ', '_').toUpperCase();

export const options = [
  'Admin',
  'Support',
  'Scan BIN',
  'General',
  'Packing',
  'Import-Export',
  'Automation Rules',
  'Transactions',
  'Bin Catalog',
  'Carta Porte',
  'Anexo 24',
  'Administrador Cliente',
].map((item, index) => ({
  key: index,
  text: item,
  value: fixRole(item),
}));

export const [
  admin,
  support,
  scanBin,
  general,
  packing,
  importExport,
  automationRules,
  transactions,
  binCatalog,
  cartaPorte,
  anexo24,
  administradorCliente,
] = options.map((item) => item.value);

const CheckRole = (roles, role) => roles.includes(role);
const validateRoles = (roles, validRoles) => {
  try {
    return (
      (roles.length &&
        Array.isArray(validRoles) &&
        roles.some(
          (role) => CheckRole([support, admin, ...validRoles], role) //Support y admin tiene acceso a todo
        ) &&
        true) ||
      false
    );
  } catch (err) {
    console.error('validateRoles', err);
  }

  return false;
};

const CheckIsAdmin = (roles) => CheckRole(roles, admin);
const CheckIsSupport = (roles) => CheckRole(roles, support);
const CheckIsScanBin = (roles) => CheckRole(roles, scanBin);
const CheckIsGeneral = (roles) => CheckRole(roles, general);

const allowAnexo24 = (roles) => validateRoles(roles, [anexo24]);
const allowAdministradorCliente = (roles) =>
  validateRoles(roles, [administradorCliente]);
const allowParcels = (roles) =>
  validateRoles(roles, [support, automationRules]);
const allowParcelsItems = (roles) => validateRoles(roles, [general]);
const allowOrders = (roles) => validateRoles(roles, [support, automationRules]);
const allowProductosTest = (roles) =>
  validateRoles(roles, [support, automationRules]);
const allowConsolidations = (roles) => validateRoles(roles, [general]);
const allowProducts = (roles) => validateRoles(roles, [general]);
const allowDailylogs = (roles) => validateRoles(roles, [scanBin, general]);
const allowUsers = (roles) => validateRoles(roles, []);
const allowTrips = (roles) => validateRoles(roles, [cartaPorte]);
const allowBillOfLadingMX = (roles) => validateRoles(roles, [cartaPorte]);
const allowShippers = (roles) => validateRoles(roles, []);
const allowPartners = (roles) => validateRoles(roles, []);
const allowPartnerships = (roles) => validateRoles(roles, []);
const allowCat_Colonia = (roles) => validateRoles(roles, []);
const allowChoferes = (roles) => validateRoles(roles, [cartaPorte]);
const allowComplementoPago = (roles) => validateRoles(roles, [cartaPorte]);
const allowReportesTimbrado = (roles) => validateRoles(roles, [cartaPorte]);
const allowEmisores = (roles) => validateRoles(roles, []);
const allowReceptores = (roles) => validateRoles(roles, [administradorCliente]);
const allowConceptos = (roles) => validateRoles(roles, [cartaPorte]);
const allowUbicaciones = (roles) => validateRoles(roles, [cartaPorte]);
const allowRemolques = (roles) => validateRoles(roles, [cartaPorte]);
const allowTrasnsportes = (roles) => validateRoles(roles, [cartaPorte]);
const allowOrganizaciones = (roles) => validateRoles(roles, []);
const allowCat_Pais = (roles) => validateRoles(roles, []);
const allowPacking = (roles) => validateRoles(roles, [packing]);
const allowImportExport = (roles) => validateRoles(roles, [importExport]);
const allowBajaShipmentsReport = (roles) => validateRoles(roles, []);
const allowAutomationRules = (roles) => validateRoles(roles, [automationRules]);
const allowClients = (roles) => validateRoles(roles, [admin, support]);
const allowTaxPacs = (roles) => validateRoles(roles, [admin, support]);
const allowSucursales = (roles) => validateRoles(roles, [admin, support]);
const allowCertificados = (roles) => validateRoles(roles, [admin, support]);
const allowTransactions = (roles) =>
  validateRoles(roles, [admin, automationRules]);
const allowBajaRevolveSavings = (roles) =>
  validateRoles(roles, [admin, general]);
const allowBinCatalog = (roles) =>
  validateRoles(roles, [admin, general, scanBin]);
export const getPermissions = (user) => {
  const roles = (user && user.roles) || [];

  return {
    allowAnexo24: allowAnexo24(roles),
    allowAdministradorCliente: allowAdministradorCliente(roles),
    allowParcels: allowParcels(roles),
    allowParcelsItems: allowParcelsItems(roles),
    allowOrders: allowOrders(roles),
    allowProductosTest: allowProductosTest(roles),
    allowConsolidations: allowConsolidations(roles),
    allowProducts: allowProducts(roles),
    allowDailylogs: allowDailylogs(roles),
    allowUsers: allowUsers(roles),
    allowTrips: allowTrips(roles),
    allowBillOfLadingMX: allowBillOfLadingMX(roles),
    allowShippers: allowShippers(roles),
    allowPartners: allowPartners(roles),
    allowPartnerships: allowPartnerships(roles),
    allowCat_Colonia: allowCat_Colonia(roles),
    allowComplementoPago: allowComplementoPago(roles),
    allowReportesTimbrado: allowReportesTimbrado(roles),
    allowChoferes: allowChoferes(roles),
    allowEmisores: allowEmisores(roles),
    allowReceptores: allowReceptores(roles),
    allowConceptos: allowConceptos(roles),
    allowUbicaciones: allowUbicaciones(roles),
    allowRemolques: allowRemolques(roles),
    allowTrasnsportes: allowTrasnsportes(roles),
    allowOrganizaciones: allowOrganizaciones(roles),
    allowCat_Pais: allowCat_Pais(roles),
    allowPacking: allowPacking(roles),
    allowImportExport: allowImportExport(roles),
    allowBajaShipmentsReport: allowBajaShipmentsReport(roles),
    allowAutomationRules: allowAutomationRules(roles),
    allowClients: allowClients(roles),
    allowTransactions: allowTransactions(roles),
    allowBinCatalog: allowBinCatalog(roles),
    allowBajaRevolveSavings: allowBajaRevolveSavings(roles),
    allowTaxPacs: allowTaxPacs(roles),
    allowSucursales: allowSucursales(roles),
    allowCertificados: allowCertificados(roles),

    isAdmin: CheckIsAdmin(roles),
    isSupport: CheckIsSupport(roles),
    isScanBin: CheckIsScanBin(roles),
    isGeneral: CheckIsGeneral(roles),
  };
};
export default null;
