import React, { forwardRef, useState } from 'react';
import { Form, Segment } from 'semantic-ui-react';
import DropdownSearchFetch from '../../../components/Formik/DropdownSearchFetch';
import DropdownSimpleFetch from '../../../components/Formik/DropdownSimpleFetch';
import FormGroupLocations from './FormGroupLocations';
import feathers from '../../../services/feathers';

const getErrorsAndTouchs = (touched, errors, index, name) => {
  return (
    touched.ubicaciones &&
    touched.ubicaciones[index] &&
    touched.ubicaciones[index][name] &&
    errors.ubicaciones &&
    errors.ubicaciones[index] &&
    errors.ubicaciones[index][name]
  );
};

const FormHeader = ({ formik }) => {
  let {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = formik;
  const serviceUsoCFDI = feathers.service('catalogo-uso-cfdi');
  const serviceFormaPago = feathers.service('catalogo-forma-pago');
  const serviceMetodoPago = feathers.service('catalogo-metodo-pago');

  return (
    <>
      <Segment>
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="RFC"
            label="RFC"
            name="RFC"
            value={values.RFC}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.RFC && { content: errors.RFC }}
          />
          <Form.Input
            placeholder="Nombre"
            label="Nombre"
            name="Nombre"
            value={values.Nombre}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.Nombre && { content: errors.Nombre }}
          />
          {/* <DropdownSimpleFetch
            fluid
            selection
            label="Regimen *"
            value={values.idRegimen}
            onChange={(e, { value }) => setFieldValue('idRegimen', value)}
            placeholder="Regimen"
            featherService="catalogo-regimen-fiscal"
            columName="Descripcion"
            columValue="c_RegimenFiscal"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_RegimenFiscal']} - ${value['Descripcion']}`,
                value: value['c_RegimenFiscal'],
              };
            }}
            error={
              errors.idRegimen &&
              touched.idRegimen && { content: errors.idRegimen }
            }
          /> */}
          <DropdownSearchFetch
            fluid
            selection
            label="Regimen *"
            value={values.idRegimen ? values.idRegimen.toString() : ''}
            onChange={(e, { value }) => setFieldValue('idRegimen', value)}
            placeholder="Regimen"
            featherService="catalogo-regimen-fiscal"
            columName="Descripcion"
            columValue="c_RegimenFiscal"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_RegimenFiscal']} - ${value['Descripcion']}`,
                value: value['c_RegimenFiscal'],
              };
            }}
            error={
              errors.idRegimen && {
                content: errors.idRegimen,
              }
            }
          />
          <Form.Input
            placeholder="Registro Tributario Exterior"
            label="Registro Tributario Exterior"
            name="RegistroTributarioExterior"
            value={values.RegistroTributarioExterior}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.RegistroTributarioExterior && {
                content: errors.RegistroTributarioExterior,
              }
            }
          />
        </Form.Group>
        <Form.Group widths={'equal'}>
          <DropdownSearchFetch
            fluid
            selection
            label="Uso Comprobante *"
            value={values.idUsoCFDIString}
            onChange={async (e, { value }) => {
              let result = await serviceUsoCFDI.find({
                query: {
                  c_UsoCFDI: value,
                },
              });
              if (result && result.data.length > 0) {
                setFieldValue('idUsoCFDIString', result.data[0].c_UsoCFDI);
                setFieldValue('idUsoCFDI', result.data[0].CCFDIID);
              } else {
                setFieldValue('idUsoCFDIString', '');
                setFieldValue('idUsoCFDI', '');
              }
            }}
            placeholder="Uso Comprobante"
            featherService="catalogo-uso-cfdi"
            columName="Descripcion"
            columValue="c_UsoCFDI"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_UsoCFDI']} - ${value['Descripcion']}`,
                value: value['c_UsoCFDI'],
              };
            }}
            error={
              errors.idUsoCFDI &&
              touched.idUsoCFDI && { content: errors.idUsoCFDI }
            }
          />
          <DropdownSearchFetch
            fluid
            selection
            label="Forma de Pago *"
            value={values.idFormaPagoString}
            onChange={async (e, { value }) => {
              let result = await serviceFormaPago.find({
                query: {
                  c_FormaPago: value,
                },
              });
              if (result && result.data.length > 0) {
                setFieldValue('idFormaPagoString', result.data[0].c_FormaPago);
                setFieldValue('idFormaPago', result.data[0].CFpagoID);
              } else {
                setFieldValue('idFormaPagoString', '');
                setFieldValue('idFormaPago', '');
              }
            }}
            placeholder="Forma de Pago"
            featherService="catalogo-forma-pago"
            columName="Descripcion"
            columValue="c_FormaPago"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_FormaPago']} - ${value['Descripcion']}`,
                value: value['c_FormaPago'],
              };
            }}
            error={
              errors.idFormaPago &&
              touched.idFormaPago && { content: errors.idFormaPago }
            }
          />
          <DropdownSearchFetch
            fluid
            selection
            label="Método de Pago *"
            value={values.idMetodoPagoString}
            onChange={async (e, { value }) => {
              let result = await serviceMetodoPago.find({
                query: {
                  c_MetodoPago: value,
                },
              });
              if (result && result.data.length > 0) {
                setFieldValue(
                  'idMetodoPagoString',
                  result.data[0].c_MetodoPago
                );
                setFieldValue('idMetodoPago', result.data[0].CMpagoID);
              } else {
                setFieldValue('idMetodoPagoString', '');
                setFieldValue('idMetodoPago', '');
              }
            }}
            placeholder="Método de Pago"
            featherService="catalogo-metodo-pago"
            columName="Descripcion"
            columValue="c_MetodoPago"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_MetodoPago']} - ${value['Descripcion']}`,
                value: value['c_MetodoPago'],
              };
            }}
            disabled={values.idTipoComprobante === 'T' ? true : false}
            error={
              errors.idMetodoPago &&
              touched.idMetodoPago && { content: errors.idMetodoPago }
            }
          />
        </Form.Group>
        <Form.Group widths={'equal'}>
          <Form.Input
            placeholder="ExentoIVA"
            label="ExentoIVA"
            name="ExentoIVA"
            type="checkbox"
            value={values.ExentoIVA}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.ExentoIVA && { content: errors.ExentoIVA }}
          />
          <Form.Input
            placeholder="NoRetieneIVA"
            label="NoRetieneIVA"
            name="NoRetieneIVA"
            type="checkbox"
            value={values.NoRetieneIVA}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.NoRetieneIVA && { content: errors.NoRetieneIVA }}
          />
          <Form.Input
            placeholder="NoRetieneISR"
            label="NoRetieneISR"
            name="NoRetieneISR"
            type="checkbox"
            value={values.NoRetieneISR}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.NoRetieneISR && { content: errors.NoRetieneISR }}
          />
          <Form.Input
            placeholder="NoRetieneIEPS"
            label="NoRetieneIEPS"
            name="NoRetieneIEPS"
            value={values.NoRetieneIEPS}
            type="checkbox"
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.NoRetieneIEPS && { content: errors.NoRetieneIEPS }}
          />
        </Form.Group>
        <FormGroupLocations
          formik={formik}
          values={{
            Pais: values.Pais,
            Estado: values.Estado,
            Municipio: values.Municipio,
            Localidad: values.Localidad,
            CodigoPostal: values.CodPos,
            Colonia: values.Colonia,
            Calle: values.Calle,
            NumExterior: values.NumExterior,
            NumInterior: values.NumInterior,
            Referencia: values.Referencia,
          }}
          names={{
            Pais: 'Pais',
            Estado: 'Estado',
            Municipio: 'Municipio',
            Localidad: 'Localidad',
            CodigoPostal: 'CodPos',
            Colonia: 'Colonia',
            Calle: 'Calle',
            NumExterior: 'NumExterior',
            NumInterior: 'NumInterior',
            Referencia: 'Referencia',
          }}
          errors={{
            Pais: errors.Pais,
            Estado: errors.Estado,
            Municipio: errors.Municipio,
            Localidad: errors.Localidad,
            CodigoPostal: errors.CodPos,
            Colonia: errors.Colonia,
            Calle: errors.Calle,
            NumExterior: errors.NumExterior,
            NumInterior: errors.NumInterior,
            Referencia: errors.Referencia,
          }}
        />
        <Form.Group widths={'equal'}>
          <DropdownSearchFetch
            fluid
            selection
            label="Nombre Banco"
            value={values.NomBancoOrdExt}
            onChange={(e, { value }) => {
              setFieldValue('NomBancoOrdExt', value);
            }}
            placeholder="Nombre Banco"
            featherService="catalogo-banco"
            columName="Descripcion"
            columValue="c_Banco"
            mapValues={(value) => {
              return {
                key: Math.random(),
                text: `${value['c_Banco']} - ${value['Descripcion']}`,
                value: value['c_Banco'],
              };
            }}
          />
          <Form.Input
            placeholder="Número Cuenta / Cheque"
            label="Número Cuenta / Cheque"
            name="CtaOrdenante"
            value={values.CtaOrdenante}
            onChange={(e, { value }) => {
              value = value.trim();
              setFieldValue('CtaOrdenante', value);
            }}
          />
        </Form.Group>
      </Segment>
    </>
  );
};

export default forwardRef((props, ref) => (
  <FormHeader {...props} innerRef={ref} />
));
