import React, { forwardRef, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import FormHeader from './FormHeader';
import FormTransportista from './FormTransportista';
import FormFactura from './FormFactura';
import GridDetails from './GridDetails';
import FormRegistroFechas from './FormRegistroFechas';

const FormDocument = ({ formik }) => {
  const [isConsolidado, setIsConsolidado] = useState(false);
  return (
    <WrapContent>
      <FormHeader formik={formik} setIsConsolidado={setIsConsolidado} />
      {/* Upload to wrapper */}
      <Grid>
        <Grid.Row>
          <Grid.Column width={10}>
            <FormTransportista formik={formik} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <FormFactura formik={formik} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            {!isConsolidado ? <FormRegistroFechas formik={formik} /> : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <GridDetails formik={formik} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </WrapContent>
  );
};

const WrapContent = styled.div``;

export default forwardRef((props, ref) => (
  <FormDocument {...props} innerRef={ref} />
));
